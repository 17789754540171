import { generatePath, Link, useNavigate } from 'react-router-dom';
import { Checkbox } from 'src/common/components/forms/Checkbox';
import { Button } from 'src/common/interactions/Button';

import { AppRoutes } from 'src/routes/app/routes';
import { useContext } from 'react';
import { ExchangeUserContext } from 'src/common/context/ExchangeUserContext';

export const AgreementCheckBox = ({
  userAgreed,
  setUserAgreed,
}: {
  userAgreed: boolean;
  setUserAgreed: (boolean) => void;
}) => {
  const { createExchangeUser } = useContext(ExchangeUserContext);
  const navigate = useNavigate();

  return (
    <div
      className="font-poppins text-[14px] leading-[21px] font-normal  md:max-w-sm mt-6"
      data-testid="agreement-checkbox-div"
    >
      <Checkbox checked={userAgreed} onChange={() => setUserAgreed(!userAgreed)} />
      <span>I agree to the </span>
      <Link className=" text-[#404040] no-underline" to={generatePath(`/${AppRoutes.EXCHANGE_TERMS_AND_CONDITIONS}`)}>
        terms & privacy policy
      </Link>
      <Button.Info
        className="w-full mb-4 mt-4"
        disabled={!userAgreed}
        onClick={() => {
          createExchangeUser()?.then(() => navigate(`/${AppRoutes.EXCHANGE_ASK_ORDER}`));
        }}
      >
        <span className="font-poppins text-[13px] leading-[19.5px] font-normal">CONTINUE</span>
      </Button.Info>
    </div>
  );
};
