import { AxiosResponse } from 'axios';

import { SortOptions } from 'src/routes/app/search-results/SearchResultsContainer';
import { ShardsDownResponseType } from '../interfaces/Shards';
import { checkFor422AndReturnData, get, post, put } from './client';
import {
  Category,
  CheckCartBody,
  CheckCartRes,
  CheckDiscountCodeBody,
  CheckDiscountCodeRes,
  DevvPayCreateChargeParams,
  DevvPayCreateChargeRes,
  DevvPayTokenListRes,
  ExchangeErrorRes,
  GetCartItemsQuery,
  GetCartItemsResponse,
  GetCartUserBalancesResponseType,
  GetCartUserBalancesType,
  NFTItem,
  NftMintIdRes,
  OrderInformation,
  PaymentEnabledFetchParams,
  PaymentProcessors,
  PropertiesGroup,
  PurchaseOrderBody,
  PurchaseOrderRes,
  Thumbnail,
  Transaction,
  TransactionUnlockItemRes,
  UserRoles,
} from '../interfaces';
import { AnnouncementsResponseType, RefundResponseType } from '../interfaces/general';
import { PaymentEnabledResponse } from './types/payments';
import { PaymentCurrenciesResponse } from '../interfaces/payment';

export type LoginParams = {
  user: string;
  pass: string;
};

export type LoginRes = {
  uuid: string;
  pub: string;
  message: string;
  code: number;
  target: string;
  emailVerified: boolean;
  roles: UserRoles[];
  username: string;
  email: string;
};

export type LogoutParams = {
  uuid: string;
};

export type LogoutRes = {
  act: string;
  protocolVersion: string;
  result: string;
  testnet: boolean;
};

export type KeywordListParams = {
  query: string;
};

export type NftItemFetchParams = {
  nftItemUuid: string;
};

// TODO: Better document salesPrice, details
export type NftItemListParams = {
  uuids: string;
};

export type SearchQueryParams = {
  query?: string;
  categories?: string[];
  limit: number;
  offset: number;
  sort?: SortOptions;
  salePrice?: string;
  details?: string;
  collapseBy?: CollapseBy;
  name: string;
  currencyPreferences?: string;
  sellerName?: string;
};

export enum CollapseBy {
  MINT_ID = 'mint_id',
  NAME = 'name',
}

export type SearchQueryResponse = {
  totalCount: number;
  nftItems: SearchedNftItem[];
  schemasOfTypesIncluded: PropertiesGroup[];
  categoriesUuidIncluded: string[];
  suggestedSellers: string[];
};

export type SearchedNftItem = {
  mintId: string;
  name: string;
  artist: string;
  imageUrl: string;
  salePrice: number;
  unitPrice?: number;
  release?: string;
  power?: string;
  ability?: string;
  itemDetails: Record<string, string>;
  fromPrice: number;
  toPrice: number;
  thumbnails: Thumbnail[];
  mintIds: string[];
  litPetsTopRank?: string;
  nftType: string;
};

export interface NftListItem extends Omit<NFTItem, 'itemDetails'> {
  release: string | null;
  power: string | null;
  ability: string | null;
  characteristic: string | null;
  characteristicvalue: string | null;
  forsale?: boolean;
  itemDetails: { [key: string]: string };
}

export type NftListItemWithSchemaProps = NftListItem & {
  schemaProps?: PropertiesGroup;
};

export type NftListItemWithFromPrice = NftListItem & {
  fromPrice?: number | null;
};
export interface SimilarNft
  extends Pick<
    NFTItem,
    'uuid' | 'mintId' | 'name' | 'artist' | 'imageUrl' | 'salePrice' | 'unitPrice' | 'thumbnails' | 'nftType'
  > {
  fromPrice?: number;
  release?: string;
  power?: string;
  ability?: string;
  itemDetails: Record<string, string>;
  litPetsTopRank?: string;
}

export type NftItemListSimilarRes = { nftItems: SimilarNft[] };

export type RegistrationParams = {
  fullName: string;
  user: string;
  pass: string;
  email: string;
  emailHash: string;
};

export type RegistrationRes = {
  code: number;
  message: string;
};

export type TransactionFetchParams = {
  transactionUuid: string;
};

export type TransactionCustomerData = TransactionFetchParams & {
  buyerName: string;
  buyerAddress1: string;
  buyerAddress2: string;
  buyerCity: string;
  buyerState: string;
  buyerPostalCode: string;
  buyerCountry: string;
};

export type TransactionListParams = {
  username: string;
  startDate: string;
  endDate: string;
  processorType: PaymentProcessors | null;
  limit: number;
};

export type TransactionListRes = {
  totalCount: number;
  transactions: Transaction[];
};

export type CategoryListParams = {
  name?: string;
  hasItems?: boolean;
  visible?: boolean;
};

export type CategoryListRes = {
  totalCount: number;
  categories: Category[];
};

export type NftMintIdParams = {
  nftMintId: string;
  limit?: number;
  offset?: number;
  filterIsLocked?: boolean;
};

export type PaymentFetchParams = {
  paymentId: string;
};

export type TransactionCircleData = {
  paymentId: string;
};

export type TransactionUnlockItemData = {
  paymentId: string;
};

type FetchAffiliateLookupResponse = {
  orderId: string;
  currencyType: string;
  amount: number;
};

type RefundRequest = {
  returnId: string;
};

export const Api = {
  admin: {
    transaction: {
      list: (params: TransactionListParams) => {
        return get<{ data: TransactionListRes }>('/api/admin/transactions', { params });
      },
    },
  },
  keyword: {
    list: (params: KeywordListParams) => {
      return get<{ data: string[] }>('/api/keywords', {
        params,
      });
    },
  },
  nftItem: {
    fetchMintIdItems: (params: NftMintIdParams) => {
      const { nftMintId, ...rest } = params;
      return get<{ data: NftMintIdRes }>(`/api/nfts/mint-id/${nftMintId}`, { params: rest });
    },
    list: (params: NftItemListParams) => {
      return get<{ data: NFTItem[] }>('/api/nfts', {
        params,
      });
    },
    fetchSimilarProducts: (params: NftItemFetchParams) => {
      const { nftItemUuid } = params;
      return get<{ data: NftItemListSimilarRes }>(`/api/nfts/similar-products/${nftItemUuid}`);
    },
    search: (params: SearchQueryParams) => {
      return get<SearchQueryResponse>('/api/nfts/search', {
        params,
      });
    },
  },
  proxy: {
    login: (params: LoginParams) => {
      return post<LoginRes>(`/api/proxy/login`, params);
    },
    logout: (params: LogoutParams) => {
      return post<LogoutRes>(`/api/proxy/logout`, params);
    },
    register: (params: RegistrationParams) => {
      return post<RegistrationRes>(`/api/proxy/register`, params);
    },
  },
  transaction: {
    update: (params: TransactionCustomerData) => {
      const { transactionUuid, ...rest } = params;
      return put<{ data: Transaction }>(`/api/transactions/${transactionUuid}`, rest);
    },
    fetch: (params: TransactionFetchParams) => {
      const { transactionUuid } = params;

      return get<{ data: Transaction }>(`/api/transactions/${transactionUuid}`);
    },
  },
  coinbaseCharge: {
    create: (orderInformation: OrderInformation) => {
      return post<{ data: string }>('/api/payments/create-cb-charge', orderInformation);
    },
  },
  category: {
    list: (params: CategoryListParams) => {
      return get<{ data: CategoryListRes }>('/api/categories', {
        params,
      });
    },
  },
  cart: {
    checkCart: (nftItems: CheckCartBody) => {
      return post<{ data: CheckCartRes }>('/api/cart/check-cart', nftItems);
    },
    getItems: (params: GetCartItemsQuery) => {
      return get<{ data: GetCartItemsResponse }>('/api/cart/items', {
        params,
      });
    },
    getUserBalances: async (): Promise<GetCartUserBalancesType> => {
      const result = await get<GetCartUserBalancesResponseType>('/api/cart/devvpay-balances');
      return result.data;
    },
  },
  discountCode: {
    checkDiscountCode: (discountCodeData: CheckDiscountCodeBody) => {
      return post<{ data: CheckDiscountCodeRes }>('/api/discount-code/check-discount-code', discountCodeData);
    },
  },
  payments: {
    fetchAffiliateLookup: (): Promise<AxiosResponse<FetchAffiliateLookupResponse>> => {
      return get('/api/payments/affiliate-lookup');
    },
    paymentEnabled: (params: PaymentEnabledFetchParams) => {
      return get<{ data: PaymentEnabledResponse }>('/api/payments/types-enabled', {
        params,
      })
        .then((data) => data.data)
        .catch((error) => checkFor422AndReturnData<ExchangeErrorRes>(error));
    },
    devvPay: {
      createCharge: (devvPayCreateChargeParams: DevvPayCreateChargeParams) => {
        return post<{ data: DevvPayCreateChargeRes }>(
          '/api/payments/devv-pay/create-charge',
          devvPayCreateChargeParams,
        );
      },
      tokens: () => {
        return get<{ data: DevvPayTokenListRes }>('/api/payments/devv-pay/tokens');
      },
    },
    unlockItem: (params: TransactionUnlockItemData) => {
      return put<{ data: TransactionUnlockItemRes }>(`/api/payments/unlock`, params);
    },
    devvPo: {
      createOrder: (purchaseOrderBody: PurchaseOrderBody) => {
        return post<{ data: PurchaseOrderRes }>('/api/purchase-order/create', purchaseOrderBody);
      },
    },
    currencies: (query?: { getExchangeRate?: boolean }): Promise<PaymentCurrenciesResponse> => {
      return get<PaymentCurrenciesResponse>('/api/payments/currencies', {
        params: query,
      });
    },
  },
  refund: {
    getRefund: (params: RefundRequest): Promise<RefundResponseType> => {
      const { returnId } = params;
      return get<RefundResponseType>(`/api/refund/${returnId}`);
    },
    postRefund: (params: RefundRequest) => {
      const { returnId } = params;
      return post(`/api/refund/${returnId}`, {});
    },
  },
  shards: {
    getShardsDown: (): Promise<ShardsDownResponseType> => {
      return get<ShardsDownResponseType>(`/api/shard/down`);
    },
  },
  announcements: {
    getAnnouncements: (): Promise<AxiosResponse<AnnouncementsResponseType>> => {
      return get<AxiosResponse<AnnouncementsResponseType>>(`/api/announcement`);
    },
  },
};
