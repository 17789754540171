/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SVGProps } from 'react';

const HamburgerMenuIcon: React.FC<SVGProps<any>> = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="2" rx="1" fill="#212D3B" />
      <rect y="6" width="16" height="2" rx="1" fill="#212D3B" />
      <rect y="12" width="16" height="2" rx="1" fill="#212D3B" />
    </svg>
  );
};

export default HamburgerMenuIcon;
