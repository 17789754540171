import { useContext, useEffect } from 'react';

import splitSdkConfig, { factoryAttributes } from 'src/config/splitio-sdk-config';
import { UserContext } from 'src/common/context/UserContext';
import { getUserName, setUserName } from 'src/common/util/split-io';

/**
 * A hook that returns the split SDK config and factory attributes.
 * @returns {object} - The split SDK config and factory attributes.
 */
const useSplit = () => {
  const { user } = useContext(UserContext);

  splitSdkConfig.core.key = user?.username || getUserName();

  useEffect(() => {
    setUserName(user?.username || '');
  }, [user]);

  return { splitSdkConfig, factoryAttributes };
};

export default useSplit;
