import axios, { AxiosRequestConfig } from 'axios';
import { Env } from 'src/common/env';

const exchangeAxios = axios.create({
  baseURL: Env.exchangeHost,
});

let sessionUuid = '';

export function setSessionUuid(_sessionUuid: string) {
  sessionUuid = _sessionUuid;
}

exchangeAxios.interceptors.request.use(function (request) {
  if (sessionUuid.length > 0) {
    request.headers = {
      ...request.headers,
      'Devvio-Session-Id': sessionUuid,
    };
  }
  return request;
});

export function get<T>(url: string, config?: AxiosRequestConfig) {
  return exchangeAxios.get<T>(url, config).then((response) => response.data);
}

export function put<T>(url: string, data: any, config?: AxiosRequestConfig) {
  return exchangeAxios.put<T>(url, data, config).then((response) => response.data);
}

export function post<T>(url: string, data: any, config?: AxiosRequestConfig) {
  return exchangeAxios.post<T>(url, data, config).then((response) => response.data);
}

export function patch<T>(url: string, data: any, config?: AxiosRequestConfig) {
  return exchangeAxios
    .patch<T>(url, data, config)
    .then((response) => {
      return response.data;
    })
    .catch((e) => console.error(e));
}

export function cancelPatch<T>(url: string, data: any, config?: AxiosRequestConfig) {
  return exchangeAxios.patch<T>(url, data, config);
}

export function destroy<T>(url: string, config?: AxiosRequestConfig) {
  return exchangeAxios.delete<T>(url, config).then((response) => response.data);
}

export function returnOnlyData<T>(data: unknown) {
  if (data && typeof data === 'object' && 'data' in data) {
    return (data as { data: T }).data as T;
  }

  return data as T;
}

export function checkFor422AndReturnData<T>(error: unknown) {
  if (axios.isAxiosError(error)) {
    if (error.response && error.response.status === 422) {
      if ('data' in (error.response.data as Record<string, unknown>)) {
        return (error.response.data as Record<string, unknown>).data as T;
      }

      return error.response.data;
    }
  }

  throw error;
}
