import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faSliders, faXmark } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

import { NoResults } from 'src/routes/app/search-results/NoResults';
import { Api, CollapseBy, SearchedNftItem, SearchQueryParams } from 'src/api';
import { formatNumber } from 'src/common/util';
import { Show } from 'src/common/layout';
import { Select } from 'src/common/components/forms/Select';
import { Loader, ResultCard } from 'src/common/components';
import { PropertiesGroup } from 'src/interfaces';
import { useQuery } from 'react-query';
import useWindowSize from 'src/common/hooks/useWindowSize';
import { FilterValue, SelectFilterFormOption } from './advanced-filter';
import styles from './SearchResultsContainer.module.scss';
import { AdvancedFilterPanel } from './advanced-filter/AdvancedFilterPanel';
import { CategoriesContext } from '../../../common/context/CategoriesContext';
import ScrollToTop from '../../../common/components/scroll-top/ScrollTop';
import SellerPillButton from '../../../common/components/buttons/SellerPillButton';

export enum SortOptions {
  RELEVANCE = 'revelance',
  MOST_RECENT = 'recent-desc',
  LEAST_RECENT = 'recent-asc',
  PRICE_HIGH = 'price-desc',
  PRICE_LOW = 'price-asc',
  UNIT_PRICE_HIGH = 'unit-price-desc',
  UNIT_PRICE_LOW = 'unit-price-asc',
}

interface NftListState {
  // a list of all the visible search results on the page
  nftItems: SearchedNftItem[];
  // the total amount possible to load from the server with the current query
  // a number if a response has been actually returned, otherwise undefined
  totalCount?: number;
  categoriesUuidIncluded: string[];
}

const OFFSET_INTERVAL = 60;

const SkeletonGrid = React.memo(() => (
  <>
    {new Array(12).fill(false).map((_, index) => {
      return (
        <div key={index} className="animate-pulse h-96 flex flex-col">
          <div className="bg-white rounded flex-grow" />
        </div>
      );
    })}
  </>
));

const filterSearchParamsAndConvert = (searchParamsObject: URLSearchParams, excludeFromObject: object): FilterValue => {
  const newObject: FilterValue = {};
  const filterExcludedParams = ['utm_', 'ref', 'tap_a', 'seller'];

  searchParamsObject.forEach((value, key) => {
    const isParamExcluded = filterExcludedParams.some((excludedParam) => key.toLowerCase().includes(excludedParam));
    if (!Object.prototype.hasOwnProperty.call(excludeFromObject, key) && !isParamExcluded) {
      try {
        newObject[key] = JSON.parse(value);
      } catch (e) {
        newObject[key] = value;
      }
    }
  });
  return newObject;
};

const defaultFiltersSchema: PropertiesGroup = {
  categories: {
    description: 'Categories',
    is_marketplace_filterable: true,
  },
  salePrice: {
    type: 'integer',
    description: 'Sale Price',
    is_marketplace_filterable: true,
  },
  currencyPreferences: {
    type: 'string',
    enum: [],
    is_marketplace_filterable: true,
  },
};

const searchIncludes = ['name'];

export const SearchResultsContainer = React.memo(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const windowSize = useWindowSize();

  // refs
  const pageBottomRef = useRef<HTMLDivElement>(null);
  const itemsRefs = useRef([]);
  const loadFromZero = useRef(true);
  const firstLoad = useRef(true);

  // states
  const [isMobile, setIsMobile] = useState(false);
  const [isFiltersPanelVisible, setIsFiltersPanelVisible] = useState(true);
  const [{ nftItems, totalCount, categoriesUuidIncluded }, setNftListState] = useState<NftListState>({
    nftItems: [],
    totalCount: undefined,
    categoriesUuidIncluded: [],
  });
  const [filtersSchema, setFiltersSchema] = useState<PropertiesGroup>(defaultFiltersSchema);

  const { getCategoriesByUuids } = useContext(CategoriesContext);

  const handleSellerClick = (sellerName: string) => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set('seller', sellerName);
    newSearchParams.set('searchOnlySellerItems', 'true');

    setSearchParams(newSearchParams, { replace: true });
  };

  const updateParamsState = (browserParams: URLSearchParams) => {
    return () => {
      const offsetParam = parseInt(browserParams.get('offset') || '0', 10) || 0;
      const limit = loadFromZero.current ? OFFSET_INTERVAL + offsetParam : OFFSET_INTERVAL;
      const offset = loadFromZero.current ? 0 : offsetParam;
      const searchOnlySellerItems = browserParams.get('searchOnlySellerItems') === 'true';

      const params: SearchQueryParams = {
        query: browserParams.get('query') || '',
        categories: browserParams.getAll('categories') ?? [],
        offset,
        salePrice: browserParams.get('salePrice') || '',
        sort: (browserParams.get('sort') as SortOptions) || SortOptions.RELEVANCE,
        limit,
        collapseBy: (browserParams.get('collapseBy') as CollapseBy) || CollapseBy.NAME,
        name: browserParams.get('name') || '',
        currencyPreferences: browserParams.get('currencyPreferences') || '',
        sellerName: searchOnlySellerItems ? browserParams.get('seller') || undefined : undefined,
      };

      const details = filterSearchParamsAndConvert(browserParams, params);

      if (details.characteristics && typeof details.characteristics === 'object' && details.characteristics.attribute) {
        const { attribute, ...minMax } = details.characteristics;
        if (Object.keys(minMax).length > 0) {
          details[attribute.toLowerCase()] = minMax;
          delete details.characteristics;
        }
      }

      if (Object.keys(details).length > 0) {
        params.details = JSON.stringify(details);
      }

      if (firstLoad.current && (Object.keys(details).length > 0 || params.salePrice)) {
        setIsFiltersPanelVisible(true);
      }

      return params;
    };
  };

  const [paramsState, setParamsState] = useState<SearchQueryParams>(updateParamsState(searchParams));

  const { isLoading: nftSearchIsLoading, data: nftSearchResponseData } = useQuery(
    ['nftSearch', paramsState],
    () => {
      return Api.nftItem.search(paramsState);
    },
    {
      cacheTime: 5000,
      refetchOnWindowFocus: false,
    },
  );

  const { data: currenciesReponseData } = useQuery(['paymentCurrencies'], () => Api.payments.currencies());

  // Set mobile when change size
  useEffect(() => {
    if (windowSize?.width && windowSize.width < 640) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize]);

  // hide filter panel on load page
  useEffect(() => {
    if (isMobile) {
      setIsFiltersPanelVisible(false);
    }
  }, [isMobile]);

  // Update params state based on window query params
  useEffect(() => {
    if (!firstLoad.current) {
      setParamsState(updateParamsState(searchParams));
    }
  }, [searchParams]);

  // Update states with API response
  useEffect(() => {
    if (nftSearchResponseData) {
      const {
        nftItems: nftItemsFromData,
        totalCount: totalCountFromData,
        schemasOfTypesIncluded,
        categoriesUuidIncluded: categoriesUuidIncludedFromData,
      } = nftSearchResponseData;

      setNftListState((prevState) => {
        const newNftItems = loadFromZero.current ? nftItemsFromData : prevState.nftItems.concat(nftItemsFromData);
        if (schemasOfTypesIncluded.length > 0) {
          const schemasHashMap = {};
          schemasOfTypesIncluded.forEach((schema) => {
            if (schema) {
              Object.entries(schema).forEach(([propertyKey, value]) => {
                schemasHashMap[propertyKey] = value;
              });
            }
          });

          setFiltersSchema((prevFilterSchemaData) => {
            const excludeFromDelete = Object.keys(defaultFiltersSchema);
            Object.keys(prevFilterSchemaData).forEach((filterKey) => {
              if (!excludeFromDelete.includes(filterKey)) {
                Reflect.deleteProperty(prevFilterSchemaData, filterKey);
              }
            });

            return {
              ...prevFilterSchemaData,
              ...schemasHashMap,
            };
          });
        }

        return {
          nftItems: newNftItems,
          totalCount: totalCountFromData,
          categoriesUuidIncluded: categoriesUuidIncludedFromData,
        };
      });
    }
  }, [nftSearchResponseData]);

  // Update states when get category response
  useEffect(() => {
    const categoryFilterSchema: PropertiesGroup = {
      categories: {
        description: 'Categories',
        is_marketplace_filterable: true,
        options: getCategoriesByUuids(categoriesUuidIncluded),
      },
    };

    setFiltersSchema((prevState) => {
      const prevStateWithoutCategories = { ...prevState };
      Reflect.deleteProperty(prevStateWithoutCategories, 'categories');
      return {
        ...categoryFilterSchema,
        ...prevStateWithoutCategories,
      };
    });
  }, [getCategoriesByUuids, categoriesUuidIncluded]);

  // Set to load from zero if items change
  useEffect(() => {
    loadFromZero.current = true;
    if (!firstLoad.current) {
      const previousItem = localStorage.getItem('currentRef');
      if (previousItem && itemsRefs && Object.keys(itemsRefs.current).length > 0) {
        itemsRefs.current[previousItem]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        localStorage.removeItem('currentRef');
      }
    }
  }, [nftItems]);

  // Disable first load
  useEffect(() => {
    firstLoad.current = false;
  });

  useEffect(() => {
    if (currenciesReponseData) {
      const currencies = currenciesReponseData.currencies || [];

      const currencyOptions: SelectFilterFormOption[] = currencies.map((currency) => ({
        name: currency.name,
        value: currency.id,
      }));

      // currencyOptions.push();

      setFiltersSchema((prevState) => ({
        ...prevState,
        currencyPreferences: {
          type: 'string',
          options: currencyOptions,
          is_marketplace_filterable: true,
        },
      }));
    }
  }, [currenciesReponseData]);

  const handleUpdateSort = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newParams = new URLSearchParams(searchParams);
    if (event.target.value) {
      newParams.set('sort', event.target.value);
    } else {
      newParams.delete('sort');
    }

    setSearchParams(newParams, { replace: true });
  };

  const handleLoadMore = () => {
    const newParams = new URLSearchParams(searchParams);
    const newOffset = parseInt(searchParams.get('offset') || '0', 10) + OFFSET_INTERVAL;
    if (newOffset === 0) {
      newParams.delete('offset');
    } else {
      newParams.set('offset', `${newOffset}`);
    }
    loadFromZero.current = false;
    setSearchParams(newParams, { replace: true });
  };

  const isLoading = nftSearchIsLoading;
  const hasMore = totalCount !== undefined && totalCount > nftItems.length;
  const showEmpty = !isLoading && totalCount === 0;
  const showGrid = !showEmpty;

  return (
    <div className="flex flex-col flex-grow">
      <ScrollToTop atMount searchChanged searchIncludes={searchIncludes} />
      <div className="flex sm:grid sm:grid-cols-3 gap-x-2 sm:gap-x-1 items-center sm:justify-between pb-4 px-4">
        <div className="flex-auto  w-80 sm:w-full">
          <button
            className={`rounded-lg px-3 py-2 mr-1 bg-gray-500 text-white ${
              !isMobile && isFiltersPanelVisible && 'ml-[14rem]'
            }`}
            type="button"
            onClick={() => setIsFiltersPanelVisible((prevState) => !prevState)}
          >
            <div className="w-max">
              {isFiltersPanelVisible ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faSliders} />}
              <span className="ml-2 hidden sm:inline">Filters</span>
            </div>
          </button>
        </div>

        <div
          className={`md:text-center font-medium whitespace-nowrap flex-grow sm:flex-none w-20 sm:ml-28 ${
            isFiltersPanelVisible && 'text-right'
          }`}
        >
          {nftItems.length > 0 && (
            <>
              {formatNumber(nftItems.length)} Result{totalCount !== 1 ? 's' : ''}
            </>
          )}
        </div>

        <div className="sm:w-full">
          <Select
            className="h-10 text-sm"
            aria-label="Sort Options"
            value={paramsState.sort}
            onChange={handleUpdateSort}
          >
            <option value={SortOptions.RELEVANCE}>Relevance</option>
            <option value={SortOptions.MOST_RECENT}>Time Added: Most Recent</option>
            <option value={SortOptions.LEAST_RECENT}>Time Added: Oldest</option>
            <option value={SortOptions.PRICE_HIGH}>Price: High to Low</option>
            <option value={SortOptions.PRICE_LOW}>Price: Low to High</option>
            <option value={SortOptions.UNIT_PRICE_HIGH}>Unit Price: High to Low</option>
            <option value={SortOptions.UNIT_PRICE_LOW}>Unit Price: Low to High</option>
          </Select>
        </div>
      </div>

      <div className={cx(styles.gridAndFilter, isFiltersPanelVisible ? styles.filtersVisible : styles.filtersHidden)}>
        {nftSearchResponseData?.suggestedSellers && nftSearchResponseData.suggestedSellers.length > 0 && (
          <div className="suggested-sellers-container mb-6 px-4">
            <h3>Suggested Sellers</h3>
            <div className="flex flex-wrap gap-2">
              {nftSearchResponseData.suggestedSellers.map((seller) => (
                <SellerPillButton key={seller} sellerName={seller} onClick={() => handleSellerClick(seller)} />
              ))}
            </div>
          </div>
        )}
        <div
          className={cx(
            styles.filterPanelContainer,
            isFiltersPanelVisible ? styles.filtersVisible : styles.filtersHidden,
          )}
        >
          <AdvancedFilterPanel filters={filtersSchema} />
        </div>

        <Show show={showEmpty}>
          <div className="px-4 flex flex-col flex-grow items-center w-full">
            <NoResults />
          </div>
        </Show>

        <Show show={showGrid}>
          <div className="px-4">
            <div
              className={cx(styles.resultsGrid, isFiltersPanelVisible ? styles.filtersVisible : styles.filtersHidden)}
            >
              {nftItems.map((nft) => (
                <div
                  ref={(element) => {
                    itemsRefs.current[nft.mintId] = element;
                  }}
                  key={`div-devvx-nft-${nft.mintId}`}
                >
                  <ResultCard key={`devvx-nft-${nft.mintId}`} result={nft} />
                </div>
              ))}
              {isLoading && <SkeletonGrid />}
            </div>
          </div>

          <div className="mt-6 mb-9 px-4" ref={pageBottomRef}>
            <Show show={hasMore}>
              {!isLoading ? (
                <button
                  onClick={handleLoadMore}
                  type="button"
                  className="text-center text-blue font-bold bg-white w-full py-4 rounded"
                  disabled={isLoading}
                >
                  Load More
                  <FontAwesomeIcon icon={faArrowDown} className="ml-2" />
                </button>
              ) : (
                <div className="bg-white flex justify-center w-full py-1">
                  <Loader alt="load more" />
                </div>
              )}
            </Show>
          </div>
        </Show>
      </div>
    </div>
  );
});
