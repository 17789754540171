export const AppRoot = '/';

export const AppRoutes = {
  LANDING: `${AppRoot}`,
  SEARCH_RESULTS: 'search',
  TRANSACTIONS_LIST: 'transactions',
  MINT_ID_SHOW: 'm/:nftMintId',
  NFT_SHOW: 't/:nftItemUuid',
  CHECKOUT_RESOLVER: 'checkout/',
  HOW_IT_WORKS: 'how-it-works',
  ENVIRONMENTAL_POLICY: 'environmental-policy',
  EXCHANGE: 'exchange',
  EXCHANGE_DASHBOARD: 'exchange-dashboard',
  EXCHANGE_MY_ASSETS: 'exchange-my-assets',
  EXCHANGE_ASK_ORDER: 'exchange-ask-order',
  EXCHANGE_OPEN_ORDER: 'exchange-open-order',
  EXCHANGE_MARKET_STATS: 'exchange-market-stats',
  EXCHANGE_TERMS_AND_CONDITIONS: 'exchange-terms-and-conditions',
  EXCHANGE_REALTIME_ORDERS: 'exchange-realtime-orders',
  EXCHANGE_ORDER_BOOK: 'exchange-order-book',
  ORDER_HISTORY: 'order-history',
  MY_ORDER_HISTORY: 'my-order-history',
  PRIVACY_POLICY: 'privacy-policy',
  TERMS_OF_USE: 'terms-of-use',
  CARD_PROCESSING: 'card-processing',
  THANK_YOU: 'thank-you',
  SELLER_TERMS_OF_USE: 'seller-quickstart-terms-of-use',
  RETURNS: 'process-return/:returnId',
  RETURN_CONFIRMATION: 'return-confirmation',
  NO_ROUTE: '*',
};
