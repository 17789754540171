import { lazy, Suspense, useContext } from 'react';
import { ExchangeContext } from 'src/common/context/ExchangeContext';
import { Loader } from '../../loader/Loader';

const ExchangeMyAssets = lazy(() => import('src/common/components/exchange/my-assets/MyAssets'));
const ExchangeBuySellOrder = lazy(() => import('src/common/components/exchange/sell-order/ExchangeBuySellOrder'));

const DashboardContainer = () => {
  const { isExchangeEnabled } = useContext(ExchangeContext);
  const LoadingSpinner = (
    <Loader className="flex h-[100vh] items-center justify-center text-blue-700" alt="Loading Dashboard" />
  );
  return (
    <div className="lg:mt-[-8px] lg:mb-[-16px] xs:flex lg:ml-[268px] justify-center lg:bg-red-400 ">
      <div className="bg-[#F4F4F4] min-h-screen font-poppins lg:pl-6 lg:pr-6 xl:pr-3.5 max-w-lg sm:max-w-lg md:max-w-lg lg:min-w-full sm:self-center md:self-center xl:pl-[30px] xl:pr[30px]">
        {isExchangeEnabled && (
          <Suspense fallback={LoadingSpinner}>
            <div className="pb-6 xl:gap-3.5 xl:flex lg:flex lg:flex-col xl:flex-row w-full xl:min-w-[1180px]">
              <Suspense fallback={LoadingSpinner}>
                <div className="flex flex-col xl:w-[67.5%]">
                  <div>
                    <ExchangeMyAssets isDashboard />
                  </div>
                </div>
              </Suspense>
              <Suspense fallback={LoadingSpinner}>
                <div className="xl:w-[31%] ml-auto mr-auto hidden lg:block">
                  <ExchangeBuySellOrder isDashboard />
                </div>
              </Suspense>
            </div>
          </Suspense>
        )}
      </div>
    </div>
  );
};

export default DashboardContainer;
