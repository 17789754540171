import type { Transition } from 'history';
import { useCallback } from 'react';

import { useBlocker } from 'src/common/hooks/useBlocker';

// Implementation of a feature from react-router v6 betas
// This will be included in a later release of react-router v6
// https://github.com/remix-run/react-router/issues/8139#issuecomment-1023105785
export function usePrompt(
  message: string | ((location: Transition['location'], action: Transition['action']) => string),
  when = true,
) {
  const blocker = useCallback(
    (tx: Transition) => {
      let response;
      if (typeof message === 'function') {
        response = message(tx.location, tx.action);
      } else {
        response = window.confirm(message);
      }
      // TODO: Resolve duplicated retries on logout cancel
      if (response) {
        tx.retry();
      }
    },
    [message],
  );
  return useBlocker(blocker, when);
}
