import { useContext } from 'react';
import { generatePath, Navigate, useLocation } from 'react-router-dom';
import { ExchangeContext } from 'src/common/context/ExchangeContext';
import Cookies from 'universal-cookie';

export const ExchangePrivateRoute = ({ children }) => {
  const cookies = new Cookies();
  const location = useLocation();
  const isExchangeEnabled = useContext(ExchangeContext);

  const userCookie = cookies.get('user');

  if (children && children.type && children.type.name === 'ExchangeWelcome') {
    return children;
  }

  if (typeof userCookie === 'undefined') {
    return <Navigate to={generatePath(`/`)} state={{ from: location }} />;
  }

  if (isExchangeEnabled.isExchangeEnabled) return children;
  return children;
};
