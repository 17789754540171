import React from 'react';

import { Thumbnail } from 'src/interfaces';

export interface ImgProps {
  alt: string;
  srcImg: string;
  width: number;
  className: string;
  thumbnails?: Thumbnail[];
}

export const Image = React.memo(({ alt, srcImg, width, className, thumbnails }: ImgProps) => {
  let src = srcImg;

  if (thumbnails) {
    src = thumbnails?.find((th) => th.width === width)?.secureUrl || srcImg;
  }

  return <img src={src} alt={alt} className={className} />;
});
