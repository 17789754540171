import React from 'react';
import cx from 'classnames';
import markdownStyle from './markdown.module.css';

export const HowItWorks = React.memo(() => {
  return (
    <div className="w-full flex-col justify-between m-4 mx-auto max-w-7xl">
      <h1 className="text-blue text-5xl font-bold text-center">How It Works</h1>
      <div className={cx(markdownStyle.md, markdownStyle.large)}>
        <p>Welcome to Glassblock, the new marketplace for NFTs and more which uses Devvio’s blockchain!</p>
        <p>
          We’re here to help buyers and sellers of digital tokens and NFTs connect together in a safe, secured, trusted
          space.
        </p>
        <p>
          Our marketplace simplifies the purchase process, and removes any fear and uncertainty about purchasing digital
          goods. Here’s how!
        </p>
        <p>
          Sellers make their items available to the marketplace by minting NFTs or tokens. Once these goods are minted,
          a seller can “consign” them to our marketplace. That means that while the items are consigned to us, the
          seller cannot “use” the digital good. Sellers have an opportunity to change the listing price of a digital
          good, or retract them from sale. Additionally, sellers go through a KYC (Know-Your-Customer) process to verify
          their identity, helping to assure that all items sold are valid, legally-owned assets.
        </p>
        <p>
          Buyers can simply search or browse the digital goods they are interested in, and add them to their cart. Once
          a buyer is ready to checkout, they can use either crypto or (soon to come) wire transfers or credit card
          transactions to buy the digital goods. During the checkout process you will have an exclusive “lock” on the
          digital items, which means nobody else will be able to purchase them while you are checking out. And once you
          complete the order you will immediately receive the items in your digital wallet.
        </p>
        <p>
          It’s simple! No fuss. No need to email or DM the owners of tokens or NFTs for the inventory to arrange a
          transfer; our unique consignment model means we handle this all for you.
        </p>
        <p>
          Glassblock is powered by Devvio’s core blockchain, which can handle up to 8 million transactions per second,
          which means transactions are speedy.
        </p>
        <p>
          In order to get started with buying on Glassblock, all you need is a “wallet” on Devvio, which you can get by
          clicking “Sign Up” on the top right navigation of the marketplace site.
        </p>
        <p>
          We’ve kept this process simple, and safe, because we believe maintaining trust is integral to a digital
          marketplace.
        </p>
        <p>
          Got questions? Our support agents are here to help at any time, using the support widget at the bottom right
          part of the site. Whether you want to buy or sell, we’re happy to help you on your journey.
        </p>
      </div>
    </div>
  );
});
