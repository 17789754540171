import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { setSessionUuid } from 'src/api/client';
import { CartContext } from 'src/common/context/CartContext';
import { Env } from 'src/common/env';
import { UserRoles } from 'src/interfaces';
import { AuthRoot, AuthRoutes } from 'src/routes/auth/auth-routes';
import { AppRoot, AppRoutes } from '../../routes/app';

export interface UserProps {
  username: string;
  email: string;
  uuid: string;
  pub: string;
  emailVerified: boolean;
  roles: UserRoles[];
}

interface UserContextInterface {
  isLoading: boolean;
  user: UserProps | null;
  isAdmin: () => boolean;
  logIn: (user: UserProps) => void;
  logOut: () => void;
}

const cookies = new Cookies();

const sessionDuration = Env.sessionDuration ? parseInt(Env.sessionDuration, 10) : 12 * 60 * 60; // default duration is 12hrs in seconds

const defaultUserContext = {
  isLoading: true,
  user: null,
  isAdmin: () => false,
  logIn: () => undefined,
  logOut: () => undefined,
};

const routesToRedirectLanding = new Set([AuthRoutes.REGISTER]);

export const UserContext = createContext<UserContextInterface>(defaultUserContext);

export const UserProvider = React.memo(({ children }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState<UserProps | null>(defaultUserContext.user);
  const [isLoading, setIsLoading] = useState(true);
  const { clearCartCookie, emptyCart } = useContext(CartContext);

  const isAdmin = useCallback(() => {
    return !!user?.roles?.includes(UserRoles.ADMIN);
  }, [user]);

  const logIn = useCallback(
    (newUser) => {
      setUser(newUser);
      cookies.set('featureFlagsUser', newUser.username);
      if (location.state === '/exchange') {
        navigate(AppRoutes.EXCHANGE);
      } else if (
        location.state &&
        typeof location.state === 'object' &&
        routesToRedirectLanding.has(Reflect.get(location.state, 'from'))
      ) {
        navigate(AppRoutes.LANDING);
      } else {
        navigate(-1);
      }
    },
    [location, navigate],
  );

  const logOut = useCallback(() => {
    // Clear user credentials/session on logout.
    setSessionUuid('');
    cookies.remove('user', { path: AppRoot });
    cookies.remove('featureFlagsUser');
    setUser(null);
    // Clear cart on logout as well.
    clearCartCookie();
    emptyCart();
    navigate(generatePath(`/${AuthRoot}/${AuthRoutes.LOGIN}`));
  }, [navigate]);

  useEffect(() => {
    const storedUser = cookies.get('user');
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  useEffect(() => {
    if (user) {
      // TODO: Swap use to a httpOnly cookie or Auth0
      cookies.set('user', user, { path: AppRoot, secure: true, sameSite: 'strict', maxAge: sessionDuration });
      setSessionUuid(user.uuid);
    } else {
      setSessionUuid('');
    }

    setIsLoading(false);
  }, [user]);

  const providerValues = useMemo(
    () => ({ isLoading, user, isAdmin, logIn, logOut }),
    [isAdmin, isLoading, logIn, logOut, user],
  );

  return <UserContext.Provider value={providerValues}>{children}</UserContext.Provider>;
});
