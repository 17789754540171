import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';

import { CoinbaseForm, CoinbaseFormData } from 'src/common/components/checkout-form/coinbase/CoinbaseForm';
import { DevvPayForm, DevvPayFormData } from 'src/common/components/checkout-form/devv-pay/DevvPayForm';
import { OrderDetailsForm } from 'src/common/components/checkout-form/order-details/OrderDetailsForm';
import { Collapsible } from 'src/common/components/forms/Collapsible';
import { Show } from 'src/common/layout';
import coinbaseIcon from 'src/assets/images/coinbase-icon.svg';
import devvioIcon from 'src/assets/images/devvio-logo.svg';
import bagShoppingIcon from 'src/assets/images/bag-shopping.svg';
import { HandleCompleteArgs, PaymentEnabledFetchParams, PAYMENT_OPTION } from 'src/interfaces';
import { CheckoutContext } from 'src/common/context/CheckoutContext';
import { useQuery, useQueryClient } from 'react-query';
import { Api } from 'src/api';
import { Mixpanel, MixpanelEvents } from 'src/common/util';
import { CartContext } from 'src/common/context/CartContext';
import { UserContext } from 'src/common/context/UserContext';
import { useFormContext } from 'react-hook-form';
import PaymentOptionSkeleton from '../skeletons/PaymentOptionSkeleton';
import { PurchaseOrderForm } from './purchase-order/PurchaseOrderForm';

export const CheckoutForm = () => {
  const [collapsibleOpenId, setCollapsibleOpenId] = useState('');
  const { register, resetField, unregister } = useFormContext<CoinbaseFormData | DevvPayFormData>();

  const isCryptoOpen = collapsibleOpenId === 'cryptoCollapsible';
  const isDevvPayOpen = collapsibleOpenId === 'devvPayCollapsible';
  const isPurchaseOrderOpen = collapsibleOpenId === 'purchaseOrderCollapsible';

  const handleCollapsibleOpen = (val: string) => {
    setCollapsibleOpenId(val === collapsibleOpenId ? '' : val);
  };
  const {
    totalValue,
    promoCode,
    totalCount,
    promoCodeData,
    subtotal,
    nftItems,
    setPaymentEnabled,
    setIsBlocking,
    setDevvPayOption,
    isOptionDisabled,
    getOptionReasons,
    hasErrorOnPaymentEnabled,
    paymentEnabledErrorMessage,
  } = useContext(CheckoutContext);
  const { cart, cartId, clearCartCookie } = useContext(CartContext);
  const { user } = useContext(UserContext);
  const queryClient = useQueryClient();

  // Payment Options Data fetching criteria to show button
  const parmasPaymentEnable: PaymentEnabledFetchParams = {
    uuids: JSON.stringify(cart.map((cartItem) => cartItem.uuid)),
  };

  if (promoCode && promoCode !== '' && promoCodeData) {
    parmasPaymentEnable.discountCodeName = promoCode;
  }
  const {
    data: dataPaymentEnabled,
    isSuccess: isSuccessPaymentEnabled,
    isLoading: isLoadingPaymentEnabled,
    isRefetching: isRefetchingPaymentEnabled,
  } = useQuery('paymentEnabledQuery', () => Api.payments.paymentEnabled(parmasPaymentEnable), {
    cacheTime: 0,
    refetchOnWindowFocus: true,
  });

  // DevvPay Payment Options Data fetching
  const { data: devvPayOptionsData } = useQuery('devvPayOptionsQuery', Api.payments.devvPay.tokens, {
    refetchOnWindowFocus: true,
  });

  const { data: userInfoQueryData, isSuccess: userInfoQueryIsSuccess } = useQuery(
    'cartUserInfo',
    Api.cart.getUserBalances,
    {
      placeholderData: { balance: [] },
      refetchOnWindowFocus: false,
    },
  );

  const isLoading =
    nftItems.length === 0 || !isSuccessPaymentEnabled || isLoadingPaymentEnabled || isRefetchingPaymentEnabled;

  useEffect(() => {
    queryClient.prefetchQuery('paymentEnabledQuery', () => Api.payments.paymentEnabled(parmasPaymentEnable), {
      cacheTime: 0,
    });
  }, [cart, totalValue, promoCodeData]);

  // Register discount data fetch as hidden discountCodeUuid form input.
  useEffect(() => {
    if (promoCodeData) {
      register('discountCodeUuid', {
        value: promoCodeData.uuid,
      });
    } else {
      unregister('discountCodeUuid');
    }
  }, [promoCodeData]);

  // Sets payment enabled options before page is rendered
  useEffect(() => {
    setPaymentEnabled(dataPaymentEnabled);
  }, [dataPaymentEnabled]);

  // Monitor/clear coinName input for DevvPay price conversions
  useEffect(() => {
    if (!isDevvPayOpen) {
      resetField('coinName');
      setDevvPayOption(null);
    }
  }, [collapsibleOpenId]);

  const handleComplete = useCallback(
    ({ success, buyerCountry, paymentProcessor }: HandleCompleteArgs) => {
      if (success) {
        Mixpanel.track(
          MixpanelEvents.PAYMENT,
          {
            'BUYER Country': buyerCountry || '',
            'NFT Payment processor': paymentProcessor,
            'NFT Items count': totalCount || 0,
            'NFT Items discount percentage': promoCodeData ? promoCodeData.percentOff : 0,
            'NFT Total amount': totalValue || 0,
            'NFT Total amount without discount': subtotal || totalValue,
            'Cart ID': cartId,
          },
          user,
        );
        clearCartCookie();
      } else {
        // Unblock leaving checkout & re-verify cart item availability.
        setIsBlocking(false);
        queryClient.invalidateQueries('nftListQuery');
        queryClient.invalidateQueries('paymentEnabledQuery');
      }
    },
    [cartId, clearCartCookie, promoCodeData, subtotal, totalCount, totalValue, user],
  );

  return (
    <Show show>
      <OrderDetailsForm />
      <h3 className="font-bold mb-7">Select your payment method</h3>
      {hasErrorOnPaymentEnabled() && paymentEnabledErrorMessage ? (
        <p className="text-red-500">{paymentEnabledErrorMessage}</p>
      ) : null}
      {!isLoading && !hasErrorOnPaymentEnabled() && (
        <Transition
          show={!isLoading}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="mb-2" key="devvPayCollapsible">
            <Collapsible
              id="devvPayCollapsible"
              open={isDevvPayOpen}
              title="DevvPay™"
              onCollapsibleClick={(id: string) =>
                !isOptionDisabled(PAYMENT_OPTION.DEVVPAY) && handleCollapsibleOpen(id)
              }
              logo={devvioIcon}
              disable={isOptionDisabled(PAYMENT_OPTION.DEVVPAY)}
              reasons={getOptionReasons(PAYMENT_OPTION.DEVVPAY)}
            >
              {isDevvPayOpen && !isOptionDisabled(PAYMENT_OPTION.DEVVPAY) && (
                <DevvPayForm
                  devvPayOptions={
                    devvPayOptionsData?.data?.tokens.map((token) => {
                      let balance = '';
                      if (userInfoQueryIsSuccess && Array.isArray(userInfoQueryData?.balance)) {
                        const balanceData = (userInfoQueryData?.balance || []).find(
                          (coin) => coin.coinName === token.coinName,
                        );
                        if (balanceData) {
                          balance = balanceData.balance;
                        }
                      }
                      return { ...token, balance };
                    }) || []
                  }
                  onCompleteCharge={handleComplete}
                  onStartCharge={() => {
                    setIsBlocking(false);
                  }}
                />
              )}
            </Collapsible>
          </div>

          <div className="mb-2" key="cryptoCollapsible">
            <Collapsible
              id="cryptoCollapsible"
              open={isCryptoOpen}
              title="Crypto"
              onCollapsibleClick={(id: string) =>
                !isOptionDisabled(PAYMENT_OPTION.COINBASE) && handleCollapsibleOpen(id)
              }
              logo={coinbaseIcon}
              disable={isOptionDisabled(PAYMENT_OPTION.COINBASE)}
              reasons={getOptionReasons(PAYMENT_OPTION.COINBASE)}
            >
              {isCryptoOpen && !isOptionDisabled(PAYMENT_OPTION.COINBASE) && (
                <CoinbaseForm
                  onCompleteCharge={handleComplete}
                  onStartCharge={() => {
                    setIsBlocking(false);
                  }}
                />
              )}
            </Collapsible>
          </div>

          <div key="purchaseOrderCollapsible">
            <Collapsible
              id="purchaseOrderCollapsible"
              open={isPurchaseOrderOpen}
              title="Purchase Order"
              onCollapsibleClick={(id: string) =>
                !isOptionDisabled(PAYMENT_OPTION.PURCHASE_ORDER) && handleCollapsibleOpen(id)
              }
              logo={bagShoppingIcon}
              disable={isOptionDisabled(PAYMENT_OPTION.PURCHASE_ORDER)}
              reasons={getOptionReasons(PAYMENT_OPTION.PURCHASE_ORDER)}
            >
              {isPurchaseOrderOpen && !isOptionDisabled(PAYMENT_OPTION.PURCHASE_ORDER) && (
                <PurchaseOrderForm
                  onCompleteCharge={handleComplete}
                  onStartCharge={() => {
                    setIsBlocking(false);
                  }}
                />
              )}
            </Collapsible>
          </div>
        </Transition>
      )}
      {isLoading && (
        <Transition
          show={isLoading}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <PaymentOptionSkeleton />
        </Transition>
      )}
    </Show>
  );
};
