/* eslint-disable jsx-a11y/no-static-element-interactions */
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState, useRef, useContext } from 'react';
import { AppRoutes } from 'src/routes/app';
import { PageThemeContext } from 'src/common/context/PageThemeContext';
import { ExchangeContext } from 'src/common/context/ExchangeContext';
import { ExchangeUserContext } from 'src/common/context/ExchangeUserContext';
import { UserContext } from 'src/common/context/UserContext';
import useWindowSize from 'src/common/hooks/useWindowSize';
import { Env } from 'src/common/env';
import Avatar from './Avatar/Avatar';
import SidebarRow from './SidebarRow/SidebarRow';
import CloseIcon from './Icons/CloseIcon';
import BillIcon from './Icons/BillIcon';
import ExchangeIcon from './Icons/ExchangeIcon';
import DashboardIcon from './Icons/DashboardIcon';
import WalletIcon from './Icons/WalletIcon';
import BookIcon from './Icons/BookIcon';
import ClockIcon from './Icons/ClockIcon';
import ChartIcon from './Icons/ChartIcon';
import ShoppingCartIcon from './Icons/ShoppingCartIcon';
import SaleIcon from './Icons/SaleIcon';

const Sidebar = () => {
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [activePath, setActivePath] = useState('/');
  const { handleSidebarCloseClick, isSidebarOpen } = useContext(ExchangeContext);
  const { user } = useContext(UserContext);
  const { width } = useWindowSize();
  const isLargerScreen = width && width > 1024;
  const shouldDisplaySidebar = user && (isSidebarOpen || isLargerScreen);

  const { exchangeUser } = useContext(ExchangeUserContext);

  const handeSidebarRowClick = (path: string) => {
    handleSidebarCloseClick();
    navigate(path);
  };
  const handeSidebarExternalLinksRowClick = (path: string) => {
    handleSidebarCloseClick();
    window.location.href = path;
  };

  const handleTermsAndPrivacyClick = () => {
    navigate(`/${AppRoutes.EXCHANGE_TERMS_AND_CONDITIONS}`);
    handleSidebarCloseClick();
  };

  const sideBarTopRows = [
    {
      path: `/${AppRoutes.EXCHANGE_DASHBOARD}`,
      title: 'Dashboard',
      icon: <DashboardIcon stroke={activePath === `/${AppRoutes.EXCHANGE_DASHBOARD}` ? '#0F69C9' : '#2F2F2F'} />,
      active: activePath === `/${AppRoutes.EXCHANGE_DASHBOARD}`,
    },
    {
      path: `/${AppRoutes.EXCHANGE_MY_ASSETS}`,
      title: 'My Assets',
      icon: <WalletIcon stroke={activePath === 'exchange-my-assets' ? '#0F69C9' : '#2F2F2F'} />,
      active: activePath === '/exchange-my-assets',
    },
    {
      path: `/${AppRoutes.EXCHANGE_ASK_ORDER}`,
      title: 'Bid & Ask',
      icon: <ExchangeIcon stroke={activePath === `/${AppRoutes.EXCHANGE_ASK_ORDER}` ? '#0F69C9' : '#2F2F2F'} />,
      active: activePath === `/${AppRoutes.EXCHANGE_ASK_ORDER}`,
    },
    {
      path: `/${AppRoutes.EXCHANGE_OPEN_ORDER}`,
      title: 'My Orders',
      icon: <BillIcon stroke={activePath === `/${AppRoutes.EXCHANGE_OPEN_ORDER}` ? '#0F69C9' : '#2F2F2F'} />,
      active: activePath === `/${AppRoutes.EXCHANGE_OPEN_ORDER}`,
    },
  ];

  const sideBarMiddleRows = [
    {
      path: `/${AppRoutes.EXCHANGE_ORDER_BOOK}`,
      title: 'Orderbook',
      icon: <BookIcon stroke={activePath === `/${AppRoutes.EXCHANGE_ORDER_BOOK}` ? '#0F69C9' : '#2F2F2F'} />,
      active: activePath === `/${AppRoutes.EXCHANGE_ORDER_BOOK}`,
    },
    {
      path: `/${AppRoutes.EXCHANGE_REALTIME_ORDERS}`,
      title: 'Realtime',
      icon: <ClockIcon stroke={activePath === `/${AppRoutes.EXCHANGE_REALTIME_ORDERS}` ? '#0F69C9' : '#2F2F2F'} />,
      active: activePath === `/${AppRoutes.EXCHANGE_REALTIME_ORDERS}`,
    },
    {
      path: `/${AppRoutes.EXCHANGE_MARKET_STATS}`,
      title: 'Market Stats',
      icon: <ChartIcon stroke={activePath === `/${AppRoutes.EXCHANGE_MARKET_STATS}` ? '#0F69C9' : '#2F2F2F'} />,
      active: activePath === `/${AppRoutes.EXCHANGE_MARKET_STATS}`,
    },
  ];
  const sideBarBottomRows = [
    {
      path: Env.exchangeLinkMarketplace,
      title: 'Marketplace',
      icon: <ShoppingCartIcon stroke={activePath === 'orders' ? '#0F69C9' : '#2F2F2F'} />,
      active: false,
    },
    {
      path: Env.exchangeLinkSeller,
      title: 'Seller',
      icon: <SaleIcon stroke={activePath === 'orders' ? '#0F69C9' : '#2F2F2F'} />,
      active: false,
    },
    {
      path: Env.exchangeLinkDevvxWallet,
      title: 'DevvX Wallet',
      icon: <WalletIcon stroke={activePath === 'orders' ? '#0F69C9' : '#2F2F2F'} />,
      active: false,
    },
  ];

  const renderSideBarTopRows = () => {
    return sideBarTopRows.map((rowProps, index) => (
      <SidebarRow key={index} onClick={handeSidebarRowClick} {...rowProps} />
    ));
  };

  const renderSideBarMiddleRows = () => {
    return sideBarMiddleRows.map((rowProps, index) => (
      <SidebarRow key={index} onClick={handeSidebarRowClick} {...rowProps} />
    ));
  };
  const renderSideBarBottomRows = () => {
    return sideBarBottomRows.map((rowProps) => (
      <SidebarRow key={rowProps.path} onClick={handeSidebarExternalLinksRowClick} {...rowProps} />
    ));
  };

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);
  const { showExchangeSideBar } = useContext(PageThemeContext);

  // to be returned from the backend laters
  const profileUrl = null;

  let profileName = exchangeUser && exchangeUser.username ? exchangeUser.username : user && user?.username;
  profileName = profileName && profileName.replace(/[^a-zA-Z0-9 ]/g, ' ');
  /* Replacing the special characters to space because the username were collpsing over the sidenav */

  return showExchangeSideBar && shouldDisplaySidebar ? (
    <motion.div
      initial={{ x: '-100%' }}
      ref={sidebarRef}
      animate={{ x: 0 }}
      exit={{ x: '-100%' }}
      transition={{
        duration: 0.2,
      }}
      className="font-poppins sidebar h-screen animate border lg:top-[63px] fixed md:self-start flex flex-col bg-white pt-8 lg:pt-0 duration-300 w-[270px] z-50"
    >
      <span
        aria-label="Close"
        className="lg:hidden absolute right-6 top-6 cursor-pointer"
        onClick={handleSidebarCloseClick}
      >
        <CloseIcon />
      </span>
      <div className="ml-6 mr-10 mt-6 mb-7 lg:mt-0 flex border-t border-solid border-gray-100 lg:border-none pt-8">
        <Avatar profileUrl={profileUrl} />
        <div className="flex flex-1 flex-col items-center justify-center">
          <h4 className="font-normal">Welcome</h4>
          <h3 className="text-[22px] uppercase font-bold text-center">{profileName}</h3>
        </div>
      </div>
      <div>{renderSideBarTopRows()}</div>
      <div className="my-6 ml-6 mr-10 flex border-t border-solid border-gray-100" />
      <div>{renderSideBarMiddleRows()}</div>
      <div className="my-6 ml-6 mr-10 flex border-t border-solid border-gray-100" />
      <div className="flex flex-1 flex-col justify-between">
        <div>{renderSideBarBottomRows()}</div>
        <div className="mb-3">
          <span
            onClick={handleTermsAndPrivacyClick}
            className="block pl-6 lg:pb-16 text-[12px] font-normal text-[#8E8E8E] cursor-pointer"
          >
            Terms & Privacy Policy
          </span>
        </div>
      </div>
    </motion.div>
  ) : (
    <div />
  );
};

export default Sidebar;
