import React from 'react';

const StatusAssetsLabel = ({ status, amount }: { status: string; amount: string }) => {
  const getIcon = (status: string) => {
    if (status.toLowerCase() === 'available') {
      return <div className="w-[8px] h-[8px] bg-[#038600] rounded-full " />;
    }
    return <div className="w-[8px] h-[8px] bg-[#CF0000] rounded-full" />;
  };
  return (
    <div className="flex">
      <div className="self-center mr-2">{getIcon(status)}</div>
      <div className="mr-2 font-normal text-[12px]">{status}</div>
      <div className="mr-2 font-normal text-[12px]">{amount}</div>
    </div>
  );
};

export default StatusAssetsLabel;
