/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SVGProps } from 'react';

const BillIcon: React.FC<SVGProps<any>> = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.03448 7.61873H16.468L14.7857 9.33195C14.3817 9.74338 14.3817 10.4104 14.7857 10.8219C15.1897 11.2333 15.8447 11.2333 16.2487 10.8219L19.697 7.31019C19.891 7.11262 20 6.84465 20 6.56523C20 6.28581 19.891 6.01784 19.697 5.82027L16.2487 2.3086C15.9874 2.04245 15.6065 1.93851 15.2495 2.03592C14.8925 2.13334 14.6136 2.41732 14.518 2.78088C14.4223 3.14444 14.5244 3.53236 14.7857 3.79851L16.468 5.51173H5.03448C4.46315 5.51173 4 5.9834 4 6.56523C4 7.14706 4.46315 7.61873 5.03448 7.61873Z"
        fill="#212D3B"
      />
      <path
        d="M13.9655 12.9033H3.53197L5.21429 11.1901C5.6183 10.7787 5.6183 10.1116 5.21429 9.70017C4.81029 9.28874 4.15528 9.28874 3.75128 9.70017L0.303011 13.2119C0.108997 13.4094 0 13.6774 0 13.9568C0 14.2362 0.108997 14.5042 0.303011 14.7018L3.75128 18.2135C4.15528 18.6249 4.81029 18.6249 5.21429 18.2135C5.6183 17.802 5.6183 17.135 5.21429 16.7235L3.53197 15.0103H13.9655C14.5368 15.0103 15 14.5387 15 13.9568C15 13.375 14.5368 12.9033 13.9655 12.9033Z"
        fill="#212D3B"
      />
    </svg>
  );
};
export default BillIcon;
