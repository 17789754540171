import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';

import { Show } from 'src/common/layout';
import { Image } from 'src/common/components';
import ToolTip from 'src/common/components/exchange/ToolTip/ToolTip';
import { Button } from 'src/common/interactions/Button';
import { CartItemWithDiscount } from 'src/interfaces';
import { SalePrice } from './SalePrice';
import { PaymentOptions } from '../payment-options/PaymentOptions';

export interface CartItemRowProps {
  devvPayOption?: { coinName: string; exchangeRate: number } | null;
  nftItem: CartItemWithDiscount;
  onRemove?: (nftItemUuid: string) => void;
  unavailable?: boolean;
  temporarilyUnavailable?: boolean;
}
// call devvPayOption by context
export const CartItemRow = React.memo(
  ({ devvPayOption, nftItem, onRemove, unavailable, temporarilyUnavailable }: CartItemRowProps) => {
    const imageUrl = nftItem.imageUrl || '/NoCategoryWhite.png';
    const classOpacity = !nftItem.forSale || nftItem.isLocked || unavailable ? 'opacity-50' : '';
    const { salePrice, discountSalePrice, paymentOptions } = nftItem;

    const handleRemoveButton = () => {
      if (onRemove) {
        onRemove(nftItem.uuid);
      }
    };

    return (
      <div className="flex justify-between p-3 border-b-2" key={nftItem.uuid}>
        <div className={`w-1/6  ${classOpacity}`}>
          <Image alt={nftItem.name} srcImg={imageUrl} thumbnails={nftItem.thumbnails} width={150} className="rounded" />
        </div>
        <div className="w-3/6 pl-5">
          <div className={classOpacity}>
            <h1 className="text-sm font-bold mb-1">
              {nftItem.name}
              <Show show={nftItem.mintRank !== ''}>
                <span className="font-normal ml-1 whitespace-nowrap">
                  # {nftItem.mintRank} of {nftItem.mintTotal}
                </span>
              </Show>
            </h1>
            <p className="mt-4">
              <span className="font-bold">Sold By</span>: {nftItem.sellerId}
            </p>
          </div>
          <Show show={!nftItem.forSale || nftItem.isLocked || unavailable || nftItem.isBanned}>
            <h3 className="text-orange-500 text-xs mt-2">
              This item is no longer available, you will need to remove it from your cart.
            </h3>
          </Show>
          <Show show={temporarilyUnavailable}>
            <h3 className="text-orange-500 text-xs mt-2">
              This item is temporarily unavailable for purchase, please try again later.
            </h3>
          </Show>
        </div>
        <div className="w-2/6 text-right">
          <SalePrice {...{ devvPayOption, discountSalePrice, salePrice }} />
          <div className="flex justify-end pt-2 items-center">
            <PaymentOptions paymentOptions={paymentOptions} tooltipPosition="left" />
          </div>
          <Show show={onRemove !== undefined}>
            <Button.Link className="py-2 no-underline" onClick={handleRemoveButton}>
              <span className="text-sm font-medium">
                <FontAwesomeIcon icon={faTimes} className="mr-1" />
                Remove
              </span>
            </Button.Link>
          </Show>
        </div>
      </div>
    );
  },
);
