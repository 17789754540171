import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';

import { AppRoot, AppRoutes } from '../../routes/app';
import { wrappedToast } from '../util/alerts';
import { Button } from '../interactions/Button';
import { Env } from '../env';

export interface CartProps {
  uuid: string;
}

interface CartContextInterface {
  isLoading: boolean;
  cartShowState: boolean;
  setCartShowState: React.Dispatch<React.SetStateAction<boolean>>;
  cart: CartProps[];
  cartId: string;
  addCartItem: (uuid: string[], goToCheckout: boolean) => void;
  removeCartItem: (uuid: string | string[]) => void;
  handleCheckout: () => void;
  emptyCart: () => void;
  clearCartCookie: () => void;
}

const cookies = new Cookies();

const defaultCartContext = {
  isLoading: true,
  cartShowState: false,
  setCartShowState: () => undefined,
  cart: [],
  cartId: '',
  addCartItem: () => undefined,
  removeCartItem: () => undefined,
  handleCheckout: () => undefined,
  emptyCart: () => undefined,
  clearCartCookie: () => undefined,
};

export const CartContext = createContext<CartContextInterface>(defaultCartContext);

export const CartProvider = React.memo(({ children }: any) => {
  const [cart, setCart] = useState<CartProps[]>(defaultCartContext.cart);
  const [cartId, setCartId] = useState<string>(defaultCartContext.cartId);
  const [cartShowState, setCartShowState] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleCheckoutWithCart = useCallback(
    (currentCart) => {
      if (currentCart.length === 0) {
        wrappedToast.warning({ title: 'No items in your cart' });
      } else {
        setCartShowState(false);
        navigate(`/${AppRoutes.CHECKOUT_RESOLVER}`);
      }
    },
    [navigate],
  );

  const handleCheckout = useCallback(() => {
    handleCheckoutWithCart(cart);
  }, [cart, handleCheckoutWithCart]);

  const addCartItem = useCallback(
    (nftItemsUuid: string[], goToCheckout) => {
      if (cart.length >= Env.maxCartItems) {
        wrappedToast.warning({
          title: "We recommend you checkout with what's currently in your cart before adding more.",
        });
        return;
      }

      const xVpn = window.localStorage.getItem('X-VPN');
      const btnGoToCheckout = !!xVpn && xVpn !== 'on';

      const cartUuids = cart.map((item) => item.uuid);
      const newCartItems = nftItemsUuid.filter((item) => !cartUuids.includes(item));

      if (newCartItems.length > 0) {
        const cartCopy = [...cart];

        if (newCartItems.length + cart.length > Env.maxCartItems) {
          wrappedToast.warning({
            title:
              "Some of the items were not added. We recommend you checkout with what's currently in your cart before adding more.",
          });
        }

        const itemsToAdd = Env.maxCartItems - cart.length;

        for (let i = 0; i < itemsToAdd; i += 1) {
          const uuid = newCartItems[i];
          if (uuid === undefined) break;

          cartCopy.push({ uuid });
        }

        setCart(cartCopy);

        toast.success(
          <>
            <span className="font-bold text-white">Added to Cart!</span>
            <div>
              {goToCheckout ? (
                <Button.Link
                  className={btnGoToCheckout ? 'opacity-50' : ''}
                  data-gtm="goToCheckout"
                  onClick={() => handleCheckoutWithCart(cartCopy)}
                  disabled={btnGoToCheckout}
                >
                  <span className="text-black underline">Go To Checkout</span>
                </Button.Link>
              ) : (
                <Button.Link data-gtm="goToCart" onClick={() => setCartShowState(true)}>
                  <span className="text-black underline">Go To Cart</span>
                </Button.Link>
              )}
            </div>
          </>,
          {
            icon: <FontAwesomeIcon color="white" icon={faShoppingCart} />,
          },
        );
      } else {
        wrappedToast.warning({ title: 'NFT has already been added to cart.' });
      }
    },
    [cart, handleCheckoutWithCart],
  );

  const emptyCart = useCallback(() => {
    setCart([]);
  }, []);

  const removeCartItem = useCallback(
    (nftItemUuid: string | string[]) => {
      const filteredNFTCart = Array.isArray(nftItemUuid)
        ? cart.filter((cartItem) => !nftItemUuid.includes(cartItem.uuid))
        : cart.filter((cartItem) => cartItem.uuid !== nftItemUuid);

      if (filteredNFTCart) {
        setCart(filteredNFTCart);
      } else {
        wrappedToast.warning({ title: 'NFT does not exist in cart.' });
      }
    },
    [cart],
  );

  const clearCartCookie = () => {
    cookies.remove('cart');
    cookies.remove('cartId');
  };

  useEffect(() => {
    const storedCart = cookies.get('cart');
    if (storedCart) {
      setCart(storedCart);
    }
  }, []);

  useEffect(() => {
    if (cart) {
      cookies.set('cart', cart, { path: AppRoot });
    }

    setIsLoading(false);
  }, [cart]);

  useEffect(() => {
    let storedCartId = cookies.get('cartId');
    if (!storedCartId) {
      storedCartId = uuidv4();
      cookies.set('cartId', storedCartId, { path: AppRoot });
    }
    setCartId(storedCartId);
  }, []);

  const cartProviderValues = useMemo(
    () => ({
      isLoading,
      cartShowState,
      cart,
      cartId,
      setCartShowState,
      addCartItem,
      emptyCart,
      removeCartItem,
      handleCheckout,
      clearCartCookie,
    }),
    [addCartItem, cart, cartId, cartShowState, emptyCart, handleCheckout, isLoading, removeCartItem],
  );

  return <CartContext.Provider value={cartProviderValues}>{children}</CartContext.Provider>;
});
