import React, { useEffect, useState, useContext } from 'react';
import { generatePath, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast } from 'react-toastify';
import ReactMarkdown from 'react-markdown';
import remarkGemoji from 'remark-gemoji';
import remarkGfm from 'remark-gfm';

import { Inventory, ItemDetail, NFTItem, NftMintIdRes } from 'src/interfaces';
import { Button } from 'src/common/interactions/Button';
import {
  camelToHuman,
  formatCurrencyWithoutDenomination,
  getRandomNftItems,
  Mixpanel,
  MixpanelEvents,
  FeatureFlags,
  FeatureFlagsEvents,
  lowercaseFirstLetter,
} from 'src/common/util';
import { Api, NftItemListParams, useLazyQuery, SimilarNft } from 'src/api';
import { Show } from 'src/common/layout';
import { Image, ResultCard } from 'src/common/components';
import { AppRoutes } from 'src/routes/app/routes';
import { UserContext } from 'src/common/context/UserContext';
import { PurchaseOptionTable } from 'src/common/components/tables/PurchaseOption/PurchaseOptionTable';
import { QtyRemainingBox } from 'src/common/components/qty-remaining-box/QtyRemainingBox';
import { CartContext } from 'src/common/context/CartContext';
import { useRehypeReadMore } from 'src/common/hooks/useRehypeReadMore';
import { Env } from 'src/common/env';
import { LitPetCard } from 'src/common/components/lit-pet-card/LitPetCard';
import { HighlightedDetails } from './HighlightedDetails';
import { MockCheckoutFormContainer } from '../checkout/MockCheckoutFormContainer';
import nftShowContainerStyles from './MintIdShowContainer.module.scss';

const defaultValueDataAddCart = {
  mintRank: 0,
  sellerId: '',
  salePrice: 0,
  saleCurrency: '',
  quantity: 0,
  isLocked: false,
  paymentOptions: {},
};

const findDetailKeysOnItemDetails = (keys: string[], itemDetails: ItemDetail[]) => {
  const details: Record<string, string> = {};

  itemDetails.forEach((itemDetail) => {
    if (keys.includes(itemDetail.key)) {
      details[itemDetail.key] = itemDetail.value;
    }
  });

  return details;
};

function getSalePriceRange(inventory: Inventory[]): string {
  if (!Array.isArray(inventory) || !inventory.length) {
    return 'N/A';
  }
  let min = inventory[0].salePrice;
  let max = inventory[0].salePrice;

  for (const item of inventory) {
    if (item.salePrice > max) {
      max = item.salePrice;
    }

    if (item.salePrice < min) {
      min = item.salePrice;
    }
  }

  if (min === max) {
    return `$${formatCurrencyWithoutDenomination(min)}`;
  }

  return `$${formatCurrencyWithoutDenomination(min)} - $${formatCurrencyWithoutDenomination(max)}`;
}

export const MintIdShowContainer = React.memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { cart, addCartItem } = useContext(CartContext);
  const [paneState, setPaneState] = useState(false);
  const [addCartState, setAddCartState] = useState(false);
  const [goToCheckout, setGoToCheckout] = useState(true);
  const [dataAddCart, setDataAddCart] = useState<Inventory>(defaultValueDataAddCart);
  const { nftMintId } = useParams();
  const [searchParams] = useSearchParams();
  const [itemQuantity, setItemQuantity] = useState({});
  const [buyEnabled, setBuyEnabled] = useState(false);
  const [mockDataAddCart, setMockDataAddCart] = useState({ quantity: 0, totalAmount: 0 });
  const [trackedViewNFT, setTrackedViewNFT] = useState(false);
  const ipRightsLink = `${Env.host}/api/nfts/ip-rights/${nftMintId}`;
  const currencyPreferences = searchParams.get('currencyPreferences');
  const seller = searchParams.get('seller');

  const [mintIdFetchQuery, mintIdFetchResponse] = useLazyQuery<
    { nftMintId: string; currencyPreferences?: string | null },
    { data: NftMintIdRes }
  >(Api.nftItem.fetchMintIdItems);
  const [similarProductsFetchQuery, similarProductsResponse] = useLazyQuery<
    { nftItemUuid: string },
    { data: { nftItems: SimilarNft[] } }
  >(Api.nftItem.fetchSimilarProducts);

  const [nftListQuery, nftListResponse] = useLazyQuery<NftItemListParams, { data: NFTItem[] }>(Api.nftItem.list);

  useEffect(() => {
    const params: NftItemListParams = {
      uuids: JSON.stringify(cart),
    };

    if (cart.length) {
      nftListQuery(params);
    } else {
      setGoToCheckout(true);
    }
  }, [nftListQuery, cart]);

  useEffect(() => {
    if (nftListResponse?.status === 'resolved') {
      const nftItems = nftListResponse.response?.data || [];

      nftItems?.forEach((nft) => {
        if (!nft.forSale || nft.isLocked) {
          setGoToCheckout(false);
        }
      });
    }
  }, [nftListResponse]);

  const ntfItemDetailToShow = (nft: NftMintIdRes) => {
    const itemsToShow = nft.itemDetails.filter((item) => item.key !== 'bundleCoin');
    const substringKeyURL = 'url';
    const substringValueURL = 'https://';

    return itemsToShow.map((id) => {
      return (
        <div key={id.uuid} className="inline-block p-4 rounded mr-4 mb-4 bg-gray-200">
          <span>{id.friendlyName || camelToHuman(id.key)}</span>
          {id.key.indexOf(substringKeyURL) !== -1 && id.value.indexOf(substringValueURL) !== 1 ? (
            <a className="text-lg font-medium ml-4" href={id.value} target="_blank" rel="noreferrer">
              Click Here
            </a>
          ) : (
            <span className="text-lg font-bold ml-4">{id.value}</span>
          )}
        </div>
      );
    });
  };

  useEffect(() => {
    if (nftMintId) {
      mintIdFetchQuery({ nftMintId, currencyPreferences });
      setItemQuantity({});
    }
  }, [mintIdFetchQuery, nftMintId, currencyPreferences]);

  useEffect(() => {
    const { status = '', response = { data: {} } } = mintIdFetchResponse || {};
    if (status === 'error' || (status === 'resolved' && !response?.data)) {
      navigate('/not-found');
    }
  }, [mintIdFetchResponse, navigate]);

  useEffect(() => {
    const { status } = mintIdFetchResponse || {};

    if (status === 'resolved') {
      const { name = '', nfts } = mintIdFetchResponse.response?.data || {};

      if (addCartState) {
        if (nfts) {
          const { sellerId, salePrice, quantity, mintRank } = dataAddCart;
          let nftItemToAdd: string[];

          // Add selected nft to cart if is a ranked singleton
          if (mintRank) {
            const mintRankNftItem = nfts.filter((n) => n.mintRank === `${mintRank}`)[0];
            nftItemToAdd = [mintRankNftItem.uuid];
          } else {
            const nftsArr: string[] = [];
            nfts?.forEach((n) => {
              if (n.salePrice === salePrice && n.sellerId === sellerId) {
                nftsArr.push(n.uuid);
              }
            });
            nftItemToAdd = getRandomNftItems({ nfts: nftsArr, quantity });
          }

          // Track event for each uuid
          nftItemToAdd.forEach((n) => {
            Mixpanel.track(
              MixpanelEvents.ADD_NFT_CART,
              {
                'NFT UUID': n,
                'NFT Name': name,
              },
              user,
            );
          });

          addCartItem(nftItemToAdd, goToCheckout);
        } else {
          toast.error('Not Available.');
        }

        setAddCartState(false);
      }
    }
  }, [mintIdFetchResponse, user, addCartState, addCartItem, dataAddCart, goToCheckout]);

  useEffect(() => {
    const { status } = mintIdFetchResponse || {};

    if (status === 'resolved' && !trackedViewNFT) {
      const response = mintIdFetchResponse.response!.data;

      setTrackedViewNFT(true);
      Mixpanel.track(
        MixpanelEvents.VIEW_NFT,
        {
          'NFT Mint ID': response.mintId,
          'NFT Seller ID': lowercaseFirstLetter(response.sellerId),
          'NFT Name': response.name,
          'NFT Category': response.category.friendlyName,
        },
        user,
      );
    }
  }, [mintIdFetchResponse, trackedViewNFT, user]);

  useEffect(() => {
    const { status } = mintIdFetchResponse || {};

    if (status === 'resolved' && mintIdFetchResponse.response?.data) {
      const nftItem = mintIdFetchResponse.response.data;

      FeatureFlags.on(FeatureFlagsEvents.SIMILAR_PRODUCTS, (treatment) => {
        if (treatment === 'on') {
          Mixpanel.register({ Related_Products: 'V1' });
          similarProductsFetchQuery({ nftItemUuid: nftItem.nfts[0].uuid });
          window.scrollTo(0, 0);
        } else {
          Mixpanel.register({ Related_Products: 'V0' });
        }
      });
    }
  }, [mintIdFetchResponse, similarProductsFetchQuery]);

  useEffect(() => {
    FeatureFlags.on(FeatureFlagsEvents.CHECKOUT_ENABLED, (treatment) => {
      setBuyEnabled(treatment === 'on');
    });
  }, []);

  const { rehypeReadMore, toggleReadMore, showReadMore, readMoreState } = useRehypeReadMore();

  if (!mintIdFetchResponse?.response?.data) {
    return null;
  }

  const nftItem = mintIdFetchResponse.response.data;
  nftItem.imageUrl = nftItem.imageUrl ? nftItem.imageUrl : '/NoCategoryWhite.png';

  const { abilities, rank, power, release, generation } = findDetailKeysOnItemDetails(
    ['abilities', 'rank', 'power', 'release', 'generation'],
    nftItem.itemDetails,
  );

  // Exclude any item with the same mint_id as the original item
  const similarProducts =
    similarProductsResponse?.response?.data?.nftItems?.filter((nftSimilar) => nftSimilar.mintId !== nftItem.mintId) ||
    [];

  // The maximum element that can be displayed is 4. The maximum size return service is 5
  if (similarProducts.length === 5) {
    similarProducts.pop();
  }

  // If the seller filter is set on the params, filter the items from the list to only show the items from that seller.
  let filterInventory = nftItem.inventory;
  if (
    Array.isArray(filterInventory) &&
    filterInventory.length &&
    searchParams.get('searchOnlySellerItems') === 'true' &&
    seller &&
    seller !== ''
  ) {
    filterInventory = nftItem.inventory.filter((inventory) => inventory.sellerId === seller);
  }

  const onBackButtonClick = () => {
    const state = location.state as { internalLink?: boolean };
    localStorage.setItem('currentRef', nftMintId || '');

    if ((state && state.internalLink) || location.search !== '') {
      navigate(-1);
    } else {
      navigate({
        pathname: generatePath(`/${AppRoutes.SEARCH_RESULTS}`),
      });
    }
  };

  const onAddCartButtonClick = (inv, quantitySelected) => {
    const quantity = itemQuantity[quantitySelected] || 1;
    const dataToCart = { ...inv, quantity };

    if (buyEnabled) {
      if (mintIdFetchResponse.status === 'loading') {
        return;
      }

      if (nftMintId) {
        setDataAddCart(dataToCart);
        setAddCartState(true);
        mintIdFetchQuery({ nftMintId, currencyPreferences });
      }
    } else {
      setPaneState(true);
      setMockDataAddCart({ quantity, totalAmount: quantity * dataToCart.salePrice });
    }
  };

  const nftItemDescription = (nft: NftMintIdRes) => {
    if (nft?.description) return nft.description;

    if (nft.nftType === 'LitPet')
      return 'LitPets help players of LitCraft Nysperience. They can be used in business to increase the productivity of a business. They can battle it out in the Heptone Battle Arena.';

    return 'No description available.';
  };

  return (
    <div className="w-full">
      <div className="px-2 sm:px-28">
        <Button.Link className="py-2 no-underline" onClick={onBackButtonClick}>
          <span className="text-black font-semibold">
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
            Back
          </span>
        </Button.Link>
      </div>
      <div className="px-5 sm:px-28 mt-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-14">
          <div className="row-span-1 h-full">
            <div className="min-w-full bg-gray-200 inline-block shadow-md rounded border border-solid border-gray-300">
              {nftItem?.nftType === 'LitPet' ? (
                <LitPetCard
                  name={nftItem?.name}
                  litPetImage={nftItem.imageUrl}
                  ability={abilities}
                  generation={generation}
                  power={Number(power)}
                  rank={rank}
                  release={Number(release)}
                />
              ) : (
                <Image
                  alt={nftItem?.name}
                  srcImg={nftItem?.imageUrl}
                  thumbnails={nftItem?.thumbnails}
                  width={1024}
                  className="rounded"
                />
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="grid grid-cols-4 grid-rows-2 gap-4 justify-between mt-5 lg:mt-0">
              <div className="grid-start-1 col-span-3 row-span-2">
                <h1 className="text-3xl font-black tracking-tight text-gray-900 mb-3">{nftItem.name}</h1>
                <Show show={!!nftItem.ipRightsLink}>
                  View{' '}
                  <a
                    className="truncate inline text-blue no-underline hover:underline text-md"
                    href={ipRightsLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Rights
                  </a>
                </Show>
              </div>
              <div className="row-span-2 flex flex-col">
                <div className="grow">
                  <h2 className="sr-only">Product Price {getSalePriceRange(nftItem.inventory)}</h2>
                  <p className="text-2xl text-gray-900 text-right mb-3">{getSalePriceRange(nftItem.inventory)}</p>
                </div>
                <div>
                  <QtyRemainingBox
                    quantity={filterInventory.length === 1 ? filterInventory[0].quantity : filterInventory.length}
                    mintTotal={nftItem?.mintTotal}
                  />
                </div>
              </div>
            </div>
            <ReactMarkdown
              className={`${nftShowContainerStyles.nftDescription} mt-4 mb-3 text-clip overflow-hidden`}
              remarkPlugins={[remarkGfm, remarkGemoji]}
              rehypePlugins={[[rehypeReadMore]]}
            >
              {nftItemDescription(nftItem)}
            </ReactMarkdown>
            <div className="self-end mb-3 flex items-center justify-between w-full">
              {showReadMore && (
                <Button.RoundedBlue type="button" onClick={toggleReadMore}>
                  {readMoreState ? 'Read less' : 'Read more'}
                </Button.RoundedBlue>
              )}
            </div>
            <HighlightedDetails itemDetails={nftItem?.itemDetails} />
            <hr />
            {filterInventory.length >= 1 ? (
              <div className={`${nftShowContainerStyles.nftCartItems}`}>
                <PurchaseOptionTable
                  {...{ itemQuantity, onAddCartButtonClick, setItemQuantity }}
                  nftItem={{ ...nftItem, inventory: filterInventory }}
                />
              </div>
            ) : (
              <>
                <div className="mt-4 font-bold">${formatCurrencyWithoutDenomination(nftItem.salePrice)}</div>
                <Button.Primary type="button" className="mt-4 gtm_addToCart" data-gtm="addToCart" disabled>
                  Not Available
                </Button.Primary>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="px-5 sm:px-28">
        <Show show={nftItem.itemDetails.length > 0}>
          <h3 className="font-bold mt-12">Details</h3>
          <div>{ntfItemDetailToShow(nftItem)}</div>
        </Show>
        <Show show={nftItem.searchTerms.length > 0}>
          <h3 className="font-bold mt-12">Tags</h3>
          <div>
            {nftItem.searchTerms.map((st) => {
              return (
                <div key={st.uuid} className="inline-block px-5 py-3 rounded-full mr-2 mb-2 bg-gray-200">
                  <span>{st.term}</span>
                </div>
              );
            })}
          </div>
        </Show>
        <SlidingPane
          className="sliding-pane max-w-2xl pt-20 md:pt-0"
          overlayClassName="sliding-pane-overlay"
          isOpen={paneState}
          width="auto"
          onRequestClose={() => {
            setPaneState(false);
          }}
        >
          <div>{!buyEnabled && <MockCheckoutFormContainer {...mockDataAddCart} />}</div>
        </SlidingPane>
      </div>
      {similarProducts.length > 0 && (
        <div className="px-0 sm:px-28 mt-4">
          <h3 className="font-bold mt-8">Shop for related products</h3>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 p-8 my-3 bg-gray-100">
            {similarProducts.map((nft) => (
              <ResultCard
                key={`similar-products-card-${nft.uuid}`}
                mapKey={`similar-products-card-${nft.uuid}`}
                result={nft}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
});
