import React from 'react';
import classnames from 'classnames';
import devvXLoader from 'src/assets/images/loader.svg';

import style from './Loader.module.css';

export interface LoaderProps {
  alt: string;
  className?: string;
}

export const Loader = React.memo(({ alt, className }: LoaderProps) => {
  return (
    <div className={classnames(style.loaderContainer, className)}>
      <div className={style.load} />
      <img className="max-w-[52px]" src={devvXLoader} alt={alt} />
    </div>
  );
});
