import React, { SVGProps } from 'react';

interface Props extends SVGProps<any> {
  iconFill?: string;
}

const CloseIcon: React.FC<Props> = ({ iconFill, ...others }) => {
  return (
    <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" {...others}>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="1-Menu" transform="translate(-240.000000, -24.000000)" fill="#000000" fillRule="nonzero">
          <g id="close" transform="translate(240.000000, 24.000000)">
            <path
              d="M9.97500482,7.50048069 L14.6154586,2.8600269 C15.1281805,2.34730499 15.1281805,1.51605463 14.6154586,1.00413383 L13.9968275,0.385502809 C13.4839454,-0.127379361 12.652695,-0.127379361 12.1407742,0.385502809 L7.50048069,5.02579634 L2.8600269,0.384541435 C2.34730499,-0.128180478 1.51605463,-0.128180478 1.00413383,0.384541435 L0.384541435,1.00317246 C-0.128180478,1.51605463 -0.128180478,2.34730499 0.384541435,2.85922578 L5.02579634,7.50048069 L0.385502809,12.1407742 C-0.127379361,12.6536564 -0.127379361,13.4849067 0.385502809,13.9968275 L1.00413383,14.6154586 C1.51685575,15.1281805 2.34810615,15.1281805 2.8600269,14.6154586 L7.50048069,9.97500482 L12.1407742,14.6154586 C12.6536564,15.1281805 13.4849067,15.1281805 13.9968275,14.6154586 L14.6154586,13.9968275 C15.1281805,13.4839454 15.1281805,12.652695 14.6154586,12.1407742 L9.97500482,7.50048069 Z"
              id="Path"
              className={iconFill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CloseIcon;
