import { lazy, Suspense, useContext } from 'react';

import ExchangeLayout from 'src/common/components/exchange/ExchangeLayout';
import ExchangeMarketStats from 'src/common/components/exchange/market-stats/MarketStats';
import { ExchangeContext } from 'src/common/context/ExchangeContext';

const ExchangeOpenOrders = lazy(() => import('src/common/components/exchange/open-filled-orders/ExchangeOpenOrder'));
const ExchangeRealtimeOrders = lazy(() => import('src/common/components/exchange/realtime/ExchangeRealtimeOrders'));
const ExchangeBuySellOrder = lazy(() => import('src/common/components/exchange/sell-order/ExchangeBuySellOrder'));
const ExchangeOrderBook = lazy(() => import('src/common/components/exchange/orderbook/ExchangeOrderBook'));

export const ExchangeContainer: React.FC = () => {
  const { isExchangeEnabled } = useContext(ExchangeContext);

  return isExchangeEnabled ? (
    <Suspense fallback={null}>
      <ExchangeLayout>
        <ExchangeBuySellOrder />
      </ExchangeLayout>
    </Suspense>
  ) : null;
};

export const ExchangeOpenOrderContainer: React.FC = () => {
  const { isExchangeEnabled } = useContext(ExchangeContext);

  return isExchangeEnabled ? (
    <Suspense fallback={null}>
      <ExchangeLayout>
        <ExchangeOpenOrders />
      </ExchangeLayout>
    </Suspense>
  ) : null;
};

export const ExchangeOrderBookContainer: React.FC = () => {
  const { isExchangeEnabled } = useContext(ExchangeContext);

  return isExchangeEnabled ? (
    <Suspense fallback={null}>
      <ExchangeLayout>
        <ExchangeOrderBook />
      </ExchangeLayout>
    </Suspense>
  ) : null;
};
export const ExchangeRealtimeContainer: React.FC = () => {
  const { isExchangeEnabled } = useContext(ExchangeContext);

  return isExchangeEnabled ? (
    <Suspense fallback={null}>
      <ExchangeLayout>
        <ExchangeRealtimeOrders />
      </ExchangeLayout>
    </Suspense>
  ) : null;
};

export const ExchangeMarketStatsContainer: React.FC = () => {
  const { isExchangeEnabled } = useContext(ExchangeContext);
  return isExchangeEnabled ? (
    <Suspense fallback={null}>
      <ExchangeLayout>
        <ExchangeMarketStats />
      </ExchangeLayout>
    </Suspense>
  ) : null;
};
