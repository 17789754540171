import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SelectFilterFormOption } from '.';

interface SelectFilterCategoryProps {
  onApply: (categories: string[]) => void;
  onUnmount: () => void;
  onMount: () => void;
  name: string;
  options: SelectFilterFormOption[];
}

export const SelectFilterCategory = ({ options, onApply, name, onUnmount, onMount }: SelectFilterCategoryProps) => {
  const [searchParams] = useSearchParams();
  const [categorySelected, setCategorySelected] = useState<string[]>(searchParams.getAll('categories') || []);

  const handleSelectCategory = (selectedItem: string, isSelected: boolean) => {
    const newSelections = !isSelected
      ? categorySelected.concat([selectedItem])
      : categorySelected.filter((categoryName) => categoryName !== selectedItem);
    setCategorySelected(newSelections);

    onApply(newSelections);
  };

  useEffect(() => {
    const categoriesFromUrl = searchParams.getAll('categories');
    if (categoriesFromUrl !== categorySelected) {
      setCategorySelected(categoriesFromUrl || []);
    }
  }, [searchParams]);

  useEffect(() => {
    onMount();
    return () => {
      onUnmount();
    };
  }, []);

  return (
    <div className="flex flex-wrap" aria-label={`${name} select filter`}>
      {options?.map((category) => {
        const isSelected = categorySelected.includes(category.value);
        return (
          <span
            className={`cursor-pointer rounded-lg px-3 py-1 m-2 text-base ${
              isSelected ? 'bg-gray-500 text-white' : 'bg-gray-300'
            }`}
            key={`listCategory=${category.name}`}
            onClick={() => handleSelectCategory(category.value, isSelected)}
          >
            {category.name}
          </span>
        );
      })}
    </div>
  );
};
