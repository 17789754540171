/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { orderStatus } from 'src/interfaces/ExchangeOrder';
import { cancelPatch, get, post } from './exchangeClient';

import {
  ExchangeErrorRes,
  ExchangeOrderParams,
  ExchangeTradingPair,
  GetExchangeUserOrdersResponse,
  GetExchangeUserResponse,
  UserBalanceResponse,
  GetExchangeSystemOrdersResponse,
  GetExchangeTradePerformance,
  PostExchangeUserOrderResponse,
  GetExchangeBalanceVariation,
} from '../interfaces';

export interface SystemRequestProps {
  limit?: number;
  status?: string;
}

export interface SystemOrdersFilters {
  limit?: number;
  status?: string;
  side?: string;
  trading_pair?: string;
}

export interface TradeVolume {
  period: string;
  timeInterval: string;
  assetName?: string;
}

export const ExchangeApi = {
  exchange: {
    getMyUser: () => {
      return get<GetExchangeUserResponse>(`/api/users/me`);
    },
    getMyOrders: (status = 'pending') => {
      return get<GetExchangeUserOrdersResponse[]>(`/api/orders/me?status=${status}`);
    },
    getSystemOrders: ({
      limit = 100,
      status = orderStatus.fulFilled.toLocaleLowerCase(),
      side,
      trading_pair,
    }: SystemOrdersFilters): Promise<GetExchangeSystemOrdersResponse> =>
      get<GetExchangeSystemOrdersResponse>('/api/orders', { params: { limit, status, side, trading_pair } }),
    postOrder: (order: ExchangeOrderParams) => {
      return post<PostExchangeUserOrderResponse>('/api/orders', order);
    },
    createUser: (user) => {
      return post<GetExchangeUserOrdersResponse[]>('/api/users', user);
    },
    cancelOrder: (orderId: string): Promise<AxiosResponse<PostExchangeUserOrderResponse, any>> => {
      return cancelPatch<PostExchangeUserOrderResponse>(`/api/orders/cancel/${orderId}`, { id: orderId });
    },
    getTradingPairs: () => {
      return get<ExchangeTradingPair[]>(`/api/orders/trading-pairs`);
    },
    getBalances: () => {
      return get<Array<UserBalanceResponse> | ExchangeErrorRes>(`/api/users/me/assets`);
    },
    getBalanceVariationForCoin: (coinId = '', timeRange = '') => {
      return get<GetExchangeBalanceVariation>(
        `api/analytics/me/balances/variation?coin_id=${coinId}&time_range=${timeRange}`,
      );
    },
    getTradesPerformance: (timeRange, timeInterval, coinId) => {
      return get<GetExchangeTradePerformance>(
        `api/analytics/trades/performance?time_range=${timeRange}&time_interval=${timeInterval}&coin_id=${coinId}`,
      );
    },
    getMyTradesPerformance: (timeRange, timeInterval, coinId) => {
      return get<GetExchangeTradePerformance>(
        `api/analytics/me/balances/performance?time_range=${timeRange}&time_interval=${timeInterval}&coin_id=${coinId}`,
      );
    },
    postIngestBalances: () => {
      return post(`/api/users/me/ingest/balances`, null);
    },
  },
};
