import { useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

interface ScrollToTopProps {
  atMount?: boolean;
  pathnameChanged?: boolean;
  searchChanged?: boolean;
  hashChanged?: boolean;
  searchIncludes?: string[];
}

const scrollTopFn = () => {
  window.scrollTo(0, 0);
};

const ScrollToTop = ({
  pathnameChanged = false,
  searchChanged = false,
  hashChanged = false,
  atMount = false,
  searchIncludes = [],
}: ScrollToTopProps) => {
  const prevValues = useRef<Record<string, string>>({});
  const { pathname, hash } = useLocation();
  const [searchParams] = useSearchParams();

  const includedParamsObject = Array.from(searchParams.entries())
    .filter(([key]) => searchIncludes?.includes(key))
    .reduce((paramsObject, [key, value]) => {
      Reflect.set(paramsObject, key, value);
      return paramsObject;
    }, {});

  if (JSON.stringify(prevValues.current) !== JSON.stringify(includedParamsObject)) {
    prevValues.current = includedParamsObject;
  }

  useEffect(() => {
    if (atMount) {
      scrollTopFn();
    }
  }, [atMount]);

  useEffect(() => {
    if (pathnameChanged) {
      scrollTopFn();
    }
  }, [pathname, pathnameChanged]);

  useEffect(() => {
    if (searchChanged) {
      scrollTopFn();
    }
  }, [prevValues.current, searchChanged]);

  useEffect(() => {
    if (hashChanged) {
      scrollTopFn();
    }
  }, [hash, hashChanged]);

  return null;
};

ScrollToTop.defaultProps = {
  pathnameChanged: false,
  searchChanged: false,
  hashChanged: false,
  atMount: false,
};

export default ScrollToTop;
