import React, { useContext, useEffect, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { Api, LoginParams, LoginRes, useLazyQuery } from 'src/api';
import { UserContext } from 'src/common/context/UserContext';
import { Input } from 'src/common/components/forms/Input';
import { FormGroup } from 'src/common/components/forms/FormGroup';
import { PasswordInput } from 'src/common/components/forms/PasswordInput';
import { Button } from 'src/common/interactions/Button';
import { getFormError, sha256, Mixpanel, MixpanelEvents } from 'src/common/util';
import { AuthRoot, AuthRoutes } from 'src/routes/auth/auth-routes';
import { Env } from 'src/common/env';
import glassblockLogoMarket from 'src/assets/images/glassblock-marketplace-logo.svg';
import authStyles from '../Auth.module.scss';

interface FormData {
  username: string;
  password: string;
}

export const SignInContainer = React.memo(() => {
  const { logIn } = useContext(UserContext);
  const [loginQuery, loginResponse] = useLazyQuery<LoginParams, LoginRes>(Api.proxy.login);
  const [responseError, setResponseError] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors: formError },
  } = useForm<FormData>();

  const handleClearError = () => {
    setResponseError('');
  };

  const onSubmit = ({ username, password }: FormData) => {
    if (loginResponse.status === 'loading') {
      return;
    }
    loginQuery({
      user: username,
      pass: sha256(password + username.toLowerCase()),
    });
  };

  useEffect(() => {
    if (loginResponse.status === 'resolved') {
      const { uuid, pub, message, emailVerified, roles, username, email } = loginResponse.response!;

      if (uuid && pub) {
        Mixpanel.peopleSet({ email, userName: username });
        Mixpanel.track(MixpanelEvents.LOGIN, { username, email });
        logIn({ username, uuid, pub, emailVerified, roles, email });
      } else if (message) {
        setResponseError(message);
      } else {
        setResponseError('Unable to login.');
      }
    } else if (loginResponse.status === 'error') {
      setResponseError('Unable to login.');
    }
  }, [logIn, loginResponse]);

  const error = responseError || getFormError(formError);

  return (
    <div className="flex flex-grow items-center justify-center flex-col">
      <div className="md:w-[32rem] md:border md:rounded md:shadow-lg md:px-16 px-2 pb-12 py-12">
        <img className="w-full mx-auto mb-8" src={glassblockLogoMarket} alt="Glassblock Marketplace" />
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <FormGroup onClick={handleClearError}>
            <Input
              {...register('username', { required: 'Username is required.' })}
              className={`${authStyles.authInput} ${formError.username && authStyles.hasError}`}
              type="text"
              placeholder="Username"
              aria-label="Username"
            />
          </FormGroup>
          <FormGroup className="mb-4" onClick={handleClearError}>
            <PasswordInput
              {...register('password', { required: 'Password is required.' })}
              className={`${authStyles.authInput} ${formError.password && authStyles.hasError}`}
              placeholder="Password"
              aria-label="Password"
            />
          </FormGroup>
          <div className="text-center text-red-500 my-4" aria-label="error">
            {error}
          </div>
          <div className="text-center">
            <Button.Primary>
              <span className="mx-10">Login</span>
              <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
            </Button.Primary>
          </div>
        </form>
        <div className="text-center mt-4">
          <Link className="text-blue no-underline" to={generatePath(`/${AuthRoot}/${AuthRoutes.REGISTER}`)}>
            Don&apos;t have an account?
          </Link>
        </div>
        <div className="text-center mt-4">
          <a className="text-blue no-underline" href={Env.authHost}>
            Unable to log in?
          </a>
        </div>
      </div>
    </div>
  );
});
