import React, { ReactText, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { UserContext } from 'src/common/context/UserContext';
import { CartContext } from 'src/common/context/CartContext';
import { AuthRoot, AuthRoutes } from 'src/routes/auth/auth-routes';
import { checkCart } from 'src/api';
import { wrappedToast } from 'src/common/util/alerts';
import { CheckoutFormContainer } from './CheckoutFormContainer';

export const CheckoutResolver = React.memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, isLoading: userIsLoading } = useContext(UserContext);
  const { cart, clearCartCookie, removeCartItem } = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(true);
  const checkoutToast = useRef<null | ReactText>(null);

  const sendCheckoutWarning = (toastFn: () => ReactText) => {
    if (!toast.isActive(checkoutToast.current as ReactText)) {
      checkoutToast.current = toastFn();
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (userIsLoading === false) {
      if (!user?.uuid) {
        wrappedToast.warning({ title: 'You must login or register before checking out' });
        navigate(`/${AuthRoot}/${AuthRoutes.LOGIN}`, { state: { pathBeforeLogin: location.pathname }, replace: true });
      } else if (cart.length === 0) {
        // If we get here and there's nothing in the cart we should redirect to the landing page
        sendCheckoutWarning(() =>
          wrappedToast.warning({
            title: 'No items in your cart',
            options: { toastId: 'cart-items-removed-toast' },
          }),
        );
        navigate(`/`, { replace: true });
      } else {
        const nftItems = cart.map((item) => ({ uuid: item.uuid }));

        checkCart(nftItems)
          .then((result) => {
            if (result && result.nftItems.length > 0) {
              const uuidsToRemove = result.nftItems.map((item) => item.uuid);
              removeCartItem(uuidsToRemove);
              clearCartCookie();
              if (result.nftItems.length === uuidsToRemove.length) {
                sendCheckoutWarning(() =>
                  wrappedToast.warning({
                    title: 'Some items in your cart are no longer available.',
                    options: { toastId: 'cart-items-removed-toast' },
                  }),
                );
              }
            } else {
              setIsLoading(false);
            }
          })
          .catch((error) => {
            toast.error(error.message);
            navigate(`/`, { replace: true });
          });
      }
    }
  }, [userIsLoading, user, location.pathname, navigate, cart, removeCartItem]);

  return isLoading ? <div>Loading...</div> : <CheckoutFormContainer />;
});
