import React, { useCallback, useContext } from 'react';
import { CartContext } from 'src/common/context/CartContext';
import { CheckoutContext } from 'src/common/context/CheckoutContext';
import { BackButton } from '../buttons/BackButton';
import { CheckoutpromoCode } from '../checkout-promo-code/CheckoutpromoCode';
import ToolTip from '../exchange/ToolTip/ToolTip';
import { CartItemRow } from '../nft-items/CartItemRow';

export const CheckoutOrderDetails = React.memo(() => {
  const { nftItems, totalCount, devvPayOption, setIsLastItem } = useContext(CheckoutContext);
  const { cart, removeCartItem } = useContext(CartContext);

  const handleItemRemove = useCallback(
    (nftUuid: string) => {
      let result: boolean;
      if (cart.length > 1) {
        // eslint-disable-next-line no-alert
        result = window.confirm('Would you like to remove this item from your cart?');
        if (!result) {
          return;
        }
      } else {
        setIsLastItem(true);
        // eslint-disable-next-line no-alert
        result = window.confirm('Are you sure you want to leave? The items in your cart may no longer be available.');
        if (!result) {
          setIsLastItem(false);
          return;
        }
      }

      removeCartItem(nftUuid);
    },
    [cart, removeCartItem, setIsLastItem],
  );

  return (
    <div className="px-4 md:px-10 pt-8 pb-8 md:pb-40 md:fixed md:h-full min-w-[50%]">
      <div className="items-start justify-content-start mb-4 flex md:hidden">
        <BackButton />
      </div>
      <div className="flex flex-col h-full">
        <h3 className="font-bold">Order Details</h3>
        <section className="flex flex-row justify-between items-center bg-gray-100 p-3 rounded shadow">
          <div>
            {totalCount} {totalCount === 1 ? 'Item' : 'Items'}
          </div>
          <ToolTip title="Currency" className="ml-1 text-blue-400" position="left" />
        </section>
        <section className="flex-grow overflow-auto">
          {nftItems?.map((item) => (
            <CartItemRow key={item.uuid} nftItem={item} onRemove={handleItemRemove} devvPayOption={devvPayOption} />
          ))}
        </section>
        <CheckoutpromoCode />
      </div>
    </div>
  );
});
