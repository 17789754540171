import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import coinbaseLogo from 'src/assets/images/coinbase-icon.svg';
import { Input } from 'src/common/components/forms/Input';
import { Label } from 'src/common/components/forms/Label';
import { Button } from 'src/common/interactions/Button';
import { formatCurrencyWithoutDenomination } from 'src/common/util';

type CheckoutFormContainerProps = {
  quantity: number;
  totalAmount: number;
};

export const MockCheckoutFormContainer = React.memo((props: CheckoutFormContainerProps) => {
  const { totalAmount, quantity } = props;

  return (
    <div className="grid grid-cols-3 gap-3">
      <section className="col-span-3 flex flex-col md:flex-row md:items-center justify-between pb-5">
        <div>
          <h3 className="text-2xl font-bold mb-2 md:mb-0">Checkout</h3>
        </div>
      </section>
      <div className="col-span-3 bg-zest text-white p-3">
        <FontAwesomeIcon icon={faShoppingCart} className="mr-2" />
        <span>Checkout coming soon!</span>
      </div>

      <section className="col-span-3 flex justify-between items-center bg-gray-200 p-3">
        <div className="opacity-50">
          {quantity} {quantity > 1 ? 'Items' : 'Item'}
        </div>
        <div className="opacity-50">
          Total &nbsp;<span className="font-bold">${formatCurrencyWithoutDenomination(totalAmount)}</span>
        </div>
      </section>

      <div className="col-span-3 mt-2">
        <h2 className="text-2xl font-bold text-gray-400">Your Information</h2>
        <Label className="uppercase mt-3 text-gray-400">Your Name</Label>
        <Input className="border mt-2" disabled />
        <Label className="uppercase mt-3 text-gray-400">Street Address</Label>
        <Input className="border mt-2" disabled />
        <Label className="uppercase mt-3 text-gray-400">Address Line 2</Label>
        <Input className="border mt-2" disabled />
        <div className="grid grid-cols-4 gap-3">
          <div className="col-span-4 sm:col-span-2">
            <Label className="uppercase mt-3 text-gray-400">City</Label>
            <Input className="border mt-2" disabled />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <Label className="uppercase mt-3 text-gray-400">State</Label>
            <Input className="border mt-2" disabled />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <Label className="uppercase mt-3 text-gray-400">Zip</Label>
            <Input className="border mt-2" disabled />
          </div>
        </div>
        <Label className="uppercase mt-3 text-gray-400">Phone</Label>
        <Input className="border mt-2 !w-1/2" disabled />
      </div>
      <section className="col-span-3">
        <hr className="my-5 border-t-2" />
        <Button.Muted className="w-full flex items-center justify-center" type="button" disabled>
          <img className="inline w-6 mr-2" src={coinbaseLogo} alt="" />
          Pay with Crypto
        </Button.Muted>
      </section>
    </div>
  );
});
