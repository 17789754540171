/* eslint-disable camelcase */
import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { formatDetailsLink } from '../orderHistory.service';

export interface OrderHistoryRowType {
  createdAt: string;
  name: string;
  creator?: string;
  category: string;
  details: string;
  salePrice: number;
}

const OrderHistoryRow: React.FC<OrderHistoryRowType> = ({ createdAt, name, creator, category, details, salePrice }) => {
  const renderTD = (item, addClassName = '') => (
    <td width="150px" className={`text-sm font-medium px-4 min-width-[150px] ${addClassName}`}>
      {item}
    </td>
  );
  return (
    <tr className="border-b">
      <td className="text-sm font-medium px-4">{dayjs(createdAt).format('MM/DD/YYYY')}</td>
      <td className="text-sm font-medium px-4 lg:w-[400px]">{name}</td>
      <td className="text-sm font-medium px-4">{creator}</td>
      {renderTD(category, 'capitalize')}
      {renderTD(`USD $${salePrice?.toFixed(2) || 0.0}`)}
      <td className="text-sm font-light px-2">
        <Link to={formatDetailsLink(details)}>
          <FontAwesomeIcon icon={faAngleRight} className="cursor-pointer" />
        </Link>
      </td>
    </tr>
  );
};

export default OrderHistoryRow;
