/* eslint-disable camelcase */
import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { formatDetailsLink } from 'src/common/components/tables/OrderHistoryTable/orderHistory.service';

export interface MyOrderHistoryRowType {
  id: string;
  createdAt: string;
  item_name: string;
  creator?: string;
  category_name: string;
  details: string;
  salePrice: number;
  payment_method: string;
}

const MyOrderHistoryRow: React.FC<MyOrderHistoryRowType> = ({
  id,
  createdAt,
  item_name,
  creator,
  category_name,
  details,
  salePrice,
  payment_method,
}) => {
  const renderTD = (item, addClassName = '') => (
    <td width="150px" className={`text-sm font-medium px-4 min-width-[150px] ${addClassName}`}>
      {item}
    </td>
  );
  return (
    <tr className="border-b">
      <td className="px-4 py-1 text-sm font-medium">{id}</td>
      <td className="text-sm font-medium px-4">{dayjs(createdAt).format('MM/DD/YYYY')}</td>
      <td className="text-sm font-medium px-4 lg:w-[400px]">{item_name}</td>
      <td className="text-sm font-medium px-4">{creator}</td>
      {renderTD(category_name, 'capitalize')}
      {renderTD(`USD $${salePrice.toFixed(2)}`)}
      <td className="text-sm font-medium px-4">{payment_method}</td>
      <td className="text-sm font-light px-2">
        <Link to={formatDetailsLink(details)}>
          <FontAwesomeIcon icon={faAngleRight} className="cursor-pointer" />
        </Link>
      </td>
    </tr>
  );
};

export default MyOrderHistoryRow;
