import { useContext, useEffect, useState } from 'react';
import NVD3Chart from 'react-nvd3';

import { TabPanel, useTabs } from 'react-headless-tabs';
import { useQuery } from 'react-query';
import {
  getBalances as exchangeGetBalances,
  getMyBalancesVariations,
  getMyTradesPerformance,
  postIngestBalances,
} from 'src/api/exchange';
import { ExchangeUserContext } from 'src/common/context/ExchangeUserContext';
import { Env } from 'src/common/env';
import { GetExchangeBalanceVariation, GetExchangeTradePerformance } from 'src/interfaces';
import { TabSelector } from '../TabSelector';

import { EmptyTableBody } from '../../tables/EmptyTable/EmptyTableBody';
import ExchangeHeaderRow from '../ExchangeHeaderRow';
import AvailableAndPendingTokenViewCard from './AvailableAndPendingTokenViewCard';
import ProfitIndicator from './ProfitIndicator';
import { timeFrame } from '../market-stats/MarketStats';

const ExchangeMyAssets = (isDashboard?) => {
  const [selectedTab, setSelectedTab] = useTabs(['assets', 'deposit', 'withdraw']);
  const [cacheIntervalMs, setcacheIntervalMs] = useState(10000);
  const [refetchIntervalMs, setRefetchIntervalMs] = useState(10000);
  const { tradingPairs } = useContext(ExchangeUserContext);
  const { selectedCoinPair } = useContext(ExchangeUserContext);
  const [balanceVariation, setBalanceVariation] = useState<GetExchangeBalanceVariation | null>(null);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(timeFrame[2]);
  const [allBalancesVariations, setAllBalancesVariations] = useState<GetExchangeBalanceVariation[] | null>([]);
  const [active, setActive] = useState(selectedTimeFrame.code);

  const {
    isLoading,
    isError,
    data: balances,
  } = useQuery('userBalances', exchangeGetBalances, {
    cacheTime: cacheIntervalMs,
    refetchInterval: refetchIntervalMs,
  });
  const tradingPair = tradingPairs.find(
    (tradingPair) =>
      tradingPair.base_coin_id === selectedCoinPair?.base_coin_id &&
      tradingPair.quote_coin_id === selectedCoinPair.quote_coin_id,
  );

  const [data, setGraphData] = useState<GetExchangeTradePerformance | [] | any>([]);

  useEffect(() => {
    if (tradingPair) {
      getMyBalancesVariations(tradingPair.base_coin_id, selectedTimeFrame.code).then((result) =>
        setBalanceVariation(result),
      );
      getMyTradesPerformance(selectedTimeFrame.code, selectedTimeFrame.time_interval, tradingPair.base_coin_id).then(
        (result) => setGraphData(result),
      );
    }
  }, [selectedTimeFrame, setBalanceVariation, tradingPair, tradingPair?.base_coin_id]);

  useEffect(() => {
    const balancesVariations: GetExchangeBalanceVariation[] = [];
    tradingPairs.forEach((tp) => {
      getMyBalancesVariations(tp.base_coin_id, selectedTimeFrame.code).then((result) =>
        balancesVariations.push(result),
      );
    });
    setAllBalancesVariations(balancesVariations);
  }, [selectedTimeFrame, tradingPairs]);

  useEffect(() => {
    postIngestBalances();
    if (Env.exchangeCacheMyAssets) {
      setcacheIntervalMs(Number(Env.exchangeCacheMyAssets));
    }
    if (Env.exchangeRefetchMyAssets) {
      setRefetchIntervalMs(Number(Env.exchangeRefetchMyAssets));
    }
  }, []);

  const hasBalances = !isError && !isLoading && balances && balances.length > 0;
  const showControls = true;
  const showLegend = false;
  return (
    <div className="mr-auto ml-auto lg:shadow-lg rounded-lg lg:mt-8 lg:p-4 lg:pt-0 lg:bg-white md:max-w-[1081px] md:min-w-[500px] iphone5:min-w-0 iphone5:w-[325px] mobile:w-[365px]  customSm:w-[365px] md:pl-4 md:pr-4   mobile:ml-0 mobile2:w-[365px] mobile2:ml-0 lg:min-w-[750px] relative">
      <div>
        <ExchangeHeaderRow title="My Assets" justTitle />
      </div>
      {isDashboard && (
        <div className="bg-white rounded-lg border-2  lg:border-none lg:bg-none  mt-4 ml-4 mr-4">
          <div className="flex mt-4 justify-start mb-4">
            <div className="w-full p-2">
              <ul className="flex gap-4 flex-wrap">
                {timeFrame.map((tf) => {
                  return (
                    <li
                      key={tf.code}
                      className={`rounded-2xl gap-2 text-center p-2 cursor-pointer justify-center flex items-center ${
                        active === tf.code ? 'bg-[#0066CC] text-[#fff]' : ''
                      }`}
                      onClick={() => {
                        setSelectedTimeFrame(tf);
                        setActive(tf.code);
                      }}
                    >
                      {tf.label}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <NVD3Chart
            duration={100}
            type="lineChart"
            showControls={showControls}
            showLegend={showLegend}
            tooltips
            renderStart={() => {
              (window as any).d3.selectAll('.nvtooltip').remove();
            }}
            showValues
            x="date"
            xScale={(window as any).d3.time.scale()}
            xAxis={{
              tickFormat: (d) => {
                return (window as any).d3.time.format(selectedTimeFrame && selectedTimeFrame.d3_time_format)(
                  new Date(d),
                );
              },
              axisLabel: 'Time',
            }}
            yAxis={{
              tickFormat: (d) => {
                return (window as any).d3.format(',.2f')(d);
              },
              axisLabel: 'Heptal Value',
              axisLabelDistance: 20,
            }}
            y="amount"
            clipEdge
            interpolate="linear"
            margin={{ top: 10, right: 50, bottom: 60, left: 120 }}
            height={550}
            strokeWidth={4.5}
            color="#0066CC"
            datum={
              data && data?.coordinates && data.coordinates?.length > 0
                ? [
                    {
                      key: `${tradingPair?.quote_coin_name?.toUpperCase()}`,
                      area: true,
                      color: '#0066CC',
                      strokeWidth: 4,
                      values: data.coordinates.map((coordinate) => ({
                        date: new Date(coordinate[0]),
                        amount: coordinate[1],
                      })),
                    },
                  ]
                : []
            }
          />
        </div>
      )}
      <nav className={`flex w-fit mt-4 mb-4 lg:hidden customSm:pl-6 customSm:pr-6 ${isDashboard ? 'hidden' : ''}`}>
        <TabSelector
          isActive={selectedTab === 'assets'}
          selectedTab={selectedTab || ''}
          onClick={() => setSelectedTab('assets')}
        >
          Assets
        </TabSelector>
        {/*  The code commented below is because this is not implemented yet.   */}
        {/* <TabSelector
            isActive={selectedTab === 'deposit'}
            selectedTab={selectedTab || ''}
            onClick={() => setSelectedTab('deposit')}
          >
            Deposit
          </TabSelector>
          <TabSelector
            isActive={selectedTab === 'withdraw'}
            selectedTab={selectedTab || ''}
            onClick={() => setSelectedTab('withdraw')}
          >
            Withdraw
          </TabSelector> */}
      </nav>

      <div className="mr-auto ml-auto xs:pl-0 xs:pr-0 customSm:pl-6 customSm:pr-6">
        <TabPanel hidden={selectedTab !== 'assets'} className="lg:justify-center">
          <div className={`lg:grid ${hasBalances ? 'lg:grid-cols-2' : ''}`}>
            {hasBalances ? (
              balances &&
              balances.map((balance) => {
                let logo;
                const currencyPair = tradingPairs.find(
                  (tp) => tp.quote_coin_name === balance.coin_name && tp.quote_coin_id === balance.coin_id,
                );
                if (currencyPair) {
                  logo = currencyPair.quote_coin_image_url;
                } else {
                  logo = tradingPairs.find(
                    (tradingPair) => tradingPair.base_coin_id === balance.coin_id,
                  )?.base_coin_image_url;
                }
                const balanceVariationItem =
                  allBalancesVariations && allBalancesVariations.find((bv) => bv && bv.coin_id === balance.coin_id);

                return (
                  <div className="mt-12 lg:even:border-l-2" key={balance.coin_id}>
                    <AvailableAndPendingTokenViewCard
                      balanceItem={balance}
                      icon={logo}
                      balanceVariation={balanceVariationItem}
                    />
                  </div>
                );
              })
            ) : (
              <EmptyTableBody type="My Assets" />
            )}
          </div>
        </TabPanel>
        {/* <TabPanel hidden={selectedTab !== 'deposit'}>B</TabPanel>
          <TabPanel hidden={selectedTab !== 'withdraw'}>C</TabPanel> */}
      </div>
    </div>
  );
};

export default ExchangeMyAssets;
