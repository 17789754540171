import React from 'react';
import { generatePath, Link } from 'react-router-dom';

import { AppRoutes } from 'src/routes/app';
import { Button } from 'src/common/interactions/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

export const ThankYou = () => (
  <div className="w-full flex flex-col justify-center items-center p-8">
    <h1 className="text-blue text-5xl font-bold">Thank You!</h1>
    <h2 className="text-2xl">
      We will attempt to process your return and issue you a refund. Someone from Glassblock Support will be in touch
      shortly.
    </h2>

    <Link to={generatePath(AppRoutes.LANDING)}>
      <Button.Primary data-gtm="backToMarketplace">
        Back to Marketplace <FontAwesomeIcon icon={faShoppingCart} className="ml-2" />
      </Button.Primary>
    </Link>
  </div>
);
