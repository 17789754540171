import React, { Fragment, PropsWithChildren } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { useController, UseControllerProps } from 'react-hook-form';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faCheck } from '@fortawesome/free-solid-svg-icons';

interface Option {
  name: string;
  icon?: string;
  value: Record<string, unknown>;
}

interface SelectProps {
  inputName: string;
  className?: string;
  options: Option[];
  showSelectIcon?: boolean;
}

export const Select = React.forwardRef<HTMLSelectElement, React.SelectHTMLAttributes<HTMLSelectElement>>(
  (props, ref) => {
    const { className, children, ...rest } = props;
    return (
      <select ref={ref} className={`block w-full py-2 border-neutral-400 rounded ${className || ''}`} {...rest}>
        {children}
      </select>
    );
  },
);

export const SelectBox: React.FC<PropsWithChildren<SelectProps & UseControllerProps>> = ({
  className,
  children,
  inputName,
  showSelectIcon = false,
  ...rest
}) => {
  const {
    field: { value: selected, onChange },
  } = useController(rest);
  const { options } = rest;

  return (
    <div className={`block w-full py-2 border-neutral-400 rounded ${className || ''}`} {...rest}>
      <Listbox value={selected} onChange={onChange} name={inputName}>
        {({ open }) => (
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2  text-left focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
              <span className="block truncate">{selected?.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <FontAwesomeIcon
                  icon={open ? faCaretUp : faCaretDown}
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm pl-0 pr-3 z-20">
                {options.map((option, optIndex) => (
                  <Listbox.Option
                    key={optIndex}
                    className={({ active }) =>
                      `relative w-full cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-blue-400 text-white' : 'text-gray-900'
                      }`
                    }
                    value={option}
                  >
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {option.name}
                        </span>
                        {!showSelectIcon && option?.icon ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-700">
                            {typeof option?.icon === 'string' ? (
                              <img className="h-4 w-4" src={option?.icon} alt={option?.name} aria-hidden="true" />
                            ) : null}
                          </span>
                        ) : null}

                        {selected && !showSelectIcon ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-700">
                            <FontAwesomeIcon icon={faCheck} className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
};
