import { useEffect, useState } from 'react';

export interface UseImageLoadOptions {
  crossOrigin?: string;
}

export const useImageLoad = (imageSrc: string, options: UseImageLoadOptions = {}) => {
  const { crossOrigin } = options;
  const [isLoaded, setIsLoaded] = useState(false);
  const [image, setImage] = useState<HTMLImageElement>();

  useEffect(() => {
    const imageInstance = new Image();

    function onLoad() {
      setIsLoaded(true);
      setImage(imageInstance);
    }

    imageInstance.addEventListener('load', onLoad);

    setIsLoaded(false);

    if (imageSrc.trim() !== '') {
      if (crossOrigin !== undefined) {
        imageInstance.crossOrigin = crossOrigin || '';
      }

      imageInstance.src = imageSrc;
    }

    return () => {
      imageInstance.removeEventListener('load', onLoad);
    };
  }, [imageSrc, crossOrigin]);

  return {
    isLoaded,
    image,
  };
};
