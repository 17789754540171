import { SVGProps } from 'react';

const DashboardIcon = ({ stroke = '#2F2F2F', ...props }: SVGProps<any>) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <rect id="path-tef36_gg_q-1" x="0" y="0" width="20" height="20" />
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="1-Menu" transform="translate(-25.000000, -207.000000)">
          <g id="home" transform="translate(25.000000, 207.000000)">
            <mask id="mask-tef36_gg_q-2" fill="white">
              <use xlinkHref="#path-tef36_gg_q-1" />
            </mask>
            <g id="home-(Background/Mask)" />
            <path
              d="M10,1.66666667 L17.5,7.5 L17.5,16.6666667 C17.5,17.1086942 17.3244049,17.532617 17.0118443,17.8451777 C16.6992837,18.1577383 16.2753608,18.3333333 15.8333333,18.3333333 L4.16666667,18.3333333 C3.72463917,18.3333333 3.30071591,18.1577383 2.98815529,17.8451777 C2.67559466,17.532617 2.5,17.1086942 2.5,16.6666667 L2.5,7.5 L10,1.66666667 Z"
              id="Vector"
              stroke={stroke}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="0,0"
              fillRule="nonzero"
              mask="url(#mask-tef36_gg_q-2)"
            />
            <path
              d="M7.5,18.3333333 L7.5,10 L12.5,10 L12.5,18.3333333"
              id="Vector"
              stroke={stroke}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="0,0"
              fillRule="nonzero"
              mask="url(#mask-tef36_gg_q-2)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DashboardIcon;
