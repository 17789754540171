import React, { useContext, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import cx from 'classnames';

import { Show } from 'src/common/layout';
import { Select } from 'src/common/components/forms/Select';
import { Label } from 'src/common/components/forms/Label';
import { DevvPayToken, HandleCompleteArgs } from 'src/interfaces';

import { DevvPayChargeButton } from 'src/common/components/checkout-form/devv-pay/DevvPayChargeButton';
import infoIcon from 'src/assets/images/info-icon.svg';
import checkoutStyles from 'src/routes/app/checkout/Checkout.module.scss';
import { CheckoutContext } from 'src/common/context/CheckoutContext';

export interface DevvPayFormData {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  country: string;
  coinName: string;
  discountCodeUuid?: string;
  district: string;
  email: string;
  name: string;
  postalCode: string;
}
type DevvPayFormProps = {
  devvPayOptions: DevvPayToken[];
  onStartCharge: () => void;
  onCompleteCharge: (args: HandleCompleteArgs) => void;
};

export const DevvPayForm = ({ devvPayOptions, onStartCharge, onCompleteCharge }: DevvPayFormProps) => {
  const {
    setIsDevvPayOptionSet,
    setDevvPayOption,
    devvPayOption,
    isCurrencyFromOptionDisabled,
    getCurrencyFromOptionReasons,
  } = useContext(CheckoutContext);

  const [devvPayTokens] = useState(devvPayOptions || []);

  const {
    control,
    register,
    formState: { errors: formErrors },
  } = useFormContext<DevvPayFormData>();

  const watchCoinName = useWatch({
    control,
    name: 'coinName',
  });

  setIsDevvPayOptionSet(watchCoinName && watchCoinName !== '');

  useEffect(() => {
    const filteredToken = devvPayTokens?.find((token) => token.coinName === watchCoinName);
    setDevvPayOption(filteredToken || null);
  }, [watchCoinName]);

  return (
    <div className="relative">
      <form>
        <div className="flex items-center">
          <Label htmlFor="coinName" className={checkoutStyles.checkoutLabel}>
            Accepted Tokens
            <span className="ml-1">*</span>
          </Label>
          <div className="hover:flex-1 ml-3">
            <div className="flex-col group items-center relative">
              <img className="h-4 w-4" src={infoIcon} alt="Information Icon" />
              <div className="absolute bottom-0 flex-col hidden group-hover:flex mb-6">
                <span className="rounded-md bg-info leading-none p-2 relative text-white text-xs whitespace-no-wrap z-10 shadow-inner shadow shadow-lg shadow-xl p-4 bg-gradient-to-r from-blue-400 to-blue-500 font-bold">
                  Current list of accepted payment tokens can be found below.
                </span>
              </div>
            </div>
          </div>
        </div>
        <Select
          id="coinName"
          className={cx(
            'border mt-2',
            checkoutStyles.checkoutInput,
            checkoutStyles.checkoutSelect,
            formErrors.coinName && checkoutStyles.hasError,
          )}
          {...register('coinName', { required: 'Devvio token is required.' })}
        >
          <option value="">Select</option>
          {devvPayTokens.map((token) => {
            const isDisabled = isCurrencyFromOptionDisabled('devvPay', token.currency.id || '');
            return (
              <option value={token.coinName} key={token.coinName} disabled={isDisabled}>
                {token.coinName} {`(${token.balance || 0} available)`}{' '}
                {isDisabled
                  ? ` - Disabled (Reason: ${getCurrencyFromOptionReasons('devvPay', token.currency.id || '')?.[0]})`
                  : null}
              </option>
            );
          })}
        </Select>
        <Show show={!!formErrors.coinName}>
          <div aria-label="error" className={checkoutStyles.checkoutError}>
            {formErrors.coinName?.message}
          </div>
        </Show>
        <Show show={!!devvPayOption && Number(devvPayOption.balance) === 0}>
          <div aria-label="warning" className={checkoutStyles.checkoutWarning}>
            To complete your purchase, you must have {watchCoinName} in the DevvX Inventory.
          </div>
        </Show>
      </form>
      <hr className="my-4" />
      <section>
        <DevvPayChargeButton onCompleteCharge={onCompleteCharge} onStartCharge={onStartCharge} />
        <p className="mt-1 text-sm italic">
          (<span>*</span>) Required
        </p>
      </section>
    </div>
  );
};
