import { createContext, memo, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Api } from 'src/api';

export interface IShardsContext {
  isShardDown: (shardUrl: string) => boolean;
}

const defaultContext: IShardsContext = {
  isShardDown: () => true,
};

export const ShardsContext = createContext<IShardsContext>(defaultContext);

const TWO_MINUTES_IN_MILLISECONDS = 2 * 60 * 1000;

export const ShardsProvider = memo(({ children }: PropsWithChildren<unknown>) => {
  const { data: dataShardsDown, isSuccess: isSuccessShardsDown } = useQuery(
    ['getShardsDown'],
    async () => Api.shards.getShardsDown(),
    {
      cacheTime: TWO_MINUTES_IN_MILLISECONDS,
      refetchOnMount: 'always',
      refetchOnWindowFocus: 'always',
    },
  );

  const [shardsDown, setShardsDown] = useState<Set<string>>(new Set());

  useEffect(() => {
    if (isSuccessShardsDown) {
      const newShardsDownMap: Set<string> = new Set();
      dataShardsDown?.shards?.forEach((shardDown) => {
        newShardsDownMap.add(shardDown);
      });

      setShardsDown(newShardsDownMap);
    }
  }, [dataShardsDown, isSuccessShardsDown]);

  const isShardDown = useCallback((shardUrl) => shardsDown.has(shardUrl), [shardsDown]);

  const providerValues = useMemo<IShardsContext>(
    () => ({
      isShardDown,
    }),
    [isShardDown],
  );

  return <ShardsContext.Provider value={providerValues}>{children}</ShardsContext.Provider>;
});
