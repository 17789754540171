import { OrderProperties, orderStatus } from 'src/interfaces/ExchangeOrder';
import { ExchangeTradingPair, GetExchangeUserOrdersResponse } from 'src/interfaces';
import quintLogo from '../../assets/images/quint.png';
import devveLogo from '../../assets/images/devve-logo.svg';
import heptalLogo from '../../assets/images/heptal.png';
import { ExchangeUserProps } from '../context/ExchangeUserContext';

export const coinIcons = {
  default: devveLogo,
  quint: quintLogo,
  devve: devveLogo,
  heptal: heptalLogo,
};

/*
 *
 * @param Array of orders and status
 * @returns Array of orders filtered by the status
 */
export const filterByStatus = (orders: OrderProperties[], status: string): OrderProperties[] => {
  return (
    orders &&
    orders.filter((order: any) => {
      return order.status.toLocaleLowerCase() === status ? status : orderStatus.pending.toLocaleLowerCase();
    })
  );
};

/*
 * Return Orders filtered by selected Coin
 * @param Array of orders and selected coin
 * @returns Array of orders filtered by Selected coin pair - selectedCoinId:
 */
export const filterByCoin = (
  orders: Array<GetExchangeUserOrdersResponse> | undefined | void,
  selectedCoin: ExchangeTradingPair | null,
) => {
  if (orders && selectedCoin) return orders.filter((order) => order.trading_pair === selectedCoin.uuid);
  return [];
};

/*
 * Return Orders sorted by most recent fullfilled or updated data
 * @param Array of orders
 * @returns Array of orders sorted by most recent filled:
 */
export const sortByRecent = (orders: any, filter: string) => {
  if (!orders) return [];
  return orders.sort((orderA: any, orderB: any) => {
    let dateA = new Date();
    let dateB = new Date();
    if (orderA && orderB)
      if (orderA[filter] && orderB[filter]) {
        dateA = new Date(orderA[filter]);
        dateB = new Date(orderB[filter]);
      }
    return Number(dateB) - Number(dateA);
  });
};
