import React, { useContext, useState, useEffect, useLayoutEffect } from 'react';
import { generatePath, Link, useSearchParams } from 'react-router-dom';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tap from '@tapfiliate/tapfiliate-js';
import * as Sentry from '@sentry/react';
import { useQuery } from 'react-query';

import { CartContext } from 'src/common/context/CartContext';
import { AppRoutes } from 'src/routes/app';
import { Button } from 'src/common/interactions/Button';
import { UserContext } from 'src/common/context/UserContext';
import { PaymentProcessors } from 'src/interfaces';
import { Api } from '../../../api';

export const ThankYou = React.memo(() => {
  const { user } = useContext(UserContext);
  const { emptyCart } = useContext(CartContext);
  const [searchParams] = useSearchParams();
  const [paymentProcessor, setPaymentProcessor] = useState('');

  useQuery('affiliateLookup', Api.payments.fetchAffiliateLookup, {
    onSuccess: ({ data: { amount, currencyType, orderId } }) => {
      if (['USD', 'HTG'].includes(currencyType)) {
        const tapConversionParams = [orderId, amount, { customer_id: user?.username, currency: currencyType }];
        Tap.conversion(...tapConversionParams, '', () =>
          Sentry.captureMessage(`TapFiliate conversion -> ${JSON.stringify({ ...tapConversionParams })}`),
        );
      }
    },
  });

  // Empty cart on successful payment.
  useLayoutEffect(() => emptyCart(), []);

  useEffect(() => setPaymentProcessor(searchParams.get('processor') || ''), [searchParams]);

  return (
    <div className="w-full flex flex-col justify-center items-center p-8">
      <h1 className="text-blue text-5xl font-bold">Thank You!</h1>
      {paymentProcessor === PaymentProcessors.DEVVIOPO ? (
        <h2 className="text-2xl mb-10">
          Thank you! Your purchase order <span className="text-blue"># {searchParams.get('order') || ''}</span> was
          created successfully. Please email <span className="font-bold">support@glassblock.io</span> to remit payment
          within seven days.
        </h2>
      ) : (
        <>
          <h2 className="text-2xl">Your purchase will be available in your DevvX wallet soon!</h2>
          <p className="mb-10 text-left">
            {paymentProcessor === PaymentProcessors.COINBASE ? (
              <>
                <span>{`As soon as your payment is processed successfully a confirmation email will be sent ${
                  user?.email ? 'to: ' : 'to you.'
                }`}</span>
                <span className="font-bold">{user?.email}</span>
              </>
            ) : (
              <>
                <span>{`Your payment was processed successfully and a confirmation email will be sent ${
                  user?.email ? 'to: ' : 'to you.'
                }`}</span>
                <span className="font-bold">{user?.email}</span>
              </>
            )}
          </p>
        </>
      )}

      <Link to={generatePath(AppRoutes.LANDING)}>
        <Button.Primary data-gtm="backToMarketplace">
          Back to Marketplace <FontAwesomeIcon icon={faShoppingCart} className="ml-2" />
        </Button.Primary>
      </Link>
    </div>
  );
});
