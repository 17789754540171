/* eslint-disable no-else-return */
import { useContext, useLayoutEffect } from 'react';
import { generatePath, Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ExchangeUserContext } from 'src/common/context/ExchangeUserContext';
import { UserContext } from 'src/common/context/UserContext';
import { Button } from 'src/common/interactions/Button';
import { AppRoutes } from 'src/routes/app';

import WelcomeBackground from 'src/assets/images/welcome-background.png';
import { AuthRoot, AuthRoutes } from 'src/routes/auth/auth-routes';

export const ExchangeWelcome = () => {
  const { user } = useContext(UserContext);
  const { logIn, loading, loadingInitial, exchangeUser } = useContext(ExchangeUserContext);
  const location = useLocation();
  const navigate = useNavigate();

  function renderShowNotLoggedInScreen() {
    return (
      <>
        <p className="font-poppins font-semibold text-base mt-8 mb-5 mr-4">Log into your DevvX account to continue.</p>
        <div className="font-poppins font-normal text-base text-center md:max-w-sm">
          <Link to={generatePath(`/${AuthRoot}/${AuthRoutes.LOGIN}`)} state={{ prevPath: location.pathname }}>
            <Button.Info className="w-full mb-4 bg-[#005BCF] rounded mt-8">
              <span className="font-poppins font-normal">LOG IN</span>
            </Button.Info>
          </Link>
          <span className="text-sm">Don&apos;t have an account? </span>
          <Link
            className="text-sm no-underline"
            to={generatePath(`/${AuthRoot}/${AuthRoutes.REGISTER}`)}
            state={{ prevPath: location.pathname }}
          >
            Sign up
          </Link>
        </div>
      </>
    );
  }

  useLayoutEffect(() => {
    logIn();
  }, []);

  if (loading || loadingInitial) {
    return null;
  } else {
    if (user && !loadingInitial && (!exchangeUser || !exchangeUser?.legal_agreement_accepted)) {
      return <Navigate to={generatePath(`/${AppRoutes.EXCHANGE_TERMS_AND_CONDITIONS}`)} state={{ from: location }} />;
    } else if (user && exchangeUser) {
      navigate(`/${AppRoutes.EXCHANGE_DASHBOARD}`);
    } else {
      return (
        <div className="font-poppins lg:flex lg:justify-center lg:ml-32 lg:flex-row-reverse">
          <div className="lg:ml-5 customSm:mb-10 customSm:mr-[2.5em]">
            <img
              src={WelcomeBackground}
              alt="Welcome background"
              className="max-w-full h-auto lg:w-[1000px] customSm:w-[461px]"
            />
          </div>

          <div className="bg-[#FFFFFF] ml-7 mr-6">
            <p className="font-bold leading-[36px] text-2xl lg:mt-40 text-[#212D3B]">
              Welcome to
              <br /> Glassblock Exchange!
            </p>
            <p className="customSM:hidden font-normal leading-[24px] text-base mt-5 mb-5 mr-1 ml-1">
              Here you’ll be able to buy and sell your DevvX assets, check your balances, see trades in real time and
              much more.
            </p>

            {!user && renderShowNotLoggedInScreen()}
          </div>
        </div>
      );
    }
    return null;
  }
};
