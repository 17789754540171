/* eslint-disable no-nested-ternary */
import { SVGProps } from 'react';
import BoringAvatar from './AvatarImage';

type AvatarPropsType = {
  small?: boolean;
  big?: boolean;
  profileUrl: string | null;
};

const Avatar: React.FC<AvatarPropsType> = ({ small = false, big = false, profileUrl }: AvatarPropsType) => {
  const avatarOuterCircleClassnames = small
    ? 'w-12 h-12 after:w-2 after:h-2 after:right-0.5 after:top-1'
    : big
    ? 'h-24 w-24 after:contents[]'
    : 'h-20 w-20';

  const imgClassnames = small ? 'w-9 h-9' : big ? 'h-20 w-20' : 'h-16 w-16';
  const boringAvatarSize = small ? 36 : big ? 80 : 64;
  return (
    <div className={`rounded-full  ${avatarOuterCircleClassnames} cursor-pointer flex items-center justify-center`}>
      {profileUrl ? (
        <img className={`${imgClassnames} rounded-full`} src={profileUrl} alt="avatar" />
      ) : (
        <BoringAvatar boringAvatarSize={boringAvatarSize} />
      )}
    </div>
  );
};

export default Avatar;
