/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ReactElement, ReactNode } from 'react';

export type SidebarRowProps = {
  icon: ReactNode;
  title: string;
  onClick: (path: string) => void;
  path?: string;
  classname?: string;
  active?: boolean;
};

const SidebarRow = ({
  icon,
  title,
  onClick,
  path = '',
  classname = '',
  active = false,
}: SidebarRowProps): ReactElement => {
  return (
    <div
      onClick={() => onClick(path)}
      className={`h-12 flex items-center cursor-pointer ${active ? 'bg-blue-100' : ''} ${classname}`}
    >
      <span className={`ml-6 ${active ? 'fill-red' : ''}`}>{icon}</span>
      <span className={`pl-6 ${active ? 'text-blue-400' : 'text-gray-500'} text-[15px] font-normal`}>{title}</span>
    </div>
  );
};

export default SidebarRow;
