import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'universal-cookie';

import { AppRoot } from 'src/routes/app';
import { Env } from 'src/common/env';
import { AuthRoot, AuthRoutes } from 'src/routes/auth/auth-routes';

const cookies = new Cookies();

axios.defaults.baseURL = Env.host;

let sessionUuid = '';

export function setSessionUuid(_sessionUuid: string) {
  sessionUuid = _sessionUuid;
}

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      // TODO: handle auth error if needed
    }
    if (error.response?.status === 403) {
      setSessionUuid('');
      cookies.remove('user', { path: AppRoot });
      cookies.remove('cart');
      cookies.remove('cartId');
      window.location.href = `/${AuthRoot}/${AuthRoutes.LOGIN}`;
    }
    return Promise.reject(error);
  },
);

axios.interceptors.request.use(function (request) {
  if (sessionUuid.length > 0) {
    request.headers = {
      ...request.headers,
      'Devvio-Session-Id': sessionUuid,
    };
  }

  return request;
});

export function get<T>(url: string, config?: AxiosRequestConfig) {
  return axios.get<T>(url, config).then((response) => response.data);
}

export function put<T>(url: string, data: any, config?: AxiosRequestConfig) {
  return axios.put<T>(url, data, config).then((response) => response.data);
}

export function post<T>(url: string, data: any, config?: AxiosRequestConfig) {
  return axios.post<T>(url, data, config).then((response) => response.data);
}

export function patch<T>(url: string, data: any, config?: AxiosRequestConfig) {
  return axios.patch<T>(url, data, config).then((response) => response.data);
}

export function destroy<T>(url: string, config?: AxiosRequestConfig) {
  return axios.delete<T>(url, config).then((response) => response.data);
}

export function returnOnlyData<T>(data: unknown) {
  if (data && typeof data === 'object' && 'data' in data) {
    return (data as { data: T }).data as T;
  }

  return data as T;
}

export function checkFor422AndReturnData<T>(error: unknown) {
  if (axios.isAxiosError(error)) {
    if (error.response && error.response.status === 422) {
      if ('data' in (error.response.data as Record<string, unknown>)) {
        return (error.response.data as Record<string, unknown>).data as T;
      }

      return error.response.data;
    }
  }

  throw error;
}
