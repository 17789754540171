/* eslint-disable no-param-reassign */
/* eslint-disable no-promise-executor-return */
import { toast } from 'react-toastify';
import { useInfiniteQuery } from 'react-query';
import { ErrorDto } from 'src/interfaces';
import { getGlobalHistory } from 'src/api/history';
import { useState } from 'react';

const useGlobalHistory = (options?: Record<string, unknown>) => {
  const [sorting, setSorting] = useState<string | null>(null);
  const { data, ...globalHistoryQuery } = useInfiniteQuery<any, ErrorDto>(
    ['global-history', sorting],
    ({ pageParam = 1 }) =>
      getGlobalHistory({
        page: pageParam,
        sort: sorting,
      }),
    {
      onError: ({ response }: ErrorDto) => {
        toast.error(
          `Global history request failed: ${response?.code ? `Code ${response?.code}` : ''}${
            response?.data?.message ? `\nError: ${response?.data?.message}` : ''
          }`,
        );
      },
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage?.data.pagination.page + 1;
        const { totalPages } = lastPage.data.pagination;

        return nextPage <= totalPages ? nextPage : undefined;
      },
      enabled: true,
      keepPreviousData: true,
      ...options,
    },
  );

  const { isError } = globalHistoryQuery;

  const errorMessage = isError
    ? // eslint-disable-next-line
      globalHistoryQuery.error?.response?.data?.message || 'An unexpected error occurred.'
    : '';

  return {
    errorMessage,
    data,
    setSorting,
    ...globalHistoryQuery,
  };
};

export default useGlobalHistory;
