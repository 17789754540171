import Cookies from 'universal-cookie';
import { nanoid } from 'nanoid';

export enum FeatureFlagEvents {
  ANNOUNCEMENTS = 'ANNOUNCEMENTS_SPLIT',
  SIMILAR_PRODUCTS = 'MLT_Split',
  CHECKOUT_ENABLED = 'CHECKOUT_ENABLED_SPLIT',
  CHECKOUT_PAYMENT_CARD = 'Circle_V1_Split',
  CHECKOUT_PAYMENT_DEVVPAY = 'DevvPay_V1_Split',
  EXCHANGE_ENABLED_SPLIT = 'EXCHANGE_ENABLED_SPLIT',
  BUYER_DEVVX_UX = 'BUYER_DEVVX_UX',
  // EXPLORER_DEVVX_UX = 'EXPLORER_DEVVX_UX',
}

const COOKIE_NAME_FF = 'featureFlagsUser';
const COOKIE_USER = 'user';
const DEFAULT_TREATMENT = 'off';

const cookies = new Cookies();

export const SPLIT_TREATMENT_ON = 'on';

export const getUserName = () => {
  let featureFlagsUser = cookies.get(COOKIE_NAME_FF);
  const storedUser = cookies.get(COOKIE_USER);

  if (!featureFlagsUser) {
    if (!!storedUser && storedUser.username) {
      featureFlagsUser = storedUser.username;
    } else {
      featureFlagsUser = nanoid();
    }

    cookies.set(COOKIE_NAME_FF, featureFlagsUser);
  } else if (!!storedUser && storedUser.username !== featureFlagsUser) {
    featureFlagsUser = storedUser.username;
    cookies.set(COOKIE_NAME_FF, featureFlagsUser);
  }

  return featureFlagsUser;
};

export const setUserName = (name: string) => {
  const userName = cookies.get(COOKIE_NAME_FF);
  if (name !== '' && userName !== name) {
    cookies.set(COOKIE_NAME_FF, name);
  }

  return userName;
};
