import { useQuery } from 'react-query';
import { Api } from 'src/api';

export function usePaymentsCurrencies(getExchangeRate?: boolean) {
  const keys = ['getPaymentsCurrencies'];
  if (getExchangeRate) {
    keys.push(`getPaymentsCurrenciesWithExchangeRate`);
  }
  const {
    data,
    isFetching,
    isLoading: isLoadingQuery,
    error,
    isError,
  } = useQuery(keys, () =>
    Api.payments.currencies({
      getExchangeRate,
    }),
  );

  const hasError = isError || !!error;
  const isLoading = isLoadingQuery || isFetching;

  return {
    data,
    isLoading,
    hasError: hasError,
    error,
  };
}
