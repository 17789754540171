interface CamelToHumanOptions {
  regex: RegExp;
  separator: string;
  replacementTokens: Map<string, string>;
}

export const CAMEL_TO_HUMAN_DEFAULTS: CamelToHumanOptions = {
  regex: /(?=[A-Z])/,
  separator: ' ',
  replacementTokens: new Map([['Nft', 'NFT']]),
};

/**
 * Converts a camel-cased string to a human-readable string. The first letter of each
 * split word will be capitalized.
 *
 * Options:
 * - `regex` - where to split the string. By default, an uppercase letter.
 * - `separator` - what to join the split tokens with. By default, a string.
 * - `replacementTokens` - which tokens to replace, along with their associated replacement
 */
// eslint-disable-next-line default-param-last
export const camelToHuman = (str = '', options?: Partial<CamelToHumanOptions>): string => {
  if (str && str.length > 0 && str.charAt(0).match(/[A-Z]/)) {
    return str;
  }

  let { regex, replacementTokens, separator } = CAMEL_TO_HUMAN_DEFAULTS;

  if (options) {
    regex = options.regex || regex;
    replacementTokens = options.replacementTokens || replacementTokens;
    separator = options.separator || separator;
  }

  return str
    .split(regex)
    .map((segment, index) => {
      // Uppercase first letter of the first word
      let newSegment = segment;
      if (index === 0 && segment.length) {
        newSegment = segment.charAt(0).toLocaleUpperCase() + segment.slice(1);
      }
      return replacementTokens.get(newSegment) || newSegment;
    })
    .join(separator);
};
