import React, { createRef, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import glassblockLogoMarket from 'src/assets/images/glassblock-marketplace-logo.svg';
import { SearchBar } from 'src/common/components';
import { CategoryViewer } from 'src/common/components/category-viewer/CategoryViewer';
import { PageThemeContext } from 'src/common/context/PageThemeContext';
import { CategoriesContext } from '../../../common/context/CategoriesContext';

export const LandingPageContainer = React.memo(() => {
  const { setShowSearch } = useContext(PageThemeContext);
  const divRef: React.RefObject<HTMLDivElement> = createRef();
  const location = useLocation();

  const [searchInput, setSearchInput] = useState('');
  const { categories } = useContext(CategoriesContext);

  const handleScroll = () => {
    if (location.pathname === '/') {
      const formPosition = divRef.current?.offsetTop || 0;
      const { scrollY } = window;
      setShowSearch(formPosition - 10 < scrollY);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <div className="flex flex-grow items-center justify-center flex-col md:mt-[15%]">
      <img className="max-w-sm mb-5" src={glassblockLogoMarket} alt="Glassblock Marketplace" />
      <div className="max-w-screen-2xl w-full" ref={divRef}>
        <SearchBar onSearchInputChange={setSearchInput} />
        <CategoryViewer categories={categories} searchInputValue={searchInput} />
      </div>
    </div>
  );
});
