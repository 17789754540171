import React from 'react';
import { useFormContext } from 'react-hook-form';
import cx from 'classnames';

import { Show } from 'src/common/layout';
import { Input } from 'src/common/components/forms/Input';
import { Label } from 'src/common/components/forms/Label';
import { Select } from 'src/common/components/forms/Select';

import { countries, usStates } from 'src/data/consts';
import infoIcon from 'src/assets/images/info-icon.svg';

import checkoutStyles from 'src/routes/app/checkout/Checkout.module.scss';

export interface OrderDetailsFormData {
  email: string;
  name: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  district: string;
  postalCode: string;
  country: string;
  discountCodeUuid?: string;
}

export const OrderDetailsForm = () => {
  const {
    register,
    getValues,
    trigger: triggerValidation,
    formState: { errors: formError },
  } = useFormContext<OrderDetailsFormData>();

  return (
    <>
      <h3 className="font-bold mb-7">Enter Your Information</h3>
      <form className="mb-7">
        <Label htmlFor="name" className={checkoutStyles.checkoutLabel}>
          Your Name
          <span className="ml-1">*</span>
        </Label>
        <Input
          id="name"
          aria-label="name"
          className={`border mt-2 ${checkoutStyles.checkoutInput} ${formError.name ? checkoutStyles.hasError : ''}`}
          type="text"
          {...register('name', {
            validate: (value) => !!`${value}`.trim() || 'Name is required.',
          })}
          maxLength={255}
          onKeyDown={(e) => (e.code === 'Space' && !getValues().name ? e.preventDefault() : e)}
        />
        <Show show={formError.name !== undefined}>
          <div aria-label="error" className={checkoutStyles.checkoutError}>
            {formError.name?.message}
          </div>
        </Show>
        <Label htmlFor="addressLine1" className={checkoutStyles.checkoutLabel}>
          Address Line 1<span className="ml-1">*</span>
        </Label>
        <Input
          id="addressLine1"
          className={`border mt-2 ${checkoutStyles.checkoutInput} ${
            formError.addressLine1 ? checkoutStyles.hasError : ''
          }`}
          type="text"
          {...register('addressLine1', {
            validate: (value) => !!value.trim() || 'Address line 1 is required.',
          })}
          maxLength={255}
          onKeyDown={(e) => (e.code === 'Space' && !getValues().addressLine1 ? e.preventDefault() : e)}
        />
        <Show show={formError.addressLine1 !== undefined}>
          <div aria-label="error" className={checkoutStyles.checkoutError}>
            {formError.addressLine1?.message}
          </div>
        </Show>
        <Label htmlFor="addressLine2" className={checkoutStyles.checkoutLabel}>
          Address Line 2
        </Label>
        <Input
          id="addressLine2"
          className={`border mt-2 ${checkoutStyles.checkoutInput}`}
          type="text"
          {...register('addressLine2')}
          maxLength={255}
          onKeyDown={(e) => (e.code === 'Space' && !getValues().addressLine2 ? e.preventDefault() : e)}
        />
        <div className="grid grid-cols-6 gap-3">
          <div className="col-span-6 lg:col-span-4">
            <Label htmlFor="city" className={checkoutStyles.checkoutLabel}>
              City
              <span className="ml-1">*</span>
            </Label>
            <Input
              id="city"
              className={`border ${checkoutStyles.checkoutInput} ${formError.city ? checkoutStyles.hasError : ''}`}
              type="text"
              {...register('city', {
                validate: (value) => !!value.trim() || 'City is required.',
              })}
              maxLength={255}
              onKeyDown={(e) => (e.code === 'Space' && !getValues().city ? e.preventDefault() : e)}
            />
            <Show show={formError.city !== undefined}>
              <div aria-label="error" className={checkoutStyles.checkoutError}>
                {formError.city?.message}
              </div>
            </Show>
          </div>
          <div className="col-span-3 lg:col-span-2">
            <Label htmlFor="postalCode" className={checkoutStyles.checkoutLabel}>
              Zip Code *
            </Label>
            <Input
              id="postalCode"
              className={`border ${checkoutStyles.checkoutInput} ${
                formError.postalCode ? checkoutStyles.hasError : ''
              }`}
              type="text"
              {...register('postalCode', {
                validate: (value) => !!`${value}`.trim() || 'Zip code is required.',
              })}
              maxLength={16}
              onKeyDown={(e) => (e.code === 'Space' && !getValues().postalCode ? e.preventDefault() : e)}
            />
            <Show show={formError.postalCode !== undefined}>
              <div aria-label="error" className={checkoutStyles.checkoutError}>
                {formError.postalCode?.message}
              </div>
            </Show>
          </div>
        </div>
        <div className="flex items-center">
          <Label htmlFor="district" className={checkoutStyles.checkoutLabel}>
            State/Region
            <span className="ml-1">*</span>
          </Label>
          <div className="hover:flex-1 ml-3">
            <div className="flex-col group items-center relative">
              <img className="h-4 w-4" src={infoIcon} alt="" />
              <div className="absolute bottom-0 flex-col hidden group-hover:flex mb-6">
                <span className="rounded-md bg-info leading-none p-2 relative text-white text-xs whitespace-no-wrap z-10 shadow-inner shadow shadow-lg shadow-xl p-4 bg-gradient-to-r from-blue-400 to-blue-500 font-bold">
                  State / Country / Province / Region portion of the address.
                  <br />
                  <br />
                  US and Canada use the two-letter code for the subdivision
                </span>
              </div>
            </div>
          </div>
        </div>
        <Input
          id="district"
          className={`border mt-2 ${checkoutStyles.checkoutInput} ${formError.district ? checkoutStyles.hasError : ''}`}
          type="text"
          {...register('district', {
            validate: (value) => {
              if (!value.trim()) {
                return 'State or region is required.';
              }
              if (
                getValues().country === countries.find((x) => x.name === 'United States')?.abbreviation &&
                !usStates.some((x) => x.abbreviation.toLowerCase() === value.trim().toLowerCase())
              ) {
                return 'Please enter two-letter state abbreviation.';
              }
              return true;
            },
          })}
          maxLength={16}
          onKeyDown={(e) => (e.code === 'Space' && !getValues().district ? e.preventDefault() : e)}
        />
        <Show show={!!formError.district}>
          <div aria-label="error" className={checkoutStyles.checkoutError}>
            {formError.district?.message}
          </div>
        </Show>
        <Label htmlFor="country" className={checkoutStyles.checkoutLabel}>
          Country
          <span className="ml-1">*</span>
        </Label>
        <Select
          id="country"
          autoComplete="none"
          className={cx(
            'border mt-2',
            checkoutStyles.checkoutInput,
            checkoutStyles.checkoutSelect,
            formError.country && checkoutStyles.hasError,
          )}
          {...register('country', { required: 'Country is required.' })}
          onBlur={() => {
            triggerValidation(['district']);
          }}
        >
          <option value="">Select</option>
          {countries.map((c) => {
            return (
              <option value={c.abbreviation} key={c.abbreviation}>
                {c.name}
              </option>
            );
          })}
        </Select>
        <Show show={!!formError.country}>
          <div aria-label="error" className={checkoutStyles.checkoutError}>
            {formError.country?.message}
          </div>
        </Show>
      </form>
    </>
  );
};
