import { useEffect, useState } from 'react';

export interface UseCanvasOptions {
  width?: number;
  height?: number;
}

export const useCanvas = (options: UseCanvasOptions) => {
  const { width, height } = options;

  const [canvas, setCanvas] = useState<HTMLCanvasElement>();

  useEffect(() => {
    const newCanvas = document.createElement('canvas');

    newCanvas.width = 1024;
    newCanvas.height = 1024;

    if (width !== undefined) {
      newCanvas.width = width;
    }

    if (height !== undefined) {
      newCanvas.height = height;
    }

    setCanvas(newCanvas);
  }, [width, height]);

  return canvas;
};
