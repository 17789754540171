import React from 'react';
import Upsetx404 from 'src/assets/images/upsetx404.svg';

export const NoRoute = React.memo(() => {
  return (
    <div className="flex flex-col flex-grow items-center p-8 md:p-20 bg-neutral-100 rounded-md mx-8 my-4">
      <div className="mb-6">
        <img src={Upsetx404} alt="oh no" />
      </div>
      <h1 className="text-blue text-5xl font-bold">Oh no!</h1>
      <h2 className="sm:text-xl md:text-2xl text-center">404 - Page not found</h2>
      <p className="text-center">Looks like the page you were trying to find doesn&apos;t exist.</p>
    </div>
  );
});
