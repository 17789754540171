import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState, useRef } from 'react';
import remarkGemoji from 'remark-gemoji';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { Link, generatePath, useLocation } from 'react-router-dom';
import headerStyles from 'src/common/components/header/Header.module.scss';
import { UserDropdown } from 'src/common/components/header/UserDropdown';
import { UserContext } from 'src/common/context/UserContext';
import glassblockLogo from 'src/assets/images/glassblock-header-logo.svg';
import { Button } from 'src/common/interactions/Button';
import { AppRoutes } from 'src/routes/app';
import { Show } from 'src/common/layout';
import { SearchBar } from 'src/common/components/index';
import { CartContext } from 'src/common/context/CartContext';
import { PageThemeContext } from 'src/common/context/PageThemeContext';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { AnnouncementsResponseType } from 'src/interfaces/general';
import { Api } from 'src/api';
import { AuthRoot, AuthRoutes } from 'src/routes/auth/auth-routes';
import { FeatureFlags, FeatureFlagsEvents } from '../../util';
import { HeaderNav } from './HeaderNav';

export const Header = React.memo(() => {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { cart, setCartShowState } = useContext(CartContext);
  const { showSearch, showCartIcon, showHeader, setHeaderHeight } = useContext(PageThemeContext);
  const [announcement, setAnnouncement] = useState('');
  const [isAnnouncementEnabled, setIsAnnouncementEnabled] = useState(false);
  const headerRef = useRef(null);
  const isHome = location.pathname === '/';

  useQuery<AxiosResponse<AnnouncementsResponseType>>('announcementsQuery', Api.announcements.getAnnouncements, {
    cacheTime: 12 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setAnnouncement(data.data.header_message);
    },
  });

  useEffect(() => {
    FeatureFlags.on(FeatureFlagsEvents.ANNOUNCEMENTS, (treatment) => {
      const isFeatureEnabled = treatment === 'on';
      setIsAnnouncementEnabled(!!announcement && isFeatureEnabled && (showSearch || isHome));
    });
  }, [announcement, showSearch, location.pathname, setIsAnnouncementEnabled, isHome]);

  useEffect(() => {
    // @ts-ignore
    setHeaderHeight(headerRef?.current?.clientHeight);
  }, [announcement, isAnnouncementEnabled]);

  return (
    <div className="sticky top-0 w-full z-20">
      {showHeader && (
        <header ref={headerRef}>
          <Show show={isAnnouncementEnabled}>
            <div className="flex justify-center">
              <ReactMarkdown
                className="bg-slate-50 drop-shadow text-center py-4 px-16 w-full"
                remarkPlugins={[remarkGfm, remarkGemoji]}
              >
                {announcement}
              </ReactMarkdown>
            </div>
          </Show>
          <nav
            className={`relative border-b bg-black lg:flex lg:h-16 justify-between ${
              showSearch ? 'grid grid-cols-12 grid-rows-2 gap-0 h-36' : 'flex h-16'
            }`}
          >
            <div className="flex gap-4 md:gap-6 col-span-7 row-start-1">
              <div
                className={`flex justify-start items-center col-start-1 row-start-1 lg:relative ${
                  showSearch ? 'relative' : ''
                }`}
              >
                <Link to={generatePath(AppRoutes.LANDING)} className={`ml-4 w-11 ${headerStyles.headerLogoLink}`}>
                  <img
                    className={`inline-block ${headerStyles.logo}`}
                    src={glassblockLogo}
                    alt="Glassblock Marketplace"
                  />
                </Link>
              </div>
              <Show show={showSearch || isHome}>
                <HeaderNav />
              </Show>
            </div>
            <Show show={showSearch}>
              <div className="4xl:absolute 4xl:translate-x-1/2 4xl:right-1/2 4xl:w-full md:grow xl:max-w-screen-md top-1 flex justify-center items-center col-start-1 col-span-12 row-start-2 mt-0 lg:m-0 bg-gray-100 lg:bg-transparent w-full">
                <SearchBar isHeader />
              </div>
            </Show>
            <div className="flex justify-end items-center col-span-5 md:col-start-10 md:col-span-3 row-start-1 h-full">
              <Show show={showCartIcon}>
                <Button.NavLink type="button" className="flex" onClick={() => setCartShowState(true)}>
                  <FontAwesomeIcon className="ml-2 text-lg" icon={faShoppingCart} />
                  <span
                    className={`relative right-2 -top-2 rounded-full bg-zest max-w-3.5 h-3.5 top right px-1 pb-1 m-0 text-white font-mono text-xs leading-tight text-center ${
                      !cart.length && 'invisible'
                    }`}
                  >
                    {cart.length > 9 ? '9+' : cart.length}
                  </span>
                </Button.NavLink>
              </Show>
              <Show show={!user}>
                <div className="flex gap-2 md:gap-4 pr-2 whitespace-nowrap">
                  <Link to={generatePath(`/${AuthRoot}/${AuthRoutes.LOGIN}`)}>
                    <Button.NavLink type="button">Login</Button.NavLink>
                  </Link>
                  <span className="border-r" />
                  <Link to={generatePath(`/${AuthRoot}/${AuthRoutes.REGISTER}`)}>
                    <Button.NavLink type="button">Sign Up</Button.NavLink>
                  </Link>
                </div>
              </Show>
              <Show show={!!user}>
                <UserDropdown />
              </Show>
            </div>
          </nav>
        </header>
      )}
    </div>
  );
});
