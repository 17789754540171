import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

import formStyles from './Forms.module.scss';

export const PasswordInput = React.forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>(
  (props, ref) => {
    const { className, ...rest } = props;
    const [isShown, setIsShown] = useState(false);
    const toggleShowPassword = useCallback(() => {
      setIsShown((prevValue) => !prevValue);
    }, [setIsShown]);

    return (
      <div className="relative">
        <input
          ref={ref}
          className={`block w-full py-3 px-4 border-neutral-400 rounded text-sm pr-12 ${className || ''}`}
          {...rest}
          type={isShown ? 'text' : 'password'}
        />
        <button
          type="button"
          className={`absolute right-4 bg-transparent border-0 ${formStyles.passwordToggle}`}
          onClick={toggleShowPassword}
        >
          <FontAwesomeIcon icon={isShown ? faEye : faEyeSlash} className="text-blue" size="lg" />
        </button>
      </div>
    );
  },
);
