import React, { useEffect, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { Api, RegistrationParams, RegistrationRes, useLazyQuery } from 'src/api';
import { FormGroup } from 'src/common/components/forms/FormGroup';
import { Input } from 'src/common/components/forms/Input';
import { PasswordInput } from 'src/common/components/forms/PasswordInput';
import { Button } from 'src/common/interactions/Button';
import { sha256, getFormError } from 'src/common/util';
import { AuthRoot, AuthRoutes } from 'src/routes/auth/auth-routes';
import authStyles from 'src/routes/auth/Auth.module.scss';
import glassblockLogoMarket from 'src/assets/images/glassblock-marketplace-logo.svg';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  password: string;
  passwordConfirm: string;
}

const schemaValidation = z
  .object({
    firstName: z.string().min(1, { message: 'First name is required.' }),
    lastName: z.string().min(1, { message: 'Last name is required.' }),
    email: z
      .string()
      .min(1, { message: 'Email address is required.' })
      .email({ message: 'Invalid email address. Please try again.' })
      .superRefine((val, ctx) => {
        const secondPartEmail = val.split('@')[1];
        const comSplit = secondPartEmail.split('.')[1];

        if (comSplit.substring(0, 2) === 'co') {
          if (comSplit !== 'com') {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid email address. Please try again.',
            });
          }
        }
      }),
    username: z
      .string()
      .min(1, { message: 'Username is required.' })
      .regex(/^[^<>"']+$/i, `Username must not contain < > " or '`),
    password: z.string().min(1, { message: 'Password is required.' }),
    passwordConfirm: z.string().min(1, { message: 'Password confirmation is required.' }),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: 'Passwords do not match.',
    path: ['passwordConfirm'],
  });

export const RegistrationContainer = React.memo(() => {
  const [errorMessage, setErrorMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [registerQuery, registerResponse] = useLazyQuery<RegistrationParams, RegistrationRes>(Api.proxy.register);

  const {
    register,
    handleSubmit,
    formState: { errors: formError },
  } = useForm<FormData>({
    resolver: zodResolver(schemaValidation),
  });

  const handleClearError = () => {
    setErrorMessage('');
  };

  const onSubmit = ({ firstName, lastName, email, username, password }: FormData) => {
    if (registerResponse.status === 'loading') {
      return;
    }

    const userData = {
      fullName: `${firstName} ${lastName}`,
      user: username,
      pass: sha256(password + username.toLowerCase()),
      email: email,
      emailHash: sha256(password + email.toLowerCase()),
    };

    registerQuery(userData);
  };

  useEffect(() => {
    if (registerResponse.status === 'resolved') {
      const { code, message } = registerResponse.response!;

      if (code === 4030) {
        setEmailSent(true);
      } else if (message) {
        setErrorMessage(message);
      } else {
        setErrorMessage('Unable to register.');
      }
    } else if (registerResponse.status === 'error') {
      setErrorMessage('Unable to register.');
    }
  }, [registerResponse]);

  const error = errorMessage || getFormError(formError);

  return (
    <div className="flex flex-grow items-center justify-center flex-col">
      <div className="md:border md:rounded md:shadow-lg md:px-16 pb-12 md:py-12">
        <img className="max-w-sm mb-5 mx-auto" src={glassblockLogoMarket} alt="Glassblock Marketplace" />
        {!emailSent && (
          <form onSubmit={handleSubmit(onSubmit)} className="md:w-96">
            <div className="grid grid-cols-2 gap-4">
              <FormGroup onClick={handleClearError}>
                <Input
                  {...register('firstName')}
                  className={`${authStyles.authInput} ${formError.firstName && authStyles.hasError}`}
                  placeholder="First Name"
                  type="text"
                  aria-label="First Name"
                />
              </FormGroup>
              <FormGroup onClick={handleClearError}>
                <Input
                  {...register('lastName')}
                  className={`${authStyles.authInput} ${formError.lastName && authStyles.hasError}`}
                  placeholder="Last Name"
                  type="text"
                  aria-label="Last Name"
                />
              </FormGroup>
            </div>
            <FormGroup onClick={handleClearError}>
              <Input
                {...register('email')}
                type="text"
                className={`${authStyles.authInput} ${formError.email && authStyles.hasError}`}
                placeholder="Email Address"
                aria-label="Email Address"
              />
            </FormGroup>
            <FormGroup onClick={handleClearError}>
              <Input
                {...register('username')}
                className={`${authStyles.authInput} ${formError.username && authStyles.hasError}`}
                type="text"
                placeholder="Username"
                aria-label="Username"
              />
            </FormGroup>
            <FormGroup onClick={handleClearError}>
              <PasswordInput
                {...register('password')}
                className={`${authStyles.authInput} ${formError.password && authStyles.hasError}`}
                type="password"
                placeholder="Password"
                aria-label="Password"
              />
            </FormGroup>
            <FormGroup onClick={handleClearError} className="mb-4">
              <PasswordInput
                {...register('passwordConfirm')}
                className={`${authStyles.authInput} ${formError.passwordConfirm && authStyles.hasError}`}
                type="password"
                placeholder="Confirm Password"
                aria-label="Confirm Password"
              />
            </FormGroup>
            <div className="text-center text-red-500 my-4" aria-label="error">
              {error}
            </div>
            <div className="text-center">
              <Button.Primary>
                Sign Up
                <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
              </Button.Primary>
            </div>
          </form>
        )}
        {emailSent && <div className="text-center">Please check your email for confirmation before continuing.</div>}
        <div className="text-center mt-4">
          <Link
            className="text-blue no-underline"
            state={{ from: AuthRoutes.REGISTER }}
            to={generatePath(`/${AuthRoot}/${AuthRoutes.LOGIN}`)}
          >
            {emailSent ? 'Proceed to Login' : 'Already have an account? Log In'}
          </Link>
        </div>
      </div>
    </div>
  );
});
