import React from 'react';

import { Show } from 'src/common/layout';

interface AlertWarningProps {
  title: string;
  text?: string;
}

export const AlertWarning = ({ title, text = '' }: AlertWarningProps) => {
  return (
    <>
      <span className="font-bold text-white">{title}</span>
      <Show show={text?.length > 0}>
        <br />
        <span className="text-white">{text}</span>
      </Show>
    </>
  );
};

AlertWarning.defaultProps = {
  text: '',
};
