import React from 'react';

export const Input = React.forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
  const { className, ...rest } = props;
  return (
    <input
      ref={ref}
      className={`block w-full py-3 px-4 border-neutral-400 rounded text-sm ${className || ''}`}
      {...rest}
    />
  );
});
