import { NftItemsUuidArr, CheckCartRes, CheckCartErrorRes } from '../interfaces';
import { Api } from '.';
import { checkFor422AndReturnData } from './client';

export const checkCart = async (nftItems: NftItemsUuidArr): Promise<CheckCartRes> => {
  return Api.cart
    .checkCart({
      nftItems,
    })
    .then(() => null)
    .catch((error) => checkFor422AndReturnData<CheckCartErrorRes>(error));
};
