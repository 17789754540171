import React from 'react';

export const useSortableData = (data, config = null) => {
  const [sortConfig, setSortConfig] = React.useState<{ direction: string; key: string } | null>(config);

  const sortedItems = React.useMemo(() => {
    const sortableData = data?.pages.map((page) => page?.data?.history?.map((item) => item)).flat();
    return sortableData;
  }, [data, sortConfig]);

  const requestSort = (key: string) => {
    let direction = '+';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === '+') {
      direction = '-';
    }

    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
