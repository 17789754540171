import { SelectFilterFormOption } from './routes/app/search-results/advanced-filter';

export type NftItemsArr = { uuid: string }[];

export enum PaymentProcessors {
  COINBASE = 'coinbase',
  DEVVIO = 'devv_pay',
  DEVVIOPO = 'devv_po',
}

export enum TransactionStatuses {
  PENDING = 'pending',
  COMPLETE = 'complete',
  ERROR = 'error',
  REFUNDED = 'refunded',
  PARTIAL_ERROR = 'partial_error',
}

export enum TransactionNftItemStatuses {
  PENDING = 'pending',
  COMPLETE = 'complete',
  ERROR = 'error',
  REFUNDED = 'refunded',
}

export enum CurrencyTypes {
  USD = 'USD',
  ETH = 'ETH',
  BTC = 'BTC',
  USDC = 'USDC',
  HEP = 'HTG',
  LTG = 'LTG',
}

export enum UserRoles {
  ADMIN = 'devvadmin',
  USER = 'devvuser',
  MINTER = 'minter',
}

export interface Category {
  uuid: string;
  name: string;
  friendlyName: string;
  hasItems: boolean;
  visible: boolean;
  imageUrl?: string;
}

export interface ItemDetail {
  uuid: string;
  key: string;
  value: string;
  nftItem: NFTItem;
  friendlyName?: string;
}

export interface Inventory {
  mintRank: number;
  sellerId: string;
  salePrice: number;
  saleCurrency: string;
  quantity: number;
  isLocked: boolean;
  paymentOptions: PaymentOptions;
  isBanned?: boolean;
}

export interface NFTItem {
  uuid: string;
  name: string;
  category: string;
  description: string;
  forSale: boolean;
  imageUrl: string;
  mintId: string;
  sellerId?: string;
  mintRank: string;
  mintTotal?: number;
  nftLink: string;
  saleCurrency: string;
  salePrice: number;
  unitPrice: number;
  itemDetails: ItemDetail[];
  searchTerms: SearchTerm[];
  artist: string;
  remoteId: string;
  transactionNftItems: TransactionNftItem[];
  currencyPreferences: CurrencyTypes[];
  isLocked: boolean;
  thumbnails?: Thumbnail[];
  nftType?: string;
  bundleCoin?: string;
  isBanned?: boolean;
}

export interface SearchTerm {
  uuid: string;
  term: string;
  nftItem: NFTItem;
}

export interface Transaction {
  uuid: string;
  username: string; // The devvio username associated with this user
  amount: number;
  currencyType?: CurrencyTypes;
  error: string;
  remoteTransactionId?: string;
  remoteSessionId: string;
  status: TransactionStatuses;
  paymentProcessor?: PaymentProcessors;
  transactionNftItems: TransactionNftItem[];
  expiresAt: Date;
  createdAt: Date;
  updatedAt: Date;
  orderId: string;
}

export interface TransactionNftItem {
  uuid: string;
  createdAt: Date;
  updatedAt: Date;
  fulfillResponse: string; // Stringified JSON of the response from the fulfill endpoint on success or failure
  fulfillRequest: string;
  transaction: Transaction;
  nftItem: NFTItem;
  status: TransactionNftItemStatuses;
}
export interface TransactionAddress {
  buyerName: string;
  buyerAddress1: string;
  buyerAddress2?: string;
  buyerCity: string;
  buyerState: string;
  buyerPostalCode?: string;
  buyerCountry: string;
}

export interface OrderInformation extends TransactionAddress {
  publicAddress: string;
  nftItems: NftItemsArr;
  username: string;
  discountCodeUuid?: string;
}

export interface SchemaProperty {
  type?: 'string' | 'integer';
  description?: string;
  is_marketplace_display_listed?: boolean;
  is_marketplace_searchable?: boolean;
  is_marketplace_filterable?: boolean;
  enum?: string[];
  minimum?: number;
  maximum?: number;
  characteristics?: object;
  options?: SelectFilterFormOption[];
}

export interface PropertiesGroup {
  [item: string]: SchemaProperty;
}

export interface MintIdNft {
  uuid: string;
  createdAt: Date;
  updatedAt: Date;
  forSale: boolean;
  saleCurrency: string;
  salePrice: number;
  mintRank: string;
  nftLink?: string;
  remoteId: string;
  isLocked: boolean;
  sellerId: string;
}

export interface NftMintIdRes {
  createdAt: Date;
  updatedAt: Date;
  name: string;
  creator: string;
  category: Category;
  sellerId: string;
  description: string;
  imageUrl?: string;
  saleCurrency: string;
  salePrice: number;
  nftType: string;
  ipRightsContentType: string;
  ipRightsLink: string;
  artist: string;
  mintId: string;
  mintTotal: number;
  inventoryTotal: number;
  searchTerms: Array<{ uuid: string; term: string }>;
  schemaProps: any;
  nfts: MintIdNft[];
  inventory: Inventory[];
  itemDetails: ItemDetail[];
  thumbnails?: Thumbnail[];
  shardUrl: string;
}

export type NftItemsUuidArr = { uuid: string; message?: string }[];

export type CheckCartBody = {
  nftItems: NftItemsUuidArr;
};

export type CheckCartErrorRes = CheckCartBody & {
  message: string;
  errorCode: 'PROTECTED_ITEMS' | 'UNAVAILABLE_ITEMS';
};

export type CheckCartRes = null | CheckCartErrorRes;

export type Thumbnail = {
  height: number;
  width: number;
  secureUrl: string;
  type: string;
};

export interface GetCartItemsQuery {
  uuids: NftItemsUuidArr;
}

export interface CartItem
  extends Pick<
    NFTItem,
    | 'uuid'
    | 'mintId'
    | 'name'
    | 'artist'
    | 'salePrice'
    | 'imageUrl'
    | 'forSale'
    | 'isLocked'
    | 'thumbnails'
    | 'sellerId'
    | 'nftType'
    | 'bundleCoin'
    | 'mintRank'
    | 'mintTotal'
    | 'isBanned'
  > {
  shardUrl: string;
  paymentOptions: PaymentOptions;
}

export interface GetCartItemsResponse {
  cartItems: CartItem[];
  itemsCount: number;
  totalValue: number;
}
export interface GetCartUserBalancesResponseType {
  data: GetCartUserBalancesType;
}

export interface GetCartUserBalancesType {
  balance: Array<{ balance: string; coinId: string; coinName: string }>;
}
export interface GetCartUserInfoResponse {
  balance: Array<{ balance: string; coinId: string; coinName: string }>;
}

export interface CartItemWithDiscount extends CartItem {
  discountSalePrice?: number;
  currencyPreferences?: CurrencyTypes[];
}

export interface DiscountScope {
  sellers?: string[];
  nftTypes?: string[];
  prices?: number[];
}

export interface NftListState {
  nftItems: CartItemWithDiscount[];
  subtotal: number;
  totalCount: number;
  totalDiscount: number;
  totalValue: number;
  disabledAllButtons: boolean;
}

export interface PromoCodeData {
  uuid: string;
  description: string;
  discountType: DiscountType;
  percentOff: number;
  discountScope: DiscountScope;
  numberOfItemsAvailableToUse: number | null;
}

export enum DiscountType {
  PERCENTAGE = 'percentage',
}

export interface DiscountCode {
  uuid: string;
  discountCodeName: string;
  percentOff: number;
  startDate: Date;
  endDate: Date;
  discountType: DiscountType;
  discountScope: object;
  discountQuantity: number;
  discountQuantityUsed: number;
  createdAt: Date;
  updatedAt: Date;
  numberOfItemsAvailableToUse: number | null;
}

export type CheckDiscountCodeBody = {
  discountCodeName: string;
};

export type CheckDiscountCodeErrorRes = {
  message: string;
};

export type CheckDiscountCodeRes = DiscountCode | CheckDiscountCodeErrorRes;

export interface HandleCompleteArgs {
  success: boolean;
  buyerCountry?: string;
  paymentProcessor: PaymentProcessors;
}

export interface CircleCheckTransactionRes {
  status: string;
  errorCode: string;
}

export interface TransactionUnlockItemRes {
  message: string;
  nftItem: string[];
}

export enum DevvPayStatus {
  SUCCESS = 'success',
  DELAYED = 'delayed',
  ERROR = 'error',
}

export interface DevvPayCreateChargeParams extends TransactionAddress {
  coinName: string;
  discountCodeUuid?: string;
  nftItems: NftItemsArr;
  publicAddress: string;
  username: string;
}

export interface DevvPayCreateChargeResult {
  status: DevvPayStatus;
  message: string;
  nftItems?: NftItemsUuidArr;
}
export interface DevvPayCheckCartError {
  status: DevvPayStatus;
  message: string;
  nftItems: NftItemsUuidArr;
}

export interface PurchaseOrderBody extends TransactionAddress {
  nftItems: NftItemsUuidArr;
}

export type PaymentEnabledFetchParams = {
  uuids: string;
  discountCodeName?: string;
};

export enum PAYMENT_OPTION {
  DEVVPAY = 'devvPay',
  COINBASE = 'coinbase',
  PURCHASE_ORDER = 'purchase_order',
}

export type PurchaseOrderRes = Transaction;

export type DevvPayCreateChargeRes = DevvPayCreateChargeResult | DevvPayCheckCartError;

export interface DevvPayToken {
  coinName: string;
  coinId: string;
  itemScope?: Record<string, unknown>;
  shardUrl: string;
  exchangeRate: number;
  exchangeCurrency: string;
  balance?: string;
  currency: {
    id: string;
    name: string;
  };
}

export interface DevvPayTokenListRes {
  tokens: DevvPayToken[];
}

export type ExchangeErrorRes = {
  message: string;
};
export interface GetExchangeUserResponse {
  username: string;
  createdAt: string;
  updatedAt: string;
  kyc_validated: boolean;
  legal_agreement_accepted: boolean;
  legal_agreement_accepted_timestamp: string | null;
  orders: [];
}
export interface GetExchangeTradePerformance {
  coordinates: {
    volumes: [];
    avg_prices: [];
  };
  maxY: number;
  coin_id: string;
  time_interval: string;
  time_range: string;
}

export interface GetExchangeBalanceVariation {
  coin_id: string;
  time_range: string;
  variation: number;
}

export interface GetExchangeSystemOrdersResponse {
  items: Array<GetExchangeUserOrdersResponse>;
  meta: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export interface GetExchangeSystemOrdersResponseInfiniteQuery {
  pages: [];
  items: [];
}

export interface PostExchangeUserOrderResponse {
  uuid: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  fulfilled_at: string;
  exchange: string;
  trading_pair: string;
  side: string;
  asset_amount: number;
  total_price: number;
  unit_price: number;
  type: string;
  maker: string;
  friendly_id: string;
}
export interface GetExchangeUserOrdersResponse extends PostExchangeUserOrderResponse {
  completion_percentage: number;
  net_amount: number;
}

export interface ExchangeOrderParams {
  asset: string;
  base_coin_id: string;
  asset_amount: number;
  total_price: number;
  quote_coin_id: string;
  unit_price: number;
  price_currency: string;
  side: string;
  buyer?: string;
  seller?: string;
  trading_pair?: string;
  type: string;
}

export interface ExchangeTradingPair {
  allow_fractional_currency: boolean;
  base_coin_id: string;
  base_coin_name: string;
  createdAt: string;
  quote_coin_id: string;
  quote_coin_name: string;
  fee_coin_id: string;
  fee_coin_name: string;
  fee_percentage: number;
  lot_size: number;
  status: string;
  type: string;
  updatedAt: string;
  uuid: string;
  base_coin_image_url: string;
  quote_coin_image_url: string;
  fee_coin_image_url: string;
  shard_url: string;
}
export interface CancelOrderRes {
  uuid: string;
}

export interface UserBalanceResponse {
  balance: number;
  coin_id: string;
  coin_name: string;
  in_position: number;
  available: number;
}

export type ErrorDto = {
  response?: {
    code?: string | number;
    data?: {
      message: string;
    };
  };
};

export type TablePaginationType = {
  pageSize: number;
  pageIndex: number;
  sort?: string;
  total?: number;
  totalPages?: number;
};

export interface GlobalHistoryResponse {
  history: Array<object>;
  pagination: {
    page: number;
    perPage: number;
    totalPages: number;
  };
}

export type SelectedCoinPair = ExchangeTradingPair;
export interface TimeFrame {
  code: string;
  label: string;
  d3_time_format: string;
  time_interval: string;
}

export type PaymentOptions = Record<
  string,
  {
    name: string;
    currencies: string[];
  }
>;
