import React, { useCallback, useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'src/common/interactions/Button';
import { Loader } from 'src/common/components/loader/Loader';
import {
  HandleCompleteArgs,
  PaymentProcessors,
  PAYMENT_OPTION,
  PurchaseOrderBody,
  PurchaseOrderRes,
} from 'src/interfaces';
import { UserContext } from 'src/common/context/UserContext';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/routes/app';
import { toast } from 'react-toastify';
import { Api } from 'src/api';
import { useMutation } from 'react-query';
import { CheckoutContext } from 'src/common/context/CheckoutContext';
import { PurchaseOrderFormData } from './PurchaseOrderForm';

type PurchaseOrderButtonProps = {
  onCompleteCharge: (args: HandleCompleteArgs) => void;
  onStartCharge: () => void;
};

export const PurchaseOrderButton = (props: PurchaseOrderButtonProps) => {
  const { onCompleteCharge, onStartCharge } = props;
  const { nftItems, isOptionDisabled } = useContext(CheckoutContext);

  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const {
    getValues: getFormValues,
    handleSubmit,
    formState: { errors: formErrors, isDirty },
  } = useFormContext<PurchaseOrderFormData>();

  const purchaseOrderMutation = useMutation((payload: PurchaseOrderBody) => {
    return Api.payments.devvPo.createOrder(payload);
  });

  const onSendCustomerData = async () => {
    setIsLoading(true);
    const { name, addressLine1, addressLine2, city, district, discountCodeUuid, postalCode, country } = getFormValues();

    const payload = {
      username: user?.username || '',
      buyerName: name,
      buyerAddress1: addressLine1,
      buyerAddress2: addressLine2,
      buyerCity: city,
      buyerState: district,
      buyerPostalCode: postalCode,
      buyerCountry: country,
      discountCodeUuid: discountCodeUuid,
      nftItems: nftItems.map((item) => ({ uuid: item.uuid })),
    };

    purchaseOrderMutation.mutate(payload, {
      onSuccess: ({ data }: { data: PurchaseOrderRes }) => {
        navigate(`/${AppRoutes.THANK_YOU}?processor=${PaymentProcessors.DEVVIOPO}&order=${data.orderId}`);
      },
      onError: () => {
        setIsLoading(false);
        onCompleteCharge({
          success: false,
          buyerCountry: country,
          paymentProcessor: PaymentProcessors.DEVVIOPO,
        });
        toast.error('Something happened. Please try again.', { autoClose: false });
      },
    });
  };

  const createPurchaseOrder = useCallback(
    (data) => {
      // Workaround for handling browser autofilled inputs
      const autoFillCheck =
        JSON.stringify(
          Object.keys(data).reduce((acc, key) => {
            acc[key] = '';
            return acc;
          }, {}),
        ) !== JSON.stringify(data);

      if (
        ((Object.keys(formErrors).length === 0 && isDirty) || autoFillCheck) &&
        !isOptionDisabled(PAYMENT_OPTION.PURCHASE_ORDER)
      ) {
        onStartCharge();
        onSendCustomerData();
      }
    },
    [formErrors, isDirty],
  );

  return (
    <div className="flex justify-center">
      <Button.Info
        onClick={handleSubmit(createPurchaseOrder)}
        className="w-full xl:w-1/3 flex items-center justify-center gtm_payNowCoinbase"
        data-gtm="payPurchaseOrder"
        disabled={isLoading}
        type="button"
      >
        {isLoading ? <Loader alt="Processing ..." /> : 'Submit Purchase'}
      </Button.Info>
    </div>
  );
};
