import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'src/common/interactions/Button';
import { Api, useLazyQuery } from 'src/api';
import { CartItem, CheckCartBody, CheckCartRes, GetCartItemsQuery, GetCartItemsResponse } from 'src/interfaces';
import { CartContext } from 'src/common/context/CartContext';
import { ShardsContext } from 'src/common/context/ShardsContext';
import { ItemTotal, Loader, CartItemRow } from 'src/common/components';
import { Show } from 'src/common/layout';
import { toast } from 'react-toastify';

interface NftListState {
  nftItems: CartItem[];
  totalCount: number;
  totalValue: number;
}

export const CartContainer = React.memo(() => {
  const [isLoading, setIsLoading] = useState(true);
  const { cart, handleCheckout, setCartShowState, removeCartItem, cartShowState } = useContext(CartContext);
  const { isShardDown } = useContext(ShardsContext);
  const [{ nftItems, totalCount, totalValue }, setNftListState] = useState<NftListState>({
    nftItems: [],
    totalCount: 0,
    totalValue: 0,
  });
  const [disableCheckout, setDisableCheckout] = useState(true);
  const [unavailableItems, setUnavailableItems] = useState<string[]>([]);

  const [nftListQuery, nftListResponse] = useLazyQuery<GetCartItemsQuery, { data: GetCartItemsResponse }>(
    Api.cart.getItems,
  );

  const [checkCartQuery, checkCartResponse] = useLazyQuery<CheckCartBody, { data: CheckCartRes }>(Api.cart.checkCart);

  useEffect(() => {
    if (cart.length) {
      nftListQuery({
        uuids: cart,
      });
    } else {
      setNftListState({
        nftItems: [],
        totalCount: 0,
        totalValue: 0,
      });
      setIsLoading(false);
    }
  }, [nftListQuery, cart]);

  useEffect(() => {
    if (nftListResponse.status === 'resolved') {
      const { cartItems, itemsCount, totalValue: totalValueFromData } = nftListResponse.response!.data;
      // check if isShardDown to disable button
      setDisableCheckout(cartItems.some((item) => isShardDown(item.shardUrl)));

      setNftListState(() => ({
        nftItems: cartItems,
        totalCount: itemsCount,
        totalValue: totalValueFromData,
      }));

      setIsLoading(false);
    }
  }, [nftListResponse]);

  // Call checkCart endpoint when cart show is true
  useEffect(() => {
    if (cartShowState && cart.length > 0) {
      checkCartQuery({
        nftItems: cart.map((item) => ({ uuid: item.uuid })),
      });
    }
  }, [cartShowState, checkCartQuery, cart]);

  // Check response from checkCart endpoint to verify items in the cart are still available
  useEffect(() => {
    if (checkCartResponse.status === 'resolved') {
      if (!checkCartResponse.response) {
        setDisableCheckout(false);
      }
    } else if (checkCartResponse.status === 'error') {
      if (checkCartResponse.error.status === 422) {
        const { data: errorData } = checkCartResponse.error;
        if (errorData && errorData.nftItems.length > 0) {
          const { nftItems: unavailableNftItems } = errorData;
          setUnavailableItems(unavailableNftItems.map((item) => item.uuid));
          setDisableCheckout(true);
        }
      } else {
        toast.error(checkCartResponse.error.message);
        setCartShowState(false);
      }
    }
  }, [checkCartResponse, setCartShowState]);

  const onCloseCart = () => {
    setCartShowState(false);
  };

  return (
    <>
      <section className="flex flex-row items-center justify-between pb-5">
        <div>
          <h3 className="text-2xl font-bold mb-0">Your Cart</h3>
        </div>

        <div>
          <Button.Link className="py-2 no-underline" onClick={onCloseCart}>
            <FontAwesomeIcon icon={faTimes} className="text-black" />
          </Button.Link>
        </div>
      </section>

      <section className="flex-grow overflow-auto">
        {nftItems.map((nftItem: CartItem) => (
          <CartItemRow
            key={nftItem.uuid}
            nftItem={nftItem}
            onRemove={removeCartItem}
            unavailable={unavailableItems.includes(nftItem.uuid)}
            temporarilyUnavailable={isShardDown(nftItem.shardUrl)}
          />
        ))}

        {isLoading && (
          <div className="flex justify-center w-full h-full items-center">
            <Loader alt="load more" />
          </div>
        )}
      </section>

      <div className="bg-white">
        <ItemTotal amount={totalValue} itemCount={totalCount} />

        <section className="col-span-3 inset-x-8 bottom-0 pb-5 text-right">
          <hr className="my-5 border-t-2" />
          <Show show={disableCheckout && totalCount > 0}>
            <h3 className="text-xs font-bold">
              Please remove any unavailable item(s) from your cart before checking out.
            </h3>
          </Show>
          <Button.Link className="py-2 no-underline" onClick={onCloseCart}>
            <span className="text-sm font-bold text-gray-500">Close</span>
          </Button.Link>
          {totalValue > 0 && (
            <Button.Primary
              type="button"
              className="mt-4 ml-4 gtm_cartCheckoutNow"
              data-gtm="cartCheckoutNow"
              onClick={handleCheckout}
              disabled={disableCheckout}
            >
              Checkout Now
              <FontAwesomeIcon icon={faCheck} className="ml-2" />
            </Button.Primary>
          )}
        </section>
      </div>
    </>
  );
});
