import React from 'react';
import { CartItem, HandleCompleteArgs } from 'src/interfaces';

import { CoinbaseChargeButton } from './CoinbaseChargeButton';

export interface CoinbaseFormData {
  email: string;
  name: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  district: string;
  postalCode: string;
  country: string;
  discountCodeUuid?: string;
}

type CoinbaseFormProps = {
  onStartCharge: () => void;
  onCompleteCharge: ({ success, buyerCountry, paymentProcessor }: HandleCompleteArgs) => void;
};

export const CoinbaseForm = (props: CoinbaseFormProps) => {
  const { onStartCharge, onCompleteCharge } = props;

  return (
    <section>
      <CoinbaseChargeButton onStartCharge={onStartCharge} onCompleteCharge={onCompleteCharge} />
      <p className="mt-6 text-sm italic">No Coinbase account required</p>
      <p className="mt-1 text-sm italic">
        (<span>*</span>) Required
      </p>
      <p className="mt-10 text-sm italic">
        PLEASE BE SURE FOR PAYMENTS THAT YOU DO NOT USE BSC (BINANCE SMART CHAIN). IF YOU DO, YOUR PURCHASE WILL NOT GO
        THROUGH AND FUNDS WILL BE LOST. NO BSC COINS, INCLUDING BEP-20 COINS, WORK WITH COINBASE COMMERCE.
      </p>
    </section>
  );
};
