import { useCallback, useMemo, useState } from 'react';
import axios, { AxiosError } from 'axios';

type UseQueryStatus = 'unresolved' | 'loading' | 'resolved' | 'error';

interface UseQueryResponse<U> {
  response?: U;
  status: UseQueryStatus;
  error?: any;
}

interface ApiError {
  response: {
    data: any;
  };
}

type UseQueryRequest<T> = (params: T) => void;

function isApiError(e): e is ApiError {
  return typeof e.response === 'object';
}

export function useLazyQuery<T, U>(query: (params: T) => Promise<U>): [UseQueryRequest<T>, UseQueryResponse<U>] {
  const [proxiedResponse, setProxiedResponse] = useState<UseQueryResponse<U>>({ status: 'unresolved' });

  const proxiedRequest = useCallback<UseQueryRequest<T>>(
    async (params) => {
      setProxiedResponse((prevState) => ({ ...prevState, status: 'loading' }));
      try {
        const response = await query(params);
        setProxiedResponse((prevState) => ({ ...prevState, response, status: 'resolved', error: undefined }));
      } catch (e: unknown) {
        let errorData;

        if (isApiError(e)) {
          errorData = e.response?.data || {};
        }

        if (axios.isAxiosError(e)) {
          errorData.status = (e as AxiosError).response?.status;
        }

        if (typeof errorData !== 'object' || errorData === null) {
          errorData = {};
        }

        if (!errorData.status) {
          errorData.status = 500;
        }
        if (!errorData.message) {
          errorData.message = 'Request failed';
        }
        setProxiedResponse((prevState) => ({ ...prevState, status: 'error', error: errorData }));
      }
    },
    [query],
  );

  return useMemo(() => [proxiedRequest, proxiedResponse], [proxiedRequest, proxiedResponse]);
}
