import React from 'react';
import { HandleCompleteArgs } from 'src/interfaces';
import { PurchaseOrderButton } from './PurchaseOrderButton';

export interface PurchaseOrderFormData {
  name: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  district: string;
  postalCode: string;
  country: string;
  discountCodeUuid?: string;
}

type PurchaseOrderFormProps = {
  onStartCharge: () => void;
  onCompleteCharge: ({ success, buyerCountry, paymentProcessor }: HandleCompleteArgs) => void;
};

export const PurchaseOrderForm = (props: PurchaseOrderFormProps) => {
  const { onStartCharge, onCompleteCharge } = props;

  return (
    <section>
      <PurchaseOrderButton onStartCharge={onStartCharge} onCompleteCharge={onCompleteCharge} />
      <p className="mt-6 text-sm italic">With a PO, you have 7 days to submit payment to support@glassblock.io.</p>
    </section>
  );
};
