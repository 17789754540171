import { useContext } from 'react';
import { ExchangeContext } from 'src/common/context/ExchangeContext';
import cx from 'classnames';
import { CoinPairSelect } from './CoinPairSelect';
import HamburgerMenuIcon from './Sidebar/Icons/HamburgerMenuIcon';
import WalletIcon from './Sidebar/Icons/WalletIcon';
import ToolTip from './ToolTip/ToolTip';
import ExchangeIcon from './Sidebar/Icons/ExchangeIcon';
import BillIcon from './Sidebar/Icons/BillIcon';
import BookIcon from './Sidebar/Icons/BookIcon';
import ClockIcon from './Sidebar/Icons/ClockIcon';

export const iconHandler = (title: string) => {
  switch (title) {
    case 'My Assets':
      return <WalletIcon />;
    case 'Bid & Ask':
      return <ExchangeIcon />;
    case 'My Orders':
      return <BillIcon />;
    case 'Realtime':
      return <ClockIcon />;
    case 'Orderbook':
      return <BookIcon />;
    default:
      return false;
  }
};

const ExchangeHeaderRow = ({
  title,
  justTitle = false,
  showTooltip = false,
}: {
  title: string;
  justTitle?: boolean;
  showTooltip?: boolean;
}) => {
  const { setIsSidebarOpen, isSidebarOpen } = useContext(ExchangeContext);

  return (
    <div className={`flex items-baseline pl-2 ${cx({ 'pt-8': justTitle })} `}>
      <div className={`lg:hidden items-baseline flex flex-1 justify-between ${!justTitle && 'mt-[26px]'}`}>
        <button
          className="flex items-center"
          type="button"
          onClick={() => {
            setIsSidebarOpen(!isSidebarOpen);
          }}
        >
          <HamburgerMenuIcon />
          <span className="pl-2 uppercase text-[18px] font-poppins font-semibold text-lg">{title}</span>
        </button>
        {showTooltip && <ToolTip title={title} />}
        {!justTitle && <CoinPairSelect />}
      </div>

      <div
        className={`flex-wrap gap-2 hidden lg:flex text-blue-400 flex-1 justify-between ${!justTitle && 'mt-[26px]'}`}
      >
        <div className="flex items-center">
          <div>{iconHandler(title)}</div>
          <span className="pl-2 uppercase text-[18px] font-poppins font-semibold text-lg">{title}</span>
          {showTooltip && <ToolTip title={title} />}
        </div>
        {!justTitle && <CoinPairSelect />}
      </div>
    </div>
  );
};

export default ExchangeHeaderRow;
