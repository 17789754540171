/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-plusplus */

import { useContext, useEffect, useState } from 'react';
import { coinIcons } from 'src/common/util';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GetExchangeBalanceVariation } from 'src/interfaces';
import { ExchangeUserContext } from 'src/common/context/ExchangeUserContext';
import ProfitIndicator from '../my-assets/ProfitIndicator';
import ExchangeHeaderRow from '../ExchangeHeaderRow';
import Charts from './Charts';

export type TimeFrameType = {
  code: string;
  label: string;
  d3_time_format: string;
  time_interval: string;
};

export const timeFrame: TimeFrameType[] = [
  {
    code: '1d',
    label: '1 day',
    d3_time_format: '%H:%m',
    time_interval: '1h',
  },
  {
    code: '5d',
    label: '5 days',
    d3_time_format: '%H:%M',
    time_interval: '1h',
  },
  {
    code: '1M',
    label: '1 Month',
    d3_time_format: '%b %d',
    time_interval: '1d',
  },
  {
    code: '3M',
    label: '3 Months',
    d3_time_format: '%b %d',
    time_interval: '2d',
  },
  {
    code: '6M',
    label: '6 Months',
    d3_time_format: '%b %d',
    time_interval: '4d',
  },
  {
    code: '1y',
    label: '1 Year',
    d3_time_format: '%b %d',
    time_interval: '8d',
  },
  {
    code: '3y',
    label: '3 Years',
    d3_time_format: '%b %d/%y',
    time_interval: '24d',
  },

  {
    code: '5y',
    label: '5 Years',
    d3_time_format: '%b %d/%y',
    time_interval: '40d',
  },
  {
    code: '10y',
    label: 'All time',
    d3_time_format: '%b %d %y',
    time_interval: '1y',
  },
];

const ExchangeMarketStats = () => {
  const { selectedCoinPair } = useContext(ExchangeUserContext);
  const { tradingPairs } = useContext(ExchangeUserContext);
  const [balanceVariation, setBalanceVariation] = useState<GetExchangeBalanceVariation | null>(null);

  const [selectedTimeFrame, setSelectedTimeFrame] = useState(timeFrame[0]);
  const [active, setActive] = useState(selectedTimeFrame.code);

  const tradingPair = tradingPairs.find(
    (tPair) =>
      tPair.base_coin_id === selectedCoinPair?.base_coin_id && tPair.quote_coin_id === selectedCoinPair.quote_coin_id,
  );

  return (
    <div className="m-auto mb-2 pb-2 lg:shadow-lg rounded-lg lg:mt-8 lg:p-2 lg:pt-0 lg:bg-white min-w-full">
      <div>
        <ExchangeHeaderRow title="Market Stats" />
      </div>
      <div className="bg-white rounded-lg border-2 lg:border-none lg:bg-none mt-4 ml-4 mr-4">
        <div className="p-4 flex mr-10">
          <div className="flex">
            <div className="rounded-full h-[32px] w-[32px]">
              <LazyLoadImage
                src={tradingPair?.base_coin_image_url ?? coinIcons.default}
                className="rounded-full  h-[32px] w-[32px]"
                alt="Coin Logo"
              />
            </div>
            <div className="rounded-full h-[32px] w-[32px]">
              <LazyLoadImage
                src={tradingPair?.quote_coin_image_url ?? coinIcons.default}
                className=""
                alt="Coin Logo"
              />
            </div>
          </div>
          <div className="flex w-full pl-2 ">
            <p className="text-2xl font-bold">
              {tradingPair?.base_coin_name.toUpperCase()}-{tradingPair?.quote_coin_name.toUpperCase()}
            </p>
          </div>
          <div className="flex w-full justify-end">
            {balanceVariation && <ProfitIndicator balanceVariation={balanceVariation} />}
          </div>
        </div>
        <div className="flex mt-4 justify-start mb-4">
          <div className="w-full p-2">
            <ul className="flex gap-4 flex-wrap">
              {timeFrame.map((tf) => {
                return (
                  <li
                    key={tf.code}
                    className={`rounded-2xl gap-2 text-center p-2 cursor-pointer justify-center flex items-center ${
                      active === tf.code ? 'bg-[#0066CC] text-[#fff]' : ''
                    }`}
                    onClick={() => {
                      setSelectedTimeFrame(tf);
                      setActive(tf.code);
                    }}
                  >
                    {tf.label}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <Charts
          height={500}
          tradingPair={tradingPair}
          setBalanceVariation={setBalanceVariation}
          selectedTimeFrame={selectedTimeFrame}
        />
      </div>
    </div>
  );
};

export default ExchangeMarketStats;
