import { FC } from 'react';
import { DocumentTextIcon } from '@heroicons/react/outline';

interface PurchaseOrderIconPropsType {
  className?: string;
}

const PurchaseOrderIcon: FC<PurchaseOrderIconPropsType> = ({ className = 'w-[19.5px] h-[19.5px] text-[12px]' }) => {
  return (
    <div className="flex items-center text-center">
      <div
        className={`border border-gray-400 rounded-full bg-gray-300 font-semibold flex justify-center self-center items-center ${className}`}
      >
        <DocumentTextIcon className="w-3" />
      </div>
    </div>
  );
};

export default PurchaseOrderIcon;
