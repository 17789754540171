import { useState } from 'react';

import rehypeTruncate from 'src/common/util/markdown';

export function useRehypeReadMore({ maxChars = 300 } = {}) {
  const [showReadMore, setShowReadMore] = useState(false);
  const [readMoreState, setReadMoreState] = useState(false);

  const rehypeReadMore = () => {
    return (
      tree: {
        children: any;
        type?: string;
        value?: string | any[];
        tagName?: string;
      },
      ...args: any
    ) => {
      const truncate = rehypeTruncate({
        maxChars,
        disable: readMoreState,
      });

      const treeChildrenLength = tree.children.length;
      const lastChild = JSON.parse(JSON.stringify(tree.children[treeChildrenLength - 1]));

      truncate({ ...tree, ...args });

      const truncatedTreeChildrenLength = tree.children.length;
      const truncatedLastChild = tree.children[truncatedTreeChildrenLength - 1];

      const checkChildren: (children: string | any[], truncatedChildren: string | any[]) => any = (
        children: string | any[],
        truncatedChildren: string | any[],
      ): boolean => {
        if (children.length === truncatedChildren.length) {
          const lastChildFromChildren = children.at(-1);
          const truncatedLastChildFromChildren = truncatedChildren.at(-1);

          if (lastChildFromChildren.children && truncatedLastChildFromChildren.children) {
            return checkChildren(lastChildFromChildren.children, truncatedLastChildFromChildren.children);
          }

          if (lastChildFromChildren.value.length === truncatedLastChildFromChildren.value.length) {
            return false;
          }

          return true;
        }

        return true;
      };

      if (treeChildrenLength === truncatedTreeChildrenLength && !readMoreState) {
        if (lastChild.children && truncatedLastChild.children) {
          setShowReadMore(checkChildren(lastChild.children, truncatedLastChild.children));
        } else if (lastChild.value.length === truncatedLastChild.value.length) {
          setShowReadMore(false);
        }
      } else {
        setShowReadMore(true);
      }
    };
  };

  const toggleReadMore = () => {
    setReadMoreState(!readMoreState);
  };

  return {
    showReadMore,
    rehypeReadMore,
    toggleReadMore,
    readMoreState,
  };
}
