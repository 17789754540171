import React from 'react';
import { generatePath, Link, useSearchParams } from 'react-router-dom';
import cx from 'classnames';
import { camelCase } from 'lodash';
import { AppRoutes } from 'src/routes/app/routes';
import { Show } from 'src/common/layout';
import { formatUnitPrice } from 'src/common/util';
import { CollapseBy, SearchedNftItem, SimilarNft } from 'src/api';
import style from './ResultCard.module.css';
import { ResultCardPrice } from './ResultCardPrice';
import { LitPetCard, LitPetCardSizes } from '../lit-pet-card/LitPetCard';

interface ResultCardProps {
  result: SearchedNftItem | SimilarNft;
  mapKey?: string;
}

const queryParamsToRemove = ['name', 'collapseBy', 'query', 'offset', 'limit'];

export const ResultCard = React.memo((props: ResultCardProps) => {
  const { result, mapKey } = props;
  result.imageUrl = result.imageUrl ? result.imageUrl : '/NoCategoryWhite.png';
  const [searchParams] = useSearchParams();
  const characteristic = JSON.parse(searchParams.get('characteristics') || '{}');
  const currencyPreferences = searchParams.get('currencyPreferences');
  const querySeller = searchParams.get('seller') || '';
  const querySearchOnlySellerItems = searchParams.get('searchOnlySellerItems') === 'true';
  const thumbnail = result.thumbnails?.find((th) => th.width === 300)?.secureUrl || result.imageUrl;
  const hasMultipleMintIds = (result as SearchedNftItem).mintIds?.length > 1;

  const litPetImage =
    (result.nftType === 'LitPet' &&
      result.thumbnails?.find((th) => th.width === 150 && th.type === 'png')?.secureUrl) ||
    result.imageUrl;

  const filteredSearchParams = new URLSearchParams(searchParams);

  for (const key of queryParamsToRemove) {
    filteredSearchParams.delete(key);
  }

  if (hasMultipleMintIds) {
    filteredSearchParams.set('name', result.name);
    filteredSearchParams.set('collapseBy', CollapseBy.MINT_ID);
  } else {
    if (querySeller !== '') filteredSearchParams.set('seller', querySeller);
    if (querySearchOnlySellerItems) filteredSearchParams.set('searchOnlySellerItems', 'true');
    if (currencyPreferences) filteredSearchParams.set('currencyPreferences', currencyPreferences);
  }

  const cardLink = hasMultipleMintIds
    ? generatePath(`/${AppRoutes.SEARCH_RESULTS}?${filteredSearchParams.toString()}`)
    : generatePath(`/${AppRoutes.MINT_ID_SHOW}?${filteredSearchParams.toString()}`, { nftMintId: `${result.mintId}` });

  return (
    <div key={mapKey} className={cx('relative', hasMultipleMintIds && 'mt-[10px]')}>
      <div className={cx(style.card, 'shadow-lg border border-solid border-gray-300 rounded z-[3]')}>
        <div className="relative h-72 overflow-hidden p-1">
          {result.nftType === 'LitPet' ? (
            <LitPetCard
              name={result.name}
              power={Number(result.power)}
              release={Number(result.release)}
              ability={result.ability || ''}
              rank={result.litPetsTopRank || ''}
              generation={result.itemDetails.generation || ''}
              litPetImage={litPetImage}
              className="h-72 mx-auto max-h-72"
              size={LitPetCardSizes.SMALL}
              quality={0.8}
            />
          ) : (
            <img src={thumbnail} className="group-hover:scale-110 h-72 mx-auto" alt="" />
          )}
        </div>
        <div className="flex flex-col flex-grow gap-2 p-5 pt-4">
          <div className="flex flex-col flex-grow gap-2">
            <div className="flex gap-2 justify-between font-bold">
              <div className="truncate">
                <Link className={style.name} to={cardLink} state={{ internalLink: true }}>
                  {result.name}
                </Link>
              </div>
              <div className="text-right italic pl-2">Price</div>
            </div>
            <div className="font-bold italic text-right pb-4">
              <ResultCardPrice
                salePrice={result.salePrice}
                fromPrice={result.fromPrice}
                toPrice={(result as SearchedNftItem).toPrice}
              />
              <Show show={typeof result.unitPrice === 'number' && result.salePrice !== result.unitPrice}>
                <div className="text-sm text-slate-500">(${formatUnitPrice(result.unitPrice)}/Count)</div>
              </Show>
            </div>
          </div>

          <div className="flex flex-wrap gap-2 text-xs">
            <Show show={hasMultipleMintIds}>
              <div className="text-base text-blue text-center w-full">See more</div>
            </Show>
            <Show show={!hasMultipleMintIds}>
              <Show show={!!characteristic?.attribute && !!result?.itemDetails[camelCase(characteristic.attribute)]}>
                <div>
                  {`${characteristic?.attribute?.charAt(0).toUpperCase()}${characteristic?.attribute?.slice(1)}`}{' '}
                  <strong>{result?.itemDetails[camelCase(characteristic?.attribute)]}</strong>
                </div>
              </Show>
              <Show show={!!result.power}>
                <div>
                  Power <strong>{result.power}</strong>
                </div>
              </Show>
              <Show show={!!result.release}>
                <div>
                  Release <strong>{result.release}</strong>
                </div>
              </Show>
              <Show show={!!result.ability}>
                <div>
                  Ability <strong>{result.ability}</strong>
                </div>
              </Show>
            </Show>
          </div>
        </div>
      </div>
      <Show show={hasMultipleMintIds}>
        <div
          className={cx(
            style.card,
            'shadow-md border border-solid border-gray-300 rounded h-full absolute left-[5px] w-full bottom-[5px] z-[2]',
          )}
        />
        <div
          className={cx(
            style.card,
            'shadow-md border border-solid border-gray-300 rounded h-full absolute left-[10px] w-full bottom-[10px] z-[1]',
          )}
        />
      </Show>
    </div>
  );
});
