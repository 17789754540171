import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createRef } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/routes/app';

export type CategoriesViewer = {
  name: string;
  value: string;
  imageUrl: string;
};

export interface CategoryViewerProps {
  categories: CategoriesViewer[];
  searchInputValue?: string;
}

export const CategoryViewer = React.memo(({ categories, searchInputValue }: CategoryViewerProps) => {
  const navigate = useNavigate();
  const divRef = createRef();

  const scroll = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
  };

  const handleOnClick = (categoryId) => {
    const queryParams = new URLSearchParams();

    queryParams.append('categories', categoryId);
    if (searchInputValue) {
      queryParams.set('query', `${searchInputValue}`);
    }

    navigate({
      pathname: generatePath(AppRoutes.SEARCH_RESULTS),
      search: queryParams.toString(),
    });
  };

  return (
    <div className="mt-10" ref={divRef as React.RefObject<HTMLDivElement>}>
      <div className="flex flex-col flex-grow items-center justify-center text-blue text-center text-sm md:mb-24">
        <p
          className="cursor-pointer"
          onClick={() => {
            scroll(divRef);
          }}
        >
          Browse by Category
        </p>
        <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
      </div>
      <div className="flex-grow grid gap-16 m-8 transition-all grid-cols-1 md:grid-cols-2 3xl:grid-cols-3">
        {categories.map((category) => (
          <div
            key={`list-${category.name}`}
            className="relative flex flex-col bg-white rounded-md group border-2 shadow-inner shadow-gray-100 cursor-pointer"
            onClick={() => handleOnClick(category.value)}
          >
            <div className="relative h-80 overflow-hidden">
              <div
                className="absolute inset-0 transform-gpu group-hover:scale-110 transition-transform duration-300 delay-75 bg-center bg-no-repeat bg-contain mt-8"
                style={{ backgroundImage: `url(${category.imageUrl})` }}
              />
            </div>
            <h6 className="text-center text-blue text-lg my-6">{category.name}</h6>
          </div>
        ))}
      </div>
    </div>
  );
});
