import { FC } from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { PaymentOptions as PaymentOptionsType } from '../../../interfaces';
import { SimpleToolTip, SimpleTooltipProps } from '../tooltips/SimpleToolTip';
import UsdIcon from '../../../assets/images/usd-icon';
import Heptal from '../../../assets/images/Heptal.svg';
import PurchaseOrderIcon from '../../../assets/images/purchase-order-icon';

export interface PaymentOptionsProps {
  paymentOptions: PaymentOptionsType;
  tooltipPosition?: SimpleTooltipProps['position'];
}

interface PaymentOptionTooltipContentProps {
  name: string;
  currencies: string[];
}

interface PaymentOptionIconProps {
  paymentOptionKey: string;
}

const PaymentOptionTooltipContent: FC<PaymentOptionTooltipContentProps> = ({ name, currencies }) => {
  return (
    <div className="flex flex-col pb-2 text-left">
      <div className="mb-2">
        <span className="font-bold text-sm">{name}</span>
      </div>
      <div>
        <span className="font-bold text-xs">Currencies:</span>
      </div>
      {currencies.map((currency) => (
        <div className="list-item ml-4 text-xs">{currency}</div>
      ))}
    </div>
  );
};

const PaymentOptionIcon: FC<PaymentOptionIconProps> = ({ paymentOptionKey }) => {
  switch (paymentOptionKey) {
    case 'devvPay':
      return (
        <div className="w-6 h-6">
          <img src={Heptal} alt="heptal" />
        </div>
      );
    case 'coinbase':
      return <UsdIcon className="w-5 h-5 m-0.5 text-xs" />;
    case 'purchase_order':
      return <PurchaseOrderIcon className="w-5 h-5 m-0.5 text-xs" />;
    default:
      return (
        <div className="w-6 h-6">
          <InformationCircleIcon />
        </div>
      );
  }
};

export const PaymentOptions: FC<PaymentOptionsProps> = ({ paymentOptions, tooltipPosition }) => {
  const paymentOptionsKeys = Object.keys(paymentOptions);

  return (
    <div className="flex flex-row">
      {paymentOptionsKeys.sort().map((key) => (
        <SimpleToolTip
          position={tooltipPosition}
          tooltipContent={
            <PaymentOptionTooltipContent name={paymentOptions[key].name} currencies={paymentOptions[key].currencies} />
          }
          icon={<PaymentOptionIcon paymentOptionKey={key} />}
        />
      ))}
    </div>
  );
};
