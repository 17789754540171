import React from 'react';
import markdownStyle from './markdown.module.css';

export const PrivacyPolicy = React.memo(() => {
  return (
    <div className="w-full flex-col justify-between m-4">
      <h1 className="text-blue text-5xl text-center font-bold">Privacy Policy</h1>
      {/* just run PrivacyPolicy.md through https://markdowntohtml.com/ and apply the `markdownStyle.md` className */}
      <div className={markdownStyle.md}>
        <h3 id="what-does-devvio-do-with-your-personal-information-">
          What does Devvio do with your personal information?
        </h3>
        <p>
          Financial companies choose how they share your personal information. Under federal law, that means personally
          identifiable information. Federal law gives consumers the right to limit some but not all sharing. Federal law
          also requires us to tell you how we collect, share, and protect your personal information. Please read this
          notice carefully to understand what we do.
        </p>
        <p>
          The types of personal information we collect and share depend on the product or service you have with us. This
          information can include:
        </p>
        <ul>
          <li>Social Security number and employment information</li>
          <li>Photo identification</li>
          <li>Account balances, transaction history and credit information</li>
          <li>Assets and investment experience</li>
          <li>Information about user transactions, including trade frequency and trade size</li>
          <li>Other information about your job, position, assets and financial sophistication</li>
        </ul>
        <p>
          All financial companies need to share customers’ personal information to run their everyday business. In the
          section below, we list the reasons financial companies can share their customers’ personal information; the
          reasons Devvio may share customers’ personal information; and whether you can limit this sharing.
        </p>
        <h3 id="reasons-we-can-share-your-personal-information">Reasons we can share your personal information</h3>
        <p>
          For our everyday business purposes — such as to process your transactions, maintain your account(s), respond
          to court orders and legal investigations, or report to credit bureaus. Devvio shares, and you cannot limit
          this sharing.
        </p>
        <p>
          For our marketing purposes — to offer our products and services to you. Devvio shares, and you cannot limit
          this sharing.
        </p>
        <p>For joint marketing with other financial companies. Devvio does not share.</p>
        <p>
          For our affiliates’ everyday business purposes — information about your transactions and experiences. Devvio
          shares, and you cannot limit this sharing.
        </p>
        <p>
          For our affiliates’ everyday business purposes — information about your creditworthiness. Devvio does not
          share.
        </p>
        <p>For our affiliates’ to market to you. Devvio shares, and you can limit this sharing.</p>
        <p>For nonaffiliates’ to market to you. Devvio does not share.</p>
        <p>
          <strong>Questions?</strong> Go to <a href="https://www.devv.io">https://www.devv.io</a>
        </p>
        <h4 id="how-does-devvio-protect-my-personal-information-">How does Devvio protect my personal information?</h4>
        <p>
          To protect your personal information from unauthorized access and use, we use security measures that comply
          with federal law. These measures include computer safeguards and secured files and buildings.
        </p>
        <h4 id="how-does-devvio-collect-my-personal-information-">How does Devvio collect my personal information?</h4>
        <p>We collect your personal information, for example, when you:</p>
        <ul>
          <li>Register an account</li>
          <li>Perform transactions</li>
          <li>Deposit or withdraw funds</li>
        </ul>
        <h4 id="how-can-i-opt-out-">How Can I Opt Out?</h4>
        <p>To Opt Out of sharing, please contact us via the following:</p>
        <ul>
          <li>Send us an email: support@devv.io</li>
        </ul>
        <h4 id="why-can-t-i-limit-all-sharing-">Why can’t I limit all sharing?</h4>
        <p>Federal law gives you the right to limit only:</p>
        <ul>
          <li>sharing for affiliates’ everyday business purposes — information about your creditworthiness</li>
          <li>affiliates from using your information to market to you</li>
          <li>sharing with nonaffiliates to market to you</li>
        </ul>
        <p>State laws and individual companies may give you more rights to limit sharing.</p>
        <h4 id="what-happens-when-i-limit-sharing-for-an-account-i-hold-jointly-with-someone-else-">
          What happens when I limit sharing for an account I hold jointly with someone else?
        </h4>
        <p>Your choices will apply to everyone on your account.</p>
        <h4 id="definitions">Definitions</h4>
        <p>
          <strong>Affiliates.</strong> Companies related by common ownership or control. They can be financial and
          nonfinancial companies.
        </p>
        <p>
          <strong>Nonaffiliates.</strong> Companies not related by common ownership or control. They can be financial
          and nonfinancial companies. Nonaffiliates we share with can include third-party identity and background check
          services.
        </p>
        <p>
          <strong>Joint marketing.</strong> A formal agreement between nonaffiliated financial companies that together
          market financial products or services to you. We don’t currently have any joint marketing partners.
        </p>
        <h4 id="other-important-information">Other Important Information</h4>
        <p>
          If your Devvio account has a Vermont address, we will not share personal information we collect about you with
          third parties unless the law allows or you provide authorization.
        </p>
      </div>
    </div>
  );
});
