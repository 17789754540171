import React from 'react';
import markdownStyle from './markdown.module.css';

export const EnvironmentalPolicy = React.memo(() => {
  return (
    <div className="w-full flex-col justify-between m-4">
      <h1 className="text-blue text-5xl font-bold text-center">Environmental Policy</h1>
      <div className={markdownStyle.md}>
        <p>
          Devvio is committed to environmental leadership in all of its business activities. It is the policy of Devvio
          to carry out its activities in a manner that minimizes environmental impacts, conserves natural resources, and
          provides for the effective stewardship of the environment. It is because of these policies that we designed
          the core Devvio blockchain (DevvX) to be more energy efficient than traditional blockchains such as those
          powering Bitcoin. In fact, a months-long study of the environmental impact of our DevvX blockchain platform as
          measured against ISO standards studied the systematic analysis of the environmental effects of DevvX, with
          preliminary results demonstrating that the DevvX blockchain platform consumes dramatically less energy than
          other platforms. According to the third-party analysis, our platform uses approximately 347 million times less
          energy per transaction than Ethereum and 3.5 billion times less energy per transaction than Bitcoin. Measured
          in terms of Joules (a unit of energy) consumed in a single transaction, DevvX consumed 2.1 Joules, as compared
          to Ethereum at 693 million Joules, and Bitcoin at over 7 billion Joules.
        </p>
        <p>
          With these policies in place we believe that we can achieve a healthy and safe environment. We are committed
          to do and will:
        </p>
        <ul>
          <li>Provide a safe and healthful workplace;</li>
          <li>Be an environmentally responsible neighbor in the communities where we operate;</li>
          <li>Promote pollution prevention, waste minimization, and conservation;</li>
          <li>Promote the effective use of innovative environmental technologies and practices;</li>
          <li>
            Ensure the responsible use of energy throughout our business operations, including conserving energy,
            improving energy efficiency, and giving reasonable preference to renewable over non-renewable energy when
            feasible;
          </li>
          <li>
            Convey to our customers, industry associates, vendors, and the general public our strong environmental
            safety and health commitment;
          </li>
          <li>Participate in efforts to improve environmental protection and understanding;</li>
          <li>Complying with applicable laws, regulations, and other promulgated environmental requirements; and</li>
          <li>Promptly correct any practice or condition not in compliance with this policy.</li>
        </ul>
        <p>Devvio is committed to continual improvement of environmental performance.</p>
      </div>
    </div>
  );
});
