import React, { useCallback, useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Button } from 'src/common/interactions/Button';
import { Loader } from 'src/common/components/loader/Loader';
import { CartItem, HandleCompleteArgs, PaymentProcessors, OrderInformation, PAYMENT_OPTION } from 'src/interfaces';
import { UserContext } from 'src/common/context/UserContext';
import { CartContext } from 'src/common/context/CartContext';
import { coinbaseCharge } from 'src/api/payments';
import { CheckoutContext } from 'src/common/context/CheckoutContext';
import { ItemsUnavailableToast } from '../../toast/ItemsUnavailableToast';
import { CoinbaseFormData } from './CoinbaseForm';
import ToolTip from '../../exchange/ToolTip/ToolTip';

type CoinbaseChargeButtonProps = {
  onCompleteCharge: ({ success, buyerCountry, paymentProcessor }: HandleCompleteArgs) => void;
  onStartCharge: () => void;
};

export const CoinbaseChargeButton = React.memo((props: CoinbaseChargeButtonProps) => {
  const { onCompleteCharge, onStartCharge } = props;
  const {
    getValues: getFormValues,
    handleSubmit,
    formState: { errors: formErrors, isDirty },
  } = useFormContext<CoinbaseFormData>();
  const { user } = useContext(UserContext);
  const { removeCartItem } = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(false);
  const { nftItems, isOptionDisabled } = useContext(CheckoutContext);

  const onSendCustomerData = async () => {
    const { name, addressLine1, addressLine2, city, district, postalCode, country, discountCodeUuid } = getFormValues();

    const payload: OrderInformation = {
      username: user?.username || '',
      publicAddress: user?.pub || '',
      buyerName: name,
      buyerAddress1: addressLine1,
      buyerAddress2: addressLine2,
      buyerCity: city,
      buyerState: district,
      buyerPostalCode: postalCode,
      buyerCountry: country,
      discountCodeUuid,
      nftItems: nftItems.map((item) => ({ uuid: item.uuid })),
    };
    setIsLoading(true);

    coinbaseCharge(payload)
      .then((responseData) => {
        if (typeof responseData === 'string') {
          onCompleteCharge({ success: true, buyerCountry: country, paymentProcessor: PaymentProcessors.COINBASE });
          window.location.replace(responseData);
          return;
        }

        setIsLoading(false);

        if (responseData.errorCode === 'PROTECTED_ITEMS') {
          toast.error(responseData.message, { autoClose: false });
          return;
        }

        if (Array.isArray(responseData.nftItems) && responseData.nftItems.length > 0) {
          const unavailableItemsUuids = responseData.nftItems.map((item) => item.uuid);
          const nftItemsToRemove = nftItems.reduce((acc, item) => {
            if (unavailableItemsUuids.includes(item.uuid)) {
              acc.push({ ...responseData.nftItems.find((nft) => nft.uuid === item.uuid), ...item });
            }
            return acc;
          }, [] as CartItem[]);

          toast.error(
            <ItemsUnavailableToast
              message={responseData.message || 'Some items in your cart are no longer available.'}
              nftItems={nftItemsToRemove}
              footerNote="Unavailable items are removed automatically from the cart."
            />,
            {
              autoClose: false,
              closeOnClick: false,
            },
          );

          removeCartItem(unavailableItemsUuids);
        } else if (responseData.message && responseData.message.length > 0) {
          toast.error(responseData.message, {
            autoClose: false,
            closeOnClick: false,
          });
        }

        onCompleteCharge({ success: false, paymentProcessor: PaymentProcessors.COINBASE });
      })
      .catch(() => {
        toast.error('Something happened while attempting to pay with Crypto. Please try again.');
        setIsLoading(false);
        onCompleteCharge({ success: false, paymentProcessor: PaymentProcessors.COINBASE });
      });
  };

  const createCoinbaseCharge = useCallback(
    (data) => {
      // Workaround for handling browser autofilled inputs
      const autoFillCheck =
        JSON.stringify(
          Object.keys(data).reduce((acc, key) => {
            acc[key] = '';
            return acc;
          }, {}),
        ) !== JSON.stringify(data);
      if (
        ((Object.keys(formErrors).length === 0 && isDirty) || autoFillCheck) &&
        !isOptionDisabled(PAYMENT_OPTION.COINBASE)
      ) {
        onStartCharge();
        onSendCustomerData();
      }
    },
    [formErrors, isDirty],
  );

  return (
    <div className="flex justify-center">
      <div className="grid w-full">
        <Button.Info
          onClick={handleSubmit(createCoinbaseCharge)}
          className="w-full xl:w-1/3 flex justify-center justify-self-center gtm_payNowCoinbase"
          data-gtm="payNowCoinbase"
          disabled={isLoading}
          type="submit"
        >
          {isLoading ? <Loader alt="Payment loading." /> : 'Submit Payment'}
        </Button.Info>
      </div>
    </div>
  );
});
