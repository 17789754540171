import { NftListItem } from 'src/api';
import { Show } from 'src/common/layout';

interface UnavailableItem extends Pick<NftListItem, 'imageUrl' | 'name' | 'uuid' | 'artist'> {
  message?: string;
}

type ItemsUnavailableToastProps = {
  nftItems: UnavailableItem[];
  message: string;
  footerNote?: string;
};

export const ItemsUnavailableToast = (props: ItemsUnavailableToastProps) => {
  const { nftItems, message, footerNote } = props;

  return (
    <>
      <p className="border-b-2 pb-2">{message}</p>
      <div className="overflow-auto max-h-48 w-full">
        {nftItems.map((item) => {
          return (
            <div
              className="grid grid-flow-row-dense grid-cols-3 grid-rows-1 justify-between items-stretch py-2 border-b-2"
              key={item.uuid}
            >
              <div className="col-span-1 object-contain ">
                <img
                  src={item.imageUrl || '/NoCategoryWhite.png'}
                  alt={item.name}
                  className="rounded drop-shadow drop-shadow-md"
                />
              </div>
              <div className="col-span-2 pl-4 flex items-stretch h-full">
                <div className="self-center">
                  <h1 className="text-sm font-bold mb-1">{item.name}</h1>
                  <Show show={item.artist.length > 0}>
                    <h3 className="text-xs">{`By ${item.artist}`}</h3>
                  </Show>
                  <Show show={typeof item.message !== 'undefined'}>
                    <p className="my-1 text-xs text-red-700 font-bold">{item.message}</p>
                  </Show>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Show show={!!footerNote}>
        <p className="my-2 text-xs">{footerNote}</p>
      </Show>
    </>
  );
};
