export class Env {
  static get(key: string) {
    return process.env[key];
  }

  static get host() {
    return Env.get('REACT_APP_HOST') || 'http://localhost:5000';
  }

  static get exchangeHost() {
    return Env.get('REACT_APP_EXCHANGE_APP_BASE_URL');
  }

  static get exchangeLinkMarketplace() {
    return Env.get('REACT_APP_EXCHANGE_LINK_MARKETPLACE');
  }

  static get exchangeLinkSeller() {
    return Env.get('REACT_APP_EXCHANGE_LINK_SELLER');
  }

  static get exchangeLinkDevvxWallet() {
    return Env.get('REACT_APP_EXCHANGE_LINK_DEVVXWALLET');
  }

  static get exchangeLinkDevvxExplorer() {
    return Env.get('REACT_APP_EXCHANGE_LINK_DEVVXEXPLORER');
  }

  static get exchangeCacheOrderbook() {
    return Env.get('REACT_APP_EXCHANGE_FE_ORDERBOOK_CACHE_MS');
  }

  static get exchangeRefetchOrderbook() {
    return Env.get('REACT_APP_EXCHANGE_FE_ORDERBOOK_REFETCH_MS');
  }

  static get exchangeCacheMyAssets() {
    return Env.get('REACT_APP_EXCHANGE_FE_MY_ASSETS_CACHE_MS');
  }

  static get exchangeRefetchMyAssets() {
    return Env.get('REACT_APP_EXCHANGE_FE_MYASSETS_REFETCH_MS');
  }

  static get exchangeCacheRealtime() {
    return Env.get('REACT_APP_EXCHANGE_FE_REALTIME_CACHE_MS');
  }

  static get exchangeRefetchRealtime() {
    return Env.get('REACT_APP_EXCHANGE_FE_REALTIME_REFETCH_MS');
  }

  static get authHost() {
    return Env.get('REACT_APP_AUTH_HOST');
  }

  static get baseUrl() {
    return Env.get('REACT_APP_BASE_URL');
  }

  static get cacheDuration() {
    return Env.get('REACT_APP_CACHE_DURATION');
  }

  static get sessionDuration() {
    return Env.get('REACT_APP_SESSION_DURATION');
  }

  static get gaTagId() {
    return Env.get('REACT_APP_GA_TAG_ID');
  }

  static get gtmId() {
    return Env.get('REACT_APP_GTM_ID');
  }

  static get fullstoryOrgId() {
    return Env.get('REACT_APP_FULLSTORY_ORG_ID');
  }

  static get fullstoryDevMode() {
    return Env.get('REACT_APP_FULLSTORY_DEVMODE') === 'true';
  }

  static get sentryDsn() {
    return Env.get('REACT_APP_SENTRY_DSN');
  }

  static get mixpanelId() {
    return Env.get('REACT_APP_MIXPANEL_ID');
  }

  static get mixpanelActive() {
    return Env.get('REACT_APP_MIXPANEL_ACTIVE') === 'true';
  }

  static get mixpanelDebug() {
    return Env.get('REACT_APP_MIXPANEL_DEBUG') === 'true';
  }

  static get typeformID() {
    return Env.get('REACT_APP_TYPEFORM_ID');
  }

  static get splitIoKey() {
    return Env.get('REACT_APP_SPLIT_IO_KEY');
  }

  static get maxCartItems() {
    return Number(Env.get('REACT_APP_MAX_CART_ITEMS')) || 10;
  }

  static get tapFiliateId() {
    return Env.get('REACT_APP_TAPFILIATE_ID');
  }

  static get minimumPaymentAmount() {
    return Env.get('REACT_APP_MINIMUM_AMOUNT_FOR_PAYMENT') || 5;
  }
}
