import React, { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/common/interactions/Button';
import { Loader } from 'src/common/components/loader/Loader';
import {
  CartItem,
  HandleCompleteArgs,
  DevvPayCreateChargeParams,
  DevvPayCreateChargeResult,
  DevvPayStatus,
  NftItemsUuidArr,
  PaymentProcessors,
  PAYMENT_OPTION,
} from 'src/interfaces';
import { UserContext } from 'src/common/context/UserContext';
import { CartContext } from 'src/common/context/CartContext';
import { devvPayCharge } from 'src/api/payments';
import { AppRoutes } from 'src/routes/app';
import { CheckoutContext } from 'src/common/context/CheckoutContext';
import { ItemsUnavailableToast } from '../../toast/ItemsUnavailableToast';
import { DevvPayFormData } from './DevvPayForm';

type DevvPayChargeButtonProps = {
  onCompleteCharge: (args: HandleCompleteArgs) => void;
  onStartCharge: () => void;
};

export const DevvPayChargeButton = (props: DevvPayChargeButtonProps) => {
  const { onCompleteCharge, onStartCharge } = props;
  const { nftItems, isOptionDisabled, devvPayOption, subtotal } = useContext(CheckoutContext);

  const {
    getValues: getFormValues,
    trigger: triggerValidation,
    formState: { errors: formErrors, isDirty },
  } = useFormContext<DevvPayFormData>();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { cart, removeCartItem } = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(false);
  const onSendCustomerData = async () => {
    const {
      name,
      addressLine1,
      addressLine2,
      city,
      coinName,
      district,
      discountCodeUuid,
      postalCode,
      country,
      ...rest
    } = getFormValues();
    setIsLoading(true);

    const payload: DevvPayCreateChargeParams = {
      username: user?.username || '',
      coinName,
      publicAddress: user?.pub || '',
      buyerName: name,
      buyerAddress1: addressLine1,
      buyerAddress2: addressLine2,
      buyerCity: city,
      buyerState: district,
      buyerPostalCode: postalCode,
      buyerCountry: country,
      discountCodeUuid: discountCodeUuid,
      nftItems: nftItems.map((item) => ({ uuid: item.uuid })),
    };

    devvPayCharge(payload)
      .then((responseData) => {
        if ((responseData as DevvPayCreateChargeResult) && responseData.status === DevvPayStatus.SUCCESS) {
          onCompleteCharge({ success: true, buyerCountry: country, paymentProcessor: PaymentProcessors.DEVVIO });
          navigate(`/${AppRoutes.THANK_YOU}?processor=${PaymentProcessors.DEVVIO}`);
          return;
        }

        if (responseData.nftItems && Array.isArray(responseData.nftItems) && responseData.nftItems.length > 0) {
          const unavailableItemsUuids = responseData.nftItems.map((item) => item.uuid);
          const nftItemsToRemove = nftItems?.reduce((acc, item) => {
            if (unavailableItemsUuids.includes(item.uuid)) {
              acc.push({
                ...(responseData.nftItems as NftItemsUuidArr).find((nft) => nft.uuid === item.uuid),
                ...item,
              });
            }
            return acc;
          }, [] as CartItem[]);

          toast.error(
            <ItemsUnavailableToast
              message={responseData.message || 'Some items in your cart are no longer available.'}
              nftItems={nftItemsToRemove}
              footerNote="Unavailable items are removed automatically from the cart."
            />,
            {
              autoClose: false,
              closeOnClick: true,
            },
          );

          removeCartItem(unavailableItemsUuids);
        } else if (responseData.message && responseData.message.length > 0) {
          toast.error(responseData.message, {
            autoClose: false,
            closeOnClick: false,
          });
        }
        setIsLoading(false);
      })
      .then(() => onCompleteCharge({ success: false, paymentProcessor: PaymentProcessors.DEVVIO }))
      .catch(() => {
        toast.error('Something happened while attempting to process DevvPay payment. Please try again.');
        setIsLoading(false);
        onCompleteCharge({ success: false, paymentProcessor: PaymentProcessors.DEVVIO });
      });
  };

  const createDevvPayCharge = async () => {
    // Show validation errors on the parent form
    await triggerValidation(['name', 'addressLine1', 'city', 'district', 'postalCode', 'country', 'coinName']);
    if (Object.keys(formErrors).length === 0 && isDirty && !isOptionDisabled(PAYMENT_OPTION.DEVVPAY)) {
      onStartCharge();
      onSendCustomerData();
    }
  };

  const balance = !!devvPayOption && Number(devvPayOption.balance) ? Number(devvPayOption.balance) : 0;
  const disableDevvPay = balance === 0 || balance < subtotal;

  return (
    <div className="flex justify-center items-center flex-col">
      <Button.Info
        onClick={createDevvPayCharge}
        className="w-full xl:w-1/3 flex items-center justify-center gtm_payNowCoinbase"
        data-gtm="payNowCoinbase"
        disabled={isLoading || disableDevvPay}
        type="button"
      >
        {isLoading ? <Loader alt="Payment loading." /> : 'Submit Payment'}
      </Button.Info>
      {disableDevvPay ? (
        <small className="text-red-500 py-2">Coin balance is not enough to complete the payment.</small>
      ) : null}
    </div>
  );
};
