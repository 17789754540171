import React, { PropsWithChildren } from 'react';

const ExchangeLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="lg:mt-[-8px] lg:mb-[-16px] xs:flex lg:ml-[268px] justify-center">
      <div className="bg-[#F4F4F4] min-h-screen font-poppins lg:pr-6 lg:pl-6 max-w-lg sm:max-w-lg md:max-w-lg lg:min-w-full sm:self-center md:self-center mobile2:min-w-0 iphone12promax:min-w-[365px] iphone5:min-w-[365px] xs:pl-6 xs:pr-6  ">
        {children}
      </div>
    </div>
  );
};

export default ExchangeLayout;
