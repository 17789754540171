export const formatDetailsLink = (detailsLink) => {
  const detailsLinkSplited = detailsLink.split('/');

  return `/${detailsLinkSplited[3]}/${detailsLinkSplited[4]}`;
};

export const headerColNames = [
  { colName: 'Date of transaction', accessor: 'createdAt' },
  { colName: 'Item name', accessor: 'name' },
  { colName: 'Creator', accessor: 'creator' },
  { colName: 'Category', accessor: 'category' },
  { colName: 'Item price', accessor: 'salePrice' },
];
