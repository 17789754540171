import React from 'react';

import { formatCurrencyWithoutDenomination, getDevvPayPrice } from 'src/common/util';

export interface SalePriceProps {
  devvPayOption?: { coinName: string; exchangeRate: number } | null;
  discountSalePrice?: number;
  salePrice: number;
}

export const SalePrice = ({ devvPayOption, discountSalePrice, salePrice }: SalePriceProps) => {
  const formattedDiscountPrice = formatCurrencyWithoutDenomination(discountSalePrice as number);
  const formattedSalePrice = formatCurrencyWithoutDenomination(salePrice as number);

  if (typeof discountSalePrice !== 'undefined') {
    return (
      <div className="font-bold">
        {devvPayOption ? getDevvPayPrice({ price: discountSalePrice, devvPayOption }) : `$${formattedDiscountPrice}`}
        <div className="text-xs line-through">
          {devvPayOption ? getDevvPayPrice({ price: salePrice, devvPayOption }) : `$${formattedSalePrice}`}
        </div>
      </div>
    );
  }

  return (
    <div className="font-bold">
      {devvPayOption ? getDevvPayPrice({ price: salePrice, devvPayOption }) : `$${formattedSalePrice}`}
    </div>
  );
};
