import React, { useEffect, useState } from 'react';

import { ItemDetail } from 'src/interfaces';
import { Show } from 'src/common/layout';

interface HighlightedDetailsProps {
  itemDetails: ItemDetail[];
}
interface NameValueProps {
  name: string;
  value: string;
}

export const HighlightedDetails = React.memo((props: HighlightedDetailsProps) => {
  const { itemDetails } = props;
  const [highlightedPowers, setHighlightedPowers] = useState<NameValueProps[]>([]);

  useEffect(() => {
    const map = {
      abilities: {
        name: 'Ability',
      },
      power: {
        name: 'Power',
      },
      release: {
        name: 'Release',
      },
      heptagenBranch: {
        name: 'Heptagen Branch',
      },
      rank: {
        name: 'Rank',
      },
    };
    const { tempHighlight } = itemDetails.reduce(
      (acc, item) => {
        if (map[item.key]) {
          acc.tempHighlight.push({ name: map[item.key].name, value: item.value });
        }
        return acc;
      },
      {
        tempHighlight: [] as NameValueProps[],
      },
    );
    setHighlightedPowers(tempHighlight);
  }, [itemDetails]);

  return (
    <div>
      <Show show={highlightedPowers.length > 0}>
        <div className="mt-4">
          {highlightedPowers.map((hp, i) => {
            return (
              <div key={`${hp.name}-${i}`} className="inline-block mr-4 mb-4">
                <span>{hp.name}:</span>
                <span className="font-bold ml-1">{hp.value}</span>
              </div>
            );
          })}
        </div>
      </Show>
    </div>
  );
});
