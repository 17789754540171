import { FC, ReactElement } from 'react';
import './SimpleToolTip.scss';
import classNames from 'classnames';

export interface SimpleTooltipProps {
  className?: string;
  tooltipContent: ReactElement;
  icon: ReactElement;
  position?: 'left' | 'bottom';
}

export const SimpleToolTip: FC<SimpleTooltipProps> = ({ className, tooltipContent, icon, position = 'bottom' }) => {
  return (
    <div className={classNames('tooltip', 'hover:cursor-pointer', className)}>
      {icon}
      <div className={classNames('tooltiptext', position)}>{tooltipContent}</div>
    </div>
  );
};
