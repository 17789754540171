/* eslint-disable no-nested-ternary */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faArrowsUpDown } from '@fortawesome/free-solid-svg-icons';

type OrderHistoryTableHeadProps = {
  headerColNames: Array<{ colName: string; accessor: string }>;
  requestSort: (item: string) => void;
  getClassNamesFor: (item: string) => string | undefined;
};

const OrderHistoryTableHead: React.FC<OrderHistoryTableHeadProps> = ({
  headerColNames,
  requestSort,
  getClassNamesFor,
}) => {
  const renderTH = (item) => {
    const sortIcon = (accessor) => {
      const className = getClassNamesFor(accessor) || '';
      return className === '+' ? (
        <FontAwesomeIcon icon={faArrowDown} />
      ) : className === '-' ? (
        <FontAwesomeIcon icon={faArrowUp} />
      ) : (
        <FontAwesomeIcon icon={faArrowsUpDown} />
      );
    };
    return (
      <th
        key={item.colName}
        scope="col"
        className="text-sm font-semibold px-4 py-4 sticky top-0 bg-[#F4F4F4] whitespace-nowrap text-left"
      >
        <button type="button" onClick={() => requestSort(item.accessor)} className={getClassNamesFor(item.accessor)}>
          {item.colName} {sortIcon(item.accessor)}
        </button>
      </th>
    );
  };

  return (
    <thead className="border-b">
      <tr>
        {headerColNames.map((item) => renderTH(item))}
        <th scope="col" className="text-sm font-semibold px-4 py-4 sticky top-0 bg-[#F4F4F4] text-left">
          {' '}
        </th>
      </tr>
    </thead>
  );
};

export default OrderHistoryTableHead;
