import React from 'react';

export const TermsOfUse = React.memo(() => {
  return (
    <div className="w-full flex flex-col justify-center m-4 p-8">
      <h1 className="text-blue text-center text-5xl font-bold">Devvio Terms of Service</h1>
      <p>
        This Terms of Service (“Agreement”) is a binding contract between you, an individual or entity user or
        authorized representative of such user (“you”) and Devvio Inc. (“Devvio”) (also herein referred to as “we,” “us”
        or “our”). This Agreement governs your use of any website that links to these terms, including devv.io,
        devvio.com, devvesg.com or glassblock.io (and all related subdomains) and its computer or mobile applications
        (“Site”) and the services we provide (“Services”) regarding Digital Assets (“Digital Assets”). In addition to
        providing access to our Site, our Services include providing KYC/AML compliance tools; access to one or more
        hosted Digital Asset wallets to track, manage, and arrange the transfer of supported digital assets; conversion
        services to arrange for the purchase and sale of Digital Assets in transactions. Devvio is not a bank,
        broker-dealer, tax advisor, or investment adviser and does not offer these or any related services. BY ACCESSING
        OR USING THE SITE OR OUR SERVICES, YOU AGREE THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO BE BOUND BY ALL OF THE
        TERMS AND CONDITIONS CONTAINED IN THIS AGREEMENT INCLUDING THOSE RELATED TO LIMITATIONS OF LIABILITY AND
        ARBITRATION, AS WELL AS OUR PRIVACY POLICY AND ANY E SIGN CONSENT POLICIES. IF YOU DO NOT AGREE TO THESE TERMS,
        YOU MUST NOT ACCESS THE SITE OR USE OUR SERVICES.
      </p>
      <ol className="ml-4">
        <li>
          1. General Use
          <ol className="ml-4">
            <li className="mt-2">
              1. Eligibility. To be eligible to use our Site and Services you must be at least 18 years old and meet
              other threshold criteria. For example, we prohibit certain types of entities and individuals from using
              our Site and Services including, but not limited to, foreign financial institutions, foreign shell banks,
              private banking accounts, senior foreign political figures, FINRA employees, and US broker-dealer
              employees.
            </li>
            <li className="mt-2">
              2. Amendments. We may amend, modify, or add terms to this Agreement by posting it on the Site or emailing
              the revised Agreement to you, and the revised Agreement shall be effective at such time. If you do not
              agree with any such modification, your sole and exclusive remedy is to terminate your use of the Services
              and close your Account. You agree that we shall not be liable to you or any third party for any
              modification or termination of the Services, or suspension or termination of your access to the Services,
              except to the extent otherwise expressly set forth herein.
            </li>
            <li className="mt-2">
              3. Devvio Accounts. In order to use Devvio’s site and services, you will need to register for a DevvX
              Account (a “DevvX Account”). During the registration process, we will ask you for information to verify
              your identity. We may, in our sole discretion, refuse to open an account for you, or limit the number of
              DevvX Accounts or wallets that you may hold. Note that depending on your jurisdiction, you may be required
              to meet additional qualifications.
            </li>
            <li className="mt-2">
              4. Identity Verification. During registration of your Devvio Account, you agree to provide us with the
              information we request for the purposes of identity verification and so that we can fulfill our
              obligations to detect illegal activity such as money laundering, terrorist financing, and fraud. You also
              agree to permit us to keep a record of such information. You will need to complete certain verification
              procedures before you are permitted to use the Services. Your access to our Services may be limited,
              altered, or refused as a result of information collected about you during the initial onboarding
              procedures and on an ongoing basis. The information we request may include certain personal information,
              including, but not limited to, your name, address, telephone number, e-mail address, date of birth,
              taxpayer identification number, social security number, a government issued identification, and
              information regarding your bank account (such as the name of the bank, the account type, routing number,
              and account number). In providing us with this or any other required information, you confirm that the
              information is true and accurate and complete. You agree to promptly update your identity information upon
              any changes. You authorize us and our third-party service providers to make inquiries that we consider
              necessary to verify your identity or to protect you and/or us against fraud or other financial crime, and
              to take all action we deem necessary as a result of such inquiries. You acknowledge and agree that your
              personal information may be disclosed to check credit references and fraud prevention or financial crime
              agencies and that these agencies may respond to our inquiries in full. Please note this is an identity
              check only and should have no adverse effect on your credit rating. These inquiries may include, but are
              not limited to, authorizing your wireless operator to use your mobile number, name, address, email,
              network status, customer type, customer role, billing type, mobile device identifiers and other subscriber
              status details, if available, solely to allow verification of your identity and to compare information you
              have provided with your wireless operator account profile information for the duration of the business
              relationship. Devvio shall not be liable for any loss suffered as a result of such inquiries.
              Additionally, we may require you to wait some amount of time before permitting you to use certain
              Services.
            </li>
            <li className="mt-2">
              5. Risks. You agree and understand that your access and use of the Site and Services is at your own risk.
              Risks include, but are not limited to substantial or total loss when buying, selling, or holding Digital
              Assets; limited liquidity; legislative and regulatory changes or actions that may adversely affect the
              use, transfer, exchange and value of Digital Assets; Digital Assets are not insured by the FDIC or SIPC
              and do not receive any other similar protections; and Digital Assets may have an increased risk of fraud
              or cyber attack. Devvio makes no representations regarding the likelihood or probability that your
              participation on the Site or in our Services will achieve a particular outcome or goal. We shall not bear
              any liability, whatsoever, for any damage or interruptions caused by fraud, cyber attacks, or any malware
              such as, but not limited to, computer viruses, spyware, scareware, Trojan horses, or worms that may affect
              your computer. We disclaim any and all liability for phishing, spoofing or other attacks. We advise you to
              use reputable software for malware screening and attack prevention. You should also be aware that SMS and
              email services are vulnerable to spoofing and phishing attacks and should use care in reviewing messages
              purporting to originate from Devvio. You are fully responsible for the security of your account and all
              activities in connection with your credentials. Always log into your Devvio Account through [the DevvX
              application or] Devvio Site to review any transactions or required actions if you have any uncertainty
              regarding the authenticity of any communication or notice.
            </li>
          </ol>
        </li>
        <li className="mt-2">
          2. LIMITATION OF LIABILITY; NO WARRANTY.
          <ol className="ml-4">
            <li className="mt-2">
              1. IN NO EVENT SHALL DEVVIO, ITS AFFILIATES AND SERVICE PROVIDERS, OR ANY OF THEIR RESPECTIVE OFFICERS,
              DIRECTORS, AGENTS, EMPLOYEES OR REPRESENTATIVES, BE LIABLE (A) FOR ANY AMOUNT GREATER THAN THE VALUE OF
              THE SUPPORTED DIGITAL ASSET REFLECTED IN YOUR DEVVIO ACCOUNT OR (B) FOR ANY LOST PROFITS OR ANY SPECIAL,
              INCIDENTAL, INDIRECT, INTANGIBLE, OR CONSEQUENTIAL DAMAGES, WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE,
              STRICT LIABILITY, OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH EITHER THE AUTHORIZED OR UNAUTHORIZED
              USE OF THE SITE OR SERVICES, OR IN CONNECTION WITH THIS AGREEMENT, EVEN IF A DEVVIO REPRESENTATIVE KNEW OR
              SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR
              OTHER REMEDY OF ITS ESSENTIAL PURPOSE, EXCEPT TO THE EXTENT OF A FINAL JUDICIAL DETERMINATION THAT SUCH
              DAMAGES WERE A RESULT OF DEVVIO’S GROSS NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT OR INTENTIONAL VIOLATION OF
              LAW OR EXCEPT IN JURISDICTIONS THAT DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
              CONSEQUENTIAL DAMAGES.
            </li>
            <li className="mt-2">
              2. SERVICES ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS WITHOUT ANY
              REPRESENTATION OR WARRANTY, WHETHER EXPRESS, IMPLIED OR STATUTORY. TO THE MAXIMUM EXTENT PERMITTED BY
              APPLICABLE LAW, DEVVIO SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS
              FOR A PARTICULAR PURPOSE AND/OR NON-INFRINGEMENT. DEVVIO DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES
              THAT ACCESS TO THE SITE, ANY PART OF THE SERVICES, INCLUDING MOBILE SERVICES, OR ANY OF THE MATERIALS
              CONTAINED THEREIN, WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY, OR ERROR-FREE AND WILL NOT BE LIABLE FOR ANY
              LOSSES RELATING THERETO.
            </li>
          </ol>
        </li>
        <li className="mt-2">
          3. Digital Asset Wallets.
          <ol className="ml-4">
            <li className="mt-2">
              1.General. Digital asset wallets are provided by Devvio. Through these wallets, we enable you to monitor
              the storage, tracking and management of Digital Assets contained in a hosted digital currency wallet. You
              will have the ability to request, send and receive (by placing orders), and store Digital Assets from
              third parties by giving instructions through the Site. We may limit this functionality, and we may require
              you to verify your identity prior to engaging in certain transactions.
            </li>
            <li className="mt-2">
              2. Supported Digital Assets. The wallet services are available only in connection with those Digital
              Assets and protocols that Devvio, in its sole discretion, supports. Under no circumstances should you
              attempt to use your Wallet to store, send, request, or receive Digital Assets and protocols that we do not
              support. We assume no responsibility in connection with any attempt to use your wallet with Digital Assets
              that we do not support. The Digital Assets we support may change from time to time, based on our sole and
              absolute discretion. We will notify you in advance if we cease to support a particular Digital Asset.
              Devvio reserves the right to refuse to process, or to cancel or reverse, any instructions for the purchase
              or sale of a Digital Asset in its sole discretion, even after funds have been debited from your
              account(s), if we suspect the transaction involves (or has a high risk of involvement in) money
              laundering, terrorist financing, fraud, or any other type of financial crime as required by law or in
              response to a subpoena, court order, or other binding government order or to enforce limits we have placed
              on your account or for other compliance reasons.
            </li>
            <li className="mt-2">
              3. Commingled Digital Assets. Devvio may use shared blockchain addresses, controlled by Devvio or its
              custodian partners, to hold Digital Assets at third party custodians on behalf of users and/or on behalf
              of Devvio. Although we maintain separate ledgers for users and proprietary accounts, Devvio shall have no
              obligation to segregate by blockchain address Digital Assets owned by you from Digital Assets owned by
              others.
            </li>
          </ol>
        </li>
        <li className="mt-2">
          4. Transactions.
          <ol className="ml-4">
            <li className="mt-2">
              1. Digital Asset Transactions. We process transactions according to the instructions you submit to us. We
              do not guarantee the identity of any user, receiver, or another party; therefore, you should verify that
              all information is correct prior to sending instructions to us. If you submit an order, that order will be
              irrevocable and final unless that order is cancelled prior to execution. Any pending withdrawals from your
              Devvio Account that have been submitted to a Digital Asset network will be unconfirmed for a period of
              time pending sufficient confirmation of the transaction by the Digital Asset network. Transactions in a
              pending state may not be available for Devvio to reverse or otherwise alter the transaction. The funds
              used for your pending transactions will not be displayed in your account balance or be available to
              conduct other transactions.
            </li>
            <li className="mt-2">
              2. Devvio Processes. Devvio may act as agent or principal in facilitating your purchases and sales of
              Digital Assets on the Devvio platforms or across the platforms and may receive remuneration for doing so.
              Devvio may also incentivize third parties to use our platforms. You and all users, including Devvio and
              its associated persons, are strictly prohibited from engaging in fraudulent or manipulative activity
              including, but not limited to, insider trading and front-running.
            </li>
            <li className="mt-2">
              3. Purchases and Sales. After successfully completing the verification procedures, you may purchase
              supported Digital Assets by funding your Devvio Account and may sell supported Digital Assets by
              transferring the applicable Digital Asset to your Devvio Account. You authorize Devvio and our custodian
              partners, as applicable, to initiate credits and debits in your Devvio Account in settlement of
              transactions. A Transaction Fee applies to all transactions. Although Devvio and our partner custodians
              will attempt to deliver supported Digital Assets to your account as promptly as possible, funds may be
              debited from your selected payment method before the Digital Asset is delivered and viewable in your
              Devvio Account. Your receipt of funds upon withdrawal from your Devvio Account will depend on the payment
              type, and may take up to six or more business days. We and our custodian partners will use best efforts to
              fulfill all orders, but are under no obligation to do so. To secure the performance of your obligations
              under this Agreement, you may be required to grant to Devvio or its custodian partner a lien on and
              security interest in and to the balances in your Devvio Account.
            </li>
            <li className="mt-2">
              4. Reversals Cancellations. You cannot cancel, reverse, or change any transaction marked as “Complete,”
              and you may not be able to cancel, reverse, or change any transaction marked as “Pending.”
            </li>
            <li className="mt-2">
              5. Digital Asset Storage Transmission Delays. Digital assets are securely held in either online or offline
              storage or a combination of both. As a result, it may be necessary for Devvio to communicate with our
              custodian partners to retrieve certain information from offline storage in order to facilitate a Digital
              Asset transaction in accordance with your instructions, which, in turn, may delay the initiation or
              crediting of your transaction for 48 hours or longer. You acknowledge and agree that a Digital Asset
              transaction facilitated by Devvio may be delayed.
            </li>
            <li className="mt-2">
              6. Debts. If your payment is not successful or if your payment method has insufficient funds, you
              authorize Devvio, in its sole discretion, to cancel the transaction or to debit your other payment methods
              in any amount necessary to complete the transaction. You are responsible for maintaining an adequate
              balance in order to avoid overdraft.
            </li>
            <li className="mt-2">
              7. Third Parties. Devvio has no control over, or liability for, the delivery, quality, safety, legality or
              any other aspect of any goods or services that you may purchase or sell to or from a third party
              (including other users). Devvio is not responsible for ensuring that a buyer or a seller you may transact
              with will actually complete the transaction or is authorized to do so. If you experience a problem with
              any goods or services purchased from, or sold to, a third party in connection with a Digital Asset
              transferred using our Site or Services, or if you have a dispute with such third party, you must resolve
              the dispute directly with that third party. If you believe a third party has behaved in a fraudulent,
              misleading, or inappropriate manner, or if you cannot adequately resolve a dispute with a third party, you
              may notify Devvio Support at support@devv.io so that we may consider what action to take, if any.
            </li>
            <li className="mt-2">
              8. Promotions. We may conduct promotions and special offers and reserve the right to establish qualifying
              user criteria to participate in these promotions and offers. Devvio shall have no obligation to make
              promotions and special offers available to all users and may revoke these events at any time without
              notice.
            </li>
            <li className="mt-2">
              9. Fees and Other Remuneration. Generally, we receive compensation for our Services by charging fees based
              on a percentage of the transaction or by taking a spread. Our custodian partners may also charge fees for
              their services. Fees such as withdrawal fees and network fees (miner fees) to process a Digital Asset
              transaction on your behalf may be charged. Network fees will vary between Digital Assets and during
              periods of high network traffic. Certain minimums apply to the fees we charge. You can view the schedule
              of currently applicable fees per pairing and payment method as described in our website. We will notify
              you of any fees prior to your confirmation of your order. We do not refund our fees for completed
              transactions unless we have agreed to do so in writing or otherwise required by law. You hereby agree to
              pay all applicable fees and to be responsible for paying fees charged by your financial service provider.
            </li>
            <li className="mt-2">
              10. Records. Prior to executing any transaction on our platform, we will provide you with notice of the
              terms and conditions of the transactions, including the amount of the transaction, the costs associated
              with the transaction, the type and nature of the transaction, and a warning that, once executed, the
              transaction may not be undone. After you execute a transaction on our platform, as well as successful
              withdrawals and deposits, we will send you an email receipt as evidence of the transaction. In addition, a
              statement of account is available to users through our Platform.
            </li>
          </ol>
        </li>
        <li className="mt-2">
          5. Credit Card and Debit Card Processing.
          <ol className="ml-4">
            <li className="mt-2">
              1. Devvio allows the user of credit cards and debit cards backed by VISA® and MasterCard®.
            </li>
            <li className="mt-2">
              2. Devvio imposes order maximums as part of its anti-fraud measures. Users paying with credit or debit
              cards can have a maximum order size of $3000.00. If a user attempts to purchase more than this amount, the
              order will not be permitted, and alternative payment mechanisms will be suggested.
            </li>
            <li className="mt-2">
              3. The daily maximum order size for any one user when using credit or debit cards is $3000.00. If a user
              attempts to purchase more than this amount in a day, the order will not be permitted, and alternative
              payment mechanisms will be suggested.
            </li>
            <li className="mt-2">
              4. The weekly maximum order size for any one user when using credit or debit cards is $5000.00. If a user
              attempts to purchase more than this amount in a week, the order will not be permitted, and alternative
              payment mechanisms will be suggested.
            </li>
            <li className="mt-2">
              5. The monthly maximum order size for any one user when using credit or debit cards is $20,000.00. If a
              user attempts to purchase more than this amount in a month, the order will not be permitted, and
              alternative payment mechanisms will be suggested.
            </li>
            <li className="mt-2">
              6. Users attempting to use a credit card or debit card registered to an embargoed country will not be
              permitted to checkout, and alternative payment methods will be suggested.
            </li>
            <li className="mt-2">
              7. In order to process a refund a user needs to submit to our support team via an email to
              support@glassblock.io or a message on our Intercom system details about order number, order amount, user
              wallet, user email, date of order, and reason for refund. As we operate our own blockchain, we areable to
              verify whether the item was in fact purchased and fulfilled, and if necessary, manually retract the item
              in question from the given user’s wallet upon sufficient proof the order was in error, and process a
              refund. Users need to provide an explicit reason and rationale for the refund and retraction. Refunds may
              take 1-2 business weeks to process.
            </li>
            <li className="mt-2">
              8. At this time, credit card and debit card orders are only available to customers whose cards are
              registered to the United States, or countries in the European Union.
            </li>
            <li className="mt-2">
              9. Orders paid for will credit cards or debit cards will be subject to an automatic 14 day hold on the
              item(s) being purchased, and the items will not be usable within, or transferrable out of, the user’s
              Devvio wallet until the mandatory hold period is completed.
            </li>
          </ol>
        </li>
        <li className="mt-2">
          6. Staking.
          <ol className="ml-4">
            <li className="mt-2">
              1. Staking Services. When you hold Digital Assets in your Devvio Account, you may be able to “stake” these
              assets via staking services facilitated by Devvio.
            </li>
            <li className="mt-2">
              2. Optional Staking. YOU ARE NOT REQUIRED TO STAKE WITH DEVVIO AND YOU CAN OPT-OUT OF DEVVIO’S STAKING
              SERVICES AT ANY TIME BY EMAILING support@devv.io. Devvio may take three (3) business days or longer to
              reflect your choice not to stake your Digital Assets. If you opt-out of staking services, you can opt back
              in at any time. Should you choose to stake any Digital Assets held in your Devvio Account, these Digital
              Assets may be locked (unavailable for withdrawal or trading) for up to three business days (or as
              otherwise applicable pursuant to the underlying protocol) from any withdrawal or trading request.
            </li>
            <li className="mt-2">
              3. Staking Service; Rewards; Commission. If you stake your assets with our service, the protocol will
              stake these on your behalf, acting as a transaction validator on the network for the Digital Asset you
              stake. If the protocol or our custodian partner successfully validates a block of transactions in the
              applicable Digital Asset, you may earn a reward granted by that Digital Asset’s network. Your reward will
              be determined by the protocols of the applicable token. Devvio will distribute this reward to you pro
              rata, minus any commissions set forth on our website. Staking rewards are not guaranteed and are subject
              to the individual protocol of the applicable Digital Asset, and may change at any time without notice.
              Devvio does not distribute any rewards earned when serving as a transaction validator or similar network
              node on its own behalf.
            </li>
            <li className="mt-2">
              4. No Guarantee. You have no right to a reward until it is received by the designated protocol or our
              custodian partner and processed by Devvio and until you have maintained a positive balance for at least
              three (3) consecutive days during the applicable period. Your reward will be distributed on a pro-rata
              basis based on your average balance of the particular Digital Asset to the total of all rewards received
              by our custodian partner and processed by Devvio during the applicable period. Rewards will be distributed
              to your Devvio Account once per month unless otherwise provided. Devvio will use reasonable efforts to
              facilitate the staking of any Digital Assets for which you are using Devvio’s staking services. The
              “staking rewards rate” is disclosed by Devvio for each particular Digital Asset. For any Digital Asset
              listed for less than 90 days, Devvio shall use commercially reasonable efforts to determine a reward rate
              that it determines is reasonably likely to be achieved for the following month. This rate is an estimate
              and changes over time. DEVVIO DOES NOT GUARANTEE THAT YOU WILL RECEIVE ANY STAKING REWARDS, ANY SPECIFIC
              STAKING REWARD, OR ANY STAKING RETURN OVER TIME, INCLUDING THE STAKING REWARDS RATE. ALL SUCH REWARDS ARE
              BASED ENTIRELY ON THE UNDERLYING PROTOCOL OF THE APPLICABLE DIGITAL ASSET.
            </li>
            <li className="mt-2">
              5. Devvio reserves the right in its sole and absolute discretion to cease providing staking services,
              including discontinuing the accrual of any rewards, in the event that Devvio suspects any fraud,
              misconduct, potential compromise of the underlying protocol or any other Prohibited Use or Prohibited
              Business.
            </li>
          </ol>
        </li>
        <li className="mt-2">
          7. General Provisions.
          <ol className="ml-4">
            <li className="mt-2">
              1. Limited License. We grant you a limited, nonexclusive, nontransferable license, subject to the terms of
              this Agreement, to access and use the Devvio Site [and DevvX app and other sites], and related content,
              materials, information (collectively, the “Content”) solely for approved purposes as permitted by Devvio
              from time to time. Any other use of the Site or Content is expressly prohibited and all other right,
              title, and interest in the Site or Content is exclusively the property of Devvio and its licensors. You
              agree you will not copy, transmit, distribute, sell, license, reverse engineer, delete or modify,
              interfere with, publish, or participate in the transfer or sale of, create derivative works from, or in
              any other way exploit any of the Content, in whole or in part. The website “devv.io”, “Devvio”, “Devv”,
              “DevvE” and any other form of “Devv” and all logos related to Devvio or displayed on the Site are either
              trademarks or registered marks of Devvio, its affiliates or its licensors are protected by copyright or
              other intellectual property rights. You may not copy, imitate, or use them without our prior written
              consent.
            </li>
            <li className="mt-2">
              2. Website Accuracy. The Devvio Site (including, without limitation, the Content) may not always be
              entirely accurate, complete or current and may also include technical inaccuracies or typographical
              errors. In an effort to continue to provide you with as complete and accurate information as possible,
              information may be changed or updated from time to time without notice, such as, but not limited to,
              information regarding our policies, products, and services. Accordingly, you should verify all information
              before relying on it, and all decisions based on information contained on the Site are your sole
              responsibility and we shall have no liability for such decisions. Links to third-party materials
              (including, without limitation, websites) may be provided as a convenience but are not controlled by us.
              You acknowledge and agree that we are not responsible for any aspect of the information, content, or
              services contained in any third-party materials or on any third party sites accessible or linked to the
              Site.
            </li>
            <li className="mt-2">
              3. Third-Party Applications. If you grant express permission to a third party to access or connect to your
              Devvio Account or otherwise use our Site or Services on your behalf, you acknowledge that granting
              permission does not relieve you of any of your responsibilities under this Agreement. You are fully
              responsible for all acts or omissions of such a third party. Further, you acknowledge and agree that you
              will not hold Devvio, its affiliates, custodian partners, and service providers responsible for, and will
              indemnify them from, any liability arising out of or related to any act or omission of any third party.
              You may change or remove permissions granted by you to third parties with respect to your Devvio Account
              at any time through the Account Settings page on the Site.
            </li>
            <li className="mt-2">
              4. Prohibited Use. In connection with your use of the Services, and your interactions with other users and
              third parties, you agree and represent you will not engage in any Prohibited Business or Prohibited Use,
              as defined herein. We reserve the right at all times to monitor, review, retain and/or disclose any
              information as necessary to satisfy any applicable law, regulation, sanctions programs, legal process or
              governmental request. If we determine, in our sole discretion, that your account or use of our Services is
              associated with a Prohibited Use or a Prohibited Business, we reserve the right to cancel or suspend your
              account, block your transactions, freeze your funds, or take any other action we deem necessary or
              appropriate. We reserve the right to take any of these actions immediately, indefinitely, and without
              notice to you.
            </li>
            <li className="mt-2">
              5. Transaction Limits - The use of all Services may be subject to a limit on the amount of volume, you may
              transact or transfer in a given period (e.g., daily). Your transaction limits may vary depending on your
              payment method, verification steps you have completed, and other factors. Devvio reserves the right to
              change applicable limits as we deem necessary in our sole discretion. If you wish to raise your limits
              beyond the posted amounts, you may submit a request at support@devv.io. We may require you to submit
              additional information about yourself or your business, provide records, and arrange for meetings with
              Devvio staff (such process, “Enhanced Due Diligence”). Devvio reserves the right to charge you costs and
              fees associated with Enhanced Due Diligence, provided that we notify you in advance of any such charges
              accruing. In our sole discretion, we may refuse to raise your limits or we may lower your limits at a
              subsequent time even if you have completed Enhanced Due Diligence.
            </li>
            <li className="mt-2">
              6. Suspension, Termination, and Cancellation. Devvio may: (a) suspend, restrict, or terminate your access
              to any or all of the Services, and/or (b) deactivate or cancel your account if: we are so required by a
              facially valid subpoena, court order, or binding order of a government authority; we reasonably suspect
              you of using your Devvio Account in connection with a Prohibited Use or Business; your use of your Devvio
              Account or our Services is subject to any pending litigation, investigation, or government proceeding
              and/or we perceive a heightened risk of legal or regulatory non-compliance associated with your activity;
              our service partners are unable to support your use; or you take any action that Devvio deems as
              circumventing our controls, including, but not limited to, opening multiple Devvio Accounts or abusing
              promotions which Devvio may offer from time to time. We are not responsible for any losses, whether direct
              or indirect, that you may incur as a result of our compliance with applicable law and regulations, the
              guidance or direction of any regulatory authority or government agency, or any writ of attachment, lien,
              levy, subpoena, warrant, or other legal order. If Devvio suspends or closes your account, or terminates
              your use of the Services for any reason, we will provide you with notice of our actions unless a court
              order or other legal or regulatory process prohibits Devvio from providing you with such notice. You
              acknowledge that Devvio’s decision to take certain actions, including limiting access to, suspending, or
              closing your Account, may be based on confidential criteria that are essential to Devvio’s risk management
              and security protocols. You agree that Devvio is under no obligation to disclose the details of its risk
              management and security procedures to you. You will be permitted to transfer Digital Assets or funds
              associated with your Digital Asset wallet(s) for ninety (90) days after Account deactivation or
              cancellation unless such transfer is otherwise prohibited (i) under the law, including but not limited to
              applicable sanctions programs, or (ii) by a facially valid subpoena or court order or (iii) due to a
              violation of the terms of this agreement including but not limited to a Prohibited Use violation, unless
              by operation of law or with the express permission of Devvio. You may cancel your Devvio Account at any
              time by withdrawing all balances from your account and contacting us. You will not be charged for
              canceling your account, but you may be required to pay withdrawal fees and any outstanding amounts owed to
              us or our custodian partners. You authorize us to cancel or suspend any pending transactions at the time
              of cancellation.
            </li>
            <li className="mt-2">
              7. Death of Account Holder. If we have reason to suspect your death, we will freeze your account, and no
              transactions may be completed until we ascertain the fact of your death. You authorize us to make
              inquiries, whether directly or through third parties, that we consider necessary to ascertain this
              information and if applicable, your designated beneficiary. If we receive confirmation of your death and
              information on your designated beneficiary in a form satisfactory to us, we will work with your designated
              beneficiary in taking appropriate action to transfer the account.
            </li>
            <li className="mt-2">
              8. Relationship of the Parties. Devvio is an independent contractor for all purposes. Nothing in this
              Agreement shall deem or cause you and Devvio to be treated as partners, joint ventures, or otherwise as
              joint associates for profit.
            </li>
            <li className="mt-2">
              9. Privacy of Others. If you receive information about another user through Devvio, you must keep the
              information confidential and only use it in connection with our Services. You may not disclose or
              distribute a user&apos;s information to a third party or use the information except as reasonably
              necessary to effectuate a transaction and other functions reasonably incidental thereto such as support,
              reconciliation and accounting unless you receive the user&apos;s express consent to do so.
            </li>
            <li className="mt-2">
              10. Password Security; Contact Information. You are responsible for maintaining adequate security and
              control of any and all IDs, passwords, hints, personal identification numbers (PINs), API keys or any
              other codes that you use to access your account and our Services. You should only give such information to
              individuals who have the authority to take actions on your behalf, and we assume that such individuals
              have such authority. Devvio requires the use of Two Factor Identification for your account. Any loss or
              compromise of the foregoing information and/or your personal information may result in unauthorized access
              to your Devvio Account by third parties and the loss or theft of any Digital Asset and/or funds held in
              your account with our custodian partners and any associated accounts, including your linked bank
              account(s) and credit card(s). You are responsible for keeping your email address and telephone number up
              to date in your Account Profile in order to receive any notices or alerts that we may send you. You are
              responsible for checking your balances and your transaction history. WE ASSUME NO RESPONSIBILITY FOR ANY
              LOSS THAT YOU MAY SUSTAIN DUE TO A COMPROMISE OF ACCOUNT LOGIN CREDENTIALS FOR WHICH WE HAVE NO CONTROL
              AND/OR YOUR FAILURE TO FOLLOW OR ACT ON ANY NOTICES OR ALERTS THAT WE MAY SEND TO YOU. In the event you
              believe your account information has been compromised, contact Devvio Support immediately at
              support@devv.io.
            </li>
            <li className="mt-2">
              11. Unclaimed Property. If you have assets or funds in your account, and Devvio is unable to contact you
              and has no record of your use of our Services for a year or more, applicable law may require us to report
              and/or deliver these assets or funds as unclaimed property to the applicable jurisdiction. Prior to doing
              so, Devvio will try to locate you with the information shown in our records. Devvio reserves the right to
              deduct a dormancy fee or other administrative charges from such unclaimed funds, as permitted by
              applicable law.
            </li>
          </ol>
        </li>
        <li className="mt-2">
          8. Customer Feedback, Queries, Complaints, and Dispute Resolution.
          <ol className="ml-4">
            <li className="mt-2">
              1. Contact Devvio. If you have any feedback, questions, or complaints, email us at support@devv.io or send
              correspondence to 2415 Princeton Dr NE, Suite L Albuquerque NM 87107 United States. When you contact us
              please provide us with your name, address, and any other information that we may need to identify you,
              your Devvio Account, and the transaction on which you have feedback, questions, or complaints.
            </li>
            <li className="mt-2">
              2. Arbitration.
              <ol className="ml-4">
                <li className="mt-2">
                  1. We will attempt to resolve any disputes through our support team. If we cannot find a mutually
                  agreeable resolution, you and we agree that any dispute arising under this Agreement shall be finally
                  settled in binding arbitration, on an individual basis, conducted by a single, neutral arbitrator in
                  New Mexico.
                </li>
                <li className="mt-2">
                  2. The arbitrator may award any relief that a court of competent jurisdiction could award, including
                  attorneys&apos; fees when authorized by law, and the arbitral decision may be enforced in any court.
                  At your request, hearings may be conducted in person or by telephone and the arbitrator may provide
                  for submitting and determining motions on briefs, without oral hearings. If the arbitrator(s) or
                  arbitration administrator would impose filing fees or other administrative costs on you, we will
                  reimburse you, upon request, to the extent such fees or costs would exceed those that you would
                  otherwise have to pay if you were proceeding in a court. We will pay additional fees or costs if
                  required to do so by applicable law. The prevailing party in any action or proceeding to enforce this
                  agreement shall be entitled to costs and attorneys&apos; fees.
                </li>
                <li className="mt-2">
                  3. Unless specifically excepted in this section, each Party will be responsible for any other fees or
                  costs that the Party may incur. If a court decides that any provision of this section is invalid or
                  unenforceable, that provision shall be severed and the other parts of this section and the remainder
                  of this Agreement will continue to apply
                </li>
                <li className="mt-2">
                  4. Exceptions. Notwithstanding the above, nothing in this Agreement will be deemed to waive, preclude
                  or otherwise limit the right of either party to: (i) bring an individual action in small claims court;
                  (ii) pursue an enforcement action through the applicable federal, state or local agency if that action
                  is available; (iii) seek injunctive relief in aid of arbitration from a court of competent
                  jurisdiction; or (iv) file suit in a court of law to address an intellectual property infringement
                  claim
                </li>
                <li className="mt-2">
                  5. Waiver. YOU ACKNOWLEDGE AND AGREE THAT BY ENTERING THIS AGREEMENT:
                  <ol className="ml-4">
                    <li className="mt-2">
                      1. YOU AND DEVVIO EACH WAIVE THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION;
                    </li>
                    <li className="mt-2">
                      2. TO THE EXTENT PERMISSIBLE BY LAW, ALL CLAIMS MUST BE BROUGHT IN A PARTY’S INDIVIDUAL CAPACITY,
                      AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, COLLECTIVE ACTION, OR
                      REPRESENTATIVE PROCEEDING (COLLECTIVELY “CLASS ACTION WAIVER”). THE ARBITRATOR MAY NOT CONSOLIDATE
                      MORE THAN ONE PERSON&apos;S CLAIMS OR ENGAGE IN ANY CLASS ARBITRATION.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li className="mt-2">
          9. Other Provisions.
          <ol className="ml-4">
            <li className="mt-2">
              1. Release of Devvio; Indemnification. If you have a dispute with one or more users of our Services, you
              release Devvio, its service providers, and each of their respective officers, directors, agents, joint
              venturers, employees and representatives from any and all claims, demands and damages (actual and
              consequential) of every kind and nature arising out of or in any way connected with such disputes. You
              agree to indemnify and hold Devvio, its affiliates, and each of its or their respective officers,
              directors, agents, joint venturers, employees and representatives, harmless from any claim or demand
              (including attorneys&apos; fees and any fines, fees or penalties imposed by any regulatory authority)
              arising out of or related to your breach of this Agreement or your violation of any law, rule or
              regulation, or the rights of any third party.
            </li>
            <li className="mt-2">
              2. Entire Agreement. This Agreement, the Privacy Policy, E-Sign Consent, onboarding representations, trade
              confirmations, appendices incorporated by reference herein, or other ancillary agreement, if any, comprise
              the entire understanding and agreement between you and Devvio as to the subject matter hereof, and
              supersedes any and all prior discussions, agreements and understandings of any kind (including, without
              limitation, any prior versions of this Agreement), and every nature between and among you and Devvio.
              Section headings in this Agreement are for convenience only, and shall not govern the meaning or
              interpretation of any provision of this Agreement.
            </li>
            <li className="mt-2">
              3. Assignment. You may not assign any rights and/or licenses granted under this Agreement. We reserve the
              ability to assign our rights without restriction, including, without limitation, to any Devvio affiliates
              or subsidiaries, or to any successor in interest of any business associated with Devvio. Any attempted
              transfer or assignment in violation hereof shall be null and void. Subject to the foregoing, this
              Agreement will bind and inure to the benefit of the parties, their successors and permitted assigns.
            </li>
            <li className="mt-2">
              4. Severability. If any provision of this Agreement shall be determined to be invalid or unenforceable
              under any rule, law or regulation, or any governmental agency, local, state, or federal, such provision
              will be changed and interpreted to accomplish the objectives of the provision to the greatest extent
              possible under any applicable law and the validity or enforceability of any other provision of this
              Agreement shall not be affected.
            </li>
            <li className="mt-2">
              5. Change of Control. In the event that Devvio is acquired by or merged with a third party entity, we
              reserve the right, in any of these circumstances, to transfer or assign this Agreement and any information
              we have collected from you as part of such merger, acquisition, sale, or other change of control.
            </li>
            <li className="mt-2">
              6. Survival. All provisions of this Agreement which by their nature extend beyond the expiration or
              termination of this Agreement, including, without limitation, sections pertaining to suspension or
              termination, Devvio Account cancellation, debts owed to Devvio, general use of the Devvio Site, disputes
              with Devvio and general provisions, shall survive the termination or expiration of this Agreement.
            </li>
            <li className="mt-2">
              7. Governing Law. You agree that the laws of the State of New Mexico without regard to principles of
              conflict of laws, will govern this Agreement and any claim or dispute that has arisen or may arise between
              you and Devvio, except to the extent governed by federal law.
            </li>
            <li className="mt-2">
              8. Force Majeure. We shall not be liable for delays, failure in performance or interruption of Services
              which result directly or indirectly from any cause or condition beyond our reasonable control, including,
              but not limited to, any delay or failure due to any act of God, act of civil or military authorities, act
              of terrorists, civil disturbance, war, strike or other labor dispute, fire, interruption in
              telecommunications or Internet services or network provider services, failure of equipment and/or
              software, pandemics, other catastrophe or any other occurrence which is beyond our reasonable control.
              These provisions shall not affect the validity and enforceability of any remaining provisions.
            </li>
            <li className="mt-2">
              9. English Language Controls. Notwithstanding any other provision of this Agreement, any translation of
              this Agreement is provided for your convenience. The meanings of terms, conditions and representations
              herein are subject to definitions and interpretations in the English language. Any translation provided
              may not accurately represent the information in the original English.
            </li>
            <li className="mt-2">
              10. Non-Waiver of Rights. This agreement shall not be construed to waive rights that cannot be waived
              under applicable state money transmission laws in the state where you are located or under federal law.
            </li>
            <li className="mt-2">
              11. Jurisdiction. Devvio makes no representation that materials on the Site or the Services are
              appropriate, lawful or available for use in any location other than the United States of America. Those
              who choose to access or use the Site or Services from locations outside the United States of America do so
              on their own initiative and are responsible for compliance with local laws, if and to the extent, local
              laws are applicable.
            </li>
            <li className="mt-2">
              12. Taxes and W-9 Certification. You acknowledge that it is your sole responsibility to withhold, collect,
              report, and remit the correct amounts of taxes to the appropriate tax authorities. If you are a United
              States person then as a condition of using Devvio’s Services you certify under penalties of perjury that:
              <ol className="ml-4">
                <li className="mt-2">
                  1. The Social Security number or Employer Identification Number you provided is correct; and
                </li>
                <li className="mt-2">
                  2. You are not subject to backup withholding due to the failure to report interest and dividend
                  income.
                </li>
                <li className="mt-2">
                  3. Further, you understand and acknowledge that Devvio does not provide tax or legal advice and that
                  Devvio will report certain transactions to the Internal Revenue Service to the extent and manner in
                  which it is required to do so by law.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li className="mt-2">
          10. Definitions. The following words and phrases have the corresponding meanings.
          <ol className="ml-4">
            <li className="mt-2">1. Account means a User&apos;s Devvio Account.</li>
            <li className="mt-2">2. Asset means a Digital Currency, NFT, digital asset, or Fiat Currency.</li>
            <li className="mt-2">
              3. Available Balance means a User&apos;s Total Asset Value less any amounts held for Open Orders and fees.
            </li>
            <li className="mt-2">
              4. Digital Asset means a blockchain-based digital currency, security, app, coin or protocol token which is
              offered for trading on Devvio.
            </li>
            <li className="mt-2">5. Fiat Currency means a government-issued currency.</li>
            <li className="mt-2">
              6. Order means an instruction to buy or sell a specified quantity of the Base Asset at a specified price
              in the Quote Asset.
            </li>
          </ol>
        </li>
      </ol>
      <p className="mt-2">APPENDIX 1: PROHIBITED USE, PROHIBITED BUSINESSES AND CONDITIONAL USE</p>
      <ol className="ml-4">
        <li>
          1. Prohibited Use. You may not use your Devvio Account to engage in the certain categories of activity
          (“Prohibited Uses”) including those listed in this section. The specific types of use listed below are
          representative, but not exhaustive. If you are uncertain as to whether or not your use of the Services
          involves a Prohibited Use, or have questions about how these requirements apply to you, please contact us at
          support@devv.io. By opening a Devvio Account, you confirm that you will not use your Account to do any of the
          following:
          <ol className="ml-4">
            <li className="mt-2">
              1. Unlawful Activity: Activity which would violate, or assist in violation of, any law, statute,
              ordinance, or regulation, sanctions programs administered in the countries where Devvio conducts business,
              including but not limited to the U.S. Department of Treasury&apos;s Office of Foreign Assets Control
              (“OFAC”), or which would involve proceeds of any unlawful activity; publish, distribute or disseminate any
              unlawful material or information;
            </li>
            <li className="mt-2">
              2. Abusive Activity: Actions which impose an unreasonable or disproportionately large load on our
              infrastructure, or detrimentally interfere with, intercept, or expropriate any system, data, or
              information (including the use of bots or scripts); transmit or upload any material to the Site that
              contains viruses, trojan horses, worms, or any other harmful or deleterious programs; attempt to gain
              unauthorized access to the Devvio Site, other Devvio Accounts, computer systems or networks connected to
              the Devvio Site, through password mining or any other means; use Devvio Account information of another
              party to access or use the Devvio Site (Devvio regards the accessing of multiple Devvio Accounts from the
              same device to indicate potential abusive activity); or transfer your account access or rights to your
              account to a third party or by purchase of a Devvio account, unless by operation of law;
            </li>
            <li className="mt-2">
              3. Abuse Other Users: Interfere with another individual&apos;s or entity&apos;s access to or use of any
              Devvio Services; defame, abuse, extort, harass, stalk, threaten or otherwise violate or infringe the legal
              rights (such as, but not limited to, rights of privacy, publicity and intellectual property) of others;
              incite, threaten, facilitate, promote, or encourage hate, racial intolerance, or violent acts against
              others; harvest or otherwise collect information from the Devvio Site about others, including without
              limitation email addresses, without proper consent;
            </li>
            <li className="mt-2">
              4. Fraud: Activity which operates to defraud Devvio, its users, or any other person; provide any false,
              inaccurate, or misleading information to Devvio (Devvio regards temporary email addresses and disposable
              email addresses to be forms of misleading information);
            </li>
            <li className="mt-2">
              5. Gambling: Lotteries; bidding fee auctions; sports forecasting or odds making; fantasy sports leagues
              with cash prizes; internet gaming; contests; sweepstakes; games of chance;
            </li>
            <li className="mt-2">
              6. Intellectual Property Infringement: Engage in transactions involving items that infringe or violate any
              copyright, trademark, right of publicity or privacy or any other proprietary right under the law,
              including but not limited to sales, distribution, or access to counterfeit music, movies, software, or
              other licensed materials without the appropriate authorization from the rights holder; use of Devvio
              intellectual property, name, or logo, including use of Devvio trade or service marks, without express
              consent from Devvio or in a manner that otherwise harms Devvio or the Devvio brand; any action that
              implies an untrue endorsement by or affiliation with Devvio.
            </li>
          </ol>
        </li>
        <li>
          2. Prohibited Businesses. In addition to the Prohibited Uses described above, the following categories of
          businesses, business practices, and sale items are barred from Devvio (&quot;Prohibited Businesses&quot;). The
          specific types of use listed below are representative, but not exhaustive. If you are uncertain as to whether
          or not your use of Devvio involves a Prohibited Business, or have questions about how these requirements apply
          to you, please contact us at support@devv.io. By opening a Devvio Account, you confirm that you will not use
          Devvio Services in connection with any of the following businesses, activities, practices, or items:
          <ol className="ml-4">
            <li className="mt-2">
              1. Investment and Credit Services: Securities brokers; mortgage consulting or debt reduction services;
              credit counseling or repair; real estate opportunities; investment schemes;
            </li>
            <li className="mt-2">2. Restricted Financial Services: Check cashing, bail bonds; collections agencies.</li>
            <li className="mt-2">
              3. Intellectual Property or Proprietary Rights Infringement: Sales, distribution, or access to counterfeit
              music, movies, software, or other licensed materials without the appropriate authorization from the rights
              holder;
            </li>
            <li className="mt-2">
              4. Counterfeit or Unauthorized Goods: Unauthorized sale or resale of brand name or designer products or
              services; sale of goods or services that are illegally imported or exported or which are stolen;
            </li>
            <li className="mt-2">
              5. Regulated Products and Services: Marijuana dispensaries and related businesses; sale of tobacco,
              e-cigarettes, and e-liquid; online prescription or pharmaceutical services; age restricted goods or
              services; weapons and munitions; gunpowder and other explosives; fireworks and related goods; toxic,
              flammable, and radioactive materials; products and services with varying legal status on a state-by-state
              basis;
            </li>
            <li className="mt-2">
              6. Drugs and Drug Paraphernalia: Sale of narcotics, controlled substances, and any equipment designed for
              making or using drugs, such as bongs, vaporizers, and hookahs;
            </li>
            <li className="mt-2">
              7. Pseudo-Pharmaceuticals: Pharmaceuticals and other products that make health claims that have not been
              approved or verified by the applicable local and/or national regulatory body;
            </li>
            <li className="mt-2">
              8. Substances designed to mimic illegal drugs: Sale of a legal substance that provides the same effect as
              an illegal drug (e.g., salvia, kratom);
            </li>
            <li className="mt-2">
              9. Adult Content and Services: Pornography and other obscene materials (including literature, imagery and
              other media); sites offering any sexually-related services such as prostitution, escorts, pay-per view,
              adult live chat features;
            </li>
            <li className="mt-2">
              10. Multi-level Marketing: Pyramid schemes, network marketing, and referral marketing programs;
            </li>
            <li className="mt-2">
              11. Unfair, predatory or deceptive practices: Investment opportunities or other services that promise high
              rewards; Sale or resale of a service without added benefit to the buyer; resale of government offerings
              without authorization or added value; sites that we determine in our sole discretion to be unfair,
              deceptive, or predatory towards consumers;
            </li>
            <li className="mt-2">
              12. High risk businesses: any businesses that we believe poses elevated financial risk, legal liability,
              or violates card network or bank policies.
            </li>
          </ol>
        </li>
        <li>
          3. Conditional Use - Express written consent and approval from Devvio must be obtained prior to using Devvio
          Services for the following categories of business and/or use (&quot;Conditional Uses&quot;). Consent may be
          requested by contacting us at support@devv.io. Devvio may also require you to agree to additional conditions,
          make supplemental representations and warranties, complete enhanced on-boarding procedures, and operate
          subject to restrictions if you use Devvio Services in connection with any of following businesses, activities,
          or practices:
          <ol className="ml-4">
            <li className="mt-2">
              1. Money Services: Money transmitters, Digital Currency transmitters; currency or Digital Currency
              exchanges or dealers; gift cards; prepaid cards; sale of in-game currency unless the merchant is the
              operator of the virtual world; act as a payment intermediary or aggregator or otherwise resell any of the
              Devvio Services;
            </li>
            <li className="mt-2">2. Charities: Acceptance of donations for nonprofit enterprise;</li>
            <li className="mt-2">
              3. Games of Skill: Games which are not defined as gambling under this Agreement or by law, but which
              require an entry fee and award a prize;
            </li>
            <li className="mt-2">
              4. Religious/Spiritual Organizations: Operation of a for-profit religious or spiritual organization.
            </li>
          </ol>
        </li>
      </ol>
      <p className="mt-2">APPENDIX 2: VERIFICATION PROCEDURES AND LIMITS</p>
      <p>
        For some activities on the DevvX platform we are required to identify users on our platform. This ensures we
        remain in compliance with KYC/AML laws in the jurisdictions in which we operate. Devvio and our affiliates
        collect and verify information about you in order to: (a) protect Devvio and the community from fraudulent
        users, and (b) to keep appropriate records of Devvio customers, and (c) manage any transaction limitations All
        customers who wish to use Devvio services that require KYC verification, including the sending and receiving of
        Digital Assets reconciled to a blockchain, are required to establish a Devvio Account by:
      </p>
      <ul className="ml-4">
        <li className="mt-2">
          • Providing your name and valid email address, password and your state and country of residence, and certify
          that you are 18 years or older,
        </li>
        <li className="mt-2">
          • Accepting Terms of Service, onboarding confirmations, Subscriber Agreement and Privacy Policy, and
        </li>
        <li className="mt-2">
          • Verifying your identity by submitting the following information (Devvio reserves the right to request
          additional information as needed; additional information may be requested of entities such as companies and
          trusts):
          <ol className="ml-4">
            <li className="mt-2">1. Full Name</li>
            <li className="mt-2">2. Date of Birth</li>
            <li className="mt-2">
              3. Physical address
              <ol className="ml-4">
                <li className="mt-2">
                  1. Social Security Number (or identification number from a government-issued ID for certain types of
                  customers)
                </li>
                <li className="mt-2">2. Telephone number</li>
              </ol>
              <ul>
                <li className="mt-2">
                  • Submitting a selfie picture from your webcam or mobile phone, as may be required.
                </li>
                <li className="mt-2">
                  • Submit a copy of an acceptable form of identification (i.e. passport, state driver&apos;s license,
                  or state identification card)
                </li>
              </ul>
            </li>
          </ol>
        </li>
      </ul>
      <p className="my-2">
        Notwithstanding these minimum verification procedures, Devvio may require you to provide or verify additional
        information, or to wait some amount of time after completion of a transaction, before permitting you to use any
        Devvio Service and/or before permitting you to engage in transactions beyond certain volume limits.
      </p>
      <p className="mt-2">APPENDIX 3: E-SIGN DISCLOSURE AND CONSENT</p>
      <p>
        This policy describes how Devvio delivers communications to you electronically. We may amend this policy at any
        time by providing a revised version on our website. The revised version will be effective at the time we post
        it. We will provide you with prior notice of any material changes via our website.
      </p>
      <ol className="ml-4">
        <li className="mt-2">
          1. Electronic Delivery of Communications. You agree and consent to receive electronically all communications,
          agreements, documents, notices and disclosures (collectively, “Communications”) that we provide in connection
          with your Devvio Account and your use of Devvio Services. Communications include:
          <ol className="ml-4">
            <li className="mt-2">
              1. Terms of use and policies you agree to (e.g., Terms of Service and Privacy Policy), including updates
              to these agreements or policies;
            </li>
            <li className="mt-2">
              2. Account details, history, transaction receipts, confirmations, and any other Account or transaction
              information;
            </li>
            <li className="mt-2">
              3. Legal, regulatory, and tax disclosures or statements we may be required to make available to you; and
            </li>
            <li className="mt-2">
              4. Responses to claims or customer support inquiries filed in connection with your Account.
            </li>
            <li className="mt-2">
              5. We will provide these Communications to you by posting them on the Devvio website, emailing them to you
              at the primary email address listed in your Devvio profile, communicating to you via instant chat, and/or
              through other electronic communication such as text message or mobile push notification, as deemed
              appropriate.
            </li>
          </ol>
        </li>
        <li className="mt-2">
          2. Hardware and Software Requirements. In order to access and retain electronic Communications, you will need
          the following computer hardware and software:
          <ol className="ml-4">
            <li className="mt-2">1. A device with an Internet connection;</li>
            <li className="mt-2">
              2. A current web browser that includes 128-bit encryption (e.g. Internet Explorer version 9.0 and above,
              Firefox version 3.6 and above, Chrome version 31.0 and above, or Safari 7.0 and above) with cookies
              enabled;
            </li>
            <li className="mt-2">3. A valid email address (your primary email address on file with Devvio);</li>
            <li className="mt-2">4. Multifactor Authentication enabled; and</li>
            <li className="mt-2">
              5. Sufficient storage space to save past Communications or an installed printer to print them.
            </li>
          </ol>
        </li>
        <li className="mt-2">
          3. How to Withdraw Your Consent. You may withdraw your consent to receive Communications electronically by
          contacting us at support@devv.io. If you fail to provide consent or if you withdraw your consent to receive
          Communications electronically, Devvio reserves the right to immediately close your Account or charge you
          additional fees for paper copies.
        </li>
        <li className="mt-2">
          4. Updating your Information. It is your responsibility to provide us with a true, accurate and complete email
          address and your contact information, and to keep such information up to date. You understand and agree that
          if Devvio sends you an electronic Communication but you do not receive it because your primary email address
          on file is incorrect, out of date, blocked by your service provider, or you are otherwise unable to receive
          electronic Communications, Devvio will be deemed to have provided the Communication to you. You may update
          your information by logging into your account and visiting settings or by contacting our support team at
          support@devv.io.
        </li>
      </ol>
      <p className="mt-2">APPENDIX 4: STATE LICENSE DISCLOSURES</p>
      <p>Please note the following disclosures associated with virtual currency:</p>
      <ul className="ml-4">
        <li className="mt-2">
          • Virtual currency is not legal tender, is not backed by the government, and accounts and value balances are
          not subject to Federal Deposit Insurance Corporation or Securities Investor Protection Corporation
          protections.
        </li>
        <li className="mt-2">
          • Legislative and regulatory changes or actions at the state, federal, or international level may adversely
          affect the use, transfer, exchange, and value of virtual currency.
        </li>
        <li className="mt-2">
          • Transactions in virtual currency may be irreversible, and, accordingly, losses due to fraudulent or
          accidental transactions may not be recoverable.
        </li>
        <li className="mt-2">
          • Some virtual currency transactions shall be deemed to be made when recorded on a public ledger, which is not
          necessarily the date or time that the customer initiates the transaction.
        </li>
        <li className="mt-2">
          • The value of virtual currency may be derived from the continued willingness of market participants to
          exchange fiat currency for virtual currency, which may result in the potential for permanent and total loss of
          value of a particular virtual currency should the market for that virtual currency disappear.
        </li>
        <li className="mt-2">
          • There is no assurance that a person who accepts a virtual currency as payment today will continue to do so
          in the future.
        </li>
        <li className="mt-2">
          • The volatility and unpredictability of the price of virtual currency relative to fiat currency may result in
          significant loss over a short period of time.
        </li>
        <li className="mt-2">
          • The nature of virtual currency may lead to an increased risk of fraud or cyber attack.
        </li>
        <li className="mt-2">
          • The nature of virtual currency means that any technological difficulties experienced by Devvio may prevent
          the access or use of a customer&apos;s virtual currency.
        </li>
        <li className="mt-2">
          • Any bond or trust account maintained by Devvio for the benefit of its customers may not be sufficient to
          cover all losses incurred by customers.
        </li>
        <li className="mt-2">
          • Fiat withdrawals and deposits, once initiated, can be stopped pursuant to Devvio&apos;s policies and
          procedures, which follow traditional banking best practices. However, any transaction involving digital
          currencies, once initiated, may not be stoppable.
        </li>
      </ul>
      <p className="mt-2">
        POTENTIAL USERS OF DIGITAL OR VIRTUAL CURRENCIES, INCLUDING BUT NOT LIMITED TO QUINT, SHOULD BE FOREWARNED OF A
        POSSIBLE FINANCIAL LOSS AT THE TIME THAT SUCH CURRENCIES ARE EXCHANGED FOR FIAT CURRENCY DUE TO AN UNFAVORABLE
        EXCHANGE RATE. A FAVORABLE EXCHANGE RATE AT THE TIME OF EXCHANGE CAN RESULT IN A TAX LIABILITY. PLEASE CONSULT
        YOUR TAX ADVISOR REGARDING ANY TAX CONSEQUENCES ASSOCIATED WITH YOUR HOLDING OR USE OF DIGITAL OR VIRTUAL
        CURRENCIES.
      </p>
    </div>
  );
});
