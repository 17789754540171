import React from 'react';

export const Label = React.forwardRef<HTMLLabelElement, React.LabelHTMLAttributes<HTMLLabelElement>>((props, ref) => {
  const { className, children, ...rest } = props;
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label ref={ref} className={`block mb-2 ${className || ''}`} {...rest}>
      {children}
    </label>
  );
});

export const CheckboxLabel = React.forwardRef<HTMLLabelElement, React.LabelHTMLAttributes<HTMLLabelElement>>(
  (props, ref) => {
    const { className, children, ...rest } = props;
    return (
      <Label ref={ref} className={`inline-flex items-center ${className || ''}`} {...rest}>
        {children}
      </Label>
    );
  },
);
