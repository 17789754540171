import { useEffect, useState } from 'react';
import { useImageLoad } from '../../hooks/useImageLoad';
import { useCanvas } from '../../hooks/useCanvas';
import { Loader } from '../loader/Loader';

export enum LitPetCardSizes {
  LARGE = '1024px',
  SMALL = '300px',
}

export interface LitPetCardProps {
  name: string;
  power: number;
  release: number;
  ability: string;
  rank: string;
  generation: string;
  litPetImage: string;
  size?: LitPetCardSizes;
  className?: string;
  quality?: number;
}

const cardSizes = {
  [LitPetCardSizes.LARGE]: {
    width: 1024,
    height: 1024,
    divider: 1,
  },
  [LitPetCardSizes.SMALL]: {
    width: 256,
    height: 256,
    divider: 4,
  },
};

const SupportedRanks = ['Diamond', 'Gold', 'Silver', 'Normal'];
const SupportedGeneration = ['Genesis'];

const cardImages = {
  Diamond: {
    [LitPetCardSizes.SMALL]:
      'https://res.cloudinary.com/glassblock-io/image/upload/h_300,w_300/v1683642619/lit-pet-cards/Diamond-v2.png',
    [LitPetCardSizes.LARGE]:
      'https://res.cloudinary.com/glassblock-io/image/upload/h_1024,w_1024/v1683642619/lit-pet-cards/Diamond-v2.png',
  },
  Gold: {
    [LitPetCardSizes.SMALL]:
      'https://res.cloudinary.com/glassblock-io/image/upload/h_300,w_300/v1680096647/lit-pet-cards/Gold.png',
    [LitPetCardSizes.LARGE]:
      'https://res.cloudinary.com/glassblock-io/image/upload/h_1024,w_1024/v1680096648/lit-pet-cards/Gold.png',
  },
  Silver: {
    [LitPetCardSizes.SMALL]:
      'https://res.cloudinary.com/glassblock-io/image/upload/h_300,w_300/v1680096647/lit-pet-cards/Silver.png',
    [LitPetCardSizes.LARGE]:
      'https://res.cloudinary.com/glassblock-io/image/upload/h_1024,w_1024/v1680096648/lit-pet-cards/Silver.png',
  },
  Genesis: {
    [LitPetCardSizes.SMALL]:
      'https://res.cloudinary.com/glassblock-io/image/upload/h_300,w_300/v1680096647/lit-pet-cards/Genesis.png',
    [LitPetCardSizes.LARGE]:
      'https://res.cloudinary.com/glassblock-io/image/upload/h_1024,w_1024/v1680096648/lit-pet-cards/Genesis.png',
  },
  Normal: {
    [LitPetCardSizes.SMALL]:
      'https://res.cloudinary.com/glassblock-io/image/upload/h_300,w_300/v1680096647/lit-pet-cards/Normal.png',
    [LitPetCardSizes.LARGE]:
      'https://res.cloudinary.com/glassblock-io/image/upload/h_1024,w_1024/v1680096648/lit-pet-cards/Normal.png',
  },
};

export const LitPetCard = (props: LitPetCardProps) => {
  const {
    name,
    power,
    release,
    ability,
    rank,
    generation,
    litPetImage,
    size = LitPetCardSizes.LARGE,
    className,
    quality = 1,
  } = props;
  const [mergedImage, setMergedImage] = useState('');
  const [cardImageSrc, setCardImageSrc] = useState('');

  const pngLitPetImage = litPetImage.endsWith('.png')
    ? litPetImage
    : `${litPetImage.slice(0, litPetImage.lastIndexOf('.'))}.png`;

  const replacedName = name.replace('LitPet - ', '');

  const { isLoaded: isLitPetImageLoaded, image: litPetImageInstance } = useImageLoad(pngLitPetImage, {
    crossOrigin: 'anonymous',
  });
  const { isLoaded: isCardImageLoaded, image: cardImageInstance } = useImageLoad(cardImageSrc, {
    crossOrigin: 'anonymous',
  });

  const canvas = useCanvas(cardSizes[size]);

  useEffect(() => {
    if (rank === 'Normal' && SupportedGeneration.includes(generation)) {
      setCardImageSrc(cardImages[generation][size]);
      return;
    }

    if (SupportedRanks.includes(rank)) {
      setCardImageSrc(cardImages[rank][size]);
    }
  }, [generation, rank, size]);

  useEffect(() => {
    if (isLitPetImageLoaded && isCardImageLoaded) {
      if (canvas === undefined) return;

      const ctx = canvas.getContext('2d');

      if (ctx === null) return;

      if (cardImageInstance && litPetImageInstance) {
        const { width, height, divider } = cardSizes[size];
        ctx.drawImage(cardImageInstance, 0, 0, width, height);

        const nameFontSize = Math.floor(60 / divider);
        const abilityFontSize = Math.floor(45 / divider);
        const statsFontSize = Math.floor(50 / divider);

        ctx.font = `${nameFontSize}px MedievalSharp`;
        ctx.textAlign = 'center';
        ctx.fillText(replacedName, 475 / divider, 105 / divider, 295 / divider);

        ctx.font = `${abilityFontSize}px MedievalSharp`;
        ctx.fillText(ability, 475 / divider, 822 / divider, 320 / divider);

        ctx.font = `bold ${statsFontSize}px Arial`;
        ctx.fillText(power.toString(), 220 / divider, 933 / divider);
        ctx.fillText(release.toString(), 738 / divider, 933 / divider);

        ctx.drawImage(litPetImageInstance, 220 / divider, 130 / divider, 500 / divider, 500 / divider);

        setMergedImage(canvas.toDataURL('image/png', quality));
      }
    }
  }, [
    isLitPetImageLoaded,
    isCardImageLoaded,
    litPetImageInstance,
    cardImageInstance,
    canvas,
    replacedName,
    ability,
    power,
    release,
    quality,
    size,
  ]);

  return mergedImage !== '' ? (
    <img src={mergedImage} alt={`LitPet Card - ${name}`} className={className} />
  ) : (
    <Loader className="h-72 flex items-center justify-center text-blue-700" alt="Loading Card" />
  );
};
