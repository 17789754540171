import React from 'react';

type QtyRemainingBoxType = {
  quantity: number;
  mintTotal: number;
};

export const QtyRemainingBox = ({ quantity, mintTotal }: QtyRemainingBoxType) => (
  <div className="text-blue-600 text-right">
    <b>In Stock</b>
    <br />
    {quantity} of {mintTotal}
  </div>
);
