/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-nested-ternary */

import React, { useEffect } from 'react';
import { Menu, Popover, Transition } from '@headlessui/react';
import { v4 as uuidv4 } from 'uuid';
import { Loader } from 'src/common/components/loader/Loader';
import { useInfiniteScroll } from 'src/common/hooks/useInfiniteScroll';
import usePages from 'src/common/hooks/usePages';
import { useSortableData } from 'src/common/components/tables/hooks/useSortableData';
import OrderHistoryRow, { OrderHistoryRowType } from './OrderHistoryRow/OrderHistoryRow';
import OrderHistoryTableHead from './OrderHistoryTableHead/OrderHistoryTableHead';
import { headerColNames } from './orderHistory.service';

export type TablePaginationType = {
  pageSize: number;
  pageIndex: number;
  totalPages?: number;
};

interface OrderHistoryTableProps {
  data: any;
  hasNextPage: boolean | undefined;
  fetchNextPage: () => void;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  isFetching: boolean;
  isHardRefreshing: boolean;
  sorting: (string) => void;
}

const OrderHistoryTable: React.FC<OrderHistoryTableProps> = ({
  data,
  hasNextPage,
  fetchNextPage,
  isLoading,
  isFetchingNextPage,
  isFetching,
  isHardRefreshing,
  sorting,
}) => {
  const [setElement] = useInfiniteScroll(fetchNextPage);
  const { items, requestSort, sortConfig } = useSortableData(data);

  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  useEffect(() => {
    if (sortConfig?.key) {
      sorting(`${sortConfig.direction}${sortConfig.key}`);
    }
  }, [sortConfig]);

  return (
    <div className="mt-12">
      <div className="font-bold text-2xl">All Orders</div>
      <div className="flex flex-col">
        <div className="py-2 inline-block min-w-full">
          <div className=" border max-h-[400px]  overflow-auto">
            <table className="min-w-full table-fixed ">
              <OrderHistoryTableHead
                requestSort={requestSort}
                getClassNamesFor={getClassNamesFor}
                headerColNames={headerColNames}
              />
              <tbody>
                {data && !isHardRefreshing ? (
                  <>
                    {items.map((item: OrderHistoryRowType) => (
                      <OrderHistoryRow key={uuidv4()} {...item} />
                    ))}
                    {hasNextPage && !isHardRefreshing && !isFetchingNextPage && (
                      <div ref={setElement} className="IntersectingDiv h-[150px]" />
                    )}
                  </>
                ) : null}
              </tbody>
            </table>
            {isFetchingNextPage ? (
              <div className="flex h-[100px] mx-auto items-center justify-center text-blue-700">
                <Loader alt="Loading..." />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistoryTable;
