import { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExchangeContext } from 'src/common/context/ExchangeContext';
import { ExchangeUserContext } from 'src/common/context/ExchangeUserContext';
import { AppRoutes } from 'src/routes/app';

import { AgreementCheckBox } from './AgreementCheckBox';

export const ExchangeTermsAndConditions = () => {
  const { exchangeUser, loadingInitial } = useContext(ExchangeUserContext);
  const { isExchangeEnabled } = useContext(ExchangeContext);

  const [userAgreed, setUserAgreed] = useState(false);
  const [showAgreement, setshowAgreement] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const location: any = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsReady(false);
    if (
      isExchangeEnabled &&
      location?.state?.from?.pathname === '/exchange' &&
      exchangeUser?.legal_agreement_accepted &&
      !loadingInitial
    ) {
      navigate(`/${AppRoutes.EXCHANGE_DASHBOARD}`);
    }
    if (exchangeUser?.legal_agreement_accepted) {
      setshowAgreement(false);
    } else {
      setshowAgreement(true);
    }
    setIsReady(true);
  }, [exchangeUser, isExchangeEnabled, loadingInitial, location?.state?.from?.pathname, navigate, setshowAgreement]);
  return isReady ? (
    <div className="font-poppins mr-6 ml-6 mt-6 flex-auto max-w-md sm:max-w-md md:max-w-lg lg:max-w-2xl self-center">
      <p className="text-[17px] leading-[25.5px] font-semibold mb-4" data-testid="terms-and-condition-title">
        Terms and privacy policy
      </p>
      <div
        className="font-poppins text-[13px] leading-[19.5px] pl-1 pr-1 h-[450px] sm:h-[450px] md:h-[450px] overflow-y-scroll w-full font-normal border-none"
        data-testid="terms-and-condition-textarea"
      >
        <p className="mb-[20px] pt-2">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis impedit architecto dolorum? Quod
          quibusdam dolores quas, error voluptatum pariatur at enim libero? Obcaecati, dignissimos fuga! Provident
          expedita ducimus repellendus fugit! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis
          impedit architecto dolorum? Quod quibusdam dolores quas, error voluptatum pariatur at enim libero? Obcaecati,
          dignissimos fuga! Provident expedita ducimus repellendus fugit! Lorem ipsum dolor sit, amet consectetur
          adipisicing elit. Blanditiis impedit architecto dolorum? Quod quibusdam dolores quas, error voluptatum
          pariatur at enim libero? Obcaecati, dignissimos fuga! Provident expedita ducimus repellendus fugit! Lorem
          ipsum dolor sit, amet
        </p>
        <p className="mb-[20px]">
          {' '}
          consectetur adipisicing elit. Blanditiis impedit architecto dolorum? Quod quibusdam dolores quas, error
          voluptatum pariatur at enim libero? Obcaecati, dignissimos fuga! Provident expedita ducimus repellendus fugit!
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis impedit architecto dolorum? Quod
          quibusdam dolores quas, error voluptatum pariatur at enim libero? Obcaecati, dignissimos fuga! Provident
          expedita ducimus repellendus fugit! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis
          impedit architecto dolorum? Quod quibusdam dolores quas, error voluptatum pariatur at enim libero? Obcaecati,
          dignissimos fuga! Provident expedita ducimus repellendus fugit! Lorem ipsum dolor sit, amet consectetur
          adipisicing elit. Blanditiis impedit architecto dolorum? Quod quibusdam dolores quas, error voluptatum
          pariatur at enim libero? Obcaecati, dignissimos fuga! Provident expedita ducimus repellendus fugit! Lorem
          ipsum dolor sit, amet
        </p>
        <p className="mb-[20px]">
          {' '}
          consectetur adipisicing elit. Blanditiis impedit architecto dolorum? Quod quibusdam dolores quas, error
          voluptatum pariatur at enim libero? Obcaecati, dignissimos fuga! Provident expedita ducimus repellendus fugit!
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis impedit architecto dolorum? Quod
          quibusdam dolores quas, error voluptatum pariatur at enim libero? Obcaecati, dignissimos fuga! Provident
          expedita ducimus repellendus fugit! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis
          impedit architecto dolorum? Quod quibusdam dolores quas, error voluptatum pariatur at enim libero? Obcaecati,
          dignissimos fuga! Provident expedita ducimus repellendus fugit! Lorem ipsum dolor sit, amet consectetur
          adipisicing elit. Blanditiis impedit architecto dolorum? Quod quibusdam dolores quas, error voluptatum
          pariatur at enim libero? Obcaecati, dignissimos fuga! Provident expedita ducimus repellendus fugit!
        </p>
      </div>
      {showAgreement && <AgreementCheckBox userAgreed={userAgreed} setUserAgreed={setUserAgreed} />}
    </div>
  ) : null;
};
