import { CheckCartErrorRes, DevvPayCreateChargeParams, DevvPayCreateChargeRes, OrderInformation } from 'src/interfaces';
import { Api } from '.';
import { checkFor422AndReturnData, returnOnlyData } from './client';

export const coinbaseCharge = (orderInformation: OrderInformation): Promise<string | CheckCartErrorRes> => {
  return Api.coinbaseCharge
    .create(orderInformation)
    .then((data) => returnOnlyData<string>(data))
    .catch((error) => checkFor422AndReturnData<CheckCartErrorRes>(error));
};

export const devvPayCharge = (
  devvPayCreateChargeParams: DevvPayCreateChargeParams,
): Promise<DevvPayCreateChargeRes> => {
  return Api.payments.devvPay
    .createCharge(devvPayCreateChargeParams)
    .then((data) => returnOnlyData<string>(data))
    .catch((error) => checkFor422AndReturnData<CheckCartErrorRes>(error));
};
