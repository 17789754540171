import { BackButton } from 'src/common/components/buttons/BackButton';
import OrderHistoryTable from 'src/common/components/tables/OrderHistoryTable/OrderHistoryTable';
import useGlobalHistory from './hooks/useGlobalHistory';

const GlobalOrderHistory = () => {
  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage, isFetching, setSorting } =
    useGlobalHistory();

  const isHardRefreshing = isFetching && !isFetchingNextPage;

  return (
    <div className="bg-white my-[-22px] pl-0.5 h-[83vh] bg-white">
      <div className="md:max-w-5xl lg:max-w-7xl sm:mx-[32px] md:mx-[50px] lg:mx-[100px]">
        <BackButton />
        <OrderHistoryTable
          data={data}
          isHardRefreshing={isHardRefreshing}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          isFetching={isFetchingNextPage}
          sorting={setSorting}
        />
      </div>
    </div>
  );
};

export default GlobalOrderHistory;
