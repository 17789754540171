export default function rehypeTruncate({
  disable = false,
  ellipses = '\u2026',
  ignoreTags = [],
  maxChars = 120,
}: {
  disable?: boolean;
  ellipses?: string;
  ignoreTags?: string[];
  maxChars?: number;
} = {}) {
  function truncateNode(
    node: {
      type: string;
      value: string | any[];
      tagName: string;
      children: any[];
    },
    tf = 0,
  ) {
    let foundText = tf;

    if (node.type === 'text') {
      foundText += node.value.length;
      if (foundText >= maxChars) {
        // eslint-disable-next-line no-param-reassign
        node.value = `${node.value.slice(0, node.value.length - (foundText - maxChars))}${ellipses}`;
        return maxChars;
      }
    }

    if (node.type === 'root' || node.type === 'element') {
      if (node.type === 'element' && ignoreTags.includes(node.tagName)) {
        return foundText;
      }
      for (let i = 0; i < node.children.length; i += 1) {
        if (foundText === maxChars) {
          node.children.splice(i, 1);
          i -= 1;
          // eslint-disable-next-line no-continue
          continue;
        }
        foundText = truncateNode(node.children[i], foundText);
      }
    }

    return foundText;
  }

  function truncator(tree: { type: string; value: string | any[]; tagName: string; children: any[] }) {
    if (!disable) {
      truncateNode(tree);
    }
  }

  return truncator;
}
