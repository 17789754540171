import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Outlet } from 'react-router-dom';
import { Api } from '../../api';
import { CategoriesViewer } from '../components/category-viewer/CategoryViewer';
import { camelToHuman } from '../util';

interface ICategoriesContext {
  categories: CategoriesViewer[];
  getCategoriesByUuids: (uuids: string[]) => CategoriesViewer[];
}

const defaultCategoriesContext: ICategoriesContext = {
  categories: [],
  getCategoriesByUuids: () => [],
};

export const CategoriesContext = createContext<ICategoriesContext>(defaultCategoriesContext);

export const CategoriesProvider = ({ children }) => {
  const [categories, setCategories] = useState<CategoriesViewer[]>([]);

  const { data: categoryListResponseData } = useQuery('categoryList', () => {
    return Api.category.list({
      hasItems: true,
    });
  });

  useEffect(() => {
    if (categoryListResponseData) {
      setCategories(
        categoryListResponseData.data.categories.map((category) => ({
          name: camelToHuman(category.friendlyName),
          value: category.uuid,
          imageUrl: category.imageUrl || '/NoCategoryWhite.png',
        })),
      );
    }
  }, [categoryListResponseData]);

  const getCategoriesByUuids = useCallback(
    (uuids: string[]) => {
      if (uuids.length === 0) {
        return categories;
      }

      return categories.filter((category) => uuids.includes(category.value));
    },
    [categories],
  );

  const providedValues: ICategoriesContext = useMemo(
    () => ({
      categories,
      getCategoriesByUuids,
    }),
    [categories, getCategoriesByUuids],
  );

  return <CategoriesContext.Provider value={providedValues}>{children}</CategoriesContext.Provider>;
};

export const CategoriesContextLayout = () => (
  <CategoriesProvider>
    <Outlet />
  </CategoriesProvider>
);
