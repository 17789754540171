import React, { useEffect, useState, PropsWithChildren } from 'react';
import { Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import ToolTip from '../exchange/ToolTip/ToolTip';

interface IProps {
  logo?: string;
  onCollapsibleClick: (id: string) => void;
  id: string;
  open?: boolean;
  title: string;
  show?: boolean;
  disable?: boolean;
  reasons?: string[];
}

export const Collapsible: React.FC<PropsWithChildren<IProps>> = ({
  logo,
  open = false,
  children,
  title,
  show = true,
  id,
  disable = false,
  reasons,
  onCollapsibleClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => setIsOpen(open), [open]);

  const handleFilterOpening = (buttonId: string) => {
    setIsOpen((prev) => !prev);
    onCollapsibleClick(buttonId);
  };

  return (
    <div>
      {show && (
        <>
          <section className="grid">
            <button
              id={id}
              type="button"
              className={`text-white rounded p-3 h-12
                ${disable && 'bg-gray-300'}
                ${!isOpen && !disable && 'bg-blue-400 hover:bg-blue-600'}
                ${isOpen && !disable && 'bg-blue-600'}`}
              onClick={() => !disable && handleFilterOpening(id)}
            >
              <div className="flex items-center">
                <div className="grow">
                  {logo && <img className="inline h-[20px] mr-4" src={logo} alt="" />}
                  <span className="">{title}</span>
                </div>
                {!disable && (
                  <FontAwesomeIcon className="flex-none mr-2" icon={!isOpen ? faChevronDown : faChevronUp} />
                )}
                {disable && reasons && (
                  <span className="mr-2">
                    <ToolTip
                      setUppercase={false}
                      title={reasons?.reduce((previousMsg, msg) => `${msg} ${previousMsg}`, '')}
                    />
                  </span>
                )}
              </div>
            </button>
          </section>
          <div className="border-bottom">
            <Transition
              show={isOpen}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="p-3"
                style={{
                  display: isOpen ? 'inline' : 'none',
                }}
              >
                {children}
              </div>
            </Transition>
          </div>
        </>
      )}
    </div>
  );
};
