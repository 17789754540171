import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useEffect, useState } from 'react';
import { useLocation, matchRoutes, RouteObject } from 'react-router-dom';
import { AppRoot, AppRoutes } from 'src/routes/app';
import { AuthRoot } from 'src/routes/auth/auth-routes';

type PageTheme = 'white' | 'light';
type PageWidth = 'full' | 'constrained';

interface PageThemeContextValues {
  theme: PageTheme;
  width: PageWidth;
  showSearch: boolean;
  showCartIcon: boolean;
  showHeader: boolean;
  showFooter: boolean;
  showTopPadding: boolean;
  showExchangeSideBar: boolean;
  headerHeight: number;
  setHeaderHeight: Dispatch<SetStateAction<number>>;
  setShowSearch: Dispatch<SetStateAction<boolean>>;
}

export const PageThemeContext = createContext<PageThemeContextValues>({
  theme: 'light',
  width: 'constrained',
  showSearch: false,
  showCartIcon: true,
  showHeader: true,
  showFooter: true,
  showTopPadding: true,
  showExchangeSideBar: false,
  headerHeight: 0,
  setHeaderHeight: () => {},
  setShowSearch: () => {},
});

export const showExchangeSideBarExactPaths = [
  `/${AppRoutes.EXCHANGE}`,
  `/${AppRoutes.EXCHANGE_TERMS_AND_CONDITIONS}`,
  `/${AppRoutes.EXCHANGE_ASK_ORDER}`,
  `/${AppRoutes.EXCHANGE_OPEN_ORDER}`,
  `/${AppRoutes.EXCHANGE_MY_ASSETS}`,
  `/${AppRoutes.EXCHANGE_MARKET_STATS}`,
];

export const hideSearchExactPaths = [
  `/${AppRoutes.EXCHANGE}`,
  `/${AppRoutes.EXCHANGE_TERMS_AND_CONDITIONS}`,
  `/${AppRoutes.EXCHANGE_ASK_ORDER}`,
  `/${AppRoutes.EXCHANGE_OPEN_ORDER}`,
  `/${AppRoutes.EXCHANGE_DASHBOARD}`,
  `/${AppRoutes.EXCHANGE_MY_ASSETS}`,
  `/${AppRoutes.EXCHANGE_REALTIME_ORDERS}`,
  `/${AppRoutes.EXCHANGE_ORDER_BOOK}`,
  `/${AppRoutes.EXCHANGE_MARKET_STATS}`,
];

export const hideHeaderBasePaths = [`/${AppRoutes.CARD_PROCESSING}`];

export const hideFooterBasePaths = [
  `/${AppRoutes.CARD_PROCESSING}`,
  `/${AppRoutes.EXCHANGE}`,
  `/${AppRoutes.EXCHANGE_TERMS_AND_CONDITIONS}`,
  `/${AppRoutes.EXCHANGE_ASK_ORDER}`,
  `/${AppRoutes.EXCHANGE_OPEN_ORDER}`,
  `/${AppRoutes.EXCHANGE_REALTIME_ORDERS}`,
  `/${AppRoutes.EXCHANGE_ORDER_BOOK}`,
  `/${AppRoutes.EXCHANGE_MARKET_STATS}`,
];

export const hideSearchBasePaths = [
  `/${AuthRoot}`,
  `/${AppRoutes.TRANSACTIONS_LIST}`,
  `/${AppRoutes.CHECKOUT_RESOLVER}`,
  `/${AppRoutes.EXCHANGE_ASK_ORDER}`,
  `/${AppRoutes.EXCHANGE_OPEN_ORDER}`,
  `/${AppRoutes.EXCHANGE_MARKET_STATS}`,
  ...hideFooterBasePaths,
];

export const hideTopPaddingBasePaths = [`/${AppRoutes.EXCHANGE}`, `/${AppRoutes.EXCHANGE_TERMS_AND_CONDITIONS}`];

export const hideCartBasePaths = [`/${AppRoutes.CHECKOUT_RESOLVER}`];

const LightPages: RouteObject[] = [{ path: AppRoutes.SEARCH_RESULTS }];
const FullWidthPages: RouteObject[] = [{ path: AppRoutes.SEARCH_RESULTS }, { path: AppRoutes.NO_ROUTE }];

export const PageThemeProvider = ({ children }: PropsWithChildren<any>) => {
  const location = useLocation();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [showSearch, setShowSearch] = useState(false);
  const [context, setContext] = useState<PageThemeContextValues>({
    theme: 'light',
    width: 'constrained',
    showSearch,
    setShowSearch,
    showCartIcon: true,
    showHeader: true,
    showFooter: true,
    showTopPadding: true,
    showExchangeSideBar: false,
    headerHeight,
    setHeaderHeight,
  });

  useEffect(() => {
    setContext((prevState) => {
      const theme: PageTheme = matchRoutes(LightPages, location) !== null ? 'light' : 'white';
      const width: PageWidth = matchRoutes(FullWidthPages, location) !== null ? 'full' : 'constrained';
      const testShowSearch =
        location.pathname === AppRoot
          ? showSearch
          : !(
              hideSearchExactPaths.includes(location.pathname) ||
              hideSearchBasePaths.some((basePath) => location.pathname.startsWith(basePath))
            );

      const showCartIcon = !hideSearchBasePaths.some((basePath) => location.pathname.startsWith(basePath));
      const showHeader = !hideHeaderBasePaths.some((basePath) => location.pathname.startsWith(basePath));
      const showFooter = !hideFooterBasePaths.some((basePath) => location.pathname.startsWith(basePath));
      const showTopPadding = !hideTopPaddingBasePaths.some((basePath) => location.pathname.startsWith(basePath));
      const showExchangeSideBar = showExchangeSideBarExactPaths.some((basePath) =>
        location.pathname.startsWith(basePath),
      );

      if (
        theme !== prevState.theme ||
        width !== prevState.width ||
        testShowSearch !== prevState.showSearch ||
        setShowSearch !== prevState.setShowSearch ||
        showCartIcon !== prevState.showCartIcon ||
        showHeader !== prevState.showHeader ||
        showFooter !== prevState.showFooter ||
        headerHeight !== prevState.headerHeight ||
        setHeaderHeight !== prevState.setHeaderHeight ||
        showTopPadding !== prevState.showTopPadding ||
        showExchangeSideBar !== prevState.showExchangeSideBar
      ) {
        return {
          theme,
          width,
          showSearch: testShowSearch,
          setShowSearch,
          showCartIcon,
          showHeader,
          showFooter,
          headerHeight,
          setHeaderHeight,
          showTopPadding,
          showExchangeSideBar,
        };
      }
      return prevState;
    });
  }, [location, headerHeight, showSearch]);

  return <PageThemeContext.Provider value={context}>{children}</PageThemeContext.Provider>;
};
