import React from 'react';

interface UsdIconPropsType {
  className?: string;
}

const UsdIcon: React.FC<UsdIconPropsType> = ({ className = 'w-[19.5px] h-[19.5px] text-[12px]' }) => {
  return (
    <div className="flex items-center text-center">
      <div
        className={`border border-gray-400 rounded-full bg-gray-300 font-semibold flex justify-center self-center items-center ${className}`}
      >
        $
      </div>
    </div>
  );
};

export default UsdIcon;
