import BoringAvatar from 'boring-avatars';

type AvatarImagePropsType = {
  boringAvatarSize: number;
};

const AvatarImage: React.FC<AvatarImagePropsType> = ({ boringAvatarSize }: AvatarImagePropsType) => {
  const colorPalette = ['#94CA2D', '#E5762E', '#1969C9'];
  return <BoringAvatar size={boringAvatarSize} colors={colorPalette} variant="marble" square={false} />;
};

export default AvatarImage;
