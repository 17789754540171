import React, { Fragment, useContext, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

import { Button } from 'src/common/interactions/Button';
import { UserContext } from 'src/common/context/UserContext';
import { Api, LogoutParams, LogoutRes, useLazyQuery } from 'src/api';
import { AppRoutes } from 'src/routes/app';
import { Env } from 'src/common/env';

export const UserDropdown = React.memo(() => {
  const location = useLocation();
  const { user, logOut } = useContext(UserContext);
  const [logoutRequest, logoutResponse] = useLazyQuery<LogoutParams, LogoutRes>(Api.proxy.logout);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const handleLogOut = async () => {
    if (logoutResponse.status === 'loading') {
      return;
    }

    if (user) {
      const logoutPayload = {
        uuid: user.uuid,
      };

      logoutRequest(logoutPayload);
    }
  };

  useEffect(() => {
    if (logoutResponse.status === 'resolved' || logoutResponse.status === 'error') {
      logOut(); // even if remote logout fails, still destroy local user instance
    }
  }, [logoutResponse, logOut]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="flex items-center">
        <Menu.Button as={React.Fragment}>
          <Button.NavLink
            type="button"
            className="flex justify-end items-center md:p-2 mr-4 md:mr-0"
            aria-label="User Dropdown"
          >
            <div className="truncate md:p-1">{user?.username}</div>
            <FontAwesomeIcon className="ml-2" icon={faCaretDown} />
          </Button.NavLink>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <a
                className={cx(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 no-underline text-neutral-700 hover:text-neutral-700',
                )}
                href={`${Env.authHost}/account`}
                target="_blank"
                rel="noreferrer"
              >
                Settings
              </a>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <Link
                className={cx(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 no-underline text-neutral-700 hover:text-neutral-700',
                )}
                to={`/${AppRoutes.MY_ORDER_HISTORY}`}
              >
                My orders
              </Link>
            )}
          </Menu.Item>
          {location.pathname !== `/${AppRoutes.CHECKOUT_RESOLVER}` ? (
            // @ts-ignore
            <Menu.Item onClick={handleLogOut}>
              {({ active }) => (
                <Button.NavDropdown
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-neutral-700 w-full text-left',
                  )}
                >
                  Logout
                </Button.NavDropdown>
              )}
            </Menu.Item>
          ) : null}
        </Menu.Items>
      </Transition>
    </Menu>
  );
});
