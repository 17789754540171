/* eslint-disable react/style-prop-object */
import React, { SVGProps } from 'react';

interface Props extends SVGProps<any> {
  isPositive: boolean;
}

const ProfitIndicatorArrowIcon: React.FC<Props> = ({ isPositive }) => {
  return (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          isPositive
            ? 'M5.00321 0.446817L1.19287 3.58486C0.902217 3.84456 0.874544 4.26077 1.09828 4.53406C1.32201 4.80735 1.77418 4.85238 2.04747 4.62849L4.75825 2.39397V9.37759C4.75825 9.74891 5.05937 10.05 5.43071 10.05C5.80205 10.05 6.10317 9.74892 6.10317 9.37759V2.39397L8.81395 4.62849C9.08724 4.85223 9.533 4.80188 9.76315 4.53406C10.0005 4.25779 9.94185 3.8086 9.66855 3.58486L5.85821 0.446817C5.57099 0.242469 5.28107 0.25998 5.00321 0.446817Z'
            : 'M4.99679 10.5532L8.80713 7.41514C9.09778 7.15544 9.12546 6.73923 8.90172 6.46594C8.67799 6.19265 8.22582 6.14762 7.95253 6.37151L5.24175 8.60603L5.24175 1.62241C5.24175 1.25109 4.94063 0.949951 4.56929 0.949951C4.19795 0.949951 3.89683 1.25108 3.89683 1.62241L3.89683 8.60603L1.18605 6.37151C0.912755 6.14777 0.466998 6.19812 0.236854 6.46594C-0.000483513 6.74221 0.0581503 7.1914 0.331446 7.41514L4.14179 10.5532C4.42901 10.7575 4.71893 10.74 4.99679 10.5532Z'
        }
        fill={isPositive ? '#038600' : '#CF0000'}
      />
    </svg>
  );
};
export default ProfitIndicatorArrowIcon;
