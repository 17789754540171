/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SVGProps } from 'react';

const ShoppingCartIcon: React.FC<SVGProps<any>> = () => {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49996 19.3332C8.03972 19.3332 7.66663 18.9601 7.66663 18.4998C7.66663 18.0396 8.03972 17.6665 8.49996 17.6665C8.9602 17.6665 9.33329 18.0396 9.33329 18.4998C9.33329 18.9601 8.9602 19.3332 8.49996 19.3332Z"
        stroke="#2F2F2F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6667 19.3332C17.2065 19.3332 16.8334 18.9601 16.8334 18.4998C16.8334 18.0396 17.2065 17.6665 17.6667 17.6665C18.1269 17.6665 18.5 18.0396 18.5 18.4998C18.5 18.9601 18.1269 19.3332 17.6667 19.3332Z"
        stroke="#2F2F2F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.83337 1.8335H5.16671L7.40004 12.9918C7.47624 13.3755 7.68496 13.7201 7.98966 13.9654C8.29436 14.2107 8.67562 14.341 9.06671 14.3335H17.1667C17.5578 14.341 17.9391 14.2107 18.2438 13.9654C18.5485 13.7201 18.7572 13.3755 18.8334 12.9918L20.1667 6.00016H6.00004"
        stroke="#2F2F2F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShoppingCartIcon;
