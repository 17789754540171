import React, { useContext, useEffect, useState } from 'react';
import Downshift, { StateChangeOptions } from 'downshift';
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { Show } from 'src/common/layout';
import { Api, KeywordListParams, useLazyQuery } from 'src/api';
import { useDebounce } from 'src/common/hooks/useDebounce';
import { usePrevious } from 'src/common/hooks/usePrevious';
import { UserContext } from 'src/common/context/UserContext';
import { Mixpanel, MixpanelEvents } from 'src/common/util';
import { UseFormRegister } from 'react-hook-form';
import { Checkbox } from '../forms/Checkbox';

function shouldQueryTypeahead(currentValue: string, previousValue: string | undefined) {
  // Logic for when to make another typeahead query.
  // Current value must exist and have a length of at least 2
  // Previous value must be empty or undefined or have length 1 OR
  // previous value should not match or be a substring of current value
  const lowercaseCurrentValue = currentValue.toLowerCase();
  const lowercasePreviousValue = (previousValue || '').toLowerCase();
  return (
    currentValue &&
    currentValue.length >= 2 &&
    (!previousValue ||
      previousValue.length === 1 ||
      (!lowercaseCurrentValue.includes(lowercasePreviousValue) && lowercasePreviousValue !== lowercaseCurrentValue))
  );
}

interface TypeaheadMenuProps {
  items: string[];
  inputValue: string | null;
  highlightedIndex: number | null;
  getItemProps: any;
  isHeader: boolean;
}

const TypeaheadMenu = React.forwardRef<HTMLUListElement, TypeaheadMenuProps>((props: TypeaheadMenuProps, ref) => {
  const { items, inputValue, highlightedIndex, getItemProps } = props;
  const filteredResults = items.filter((item) => !inputValue || item.toLowerCase().includes(inputValue.toLowerCase()));
  return (
    <ul
      ref={ref}
      className={cx(
        `origin-top-right absolute right-0 mt-2 w-full rounded-md bg-white focus:outline-none`,
        inputValue && filteredResults.length > 0 && 'px-3 shadow-lg ring-1 ring-black ring-opacity-5',
      )}
    >
      {filteredResults.map((item, index) => (
        <li
          className={cx(
            `first:mt-1 last:mb-1 p-2 text-left`,
            highlightedIndex === index && 'bg-blue-300 bg-opacity-10',
          )}
          {...getItemProps({ key: item, item, index })}
        >
          {item}
        </li>
      ))}
    </ul>
  );
});

interface SearchBarTypeaheadProps {
  isHeader?: boolean;
  defaultValue?: string;
  register: UseFormRegister<{ search: string; searchOnlySellerItems: boolean }>;
  onClearClick: () => void;
  isEmpty: boolean;
  searchSeller?: string;
}

export const SearchBarTypeahead = React.memo((props: SearchBarTypeaheadProps) => {
  const { isHeader, defaultValue = '', register, onClearClick, isEmpty, searchSeller } = props;
  const { user } = useContext(UserContext);
  const [keywordQuery, keywordResponse] = useLazyQuery<KeywordListParams, { data: string[] }>(Api.keyword.list);
  const [value, setValue] = useState(defaultValue);
  const [items, setItems] = useState<string[]>([]);
  const debouncedValue = useDebounce<string>(value, 200);
  const prevValue = usePrevious<string>(debouncedValue);
  const DEFAULT_VALUE_CLEAR_HELPER_NUMBER = '-1';

  const handleStateChange = (changes: StateChangeOptions<string>) => {
    if ('selectedItem' in changes && changes.selectedItem) {
      setValue(changes.selectedItem);
    } else if ('inputValue' in changes && (changes.inputValue || changes.inputValue === '')) {
      setValue(changes.inputValue);
    }
  };

  useEffect(() => {
    if (shouldQueryTypeahead(debouncedValue, prevValue)) {
      keywordQuery({ query: debouncedValue });
    } else if (debouncedValue.length < 2) {
      setItems([]);
    }
  }, [debouncedValue, keywordQuery, prevValue]);

  useEffect(() => {
    if (keywordResponse.status === 'resolved') {
      setItems(keywordResponse.response?.data || []);
    }
  }, [keywordResponse]);

  useEffect(() => {
    if (defaultValue !== DEFAULT_VALUE_CLEAR_HELPER_NUMBER) {
      // Log search query change in mixpanel
      Mixpanel.track(MixpanelEvents.SEARCH, { 'Search Text': defaultValue }, user);
      setValue(defaultValue);
    } else {
      setValue('');
    }
  }, [defaultValue, user]);

  return (
    <Downshift selectedItem={value} onStateChange={handleStateChange}>
      {({ getMenuProps, getItemProps, closeMenu, isOpen, inputValue, highlightedIndex }) => (
        <div className="relative w-full">
          <div className="relative bg-white rounded flex items-center">
            <div
              className={cx('relative', {
                grow: isHeader && searchSeller !== '',
                'w-full': !(searchSeller !== ''),
              })}
            >
              <input
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    closeMenu();
                  }
                }}
                className={cx('h-14 w-full pr-12 ring-white', {
                  'rounded-l-none': isHeader,
                  'rounded-lg': !isHeader,
                  'ml-0': isHeader,
                  'md:ml-1': !isHeader,
                  'border-0': isHeader,
                })}
                type="text"
                placeholder="What are you looking for? Begin typing..."
                aria-label="Search Input"
                {...register('search')}
              />
              <button
                onClick={() => onClearClick()}
                type="button"
                className={cx(`absolute right-4 top-4 bg-transparent border-0 ${isEmpty ? 'hidden' : ''}`, {
                  'right-12': !(searchSeller !== ''),
                })}
                aria-label="Clear"
              >
                <FontAwesomeIcon icon={faXmark} className="text-gray-500 w-5 h-5" />
              </button>
            </div>
            <div
              className={`inline w-max max-w-[20%] border-l-2 h-10 text-right py-2 pl-4 mr-12 truncate ${
                !searchSeller ? 'hidden' : ''
              }`}
            >
              <Checkbox {...register('searchOnlySellerItems')} />
              <span>{searchSeller}</span>
            </div>
            <button
              onClick={() => closeMenu()}
              type="submit"
              className="absolute right-4 top-4 bg-transparent border-0"
              aria-label="Search"
            >
              <FontAwesomeIcon icon={faSearch} className="text-blue" size="lg" />
            </button>
          </div>
          <Show show={isOpen && inputValue !== undefined && inputValue !== null && inputValue.length > 0}>
            <TypeaheadMenu
              {...getMenuProps({}, { suppressRefError: true })}
              highlightedIndex={highlightedIndex}
              inputValue={inputValue}
              items={items}
              getItemProps={getItemProps}
              isHeader={isHeader}
            />
          </Show>
        </div>
      )}
    </Downshift>
  );
});
