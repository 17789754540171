import { GlobalHistoryResponse } from 'src/interfaces';
import axios from 'axios';

export const getGlobalHistory = async ({ page, sort }): Promise<GlobalHistoryResponse> => {
  const response = await axios.get<GlobalHistoryResponse>('api/transactions/history', {
    params: {
      page,
      sort,
    },
  });
  return response.data;
};

export const getMyOrderHistory = async ({ page, sort }): Promise<GlobalHistoryResponse> => {
  const response = await axios.get<GlobalHistoryResponse>('api/transactions/user/history', {
    params: {
      page,
      sort,
    },
  });
  return response.data;
};
