/* eslint-disable react/button-has-type */
import React from 'react';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  small?: boolean;
  type?: 'button' | 'submit';
  buttonColor?: string;
}

const Custom = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { buttonColor, className, children, small, type, ...rest } = props;

  const buttonType: 'button' | 'submit' = type || 'submit';

  return (
    <button
      type={buttonType}
      ref={ref}
      className={`${rest.disabled ? 'bg-neutral-300 hover:bg-neutral-300' : buttonColor}  text-black rounded ${
        small ? 'p-1 font-normal' : 'p-3 font-bold'
      } ${className || ''}`}
      {...rest}
    >
      {children}
    </button>
  );
});

const Primary = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className, children, small, type, ...rest } = props;

  const buttonType: 'button' | 'submit' = type || 'submit';

  return (
    <button
      type={buttonType}
      ref={ref}
      className={`${
        rest.disabled ? 'bg-neutral-300 hover:bg-neutral-300' : 'bg-atlantis hover:bg-atlantis-600'
      }  text-black rounded ${small ? 'p-1 font-normal' : 'p-3 font-bold'} ${className || ''}`}
      {...rest}
    >
      {children}
    </button>
  );
});

const Info = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className, children, small, type, ...rest } = props;

  const buttonType: 'button' | 'submit' = type || 'submit';

  return (
    <button
      type={buttonType}
      ref={ref}
      className={`${
        rest.disabled ? 'bg-neutral-300 hover:bg-neutral-300' : 'bg-[#0066CC] hover:bg-[#0066CC]'
      }  text-white rounded ${small ? 'p-1 font-normal' : 'p-3 px-5 font-bold'} ${className || ''}`}
      {...rest}
    >
      {children}
    </button>
  );
});

const Muted = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className, children, small, type, ...rest } = props;

  const buttonType: 'button' | 'submit' = type || 'submit';
  return (
    <button
      type={buttonType}
      ref={ref}
      className={`bg-gray-200 text-white rounded ${small ? 'p-1 font-normal' : 'p-3 font-bold'} ${className || ''}`}
      {...rest}
    >
      {children}
    </button>
  );
});

const Warning = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className, children, small, type, ...rest } = props;
  const buttonType: 'button' | 'submit' = type || 'submit';
  return (
    <button
      type={buttonType}
      ref={ref}
      className={`bg-red-500 hover:bg-red-600 text-white rounded ${small ? 'p-1 font-normal' : 'p-3 font-bold'} ${
        className || ''
      }`}
      {...rest}
    >
      {children}
    </button>
  );
});

const Outline = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className, children, small, type, ...rest } = props;
  const buttonType: 'button' | 'submit' = type || 'submit';
  return (
    <button
      type={buttonType}
      ref={ref}
      className={`bg-transparent hover:bg-atlantis-500 text-atlantis-500 hover:text-black rounded border border-atlantis-500 hover:border-transparent ${
        small ? 'p-1 font-normal' : 'p-3 font-bold'
      } ${className || ''}`}
      {...rest}
    >
      {children}
    </button>
  );
});

const RoundedPrimary = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className, children, type, ...rest } = props;
  const buttonType: 'button' | 'submit' = type || 'submit';
  return (
    <button
      type={buttonType}
      ref={ref}
      className={`bg-white hover:bg-red1 text-red1 text-xs font-bold hover:text-white rounded-full border-2 border-red1 px-5 py-2.5 uppercase ${
        className || ''
      }`}
      {...rest}
    >
      {children}
    </button>
  );
});

const Link = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className, children, type, ...rest } = props;
  const buttonType: 'button' | 'submit' = type || 'submit';
  return (
    <button
      type={buttonType}
      ref={ref}
      className={`bg-transparent text-blue-600 underline ${className || ''}`}
      {...rest}
    >
      {children}
    </button>
  );
});

const NavLink = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className, children, type, ...rest } = props;
  const buttonType: 'button' | 'submit' = type || 'submit';
  return (
    <button type={buttonType} ref={ref} className={`bg-transparent text-white ${className || ''}`} {...rest}>
      {children}
    </button>
  );
});

const NavDropdown = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className, children, type, ...rest } = props;
  const buttonType: 'button' | 'submit' = type || 'submit';
  return (
    <button type={buttonType} ref={ref} className={`p-2 ${className || 'bg-transparent'}`} {...rest}>
      {children}
    </button>
  );
});

const Category = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className, children, type, small, ...rest } = props;
  const buttonType: 'button' | 'submit' = type || 'submit';
  return (
    <button
      type={buttonType}
      ref={ref}
      className={`bg-neutral-700 hover:bg-neutral-800 text-white rounded md:rounded-r-none ${
        small ? 'p-1 font-normal' : 'p-4 font-bold'
      } ${className || ''}`}
      {...rest}
    >
      {children}
    </button>
  );
});

const RoundedBlue = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className, children, type, ...rest } = props;
  const buttonType: 'button' | 'submit' = type || 'submit';
  return (
    <button
      type={buttonType}
      ref={ref}
      className={`bg-blue-500 hover:bg-blue-600 text-white text-xs font-bold rounded-full px-5 py-2.5 uppercase text-center align-middle ${
        className || ''
      }`}
      {...rest}
    >
      {children}
    </button>
  );
});

export const Button = {
  Category,
  Custom,
  Link,
  Outline,
  Primary,
  Info,
  Muted,
  RoundedPrimary,
  Warning,
  NavLink,
  NavDropdown,
  RoundedBlue,
};
