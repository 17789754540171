import React, { memo, SyntheticEvent, useMemo, useState } from 'react';
import { Inventory } from 'src/interfaces';

interface PriceHeaderProps {
  inventory: Inventory[];
  onCurrencySelect: (currency: string) => void;
  defaultCurrency?: string;
  disableSelect?: boolean;
}

export const PriceHeader = memo(({ inventory, onCurrencySelect, defaultCurrency, disableSelect }: PriceHeaderProps) => {
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency || '');

  const currencies = useMemo(() => {
    const currenciesSet = new Set<string>();
    for (const item of inventory) {
      const { paymentOptions } = item;
      for (const paymentOption of Object.values(paymentOptions)) {
        paymentOption.currencies.forEach((currency) => currenciesSet.add(currency));
      }
    }
    return Array.from(currenciesSet);
  }, [inventory]);

  const handleSelect = (event: SyntheticEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    setSelectedCurrency(value);
    onCurrencySelect(value);
  };

  return (
    <div className="flex items-center gap-2">
      <span>Price</span>
      <select
        className="text-xs rounded-md pl-2 pr-6 py-1"
        value={selectedCurrency}
        onChange={handleSelect}
        disabled={disableSelect}
      >
        {currencies.map((currency) => (
          <option key={`price-header-currency-${currency}`} value={currency}>
            {currency}
          </option>
        ))}
      </select>
    </div>
  );
});
