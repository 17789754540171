import { SplitFactory } from '@splitsoftware/splitio';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';

import { Env } from 'src/common/env';

export enum FeatureFlagsEvents {
  ANNOUNCEMENTS = 'ANNOUNCEMENTS_SPLIT',
  SIMILAR_PRODUCTS = 'MLT_Split',
  CHECKOUT_ENABLED = 'CHECKOUT_ENABLED_SPLIT',
  CHECKOUT_PAYMENT_CARD = 'Circle_V1_Split',
  CHECKOUT_PAYMENT_DEVVPAY = 'DevvPay_V1_Split',
  EXCHANGE_ENABLED_SPLIT = 'EXCHANGE_ENABLED_SPLIT',
  BUYER_DEVVX_UX = 'BUYER_DEVVX_UX',
  // EXPLORER_DEVVX_UX = 'EXPLORER_DEVVX_UX',
}

interface callbackInterface {
  (message: string): void;
}

const COOKIE_NAME_FF = 'featureFlagsUser';
const COOKIE_USER = 'user';
const DEFAULT_TREATMENT = 'off';

const getUserKey = () => {
  const cookies = new Cookies();
  const storedUser = cookies.get(COOKIE_USER);
  let keyUser = cookies.get(COOKIE_NAME_FF);

  if (!keyUser) {
    if (!!storedUser && storedUser.username) {
      keyUser = storedUser.username;
    } else {
      keyUser = uuidv4();
    }

    cookies.set(COOKIE_NAME_FF, keyUser);
  } else if (!!storedUser && storedUser.username !== keyUser) {
    keyUser = storedUser.username;
    cookies.set(COOKIE_NAME_FF, keyUser);
  }

  return keyUser;
};

const actions = {
  on: (name: string, callback: callbackInterface) => {
    if (Env.splitIoKey) {
      const keyUser = getUserKey();

      const factory: SplitIO.ISDK = SplitFactory({
        core: {
          authorizationKey: Env.splitIoKey,
          key: keyUser,
        },
        startup: {
          readyTimeout: 1.5, // 1.5 sec
        },
      });

      // And get the client instance you'll use
      const client: SplitIO.IClient = factory.client();

      client.on(client.Event.SDK_READY, () => {
        const treatment: SplitIO.Treatment = client.getTreatment(name);
        callback(treatment);
      });
    } else {
      callback(DEFAULT_TREATMENT);
    }
  },
};

export const FeatureFlags = actions;
