/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ExchangeErrorRes,
  ExchangeOrderParams,
  ExchangeTradingPair,
  GetExchangeUserResponse,
  UserBalanceResponse,
} from 'src/interfaces';
import { ExchangeApi } from '.';
import { checkFor422AndReturnData, returnOnlyData } from './client';

export const getMyUser = (): Promise<GetExchangeUserResponse> => {
  return ExchangeApi.exchange
    .getMyUser()
    .then((data) => data)
    .catch((error) => checkFor422AndReturnData<ExchangeErrorRes>(error));
};

export const getMyOrders = (): Promise<string | ExchangeErrorRes> => {
  return ExchangeApi.exchange
    .getMyOrders()
    .then((data) => {
      returnOnlyData<string>(data);
    })
    .catch((error) => checkFor422AndReturnData<ExchangeErrorRes>(error));
};

export const getTradingPairs = (): Promise<ExchangeTradingPair[]> => {
  return ExchangeApi.exchange
    .getTradingPairs()
    .then((data) => data)
    .catch((error) => checkFor422AndReturnData<ExchangeErrorRes>(error));
};
export const getBalances = (): Promise<Array<UserBalanceResponse>> => {
  return ExchangeApi.exchange
    .getBalances()
    .then((data) => data)
    .catch((error) => checkFor422AndReturnData<ExchangeErrorRes>(error));
};

export const postOrder = (order: ExchangeOrderParams): Promise<string | ExchangeErrorRes> => {
  return ExchangeApi.exchange
    .postOrder(order)
    .then((data) => returnOnlyData<string>(data))
    .catch((error) => checkFor422AndReturnData<ExchangeErrorRes>(error));
};

export const createUser = (user): Promise<GetExchangeUserResponse> => {
  return ExchangeApi.exchange
    .createUser(user)
    .then((data) => data)
    .catch((error) => checkFor422AndReturnData<ExchangeErrorRes>(error));
};

export const cancelOrder = (orderId: string): Promise<string | ExchangeErrorRes> => {
  return ExchangeApi.exchange
    .cancelOrder(orderId)
    .then((data) => returnOnlyData<string>(data))
    .catch((error) => checkFor422AndReturnData<ExchangeErrorRes>(error));
};

export const getTradesPerformance = (timeRange = '1W', timeInterval = '1h', coinId = ''): Promise<any> => {
  return ExchangeApi.exchange
    .getTradesPerformance(timeRange, timeInterval, coinId)
    .then((data) => data)
    .catch((error) => checkFor422AndReturnData<ExchangeErrorRes>(error));
};
export const getMyTradesPerformance = (timeRange = '1W', timeInterval = '1h', coinId = ''): Promise<any> => {
  return ExchangeApi.exchange
    .getMyTradesPerformance(timeRange, timeInterval, coinId)
    .then((data) => data)
    .catch((error) => checkFor422AndReturnData<ExchangeErrorRes>(error));
};

export const getMyBalancesVariations = (coinId = '', timeRange = '1D'): Promise<any> => {
  return ExchangeApi.exchange.getBalanceVariationForCoin(coinId, timeRange);
};

export const postIngestBalances = (): Promise<any> => {
  return ExchangeApi.exchange
    .postIngestBalances()
    .then((data) => data)
    .catch((error) => checkFor422AndReturnData<ExchangeErrorRes>(error));
};
