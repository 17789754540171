import * as crypto from 'crypto-browserify';

const noDenominationFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
const numberFormatter = new Intl.NumberFormat();

export function formatCurrencyWithoutDenomination(number: number) {
  return noDenominationFormatter.format(number);
}

export function formatNumber(number: number) {
  return numberFormatter.format(number);
}

export function sha256(message: string) {
  const hashBuffer = crypto.createHash('sha256');
  const data = hashBuffer.update(message);
  return data.digest('hex');
}

export function formatAsTimeString(t: number): string {
  const hours: number = Math.floor(t / 3600);
  const minutes: number = Math.floor((t - hours * 3600) / 60);
  const seconds: number = t - hours * 3600 - minutes * 60;
  const hh = hours < 10 ? `0${hours}` : `${hours}`;
  const mm = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const ss = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${hh}:${mm}:${ss}`;
}

export function getFormError(errorObj: { [x: string]: any }) {
  const fields = ['firstName', 'lastName', 'email', 'username', 'password', 'passwordConfirm'];
  for (const field of fields) {
    const errorMessage = errorObj[field]?.message;
    if (errorMessage) return errorMessage;
  }
  return null;
}

export function isValueStringAndValid(value: unknown): value is string {
  return typeof value === 'string' && value.trim() !== '' && value !== 'any';
}

export function isValueObjectAndValid(value: unknown): value is object {
  return typeof value === 'object' && value !== null && Object.keys(value).length > 0;
}

export function isValueArrayAndValid(value: unknown): value is Array<unknown> {
  return Array.isArray(value);
}

export function isNumber(num: undefined | null | string | number) {
  return (
    num !== null &&
    num !== undefined &&
    (typeof num === 'number' || (typeof num === 'string' && !/^\s*$/.test(num) && Number.isFinite(Number(num))))
  );
}

/**
 * Checks if the given string is a valid HTTP URL.
 * @param {string} string - the string to check for a valid HTTP URL.
 * @returns {boolean} - true if the string is a valid HTTP URL, false otherwise.
 */
export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

/**
 * Returns a random number between min and max.
 * @param {number} min - The minimum number to return.
 * @param {number} max - The maximum number to return.
 * @returns A random number between min and max.
 */
export function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Returns an array of random NFTs from the given array of NFTs.
 * @param {string[]} nfts - the array of NFTs to choose from.
 * @param {number} quantity - the number of NFTs to return.
 * @returns {string[]} - an array of random NFTs.
 */
export function getRandomNftItems({ nfts, quantity }: { nfts: string[]; quantity: number }) {
  let nftArr = nfts;
  const randomNfts: string[] = [];
  for (let i = 0; i < quantity; i += 1) {
    const index = getRandomNumber(0, nftArr.length - 1);
    const randomNft = nftArr[index];
    randomNfts.push(randomNft);
    nftArr = nftArr.filter((nft) => nft !== randomNft);
  }
  return randomNfts;
}

export function lowercaseFirstLetter(tx: string) {
  return `${tx.charAt(0).toLowerCase()}${tx.slice(1)}`;
}

/**
 * Takes in a list of strings and returns a single string with all of the classes in the list
 * separated by spaces.
 * @param {...string} classes - the list of classes to join together
 * @returns {string} - the joined string of classes
 */
export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

/**
 * Takes in a devvPayOption object and returns a string of the rounded price in coin name.
 * @param {object} devvPayOption - the devvPayOption object
 * @param {boolean} [isPlural=true] - whether or not the coinName should be pluralized
 * @param {number} price - the price of the item
 * @returns {string} - the price of the item in the coinName with the correct number of decimals
 */
export function getDevvPayPrice({
  devvPayOption,
  isPlural = true,
  isDiscount = false,
  price,
}: {
  devvPayOption: { coinName: string; exchangeRate: number };
  isPlural?: boolean;
  isDiscount?: boolean;
  price: number;
}) {
  const rawConversion = price * devvPayOption.exchangeRate;
  const convertedPrice = Math.ceil(parseFloat(rawConversion.toFixed(2)));
  return `${convertedPrice} ${devvPayOption.coinName}${convertedPrice > 1 && isPlural ? '(s)' : ''}`;
}

export const capitalizeFirstLetter = ([first, ...rest]: string) => {
  if (first && rest) {
    return first.toUpperCase() + rest.join('');
  }
  return (first + rest) as string;
};

export const formatUnitPrice = (unitPrice) => {
  return unitPrice && unitPrice < 0.0001 ? 0.0001 : (unitPrice || 0).toFixed(4);
};

/**
 *
 * @param date = Date Object to be converted and formated into MM/dd/YYYY hh:mm
 * @returns MM/dd/YYYY hh:mm from locale date
 */
export const formatLocaleDateToUtc = (date: Date): string => {
  const year = date.getUTCFullYear();
  const month = (1 + date.getUTCMonth()).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');

  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  return `${month}/${day}/${year} ${hours}:${minutes}`;
};

/**
 *
 * @param date = Date Object used to get hours
 * @returns HH:MM from locale date to UTC
 */
export const getUTCHours = (date: Date): string => {
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

/**
 *
 * @param date = Date Object used to get date
 * @returns MM/dd/YYYY from locale date to UTC
 */
export const getUTCDate = (date: Date): string => {
  const year = date.getUTCFullYear();
  const month = (1 + date.getUTCMonth()).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  return `${month}/${day}/${year}`;
};
