import React from 'react';
import ContentLoader from 'react-content-loader';
import colors from 'tailwindcss/colors';

const PaymentOptionSkeleton = (props) => (
  <ContentLoader
    speed={2}
    width={200}
    height={80}
    viewBox="0 0 200 80"
    backgroundColor={colors.blue[400]}
    foregroundColor={colors.neutral[100]}
    className="w-full h-auto mb-5"
    preserveAspectRatio="xMidYMid meet"
    alt="Payment options are loading..."
    title="Payment options are loading..."
    {...props}
  >
    <rect x="0" y="0" rx="4" ry="4" width="200" height="20" />
    <rect x="0" y="25" rx="4" ry="4" width="200" height="20" />
    <rect x="0" y="50" rx="4" ry="4" width="200" height="20" />
  </ContentLoader>
);

export default PaymentOptionSkeleton;
