import mixpanel from 'mixpanel-browser';

import { UserProps } from 'src/common/context/UserContext';
import { Env } from 'src/common/env';

export enum MixpanelEvents {
  LOGIN = 'Log In',
  SELECT_CATEGORY = 'Category Select',
  SEARCH = 'Search',
  VIEW_NFT = 'View NFT',
  CHECKOUT = 'Checkout Start',
  ADD_NFT_CART = 'Add NFT Cart',
  PAYMENT = 'Payment Started',
}

export type PeopleProps = {
  email: string;
  userName: string;
};

const isValid = Env.mixpanelActive && Env.mixpanelId;

if (Env.mixpanelActive && Env.mixpanelId) {
  mixpanel.init(Env.mixpanelId, { debug: Env.mixpanelDebug });
}

const actions = {
  track: (name: string, props: { [key: string]: string | number }, user?: UserProps | null) => {
    if (isValid) {
      let combinedProps = { ...props };
      if (user) {
        combinedProps = { ...combinedProps, ...{ username: user.username } };
      }
      mixpanel.track(name, combinedProps);
    }
  },
  register: (props: { [key: string]: string }) => {
    if (isValid) {
      mixpanel.register(props);
    }
  },
  peopleSet: (props: PeopleProps) => {
    if (isValid) {
      mixpanel.people.set({
        $email: props.email,
        username: props.userName,
        signup_date: new Date().toISOString(),
      });
    }
  },
};

export const Mixpanel = actions;
