import React from 'react';
import cx from 'classnames';
import { GetExchangeBalanceVariation } from 'src/interfaces';
import ProfitIndicatorArrowIcon from './icons/ProfitIndicatorArrowIcon';

const ProfitIndicator = ({ balanceVariation }: { balanceVariation?: GetExchangeBalanceVariation | null }) => {
  const isPositive = (balanceVariation && balanceVariation?.variation > 0) ?? false;
  const amount =
    balanceVariation &&
    balanceVariation?.variation &&
    `${balanceVariation?.variation.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} %`;

  return balanceVariation && balanceVariation.variation ? (
    <div>
      <div
        className={`w-full p-2 h-[24px] rounded flex items-center ${cx({
          'bg-[#F5CCCC]': !isPositive,
          'bg-[#C4DEC3]': isPositive,
        })} `}
      >
        <div className={`self-center pl-2 pr-2 `}>
          <ProfitIndicatorArrowIcon isPositive={isPositive} />
        </div>
        <div
          className={`font-bold text-[11px] text-right ${cx({
            'text-[#CF0000]': !isPositive,
            'text-[#038600]': isPositive,
          })} `}
        >
          {amount}
        </div>
      </div>
    </div>
  ) : null;
};

export default ProfitIndicator;
