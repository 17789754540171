import { PropsWithChildren, ReactNode, useEffect, useState, KeyboardEvent, useReducer, useMemo } from 'react';
import { PropertiesGroup } from 'src/interfaces';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { camelToHuman, isValueArrayAndValid, isValueObjectAndValid, isValueStringAndValid } from 'src/common/util';
import { useSearchParams } from 'react-router-dom';
import { SelectFilterInput } from './SelectFilterInput';
import { MinMaxFilterInput } from './MinMaxFilterInput';
import {
  FiltersState,
  FiltersReducer,
  FiltersAction,
  FilterField,
  BasicFilterField,
  MinMaxFilterField,
  SelectFilterField,
} from '.';
import { SelectFilterCategory } from './SelectFilterCategory';

interface AdvancedFilterSectionProps {
  title: ReactNode;
}

export const AdvancedFilterSection = (props: PropsWithChildren<AdvancedFilterSectionProps>) => {
  const { title, children } = props;
  const [expanded, setExpanded] = useState(true);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'Enter') {
      setExpanded(!expanded);
    }
  };

  return (
    <div className="border-b">
      <div
        className="flex gap-2 justify-between py-4 px-6"
        role="button"
        aria-label={`Toggle ${title}`}
        aria-expanded={expanded}
        onClick={() => setExpanded(!expanded)}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        <div className="text-blue font-medium">{title}</div>
        <div>
          <FontAwesomeIcon className={`transition-transform ${expanded ? '-rotate-180' : ''}`} icon={faChevronDown} />
        </div>
      </div>
      {expanded && <div className="pb-6 px-6">{children}</div>}
    </div>
  );
};

export interface MinMaxFilter {
  attribute?: string;
  min?: number;
  max?: number;
}

interface AdvancedFilterPanelProps {
  filters: PropertiesGroup;
}

// Function to convert search params to JS values and filter keys based on exclusion array
const filterSearchParamsAndConvert = (
  searchParamsObject: URLSearchParams,
  excludeParamsFromArray: string[],
): FiltersState => {
  const newObject: FiltersState = {};

  searchParamsObject.forEach((value, key) => {
    if (!excludeParamsFromArray.includes(key)) {
      try {
        newObject[key] = JSON.parse(value);
      } catch (e) {
        newObject[key] = value;
      }
    }
  });
  return newObject;
};

// Reducer function to control filters values state
const reducerFunction: FiltersReducer = (state: FiltersState, action: FiltersAction) => {
  if (action.type === 'add') {
    if (action.key && action.value !== undefined) {
      if (state[action.key] === undefined) {
        return {
          ...state,
          [action.key]: action.value,
        };
      }
    }
  }

  if (action.type === 'remove') {
    if (action.key) {
      const newState = { ...state };
      delete newState[action.key];
      return newState;
    }
  }

  if (action.type === 'update') {
    if (action.key && action.value !== undefined) {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
  }
  return state;
};

const queryParamsToExclude = ['salePrice', 'query', 'categories', 'sort', 'offset', 'limit'];

export const AdvancedFilterPanel = ({ filters }: AdvancedFilterPanelProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterValues, dispatch] = useReducer(reducerFunction, {});
  const handleClearAll = () => {
    const newParams = new URLSearchParams(searchParams);
    const keysToDelete: string[] = [];
    for (const [key] of newParams.entries()) {
      if (!queryParamsToExclude.includes(key)) {
        keysToDelete.push(key);
      }
    }

    keysToDelete.forEach((key) => newParams.delete(key));

    Object.keys(filterValues).forEach((filterKey) => {
      const filterValue = filterValues[filterKey];
      newParams.delete(filterKey);

      if (typeof filterValue === 'object' && filterValue !== null) {
        dispatch({
          type: 'update',
          key: filterKey,
          value: {},
        });
      } else if (typeof filterValue === 'string') {
        dispatch({
          type: 'update',
          key: filterKey,
          value: '',
        });
      }
    });

    setSearchParams(newParams);
  };

  useEffect(() => {
    const filterFromParams: FiltersState = {};
    const salePrice = searchParams.get('salePrice');

    if (salePrice) {
      try {
        filterFromParams.salePrice = JSON.parse(salePrice);
      } catch (error) {
        const newParams = new URLSearchParams(searchParams);
        newParams.delete('salePrice');
        setSearchParams(newParams);
        return;
      }
    }

    Object.assign(filterFromParams, filterSearchParamsAndConvert(searchParams, queryParamsToExclude));

    Object.entries(filterFromParams).forEach(([key, value]) => {
      dispatch({
        type: 'update',
        key,
        value,
      });
    });
  }, [searchParams, setSearchParams]);

  const filterFields: FilterField[] = useMemo(() => {
    const fields: FilterField[] = [];

    Object.keys(filters).forEach((filterKey) => {
      const filter = filters[filterKey];
      const isMarketPlaceFilterable = filter.is_marketplace_filterable === true || filterKey === 'characteristics';

      if (isMarketPlaceFilterable) {
        const type = (() => {
          if (filter.type === 'integer') return 'minMax';
          if (filter.type === 'string') return 'select';
          if (filterKey === 'characteristics' && filter.type === undefined) return 'minMaxCharacteristic';
          if (filterKey === 'categories' && filter.type === undefined) return 'selectCategory';

          return null;
        })();

        if (type === 'minMax') {
          fields.push({
            key: filterKey,
            name: filter.description || camelToHuman(filterKey),
            type,
            min: filter.minimum || 0,
            max: filter.maximum || 99999,
          });

          return;
        }

        if (type === 'minMaxCharacteristic') {
          const attributesCharacteristics = Object.entries(filter)
            .filter(([, attrFilter]) => attrFilter.is_marketplace_filterable)
            .map(([, attrMap]) => ({
              label: camelToHuman(attrMap.description),
              value: attrMap.description,
            }));

          if (attributesCharacteristics.findIndex((object) => object.value === '') === -1) {
            attributesCharacteristics.unshift({
              label: '',
              value: '',
            });
          }

          fields.push({
            key: filterKey,
            name: filter.description || camelToHuman(filterKey),
            type: 'minMax',
            min: 0,
            max: 1000,
            attributes: attributesCharacteristics,
          });
        }

        if (type === 'select') {
          if (filter.enum && Array.isArray(filter.enum)) {
            const attributes = filter.enum;

            // if (!attributes.includes('')) {
            //   attributes.unshift('');
            // }

            const options = attributes.map((value) => ({ name: value, value: value }));

            fields.push({
              key: filterKey,
              name: filter.description || camelToHuman(filterKey),
              type,
              options,
            });
          }

          if (filter.options && Array.isArray(filter.options)) {
            fields.push({
              key: filterKey,
              name: filter.description || camelToHuman(filterKey),
              type,
              options: filter.options,
            });
          }
        }

        if (type === 'selectCategory') {
          if (filter.options && Array.isArray(filter.options)) {
            const attributes = filter.options || [];

            fields.push({
              key: filterKey,
              name: filter.description || camelToHuman(filterKey),
              type,
              options: attributes,
            });
          }
        }
      }
    });

    return fields;
  }, [filters]);

  const handleFilterApply = <T extends string | MinMaxFilter | undefined | Array<string>>(key: string, value: T) => {
    const newParams = new URLSearchParams(searchParams);

    dispatch({
      type: 'update',
      key,
      value,
    });

    if (isValueArrayAndValid(value)) {
      newParams.delete(key);
      value.forEach((indexValue) => newParams.append(key, indexValue));
    } else if (isValueObjectAndValid(value)) {
      newParams.set(key, JSON.stringify(value));
    } else if (isValueStringAndValid(value)) {
      newParams.set(key, value);
    } else {
      newParams.delete(key);
    }

    setSearchParams(newParams);
    window.scrollTo(0, 0);
  };

  const registerFilter = <T extends BasicFilterField, V extends Array<string> | string | MinMaxFilter | undefined>(
    filter: T,
    defaultValue: V,
  ) => {
    return {
      name: filter.name,
      value: filterValues[filter.key] as V,
      onApply: (value) => handleFilterApply<V>(filter.key, value),
      onMount: () =>
        dispatch({
          type: 'add',
          key: filter.key,
          value: defaultValue,
        }),
      onUnmount: () =>
        dispatch({
          type: 'remove',
          key: filter.key,
        }),
    };
  };

  return (
    <div className="bg-white w-full">
      <div className="flex justify-end py-3 pl-10 pr-6 border-b">
        <button
          type="button"
          className="text-blue p-1 rounded focus:outline-none focus:ring-2 focus:ring-blue-50"
          onClick={handleClearAll}
        >
          Clear All
        </button>
      </div>

      {filterFields.map((filter) => {
        if (filter.type === 'selectCategory' && 'options' in filter) {
          return (
            <AdvancedFilterSection title={filter.name} key={filter.key}>
              <SelectFilterCategory
                options={filter.options}
                {...registerFilter<SelectFilterField, string[]>(filter, [])}
              />
            </AdvancedFilterSection>
          );
        }

        if (filter.type === 'minMax' && 'min' in filter) {
          return (
            <AdvancedFilterSection title={filter.name} key={filter.key}>
              <MinMaxFilterInput
                attributes={filter.attributes}
                min={filter.min}
                max={filter.max}
                {...registerFilter<MinMaxFilterField, MinMaxFilter>(filter, {})}
              />
            </AdvancedFilterSection>
          );
        }

        if (filter.type === 'select' && 'options' in filter) {
          return (
            <AdvancedFilterSection title={filter.name} key={filter.key}>
              <SelectFilterInput options={filter.options} {...registerFilter<SelectFilterField, string>(filter, '')} />
            </AdvancedFilterSection>
          );
        }

        return null;
      })}
    </div>
  );
};
