import React from 'react';

import { formatCurrencyWithoutDenomination } from 'src/common/util';

export interface ItemTotalProps {
  amount: number;
  itemCount: number;
}

export const ItemTotal = React.memo(({ amount, itemCount }: ItemTotalProps) => {
  return (
    <section className="flex justify-between items-center bg-gray-100 p-3">
      <div>
        {itemCount} {itemCount === 1 ? 'Item' : 'Items'}
      </div>
      <div>
        Order Total &nbsp;
        <span className="font-bold">{`$${formatCurrencyWithoutDenomination(amount)}`}</span>
      </div>
    </section>
  );
});
