import './ToolTip.scss';
import * as React from 'react';
import { InformationCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';
import Heptal from 'src/assets/images/Heptal.svg';
import UsdIcon from 'src/assets/images/usd-icon';

interface Props {
  title?: string;
  className?: string;
  iconClassName?: string;
  setUppercase?: boolean;
  toolTipBoxArrow?: string;
  position?: string;
}

const TooltipType = {
  default: {
    tooltipText: 'This is a tooltip box !!',
    Icon: InformationCircleIcon,
  },
  REALTIME: {
    tooltipText: 'Orders traded in realtime',
    Icon: QuestionMarkCircleIcon,
  },
  ORDERBOOK: {
    tooltipText: 'Orders available for trading',
    Icon: QuestionMarkCircleIcon,
  },
  PAYMENT: {
    tooltipText:
      'The available payment options for each item at check out. Some items have a minimum purchase amount to become available.',
    Icon: QuestionMarkCircleIcon,
  },
  USD: {
    tooltipText: 'US Dollar',
    toolTipBoxArrow: 'ml-[-110.5px]',
    Icon: () => (
      <div>
        <UsdIcon />
      </div>
    ),
  },
  HTG: {
    tooltipText: 'Heptal',
    toolTipBoxArrow: 'ml-[-108.5px]',
    Icon: () => (
      <div className="w-6 h-6">
        <img src={Heptal} alt="heptal" />
      </div>
    ),
  },
};

const ToolTip: React.FC<Props> = ({
  title = 'default',
  className = 'ml-1.5 h-4 w-4',
  iconClassName = 'h-4 w-4',
  toolTipBoxArrow = 'ml-[-112.5px]',
  setUppercase = true,
  position = '',
}: Props) => {
  const [show, setShow] = React.useState(false);
  const toolTipBox = show
    ? `tooltip-box visible ${position} ${TooltipType[title.toUpperCase()]?.toolTipBoxArrow || toolTipBoxArrow}`
    : 'tooltip-box';
  const uppercase = setUppercase ? 'uppercase' : '';
  const { Icon } = TooltipType[title.toLocaleUpperCase()] || TooltipType.default;
  return (
    <div
      className={`wrapper hover:cursor-pointer ${className}`}
      onMouseEnter={() => setShow(true)}
      onClick={() => setShow(!show)}
      onMouseLeave={() => setShow(false)}
    >
      <Icon className={`${iconClassName}`} />
      <div className={`${toolTipBox} ${uppercase}`}>
        {TooltipType[title.toUpperCase()]?.tooltipText || title}
        <span className={`tooltip-arrow ${position}`} />
      </div>
    </div>
  );
};

export default ToolTip;
