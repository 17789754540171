import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'src/common/interactions/Button';

export const BackButton = React.memo(() => {
  const navigate = useNavigate();
  const onBackButtonClick = () => {
    navigate(-1);
  };
  return (
    <Button.Link className="py-2 no-underline" onClick={onBackButtonClick}>
      <span className="text-black font-semibold">
        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
        Back
      </span>
    </Button.Link>
  );
});
