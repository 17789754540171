import React from 'react';
import 'tailwindcss/tailwind.css';

type SellerPillButtonProps = {
  sellerName: string;
  onClick: () => void;
};

const SellerPillButton: React.FC<SellerPillButtonProps> = ({ sellerName, onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="px-8 py-2 bg-white border-2 shadow shadow-gray-100 cursor-pointer rounded-full font-poppins font-medium
                 hover:bg-gray-100 active:bg-gray-200 focus:outline-none
                 transition duration-300 ease-in-out transform active:scale-95"
    >
      {sellerName}
    </button>
  );
};

export default SellerPillButton;
