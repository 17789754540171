import * as React from 'react';

export const TabSelector = ({
  isActive,
  children,
  selectedTab,
  onClick,
}: {
  isActive: boolean;
  children: React.ReactNode;
  selectedTab: string;
  onClick: () => void;
}) => {
  const activeTabColor =
    selectedTab === 'sell'
      ? 'border-red-600 text-red-600 focus:outline-none focus:red-green-700 focus:border-red-700'
      : 'border-green-600 text-green-600 focus:outline-none focus:red-green-700 focus:border-green-700';
  return (
    <button
      type="button"
      className={`mr-auto ml-auto group inline-flex items-center px-[20%] text-[13px] uppercase font-semibold py-4 border-b-2  text-sm leading-5 cursor-pointer whitespace-nowrap ${
        isActive
          ? activeTabColor
          : 'border-gray-200 text-gray-500 hover:text-gray-600 hover:border-gray-300 focus:text-gray-600 focus:border-gray-300'
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
