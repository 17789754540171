import React, { useContext, useEffect } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import { CartContext } from 'src/common/context/CartContext';
import { CartContainer } from 'src/routes/app/cart/CartContainer';

export const CartPanel = React.memo(() => {
  const { cartShowState, setCartShowState } = useContext(CartContext);

  useEffect(() => {
    document.body.style.overflow = cartShowState ? 'hidden' : 'unset';
  }, [cartShowState]);

  return (
    <SlidingPane
      className="sliding-pane max-w-2xl z-20 pt-0"
      overlayClassName="sliding-pane-overlay z-20"
      isOpen={cartShowState}
      width="auto"
      hideHeader
      onRequestClose={() => {
        setCartShowState(false);
      }}
    >
      <div className="flex flex-col flex-grow h-full">
        <CartContainer />
      </div>
    </SlidingPane>
  );
});
