import React, { SVGProps } from 'react';

interface Props extends SVGProps<any> {
  iconFill?: string;
}

const ChartIcon: React.FC<Props> = ({ iconFill, ...others }) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0723 15.184L14.4723 15.1716L14.5096 10.3717C14.5133 9.8917 14.1958 9.56926 13.7158 9.56554C13.2358 9.56181 12.9133 9.87929 12.9096 10.3593L12.8724 15.1591L10.4724 15.1405L10.4849 13.5406C10.4886 13.0606 10.1711 12.7381 9.69109 12.7344C9.21108 12.7307 8.88863 13.0481 8.88491 13.5282L8.87249 15.1281L6.47257 15.1095L6.52223 8.70969C6.52596 8.22968 6.20848 7.90724 5.72847 7.90351C5.24846 7.89979 4.92601 8.21726 4.92229 8.69728L4.87263 15.0971L2.4727 15.0784L2.57824 1.47889C2.58196 0.99888 2.26448 0.676436 1.78447 0.672712C1.30446 0.668987 0.982014 0.986465 0.978289 1.46648L0.86655 15.866C0.862825 16.346 1.1803 16.6685 1.66032 16.6722L16.0598 16.7839C16.5398 16.7876 16.8623 16.4702 16.866 15.9902C16.8697 15.5101 16.5523 15.1877 16.0723 15.184Z"
        fill="black"
      />
      <path
        d="M16.184 0.784713L12.7441 0.75802C12.2641 0.754295 11.9417 1.07177 11.9379 1.55179C11.9342 2.0318 12.2517 2.35424 12.7317 2.35797L14.4117 2.371L11.3395 6.50728L8.80933 2.64754C8.57248 2.24569 7.93255 2.24072 7.60999 2.55821L5.19144 4.9395C4.869 5.25698 4.86528 5.73699 5.18275 6.05944C5.50023 6.38188 5.98024 6.38561 6.30269 6.06813L7.9957 4.40119L10.6045 8.42154C10.9207 8.90404 11.5608 8.90901 11.8845 8.43148L15.361 3.73833L15.3511 5.01831C15.3474 5.49833 15.6648 5.82077 16.1448 5.82449C16.6249 5.82822 16.9473 5.51074 16.951 5.03073L16.9777 1.59084C16.9814 1.11083 16.664 0.788386 16.184 0.784662L16.184 0.784713Z"
        fill="black"
      />
    </svg>
  );
};
export default ChartIcon;
