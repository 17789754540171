import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { SignInContainer } from './sign-in/SignInContainer';
import { RegistrationContainer } from './register/RegistrationContainer';
import { AuthRoutes } from './auth-routes';

export const AuthRouter = React.memo(() => {
  return (
    <Routes>
      <Route path={AuthRoutes.LOGIN} element={<SignInContainer />} />
      <Route path={AuthRoutes.REGISTER} element={<RegistrationContainer />} />
    </Routes>
  );
});
