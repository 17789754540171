/* eslint-disable react/style-prop-object */
import React, { SVGProps } from 'react';

interface Props extends SVGProps<any> {
  iconFill?: string;
}

const ClockIcon: React.FC<Props> = ({ iconFill, ...others }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_915_26586" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="white" />
      </mask>
      <g mask="url(#mask0_915_26586)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99996 18.3332C5.39759 18.3332 1.66663 14.6022 1.66663 9.99984C1.66663 5.39746 5.39759 1.6665 9.99996 1.6665C14.6023 1.6665 18.3333 5.39746 18.3333 9.99984C18.3333 14.6022 14.6023 18.3332 9.99996 18.3332Z"
          stroke="#2F2F2F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 5V10L13.3333 11.6667"
          stroke="#2F2F2F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
export default ClockIcon;
