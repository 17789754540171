import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Input } from 'src/common/components/forms/Input';
import { Select } from 'src/common/components/forms/Select';
import { Button } from 'src/common/interactions/Button';
import { isNumber, isValueObjectAndValid } from 'src/common/util';
import { MinMaxFilter, MinMaxFilterAttribute } from '.';

// Allows undefined and empty string to pass through, everything else should basically fail
const validateIsNumber = (property: string, value?: string) => {
  if (isNumber(value) || value === undefined || value === null || value === '') {
    return undefined;
  }

  return `${property} must be a number`;
};

// Validates against an optional min/max
const validateRange = (property: string, min: number, max: number, value?: string) => {
  if (isNumber(value)) {
    const numberValue = Number(value);
    if (numberValue < min) return `${property} must be ${min} or more`;
    if (numberValue > max) return `${property} must be ${max} or less`;
  }

  return undefined;
};

interface MinMaxFilterInputProps {
  onApply: (value: MinMaxFilter) => void;
  onUnmount: () => void;
  onMount: () => void;
  name: string;
  value: MinMaxFilter;
  inputIcon?: ReactNode;
  attributes?: MinMaxFilterAttribute[];
  min: number;
  max: number;
}

export const MinMaxFilterInput = (props: MinMaxFilterInputProps) => {
  const { value, name, inputIcon, attributes, min, max, onApply, onMount, onUnmount } = props;

  const updateInputState = (updatedValue: MinMaxFilter) => {
    return () => {
      if (isValueObjectAndValid(updatedValue)) {
        return {
          min: 'min' in updatedValue ? String(updatedValue.min) : '',
          max: 'max' in updatedValue ? String(updatedValue.max) : '',
          attribute: 'attribute' in updatedValue ? updatedValue.attribute : undefined,
        };
      }

      return {
        min: '',
        max: '',
        attribute: undefined,
      };
    };
  };

  const [inputsValue, setInputsValue] = useState(updateInputState(value));
  const [errors, setErrors] = useState<{
    min?: string;
    max?: string;
  }>({});

  useEffect(() => {
    onMount();
    return () => {
      onUnmount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInputsValue(updateInputState(value));
    if (value?.min === undefined && value?.max === undefined) {
      setErrors({ min: '', max: '' });
    }
  }, [value]);

  const handleApply = () => {
    const newValue: MinMaxFilter = {};

    if (isNumber(inputsValue.min)) {
      newValue.min = Number(inputsValue.min);
    }
    if (isNumber(inputsValue.max)) {
      newValue.max = Number(inputsValue.max);
    }
    if (inputsValue.attribute) {
      newValue.attribute = inputsValue.attribute;
    }

    if (newValue.min !== undefined && newValue.max !== undefined) {
      if (newValue.min > newValue.max) {
        setErrors({
          min: 'Maximum must be greater than Minimum',
        });

        return;
      }
    }

    onApply(newValue);
  };

  const validators = useMemo(() => {
    return {
      min: (inputValue) => {
        return [() => validateIsNumber('Minimum', inputValue), () => validateRange('Minimum', min, max, inputValue)];
      },
      max: (inputValue) => {
        return [() => validateIsNumber('Maximum', inputValue), () => validateRange('Maximum', min, max, inputValue)];
      },
    };
  }, [min, max]);

  const handleChange = (field: 'min' | 'max' | 'attribute', newValue: number | string) => {
    if (validators[field]) {
      setErrors((prevState) => {
        const newState = { ...prevState };
        delete newState[field];

        if (field === 'max') {
          delete newState.min;
        }

        return newState;
      });

      for (const validation of validators[field](newValue)) {
        const validationResult = validation();

        if (typeof validationResult === 'string') {
          setErrors({
            [field]: validationResult,
          });
          break;
        }
      }
    }

    setInputsValue((prevState) => ({ ...prevState, [field]: newValue }));
  };

  const attributeSelectionEnabled = Array.isArray(attributes) && attributes.length > 0;
  const icon = inputIcon ? <div className="absolute left-1 top-1/2 -translate-y-1/2">{inputIcon}</div> : null;
  const inputPadding = inputIcon ? 'pl-6' : undefined;
  const hasDisplayableErrors =
    !!Object.values(errors).filter(Boolean).length || (inputsValue.min === '' && inputsValue.max === '');

  return (
    <div className="flex flex-col gap-4 items-end">
      {attributeSelectionEnabled && (
        <div className="w-full">
          <Select
            value={inputsValue.attribute || ''}
            onChange={(event) => handleChange('attribute', event.target.value)}
            aria-label={`${name} Attribute`}
          >
            {attributes.map((attr) => (
              <option key={attr.value} value={attr.value}>
                {attr.label}
              </option>
            ))}
          </Select>
        </div>
      )}
      <div className="flex items-center gap-2">
        <div className="flex items-center gap-1">
          <div className="relative">
            {icon}
            <Input
              type="number"
              className={inputPadding}
              placeholder="Min"
              aria-label={`${name} Minimum`}
              aria-invalid={!!errors.min}
              value={inputsValue.min || ''}
              onChange={(event) => handleChange('min', event.target.value)}
            />
          </div>
        </div>
        <div>
          <strong>to</strong>
        </div>
        <div className="flex items-center gap-1">
          <div className="relative">
            {icon}
            <Input
              type="number"
              className={inputPadding}
              placeholder="Max"
              aria-label={`${name} Maximum`}
              value={inputsValue.max || ''}
              onChange={(event) => handleChange('max', event.target.value)}
              aria-invalid={!!errors.max}
            />
          </div>
        </div>
      </div>
      {!!Object.values(errors).filter(Boolean).length && (
        <div className="w-full flex flex-col gap-2">
          <div>{errors.min}</div>
          <div>{errors.max}</div>
        </div>
      )}
      <div>
        <Button.Info type="button" onClick={handleApply} disabled={hasDisplayableErrors}>
          Apply
        </Button.Info>
      </div>
    </div>
  );
};
