/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-undef */

import NVD3Chart from 'react-nvd3';
import './chart.css';
import { useEffect, useState } from 'react';
import { ExchangeApi, getMyBalancesVariations } from 'src/api';
import { TimeFrame, ExchangeTradingPair, GetExchangeTradePerformance } from 'src/interfaces';
import { VolumeBarChart } from './volume/VolumeBarChart';

const MarketChart = ({
  height,
  tradingPair,
  setBalanceVariation,
  selectedTimeFrame,
}: {
  height?: null | number | undefined;
  tradingPair: ExchangeTradingPair | undefined;
  setBalanceVariation: (balance) => void;
  selectedTimeFrame: TimeFrame | undefined;
}) => {
  const [data, setGraphData] = useState<GetExchangeTradePerformance | [] | any>([]);

  const { getTradesPerformance } = ExchangeApi.exchange;

  useEffect(() => {
    if (tradingPair && selectedTimeFrame) {
      getMyBalancesVariations(tradingPair.base_coin_id, selectedTimeFrame.code).then((result) =>
        setBalanceVariation(result),
      );
      getTradesPerformance(selectedTimeFrame.code, selectedTimeFrame.time_interval, tradingPair.base_coin_id).then(
        (result) => setGraphData(result),
      );
    }
  }, [selectedTimeFrame, getTradesPerformance, tradingPair]);

  const volArray =
    data &&
    Array.from({ length: data && data?.coordinates?.volumes?.length }).map((d, index) => {
      return {
        date: new Date(data.coordinates.volumes[index][0]),
        amount: data.coordinates.volumes[index][1],
      };
    });

  return (
    <div>
      <NVD3Chart
        duration={300}
        type="lineChart"
        showControls={true}
        showLegend={false}
        x="date"
        xScale={(window as any).d3.time.scale()}
        xAxis={{
          tickFormat: (d) => {
            return (window as any).d3.time.format(selectedTimeFrame && selectedTimeFrame.d3_time_format)(new Date(d));
          },
          axisLabel: 'Time',
        }}
        yAxis={{
          tickFormat: (d) => {
            return (window as any).d3.format(',.2f')(d);
          },
          axisLabel: 'Heptal Value',
          axisLabelDistance: 20,
        }}
        y="amount"
        clipEdge
        interpolate="linear"
        margin={{ top: 10, right: 50, bottom: 60, left: 120 }}
        height={height || 550}
        strokeWidth={4.5}
        color="#0066CC"
        tooltips={true}
        showValues={true}
        datum={
          data &&
          data?.coordinates &&
          data.coordinates &&
          data.coordinates.avg_prices &&
          data.coordinates.avg_prices.length > 0
            ? [
                {
                  key: `${tradingPair?.quote_coin_name?.toUpperCase()}`,
                  area: true,
                  color: '#0066CC',
                  strokeWidth: 4,
                  values: Array.from({ length: data.coordinates.avg_prices.length }).map((d, index) => {
                    return {
                      date: new Date(data.coordinates.avg_prices[index][0]),
                      amount: data.coordinates.avg_prices[index][1],
                    };
                  }),
                },
              ]
            : []
        }
      />
      <VolumeBarChart
        margin={{ top: 10, right: 50, bottom: 60, left: 120 }}
        colors={['#0066CC']}
        showLegend={false}
        showValues={true}
        timeFrame={selectedTimeFrame}
        staggerLabels={false}
        xLabel="date"
        yLabel="amount"
        datum={
          data && data?.coordinates && data?.coordinates?.volumes?.length > 0
            ? [
                {
                  key: `${tradingPair?.base_coin_name?.toUpperCase()} - ${tradingPair?.quote_coin_name?.toUpperCase()}`,
                  area: true,
                  strokeWidth: 4,
                  values: volArray,
                },
              ]
            : []
        }
      />
    </div>
  );
};

export default MarketChart;
