import { useEffect, useRef, useState } from 'react';
import { Select } from 'src/common/components/forms/Select';
import { Button } from 'src/common/interactions/Button';
import { SelectFilterFormOption } from '.';

interface SelectFilterInputProps {
  options: SelectFilterFormOption[];
  onApply: (value: string) => void;
  name: string;
  value: string;
  onUnmount: () => void;
  onMount: () => void;
}

export const SelectFilterInput = ({ options, onApply, name, value, onUnmount, onMount }: SelectFilterInputProps) => {
  const lastAppliedValue = useRef(value);
  const [inputValue, setInputValue] = useState(value);

  const handleApply = () => {
    lastAppliedValue.current = inputValue;
    onApply(inputValue);
  };

  useEffect(() => {
    if (options.map((option) => option.value).includes(value)) {
      setInputValue(value);
      lastAppliedValue.current = value;
    }
  }, [value, options]);

  useEffect(() => {
    onMount();
    return () => {
      onUnmount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableApply = inputValue === lastAppliedValue.current;

  return (
    <div className="flex flex-col gap-4 items-end">
      <Select
        aria-label={`${name} select filter`}
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
      >
        <option key="rank-select-option-select" value="">
          Select
        </option>
        {options.map((option) => (
          <option key={`rank-select-option-${option.value}`} value={option.value}>
            {option.name}
          </option>
        ))}
      </Select>
      <div>
        <Button.Info type="button" onClick={handleApply} disabled={disableApply}>
          Apply
        </Button.Info>
      </div>
    </div>
  );
};
