import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';

import { usePrompt } from 'src/common/hooks/usePrompt';
import { GetCartItemsResponse } from 'src/interfaces';
import { BackButton, CountdownTimer } from 'src/common/components';
import { CartContext } from 'src/common/context/CartContext';
import { UserContext } from 'src/common/context/UserContext';
import { wrappedToast } from 'src/common/util/alerts';
import { Api } from 'src/api';

import { Mixpanel, MixpanelEvents } from 'src/common/util';

import { CheckoutContext } from 'src/common/context/CheckoutContext';
import { CheckoutOrderDetails } from 'src/common/components/checkout-order-details/CheckoutOrderDetails';
import { CheckoutForm } from 'src/common/components/checkout-form/CheckoutForm';

export const CheckoutFormContainer = React.memo(() => {
  const methods = useForm({ mode: 'all' });
  const { user } = useContext(UserContext);
  const { cart, cartId } = useContext(CartContext);
  const {
    totalCount,
    totalValue,
    isBlocking,
    isLastItem,
    addNftItems,
    setTotalCount,
    setTotalValue,
    setSubtotal,
    setIsBlocking,
    setPromoCode,
    setPromoCodeData,
  } = useContext(CheckoutContext);

  // Data Fetching
  useQuery('nftListQuery', () => Api.cart.getItems({ uuids: cart }), {
    refetchOnWindowFocus: false,
    onSuccess: ({ data }: { data: GetCartItemsResponse }) => {
      if (data) {
        const { cartItems, itemsCount, totalValue: totalValueFromData } = data;

        setTotalCount(itemsCount);
        setTotalValue(totalValueFromData);
        addNftItems(cartItems);
        setSubtotal(totalValueFromData);
      }
    },
  });

  usePrompt(
    'Are you sure you want to leave? The items in your cart may no longer be available.',
    isBlocking && !isLastItem,
  );

  // clear promo code
  useLayoutEffect(() => {
    setPromoCode(null);
    setPromoCodeData(null);
  }, []);

  useEffect(() => {
    if (totalCount > 0 && totalCount > 0) {
      Mixpanel.track(
        MixpanelEvents.CHECKOUT,
        {
          'NFT Items count': totalCount,
          'NFT Total amount': totalValue,
          'Cart ID': cartId,
        },
        user,
      );
    }
  }, [cartId, totalCount, totalValue, user]);

  return (
    <FormProvider {...methods}>
      <div className="grid grid-cols-2 gap-0 -my-4 flex-1 h-full">
        <div className="col-span-2 px-4 order-last border rounded shadow-lg md:order-first md:px-16 py-2 md:py-8 md:col-span-1 md:border-0 md:shadow-none">
          <div className="items-start justify-content-start mb-4 hidden md:flex">
            <BackButton />
          </div>
          <CheckoutForm />
        </div>
        <div className="md:border-l md:shadow-2xl col-span-2 md:col-span-1 md:order-last order-first">
          <CheckoutOrderDetails />
        </div>
      </div>
    </FormProvider>
  );
});
