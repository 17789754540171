import React from 'react';

export const SellerTermsOfUse = React.memo(() => {
  return (
    <div className="w-full flex flex-col justify-center p-4">
      <h1 className="text-blue text-center text-5xl font-bold">Glassblock Seller Terms</h1>
      <p>
        These Devvio Marketplace Seller Terms concern the use of Devvio’s Glassblock Marketplace for the sale or resale
        of digital assets such as Nonfungible Tokens. You accept these terms by registering a Seller account on
        Glassblock or by placing any items for sale on Glassblock.
      </p>
      <ol className="ml-4">
        <li>
          1. <span className="text-red-600 font-bold">Context.</span> Devvio intends to introduce, or currently
          operates, Glassblock, an online system that facilitates buying, selling, and other transfers of digital
          assets, and collects certain fees in relation to use of Glassblock.
        </li>
        <li className="mt-4">
          2. <span className="text-red-600 font-bold">Listing Digital Assets in Glassblock.</span>
          <ol className="ml-4">
            <li className="mt-2">
              2.1. You must own or rightfully control a Wallet on the Devvio Blockchain to interact with Glassblock.
              Devvio Blockchain means the blockchain operated by Devvio Inc. A Wallet is a digital account associated
              with a single digital identifier for initiating and receiving tokens on the Devvio Blockchain.
            </li>
            <li className="mt-2">
              2.2. You must also be authorized to sell on Glassblock by completing all requirements Devvio may now or in
              the future impose, including without limitation compliance with Know Your Customer (“KYC”) requirements.
              You agree that you will use secure login practices, and will not let anyone else access Your Glassblock
              account, and will list on Glassblock only those assets owned by You. You represent and warrant that you
              are not barred from transactions facilitated by Glassblock, and you are not embargoed from such
              transactions and marketplace by the United States due to your location or any other reason.
            </li>
            <li className="mt-2">
              2.3. You may list assets held in Your Wallet for sale in Glassblock, including s examples assets You
              acquired and assets You minted, by using the online facilities Devvio provides for Glassblock. Devvio
              reserves the right to modify or eliminate those online facilities in its sole discretion. Assets You list
              for sale may be transferred out of your Wallet, or identified in Your Wallet as restricted from transfer,
              while they are for sale.
            </li>
            <li className="mt-2">
              2.4. Devvio may impose terms of sale on You and any purchaser. You control the price of the asset listed
              for sale subject to minimum and maximum prices that Devvio may establish from time to time and disclose to
              sellers in Glassblock. Your prices must be in a currency supported by Glassblock.
            </li>
            <li className="mt-2">
              2.5. You can cancel a listing at any time, provided a sale of the asset has not completed and is not
              pending. Devvio can cancel a listing at any time, for breach of any of these terms or for Devvio’s
              convenience, and the assets in the canceled listing will be returned to Your Wallet and any transfer
              restrictions removed.
            </li>
            <li className="mt-2">
              2.6. You warrant that You own any assets that You list for sale, and that You have the right to sell such
              assets, and that the purchase of such assets will receive good title to the entirety of the asset You
              listed, and that you truthfully and honestly disclose to prospective purchasers any and all limitations or
              impairments of your ownership or rights.
            </li>
            <li className="mt-2">
              2.7. You agree not to use derogatory, inflammatory, pornographic, or otherwise insulting, lewd, or
              inappropriate content or metadata in association with any listed asset or any listing of an asset.
            </li>
          </ol>
        </li>
        <li className="mt-4">
          3. <span className="text-red-600 font-bold">Fees and Payment.</span>
          <ol className="ml-4">
            <li className="mt-2">
              3.1. For any asset sold on Glassblock, Devvio will collect a portion of the amount paid by the purchaser,
              according to a fee schedule established from time to time by Devvio. The fee applicable to any listing
              will not be changed while the listing remains active.
            </li>
            <li className="mt-2">
              3.2. Devvio will also deduct transaction fees, such as fees charged by payment processors or by
              cryptocurrency processors, from amounts received from any sale of an item You listed on Glassblock prior
              to credited the net proceeds to You.
            </li>
            <li className="mt-2">
              3.3. The amount paid by the purchaser, after deducting Devvio’s fees and transaction fees, will be
              credited to Your account on Glassblock. You can request that funds in your Glassblock seller account be
              transferred to Your Devvio Wallet at any time, subject to holding periods and minimum transfer amounts
              that Devvio may establish from time to time.
            </li>
            <li className="mt-2">
              3.4. If any currency conversion is required, Devvio shall make currency conversion prior to crediting Your
              account, according to Glassblock’s currency conversion policy as Devvio may establish and modify from time
              to time.
            </li>
          </ol>
        </li>
        <li className="mt-4">
          4. <span className="text-red-600 font-bold">Term and Termination.</span>
          <ol className="ml-4">
            <li className="mt-2">
              4.1. Devvio can cancel your authorization to sell on Glassblock at any time, for breach of any of these
              terms or for Devvio’s convenience.
            </li>
            <li className="mt-2">
              4.2. Devvio may audit Your Glassblock account, and any of Your listings on Glassblock, at any time, and
              may terminate individual listings or Your account immediately upon discovery of breach of or noncompliance
              with these terms or any other relevant terms.
            </li>
          </ol>
        </li>
        <li className="mt-4">
          5. <span className="text-red-600 font-bold">Warranties and Indemnification.</span>
          <ol className="ml-4">
            <li className="mt-2">
              5.1. DEVVIO MAKES GLASSBLOCK AVAILABLE STRICTLY AS-IS, AND DISCLAIMS ALL WARRANTIES AS TO GLASSBLOCK.
              DEVVIO SPECIFICALLY DISCLAIMS ANY WARRANTIES OF AVAILABILITY OR FUNCTIONALITY OF GLASSBLOCK, AND ANY
              WARRANTIES CONCERNING ANY ASSET TRANSFERRED USING GLASSBLOCK.
            </li>
            <li className="mt-2">
              5.2. You warrant that You own all assets You list for sale on Glassblock, and that You have all rights
              necessary to sell such assets. You agree to hold Devvio and its officers, employees, and agents (each a “
              <span className="font-bold">Devvio Indemnitee</span>”),harmless from, and indemnify each Devvio Indemnitee
              against, any and all claims, suits, losses, damages, costs, fees, and expenses resulting from, or arising
              out of, Your use of Glassblock, including without limitation intellectual property claims and claims of
              any purchasers, except for claims or suits brought by a Devvio Indemnitee against another Devvio
              Indemnitee.
            </li>
          </ol>
        </li>
        <li className="mt-4">
          6. <span className="text-red-600 font-bold">Other Provisions.</span>
          <ol className="ml-4">
            <li className="mt-2">
              6.1. <span className="text-red-600 font-bold">No Third Party Beneficiaries. </span>
              This Agreement is for the benefit of You and Devvio only, and no third party shall be a beneficiary of
              this Agreement or have any claims or rights based hereon.
            </li>
            <li className="mt-2">
              6.2. <span className="text-red-600 font-bold">Arbitration. </span>
              Any unresolved disputes concerning this Agreement shall be submitted to arbitration in accordance with the
              then-prevailing rules of the American Arbitration Association (i) before an arbitrator agreed upon by the
              parties or (ii) if the parties cannot agree upon an arbitrator within thirty (30) days, before three
              arbitrators selected pursuant to the rules of the American Arbitration Association. The site of the
              arbitration shall be Albuquerque, New Mexico. The arbitrator(s) may award attorneys’ fees and costs as
              part of the award. The award of the arbitrator shall be binding and may be entered as a judgment in any
              court of competent jurisdiction.
            </li>
            <li className="mt-2">
              6.3. <span className="text-red-600 font-bold">Headings. </span>
              The headings of the articles, paragraphs, and clauses used in these terms are included for convenience
              only and are not to be used in interpreting or construing these terms.
            </li>
            <li className="mt-2">
              6.4. <span className="text-red-600 font-bold">Severability. </span>
              The unenforceability, invalidity, or illegality of any provisions of this Agreement shall not render the
              other provisions unenforceable, invalid, or illegal. Any unenforceable, invalid, or illegal provision
              shall be severed from this Agreement only to the extent to make the resulting provision enforceable,
              valid, and legal.
            </li>
            <li className="mt-2">
              6.5. <span className="text-red-600 font-bold">Choice of Law and Venue. </span>
              This Agreement is governed by the laws of the United States and the State of New Mexico, without reference
              to conflict of laws principles. After arbitration, as specified above, any suit to enforce any provision
              of this Agreement, or any right, remedy or other matter arising from the arbitration, will be brought
              exclusively in the state or federal courts located in New Mexico. Devvio and You agree and consent to the
              venue in and to the in-person jurisdiction of the aforementioned courts.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
});
