import React from 'react';
import { useSearchParams } from 'react-router-dom';

import MagnifyingGlass from 'src/assets/images/magnifying-glass.svg';
import { Show } from 'src/common/layout';
import { camelToHuman } from 'src/common/util';

const EXCLUDE_PARAMS = ['category', 'query', 'offset', 'utm_', 'ref', 'tap_a'];

export const NoResults = React.memo(() => {
  const searchParamsPretty: { name: string; value: string }[] = [];
  let queryText = '';
  const [searchParams] = useSearchParams();

  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    if (param === 'query') {
      queryText = value;
    }

    const isParamExcluded = EXCLUDE_PARAMS.some((excludedParam) => param.toLowerCase().includes(excludedParam));
    if (!isParamExcluded) {
      const valueChecked = (() => {
        try {
          const parsedValue = JSON.parse(value);
          if (typeof parsedValue === 'object') {
            return Object.values(parsedValue).join(' - ');
          }

          return value;
        } catch (err) {
          return value;
        }
      })();

      searchParamsPretty.push({
        name: camelToHuman(param),
        value: valueChecked,
      });
    }
  }

  const queryParamsSize = Object.keys(searchParamsPretty).length || 0;

  return (
    <div className="flex flex-col items-center p-8">
      <h3 className="text-blue text-2xl text-center">
        <Show show={queryParamsSize > 0}>
          We&apos;re sorry, we could not find anything for {queryText.trim().length > 0 && `"${queryText}" with`} the
          selected filter{queryParamsSize > 1 ? 's' : ''}:
          <br />
          <ul>
            {searchParamsPretty.map((filter) => (
              <li key={`${filter.name}`}>
                <strong className="font-semibold">{filter.name}</strong>: {filter.value}
              </li>
            ))}
          </ul>
        </Show>
        <Show show={queryParamsSize === 0}>
          We&apos;re sorry, we could not find anything {queryText.trim().length > 0 && `for "${queryText}"`}
        </Show>
      </h3>
      <div className="mb-6">
        <img src={MagnifyingGlass} alt="magnifying glass" />
      </div>
      <p className="text-center">Check to see if you spelled it correctly and try searching again.</p>
    </div>
  );
});
