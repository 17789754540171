import { capitalizeFirstLetter } from 'src/common/util';
import { coinIcons } from 'src/common/util/exchange';
import { GetExchangeBalanceVariation, UserBalanceResponse } from 'src/interfaces';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import StatusAssetsLabel from './StatusAssetsLabel';

const AvailableAndPendingTokenViewCard = ({
  icon,
  balanceItem,
  balanceVariation,
}: {
  icon?: string;
  balanceItem: UserBalanceResponse | null | undefined;
  balanceVariation: GetExchangeBalanceVariation | null | undefined;
}) => {
  const availableCoin =
    balanceItem && balanceItem.available && balanceItem.available > 0 ? balanceItem.available.toString() : '0';
  const coinsInPosition =
    balanceItem && balanceItem.in_position && balanceItem.in_position > 0 ? balanceItem.in_position.toString() : '0';
  return (
    <div className="lg:w-full">
      <div className="bg-white rounded-lg border-2 lg:border-0 text-lg shadow-lg lg:shadow-none font-poppins mt-2 xs:ml-4 xs:mr-4">
        <div className="flex mt-[26px] items-center mb-4 ml-4 mr-4">
          <div className="w-[50px] h-[50px] mr-[16px]">
            <LazyLoadImage src={icon && icon !== '' ? icon : coinIcons.default} className="h-5" alt="Coin Logo" />
          </div>
          <div>
            <div className="font-normal text-[12px] leading-[16.8px]">
              {capitalizeFirstLetter(balanceItem && balanceItem.coin_name ? balanceItem.coin_name : '')}
            </div>
            <div className="font-bold text-[22px] leading-[30px] w-[120px]">
              {balanceItem && balanceItem.balance ? balanceItem.balance : ''}
            </div>
          </div>
        </div>
        <div className="flex mb-6 justify-around">
          <StatusAssetsLabel status="Available" amount={availableCoin} />
          <StatusAssetsLabel status="In Positions" amount={coinsInPosition} />
        </div>
        <div className="border border-[#F4F4F4] mr-10 ml-10 mt-6 mb-6" />
      </div>
    </div>
  );
};

export default AvailableAndPendingTokenViewCard;
