import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from 'src/routes/app';
import { useForm } from 'react-hook-form';
import { SearchBarTypeahead } from './SearchBarTypeahead';

interface SearchBarProps {
  isHeader?: boolean;
  onSearchInputChange?: (string) => void;
}

type DoSearchProps = {
  search?: string;
  searchOnlySellerItems?: boolean;
};

export const SearchBar = React.memo((props: SearchBarProps) => {
  const { isHeader, onSearchInputChange } = props;
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryText = searchParams.get('query') || '';
  const [searchIsEmpty, setSearchIsEmpty] = useState(queryText === '');
  const querySeller = searchParams.get('seller') || '';
  const querySearchOnlySellerItems = searchParams.get('searchOnlySellerItems') === 'true';

  const [query] = useState(queryText);

  const navigate = useNavigate();
  const { register, handleSubmit, watch, resetField, setValue, getValues } = useForm({
    defaultValues: {
      search: queryText,
      searchOnlySellerItems: querySearchOnlySellerItems,
    },
  });
  const searchOnlySellerItemsForm = watch('searchOnlySellerItems');

  const doSearch = useCallback(
    (data: DoSearchProps) => {
      const queryParams = new URLSearchParams(searchParams);
      const removeQueryKeys = ['mintIds', 'collapseBy'];

      for (const key of removeQueryKeys) {
        queryParams.delete(key);
      }

      if (!data?.search) {
        if (queryParams.has('query')) {
          queryParams.delete('query');
        }
      } else {
        queryParams.set('query', `${data.search}`);
      }

      if (location.pathname.startsWith(`/${AppRoutes.SEARCH_RESULTS}`)) {
        setSearchParams(queryParams, { replace: true });
      } else {
        navigate({
          pathname: generatePath(AppRoutes.SEARCH_RESULTS),
          search: queryParams.toString(),
        });
      }
    },
    [searchParams, location.pathname, setSearchParams, navigate],
  );

  const clearSearch = useCallback(() => {
    resetField('search', {
      defaultValue: '',
    });

    if (queryText === '') {
      return;
    }

    doSearch({ searchOnlySellerItems: querySearchOnlySellerItems });
  }, [resetField, doSearch, queryText]);

  const onSubmit = (values) => {
    doSearch(values);
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'search') {
        if (value.search === '' && !searchIsEmpty) {
          setSearchIsEmpty(true);
        }

        if (value.search !== '' && searchIsEmpty) {
          setSearchIsEmpty(false);
        }

        if (onSearchInputChange) {
          onSearchInputChange(value.search);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [onSearchInputChange, watch, setSearchIsEmpty, searchIsEmpty]);

  useEffect(() => {
    const searchOnlySellerUrlState = searchParams.has('searchOnlySellerItems')
      ? searchParams.get('searchOnlySellerItems') === 'true'
      : false;

    if (searchOnlySellerUrlState !== getValues('searchOnlySellerItems')) {
      setValue('searchOnlySellerItems', searchOnlySellerUrlState);
    }
  }, [getValues, searchParams, setValue]);

  useEffect(() => {
    const queryParams = new URLSearchParams(searchParams);

    if (!searchOnlySellerItemsForm) {
      if (queryParams.has('searchOnlySellerItems')) {
        queryParams.delete('searchOnlySellerItems');
      }
    } else {
      queryParams.set('searchOnlySellerItems', `${searchOnlySellerItemsForm}`);
    }

    setSearchParams(queryParams, { replace: true });
  }, [searchOnlySellerItemsForm]);

  return (
    <form
      className={`w-full px-4 rounded ${!isHeader ? 'max-w-5xl mx-auto h-full' : ''}`}
      onSubmit={handleSubmit((values) => onSubmit(values))}
    >
      <div className="text-center">
        <div
          className={`grid gap-y-1 md:px-0 ${isHeader ? 'grid-cols-4 mb-0 px-2 ' : 'md:grid-cols-4 grid-cols-1 mb-3'}`}
        >
          <div className="col-span-4">
            <SearchBarTypeahead
              defaultValue={query}
              isHeader={isHeader}
              register={register}
              onClearClick={clearSearch}
              isEmpty={searchIsEmpty}
              searchSeller={querySeller}
            />
          </div>
        </div>
      </div>
    </form>
  );
});
