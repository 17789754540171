import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Api } from 'src/api';
import { CheckoutContext } from 'src/common/context/CheckoutContext';
import { Button } from 'src/common/interactions/Button';
import { formatCurrencyWithoutDenomination, getDevvPayPrice } from 'src/common/util';
import { getDiscountedOrderTotal, getOrderTotal } from 'src/common/util/checkout';
import { CheckDiscountCodeBody, CheckDiscountCodeRes, DiscountCode, DiscountType, PromoCodeData } from 'src/interfaces';
import { Input } from '../forms/Input';
import { Label } from '../forms/Label';

import checkoutpromoCodeStyles from './CheckoutpromoCode.module.scss';

export const CheckoutpromoCode = React.memo(() => {
  const {
    nftItems,
    promoCodeData,
    devvPayOption,
    isDevvPayOptionSet,
    totalValue,
    subtotal,
    promoCode,
    addNftItems,
    setPromoCodeData,
    setTotalValue,
    setSubtotal,
    setPromoCode,
  } = useContext(CheckoutContext);

  const [totalDiscount, setTotalDiscount] = useState<number>(0);

  const queryClient = useQueryClient();

  // Discount Code Mutator
  const checkDiscount = useMutation((discountData: CheckDiscountCodeBody) => {
    return Api.discountCode.checkDiscountCode(discountData);
  });

  const handleRemovePromoCode = (showToast = true) => {
    const removedDiscountNftItems = nftItems.map((item) => {
      const copyItem = item;
      delete copyItem.discountSalePrice;
      return copyItem;
    });
    setPromoCode('');
    setPromoCodeData(null);
    addNftItems(removedDiscountNftItems);
    setTotalDiscount(0);
    setTotalValue(getOrderTotal(removedDiscountNftItems));

    queryClient.invalidateQueries('nftListQuery');
    if (showToast) {
      toast.warning(<span className="font-bold text-white">Promo code removed successfully.</span>, {
        icon: false,
        toastId: 'promo-code-removed',
      });
    }
  };

  // Event Handlers
  const onDiscountSubmit = () => {
    if (promoCode) {
      setPromoCodeData(null);
      checkDiscount.mutate(
        {
          discountCodeName: promoCode,
        },
        {
          onSuccess: ({ data }: { data: CheckDiscountCodeRes }) => {
            if (data) {
              const discountCodeToastId = 'discount-code-id-result';
              const { discountType, uuid, percentOff, discountScope, numberOfItemsAvailableToUse } =
                data as DiscountCode;
              const description = discountType === DiscountType.PERCENTAGE ? `${percentOff}% applied` : '';
              const promoCodeResults = {
                uuid,
                description,
                discountScope,
                percentOff,
                discountType,
                numberOfItemsAvailableToUse,
              } as PromoCodeData;
              const totalWithoutDiscount = getOrderTotal(nftItems);
              const discount = getDiscountedOrderTotal({ nftItems, promoCodeData: promoCodeResults });

              // Set baseline states
              setPromoCodeData(promoCodeResults);
              setSubtotal(totalWithoutDiscount);
              setTotalValue(parseFloat(totalWithoutDiscount.toFixed(2)));

              // Set discount values
              if (discount.totalDiscount > 0) {
                setTotalDiscount(discount.totalDiscount);
                setTotalValue(discount.totalValue);
                addNftItems(discount.nftItems);

                toast.success(<span className="font-bold text-white">Promo code applied successfully.</span>, {
                  icon: false,
                  toastId: discountCodeToastId,
                });
              } else {
                setTotalDiscount(0);
                handleRemovePromoCode(false);
                toast.warning(
                  <span className="font-bold text-white">
                    Your promo code cannot be used on any of the items in your cart. As such no discount will be applied
                    and the promo code has been removed.
                  </span>,
                  { icon: false, toastId: discountCodeToastId },
                );
              }
            } else {
              setTotalDiscount(0);
            }
          },
          onError: (error: any) => {
            if (error.response.status === 422) {
              const { message } = error.response.data.data;

              if (message.length > 0) {
                toast.error(message);
              }
            } else {
              toast.error('Invalid promo code, please try again.');
            }
          },
        },
      );
    }
  };

  return (
    <div className="bg-gray-100 p-3 rounded shadow">
      <section>
        <div className="grid grid-cols-6 gap-3">
          <div className="col-span-6 lg:col-span-4">
            <Label htmlFor="buyerState" className={checkoutpromoCodeStyles.checkoutLabel}>
              Have a promo code?
            </Label>
            <Input
              id="promoCode"
              aria-label="promoCode"
              className={`border mb-3 uppercase ${checkoutpromoCodeStyles.checkoutInput}`}
              type="text"
              placeholder="Promo Code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
              disabled={!!promoCodeData}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  onDiscountSubmit();
                }
              }}
            />
          </div>
          <div className="col-span-3 lg:col-span-2 flex items-end">
            <Button.Info
              className="w-full flex items-center justify-center mb-3 gtm_applyPromoCode"
              data-gtm="applyPromoCode"
              disabled={!!promoCodeData}
              type="button"
              onClick={onDiscountSubmit}
            >
              APPLY
            </Button.Info>
          </div>
        </div>
      </section>
      <section className="flex justify-between items-center p-3">
        <div>Subtotal</div>
        <div>
          <span>
            {isDevvPayOptionSet && devvPayOption
              ? getDevvPayPrice({ price: subtotal, devvPayOption })
              : `$${formatCurrencyWithoutDenomination(subtotal)}`}
          </span>
        </div>
      </section>
      <section className="flex justify-between items-center p-3">
        <div>
          Discount &nbsp;
          <Button.Link
            className="no-underline"
            onClick={() => handleRemovePromoCode()}
            hidden={!!(!promoCodeData && !totalDiscount)}
          >
            <span className="text-sm font-medium">
              <FontAwesomeIcon icon={faTimes} className="mr-1" />
              Remove
            </span>
          </Button.Link>
        </div>
        <div>
          {isDevvPayOptionSet && devvPayOption
            ? getDevvPayPrice({ price: totalDiscount, devvPayOption })
            : `$${formatCurrencyWithoutDenomination(totalDiscount)}`}
          {!!promoCodeData && !!totalDiscount && <span className="italic">{` (${promoCodeData?.description})`}</span>}
        </div>
      </section>
      <section className="flex justify-between items-center p-3">
        <div>Order Total</div>
        <div>
          <span className="font-bold">
            {isDevvPayOptionSet && devvPayOption
              ? getDevvPayPrice({ price: totalValue, devvPayOption })
              : `$${formatCurrencyWithoutDenomination(totalValue)}`}
          </span>
        </div>
      </section>
    </div>
  );
});
