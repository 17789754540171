import React from 'react';
import { formatCurrencyWithoutDenomination } from '../../util';

interface ResultCardPriceProps {
  salePrice: number;
  fromPrice?: number;
  toPrice?: number;
}

export const ResultCardPrice = ({ salePrice, fromPrice, toPrice }: ResultCardPriceProps) => {
  if (fromPrice && toPrice && fromPrice !== toPrice) {
    return (
      <span className="text-md">
        ${formatCurrencyWithoutDenomination(fromPrice)} - ${formatCurrencyWithoutDenomination(toPrice)}
      </span>
    );
  }

  if (fromPrice) {
    return <span className="text-md">${formatCurrencyWithoutDenomination(fromPrice)}</span>;
  }

  return <span className="text-md">${formatCurrencyWithoutDenomination(salePrice)}</span>;
};
