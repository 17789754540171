import { createContext, useCallback, useMemo, useState, memo } from 'react';
import { CartItemWithDiscount, DevvPayToken, PromoCodeData } from 'src/interfaces';
import { PaymentEnabledResponse } from '../../api/types/payments';

interface CheckoutContextInterface {
  promoCode: string;
  nftItems: CartItemWithDiscount[];
  isDevvPayOptionSet: boolean;
  devvPayOption: DevvPayToken | null;
  promoCodeData: PromoCodeData | null;
  totalCount: number;
  totalValue: number;
  subtotal: number;
  isBlocking: boolean;
  isLastItem: boolean;
  setPromoCode: (string) => void;
  addNftItems: (CartItemWithDiscount) => void;
  setIsDevvPayOptionSet: (boolean) => void;
  setDevvPayOption: (DevvPayToken) => void;
  setPromoCodeData: (PromoCodeData) => void;
  setTotalCount: (number) => void;
  setTotalValue: (number) => void;
  setSubtotal: (number) => void;
  setPaymentEnabled: (PaymentEnabledResponse) => void;
  setIsBlocking: (boolean) => void;
  setIsLastItem: (boolean) => void;
  isOptionDisabled: (PAYMENT_OPTION) => boolean;
  getOptionReasons: (PAYMENT_OPTION) => string[] | undefined;
  isCurrencyFromOptionDisabled: (paymentOption: string, currency: string) => boolean;
  getCurrencyFromOptionReasons: (paymentOption: string, currency: string) => string[] | undefined;
  hasErrorOnPaymentEnabled: () => boolean;
  paymentEnabledErrorMessage: string | undefined;
}

const defaultCheckoutContext: CheckoutContextInterface = {
  promoCode: '',
  nftItems: [],
  isDevvPayOptionSet: false,
  devvPayOption: null,
  promoCodeData: null,
  totalCount: 0,
  totalValue: 0,
  subtotal: 0,
  isBlocking: true,
  isLastItem: false,
  setPromoCode: () => {},
  addNftItems: () => {},
  setIsDevvPayOptionSet: () => {},
  setDevvPayOption: () => {},
  setPromoCodeData: () => {},
  setTotalCount: () => {},
  setTotalValue: () => {},
  setSubtotal: () => {},
  setPaymentEnabled: () => {},
  setIsBlocking: () => {},
  setIsLastItem: () => {},
  isOptionDisabled: () => false,
  getOptionReasons: () => undefined,
  isCurrencyFromOptionDisabled: () => false,
  getCurrencyFromOptionReasons: () => undefined,
  hasErrorOnPaymentEnabled: () => false,
  paymentEnabledErrorMessage: undefined,
};

export const CheckoutContext = createContext<CheckoutContextInterface>(defaultCheckoutContext);

export const CheckoutProvider = memo(({ children }: any) => {
  const [promoCode, setPromoCode] = useState(defaultCheckoutContext.promoCode);
  const [promoCodeData, setPromoCodeData] = useState(defaultCheckoutContext.promoCodeData);
  const [nftItems, setNftItems] = useState(defaultCheckoutContext.nftItems);
  const [devvPayOption, setDevvPayOption] = useState(defaultCheckoutContext.devvPayOption);
  const [isDevvPayOptionSet, setIsDevvPayOptionSet] = useState(defaultCheckoutContext.isDevvPayOptionSet);
  const [totalCount, setTotalCount] = useState(defaultCheckoutContext.totalCount);
  const [totalValue, setTotalValue] = useState(defaultCheckoutContext.totalValue);
  const [subtotal, setSubtotal] = useState(defaultCheckoutContext.subtotal);
  const [paymentEnabled, setPaymentEnabled] = useState<PaymentEnabledResponse | null>(null);
  const [isBlocking, setIsBlocking] = useState(defaultCheckoutContext.isBlocking);
  const [isLastItem, setIsLastItem] = useState(defaultCheckoutContext.isLastItem);

  const addNftItems = useCallback(
    (nfts) => {
      setNftItems(nfts);
    },
    [setNftItems],
  );

  const hasErrorOnPaymentEnabled: () => boolean = useCallback(() => !!paymentEnabled?.message, [paymentEnabled]);

  const paymentEnabledErrorMessage: string | undefined = useMemo(
    () => (typeof paymentEnabled?.message === 'string' ? paymentEnabled?.message : undefined),
    [paymentEnabled],
  );

  const isOptionDisabled = useCallback(
    (paymentName) => {
      return (paymentEnabled && !paymentEnabled[paymentName]?.enabled) || false;
    },
    [paymentEnabled],
  );

  const getOptionReasons = useCallback(
    (paymentName) => {
      return (paymentEnabled && paymentEnabled[paymentName]?.reasons) || undefined;
    },
    [paymentEnabled],
  );

  const isCurrencyFromOptionDisabled = useCallback(
    (paymentName: string, currency: string) => {
      return (paymentEnabled && !paymentEnabled[paymentName]?.currencies[currency]?.enabled) || false;
    },
    [paymentEnabled],
  );

  const getCurrencyFromOptionReasons = useCallback(
    (paymentName: string, currency: string) => {
      return (paymentEnabled && paymentEnabled[paymentName]?.currencies[currency]?.reasons) || undefined;
    },
    [paymentEnabled],
  );

  const CheckoutProviderValue = useMemo(
    () => ({
      promoCode,
      nftItems,
      isDevvPayOptionSet,
      devvPayOption,
      promoCodeData,
      totalCount,
      totalValue,
      subtotal,
      isBlocking,
      isLastItem,
      setPromoCode,
      addNftItems,
      setIsDevvPayOptionSet,
      setDevvPayOption,
      setPromoCodeData,
      setTotalCount,
      setTotalValue,
      setSubtotal,
      setPaymentEnabled,
      setIsBlocking,
      setIsLastItem,
      isOptionDisabled,
      getOptionReasons,
      isCurrencyFromOptionDisabled,
      getCurrencyFromOptionReasons,
      hasErrorOnPaymentEnabled,
      paymentEnabledErrorMessage,
    }),
    [
      promoCode,
      nftItems,
      isDevvPayOptionSet,
      devvPayOption,
      promoCodeData,
      totalCount,
      totalValue,
      subtotal,
      isBlocking,
      isLastItem,
      setPromoCode,
      addNftItems,
      setIsDevvPayOptionSet,
      setDevvPayOption,
      setPromoCodeData,
      setTotalCount,
      setTotalValue,
      setSubtotal,
      setPaymentEnabled,
      setIsBlocking,
      setIsLastItem,
      isOptionDisabled,
      getOptionReasons,
      isCurrencyFromOptionDisabled,
      getCurrencyFromOptionReasons,
      hasErrorOnPaymentEnabled,
      paymentEnabledErrorMessage,
    ],
  );

  return <CheckoutContext.Provider value={CheckoutProviderValue}>{children}</CheckoutContext.Provider>;
});
