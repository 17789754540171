import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import { UserContext } from 'src/common/context/UserContext';
import { wrappedToast } from 'src/common/util/alerts';
import { AuthRoot, AuthRoutes } from 'src/routes/auth/auth-routes';
import { AppRoot, AppRoutes } from 'src/routes/app';
import { Api } from 'src/api';
import { Button } from 'src/common/interactions/Button';
import { RefundResponseType } from 'src/interfaces/general';
import { Loader } from 'src/common/components/loader/Loader';

export const ReturnsContainer: React.FC = () => {
  const { returnId = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isLoading: userIsLoading } = useContext(UserContext);
  const [isRefundConsented, setIsRefundConsented] = useState(false);

  const { data, isLoading, status, error } = useQuery<Promise<RefundResponseType>, AxiosError<{ data: string }>>(
    'refund',
    () => Api.refund.getRefund({ returnId }),
  );

  const {
    // @ts-ignore
    data: { name, imageUrl, isBundle } = {},
  } = data || {};

  const usePostMutation = useMutation(Api.refund.postRefund, {
    onSuccess: () => {
      navigate(`${AppRoot}${AppRoutes.RETURN_CONFIRMATION}`, { replace: true });
    },
    onError: (err) => {
      // @ts-ignore
      wrappedToast.warning({ title: err?.response?.data?.data || '' });
    },
  });

  const onChangeRefundConsent = () => {
    setIsRefundConsented(!isRefundConsented);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    usePostMutation.mutate({ returnId });
  };

  useEffect(() => {
    if (userIsLoading === false) {
      if (!user?.uuid) {
        wrappedToast.warning({ title: 'You must login or register before return the nft' });
        navigate(`/${AuthRoot}/${AuthRoutes.LOGIN}`, { state: { pathBeforeLogin: location.pathname }, replace: true });
      }
    }
  }, [location.pathname, navigate, userIsLoading, user?.uuid]);

  if (isLoading || usePostMutation.isLoading) {
    return (
      <div className="flex justify-center w-full h-full items-center mt-10 pt-5">
        <Loader alt="Return loading." />
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className="w-full flex flex-col justify-center items-center p-8">
        <h1 className="text-blue text-5xl font-bold">No return found.</h1>
        <h2>{error?.response?.data.data}</h2>
      </div>
    );
  }

  return (
    <form className="w-full flex flex-col justify-center items-center p-8" onSubmit={onSubmit}>
      <h2 className="sm:text-xl md:text-2xl text-center">{name}</h2>
      <img alt={name} src={imageUrl} width={300} />
      {isBundle && (
        <p className="m-4">
          If this bundle becomes unbundled, the return will remove the unbundled equivalent amount from your wallet.
        </p>
      )}
      <label htmlFor="confirm" className="flex items-center">
        <input type="checkbox" id="confirm" className="m-2" onChange={onChangeRefundConsent} />{' '}
        <span>I consent to process a return for this item</span>
      </label>
      <Button.Primary type="submit" className="mt-2" disabled={!isRefundConsented}>
        Confirm
      </Button.Primary>
    </form>
  );
};
