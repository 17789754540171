import { InformationCircleIcon } from '@heroicons/react/outline';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExchangeUserContext } from 'src/common/context/ExchangeUserContext';
import { Button } from 'src/common/interactions/Button';
import { AppRoutes } from 'src/routes/app';

import './EmptyTable.scss';
import BillIcon from './Icons/BillIcon';
import BookIcon from './Icons/BookIcon';
import WalletIcon from './Icons/WalletIcon';
import CustomToasts from '../../custom-toast/CustomToast';

interface EmptyTableBodyProperties<T extends object> {
  type?: string;
}

const EmptyTableType = (type: string) => {
  const types = {
    'My Orders': {
      text: (
        <span data-testid="empty-orders" className="empty-text mt-2 mb-8">
          You haven't placed an order yet!
        </span>
      ),
      icon: <BillIcon />,
      url: AppRoutes.EXCHANGE_ASK_ORDER,
    },
    'My Assets': {
      text: (
        <span data-testid="empty-assets" className="empty-text mt-2 mb-8">
          Your account balance is zero.
          <br />
          You must have coin to start trading.
        </span>
      ),
      icon: <WalletIcon />,
    },
    Realtime: {
      text: (
        <span data-testid="empty-realtime" className="empty-text mt-2 mb-8">
          No orders have been
          <br />
          fulfilled yet.
        </span>
      ),
      icon: <BillIcon />,
    },
    Orderbook: {
      text: (
        <span data-testid="empty-orderbook" className="empty-text mt-2 mb-8">
          There are no orders
          <br /> available for trade yet.
        </span>
      ),
      icon: <BookIcon />,
    },
    'Bid & Ask My Assets': {
      text: (
        <span data-testid="empty-buy-sell-assets" className="empty-text mt-[-5rem] mb-[-2.25rem]">
          Your account balance is zero.
          <br />
          You must have coin to start trading.
        </span>
      ),
      icon: '',
    },
  };
  return types[type];
};

export const EmptyTableBody = <T extends object>(props: EmptyTableBodyProperties<T>) => {
  const navigate = useNavigate();
  const { exchangeUser } = useContext(ExchangeUserContext);
  const { type } = props;
  if (!type) return <tbody />;

  const checkCanBuyOrSell = () => {
    if (typeof exchangeUser === 'undefined' || !exchangeUser?.legal_agreement_accepted) {
      CustomToasts({
        type: 'error',
        text: 'You must agree to the Terms and Privacy Policy before making a trade.',
        position: 'TOP_RIGHT',
        icon: <InformationCircleIcon className="h-6 w-6 mr-6" />,
      });

      setTimeout(() => navigate(`/${AppRoutes.EXCHANGE_TERMS_AND_CONDITIONS}`), 1000);
    } else {
      navigate(`/${AppRoutes.EXCHANGE_ASK_ORDER}`);
    }
  };

  return (
    <div className="flex w-full justify-center flex-col items-center bg-white">
      <div className="mt-20 mb-10 w-[110px] mr-10">{EmptyTableType(type)?.icon}</div>
      {EmptyTableType(type)?.text}
      {type && !type.includes('My Assets') ? (
        <div className="mb-26 h-[100vh]">
          <Button.Info className="mr-3 w-full mb-12" onClick={checkCanBuyOrSell} data-testid="empty-place-order">
            <span className="uppercase font-medium text-[13px]">place an order</span>
          </Button.Info>
        </div>
      ) : (
        <div className="ml-4 mb-26 h-[10vh] border-b-red-500" />
      )}
    </div>
  );
};
