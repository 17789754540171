import React from 'react';

const WalletIcon: React.FC = () => {
  return (
    <svg width="165" height="88" viewBox="0 0 165 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="161" cy="49" r="4" fill="#D9D9D9" />
      <circle cx="13.5" cy="42.5" r="2.5" fill="#D9D9D9" />
      <path
        d="M0.535534 22.5355C0.14501 22.926 0.14501 23.5592 0.535534 23.9497L2.65685 26.071L0.535534 28.1924C0.14501 28.5829 0.14501 29.2161 0.535534 29.6066C0.926058 29.9971 1.55922 29.9971 1.94975 29.6066L4.07107 27.4853L6.19239 29.6066C6.58291 29.9971 7.21608 29.9971 7.6066 29.6066C7.99713 29.2161 7.99713 28.5829 7.6066 28.1924L5.48528 26.071L7.6066 23.9497C7.99713 23.5592 7.99713 22.926 7.6066 22.5355C7.21608 22.145 6.58291 22.145 6.19239 22.5355L4.07107 24.6568L1.94975 22.5355C1.55922 22.145 0.926058 22.145 0.535534 22.5355Z"
        fill="#D9D9D9"
      />
      <path
        d="M150.536 69.5355C150.145 69.926 150.145 70.5592 150.536 70.9497L152.657 73.071L150.536 75.1924C150.145 75.5829 150.145 76.2161 150.536 76.6066C150.926 76.9971 151.559 76.9971 151.95 76.6066L154.071 74.4853L156.192 76.6066C156.583 76.9971 157.216 76.9971 157.607 76.6066C157.997 76.2161 157.997 75.5829 157.607 75.1924L155.485 73.071L157.607 70.9497C157.997 70.5592 157.997 69.926 157.607 69.5355C157.216 69.145 156.583 69.145 156.192 69.5355L154.071 71.6568L151.95 69.5355C151.559 69.145 150.926 69.145 150.536 69.5355Z"
        fill="#D9D9D9"
      />
      <path d="M56.0251 0L55.2234 8.56167H114.79C120.313 8.56167 120.313 0 114.79 0H56.0251Z" fill="#979797" />
      <path
        d="M123.901 15.2394L123.871 15.2803C126.827 17.5849 128.764 21.185 128.764 25.2805V32.2669H130.771C133.108 32.2669 134.996 34.182 135 36.5422L135 63.6799C135 66.0464 133.105 67.9582 130.773 67.9582H128.766V74.9442C128.766 81.9374 123.159 87.6289 116.257 87.6289H47.5315C40.6195 87.6289 35 81.9411 35 74.9442V12.6825C35 5.68751 40.6198 0 47.5315 0H56.0251L55.2234 8.56167H47.5331C45.2875 8.56167 43.4563 10.4148 43.4563 12.6875V74.9492C43.4563 77.2227 45.2883 79.0772 47.5331 79.0772H116.258C118.483 79.0772 120.312 77.2296 120.312 74.9492L120.313 67.967H107.836C99.9502 67.967 93.538 61.4771 93.538 53.495V46.7404C93.538 38.7603 99.9504 32.2706 107.836 32.2706H120.313V25.2825C120.313 23.0032 118.484 21.1566 116.259 21.1566H52.7251C50.3932 21.1566 48.4978 19.2448 48.4978 16.8783C48.4978 14.5118 50.3932 12.5999 52.7251 12.5999H54.8446L114.79 12.5971L120.312 12.873L123.901 15.2394ZM107.834 40.8235C104.615 40.8235 101.991 43.479 101.991 46.7366V53.4912C101.991 56.7514 104.616 59.4066 107.834 59.4066H124.411C124.436 59.4049 124.461 59.4038 124.486 59.4031C124.503 59.4026 124.521 59.4024 124.538 59.4024C124.561 59.4024 124.584 59.4028 124.607 59.4037C124.626 59.4044 124.644 59.4054 124.662 59.4066H126.544L126.545 40.8235H124.628C124.599 40.8248 124.569 40.8256 124.539 40.8256L124.514 40.8254C124.492 40.8251 124.471 40.8245 124.45 40.8235H107.834Z"
        fill="#979797"
      />
    </svg>
  );
};
export default WalletIcon;
