import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { NoRoute } from 'src/routes/app/NoRoute';
import { LandingPageContainer } from 'src/routes/app/landing-page/LandingPageContainer';
import { SearchResultsContainer } from 'src/routes/app/search-results/SearchResultsContainer';
import { MintIdShowContainer } from 'src/routes/app/mint-id-show/MintIdShowContainer';
import { CheckoutResolver } from 'src/routes/app/checkout/CheckoutResolver';
import { ThankYou } from 'src/routes/app/checkout/ThankYou';
import { HowItWorks } from 'src/routes/app/static-pages/HowItWorks';
import { EnvironmentalPolicy } from 'src/routes/app/static-pages/EnvironmentalPolicy';
import ExchangeLayout from 'src/common/components/exchange/ExchangeLayout';
import {
  ExchangeContainer,
  ExchangeMarketStatsContainer,
  ExchangeOpenOrderContainer,
  ExchangeOrderBookContainer,
  ExchangeRealtimeContainer,
} from 'src/routes/app/exchange/ExchangeContainer';
import GlobalOrderHistory from 'src/routes/app/global-order-history/GlobalOrderHistory';
import MyOrderHistory from 'src/routes/app/my-order-history/MyOrderHistory';
import { PrivacyPolicy } from 'src/routes/app/static-pages/PrivacyPolicy';
import { TermsOfUse } from 'src/routes/app/static-pages/TermsOfUse';
import { ExchangeTermsAndConditions } from 'src/common/components/exchange/terms-conditions/ExchangeTermsAndConditions';
import { AppRoutes } from 'src/routes/app/routes';
import { ExchangeWelcome } from 'src/common/components/exchange/ExchangeWelcome';
import ExchangeMyAssets from 'src/common/components/exchange/my-assets/MyAssets';
import DashboardContainer from 'src/common/components/exchange/dashboard/DashboardContainer';
import { SellerTermsOfUse } from './static-pages/SellerTermsOfUse';
import { ReturnsContainer } from './returns/ReturnsContainer';
import { ThankYou as ReturnThankYou } from './returns/ThankYou';
import { ExchangePrivateRoute } from './exchange/ExchangePrivateRoute';
import { CategoriesContextLayout } from '../../common/context/CategoriesContext';

export const AppRouter = React.memo(() => {
  return (
    <Routes>
      <Route path={AppRoutes.NO_ROUTE} element={<NoRoute />} />
      <Route element={<CategoriesContextLayout />}>
        <Route index element={<LandingPageContainer />} />
        <Route path={AppRoutes.SEARCH_RESULTS} element={<SearchResultsContainer />} />
      </Route>
      <Route path={AppRoutes.MINT_ID_SHOW} element={<MintIdShowContainer />} />
      <Route path={AppRoutes.CHECKOUT_RESOLVER} element={<CheckoutResolver />} />
      <Route path={AppRoutes.THANK_YOU} element={<ThankYou />} />
      <Route path={AppRoutes.HOW_IT_WORKS} element={<HowItWorks />} />
      <Route path={AppRoutes.ENVIRONMENTAL_POLICY} element={<EnvironmentalPolicy />} />

      <Route path={AppRoutes.EXCHANGE} element={<ExchangeWelcome />} />

      <Route
        path={AppRoutes.EXCHANGE_ASK_ORDER}
        element={
          <ExchangePrivateRoute>
            <ExchangeContainer />
          </ExchangePrivateRoute>
        }
      />
      <Route
        path={AppRoutes.EXCHANGE_OPEN_ORDER}
        element={
          <ExchangePrivateRoute>
            <ExchangeOpenOrderContainer />
          </ExchangePrivateRoute>
        }
      />

      <Route
        path={AppRoutes.EXCHANGE_ORDER_BOOK}
        element={
          <ExchangePrivateRoute>
            <ExchangeOrderBookContainer />
          </ExchangePrivateRoute>
        }
      />
      <Route
        path={AppRoutes.EXCHANGE_REALTIME_ORDERS}
        element={
          <ExchangePrivateRoute>
            <ExchangeRealtimeContainer />
          </ExchangePrivateRoute>
        }
      />

      <Route
        path={AppRoutes.EXCHANGE_TERMS_AND_CONDITIONS}
        element={
          <ExchangePrivateRoute>
            <ExchangeTermsAndConditions />
          </ExchangePrivateRoute>
        }
      />

      <Route
        path={AppRoutes.EXCHANGE_MARKET_STATS}
        element={
          <ExchangePrivateRoute>
            <ExchangeMarketStatsContainer />
          </ExchangePrivateRoute>
        }
      />

      <Route
        path={AppRoutes.EXCHANGE_DASHBOARD}
        element={
          <ExchangePrivateRoute>
            <DashboardContainer />
          </ExchangePrivateRoute>
        }
      />
      <Route
        path={AppRoutes.EXCHANGE_MY_ASSETS}
        element={
          <ExchangePrivateRoute>
            <ExchangeLayout>
              <ExchangeMyAssets />
            </ExchangeLayout>
          </ExchangePrivateRoute>
        }
      />
      <Route
        path={AppRoutes.MY_ORDER_HISTORY}
        element={
          <ExchangePrivateRoute>
            <MyOrderHistory />
          </ExchangePrivateRoute>
        }
      />

      <Route path={AppRoutes.ORDER_HISTORY} element={<GlobalOrderHistory />} />
      <Route path={AppRoutes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path={AppRoutes.TERMS_OF_USE} element={<TermsOfUse />} />
      <Route path={AppRoutes.SELLER_TERMS_OF_USE} element={<SellerTermsOfUse />} />
      <Route path={AppRoutes.RETURNS} element={<ReturnsContainer />} />
      <Route path={AppRoutes.RETURN_CONFIRMATION} element={<ReturnThankYou />} />
    </Routes>
  );
});
