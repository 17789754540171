import { toast, ToastOptions } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { AlertWarning } from 'src/common/components/alerts/AlertWarning';

export interface AlertPayload {
  title: string;
  text?: string;
  options?: ToastOptions;
}

const defaultWarningOptions = { icon: <FontAwesomeIcon icon={faExclamationCircle} color="white" /> };

export const wrappedToast = {
  warning: ({ title, text, options = {} }: AlertPayload) => {
    const optionsWithDefault = { ...defaultWarningOptions, ...options };
    return toast.warning(<AlertWarning title={title} text={text} />, optionsWithDefault);
  },
};
