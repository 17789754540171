import React, { PropsWithChildren, useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { Inventory, NftMintIdRes } from 'src/interfaces';
import { Button } from 'src/common/interactions/Button';
import { Select } from 'src/common/components/forms/Select';
import { classNames, formatCurrencyWithoutDenomination, getDevvPayPrice } from 'src/common/util';
import ToolTip from 'src/common/components/exchange/ToolTip/ToolTip';
import { ShardsContext } from 'src/common/context/ShardsContext';
import { toast } from 'react-toastify';
import { PaymentOptions } from 'src/common/components/payment-options/PaymentOptions';
import { usePaymentsCurrencies } from 'src/common/hooks/usePaymentsCurrencies';
import { PriceHeader } from './PriceHeader';

interface PurchaseOptionTableProps {
  itemQuantity: any;
  nftItem: NftMintIdRes;
  onAddCartButtonClick: (inv: Inventory, quantitySelected: string) => void;
  setItemQuantity: (value: React.SetStateAction<Record<string, unknown>>) => void;
}

const TableHeader: React.FC<PropsWithChildren<{ header: string }>> = ({ header, children }) => {
  return (
    <th
      scope="col"
      className="sticky top-0 border-b z-10 border-gray-300 bg-gray-50 bg-opacity-75 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter px-3 py-3.5"
      key={header}
    >
      {children || header}
    </th>
  );
};

type TableCellProps = { className: string; isLastRow: boolean; cellKey: string };
const TableCell: React.FC<PropsWithChildren<TableCellProps>> = ({ children, className, cellKey, isLastRow }) => {
  return (
    <td
      className={classNames(
        !isLastRow ? '' : 'border-b border-gray-200',
        className,
        'py-4 px-3 text-sm font-medium text-gray-900 ',
      )}
      key={cellKey}
    >
      {children}
    </td>
  );
};

type PurchasePriceStringFunc = (price: number) => string;

function USDPurchasePriceStringFunc(price: number) {
  return `$${formatCurrencyWithoutDenomination(price)}`;
}

function DevvPayPurchasePriceStringFuncFactory(coinName: string, exchangeRate: number): PurchasePriceStringFunc {
  return (price: number) =>
    getDevvPayPrice({
      devvPayOption: {
        coinName,
        exchangeRate,
      },
      price,
    });
}

function purchasePriceStringFuncFactory(type: string, coinName: string, exchangeRate: number): PurchasePriceStringFunc {
  switch (type) {
    case 'USD':
      return USDPurchasePriceStringFunc;
    case 'DevvPay':
      return DevvPayPurchasePriceStringFuncFactory(coinName, exchangeRate);
    default:
      throw new Error('Type not implemented');
  }
}

export const PurchaseOptionTable = ({
  itemQuantity,
  nftItem,
  onAddCartButtonClick,
  setItemQuantity,
}: PurchaseOptionTableProps) => {
  const bundleOrSingletonTypes = ['bundle', 'litpet'];
  const isNFTBundleOrSingleton = bundleOrSingletonTypes.some((item) => nftItem.nftType.toLowerCase().includes(item));
  const { isShardDown } = useContext(ShardsContext);
  const disabledByShardDown = isShardDown(nftItem.shardUrl);
  const [purchasePriceStringFunc, setPurchasePriceStringFunc] = useState<PurchasePriceStringFunc>(
    () => USDPurchasePriceStringFunc,
  );
  const { data, hasError, isLoading } = usePaymentsCurrencies(true);

  const handleCurrencyChange = (currency: string) => {
    if (hasError) {
      toast.error('Error trying to fetch currency data.');
      return;
    }

    if (isLoading) {
      toast.warning('Currencies data is still loading');
      return;
    }

    if (data) {
      const currencyData = data.currencies.find((paymentCurrency) => paymentCurrency.name === currency);
      if (currencyData) {
        const usdExchangeRate = currencyData.usdExchangeRate || 1;
        setPurchasePriceStringFunc(() => {
          return purchasePriceStringFuncFactory(
            currencyData.isDevvPay === true ? 'DevvPay' : currencyData.id,
            currencyData.name,
            usdExchangeRate,
          );
        });
      }
    }
  };

  return (
    <div className="my-8 flex flex-col h-full">
      <div className="flex-grow overflow-y-auto shadow-sm ring-1 ring-black ring-opacity-5 h-full rounded m-1">
        <table className="table-auto w-full border-separate overflow-y-scroll" style={{ borderSpacing: 0 }}>
          <thead className="bg-gray-50 ">
            <tr>
              {!isNFTBundleOrSingleton && <TableHeader header="Mint Rank" />}

              <TableHeader header="Price">
                <PriceHeader
                  inventory={nftItem.inventory}
                  onCurrencySelect={handleCurrencyChange}
                  defaultCurrency={nftItem.saleCurrency}
                  disableSelect={isLoading}
                />
              </TableHeader>

              <TableHeader header="Seller" />

              <TableHeader header="Currency">
                <div className="flex items-center">
                  Payment Options
                  <ToolTip title="payment" className="ml-1 text-blue-400" />
                </div>
              </TableHeader>

              <TableHeader header="Add to Cart">
                <span className="sr-only">Add to Cart</span>
              </TableHeader>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white overflow-y-auto h-full">
            {nftItem.inventory.map((item, i) => {
              const quantitySelected = `quantitySelected-${item.salePrice}-${item.sellerId}`;
              const isLastRow = i === nftItem.inventory.length - 1;
              const rowKey = `row-index-${i}-rank-${item.mintRank || 1}`;
              const getCellKey = (cellName: string) => `${rowKey}-cell-${cellName}`;
              return (
                <tr key={rowKey}>
                  {!isNFTBundleOrSingleton && (
                    <TableCell {...{ className: 'whitespace-nowrap', cellKey: getCellKey('mint-rank'), isLastRow }}>
                      # {item.mintRank || 1} of {nftItem.mintTotal}
                    </TableCell>
                  )}
                  <TableCell {...{ className: 'whitespace-nowrap', cellKey: getCellKey('price'), isLastRow }}>
                    {purchasePriceStringFunc(item.salePrice)}
                  </TableCell>

                  <TableCell {...{ className: 'flex-wrap', cellKey: getCellKey('seller'), isLastRow }}>
                    <a
                      className="no-underline hover:underline"
                      href={`/search?seller=${item.sellerId}&searchOnlySellerItems=true`}
                    >
                      {item.sellerId}
                    </a>
                  </TableCell>
                  <TableCell {...{ className: 'flex-wrap', cellKey: getCellKey('currency'), isLastRow }}>
                    <PaymentOptions paymentOptions={item.paymentOptions} />
                  </TableCell>
                  <TableCell
                    {...{
                      className: 'whitespace-nowrap h-full flex justify-end',
                      cellKey: getCellKey('add-to-cart'),
                      isLastRow,
                    }}
                  >
                    <Button.Primary
                      key={`${item.mintRank}-button-checkout`}
                      type="button"
                      className="w-max gtm_addToCart col-start-1"
                      data-gtm="addToCart"
                      onClick={() => onAddCartButtonClick(item, `quantitySelected-${item.salePrice}-${item.sellerId}`)}
                      disabled={item.isLocked || item?.isBanned || disabledByShardDown}
                    >
                      Add to Cart
                      <FontAwesomeIcon className="ml-2" icon={faCartPlus} />
                    </Button.Primary>
                    {isNFTBundleOrSingleton && (
                      <Select
                        className={`border inline-block w-24 ml-2 justify-self-center mr-4 ${
                          Number(item.quantity) === 1 ? 'hidden' : ''
                        }`}
                        value={itemQuantity[quantitySelected] || 1}
                        onChange={(event) => {
                          const newItemQuantity = { ...itemQuantity };
                          newItemQuantity[quantitySelected] = event.target.value;
                          setItemQuantity(newItemQuantity);
                        }}
                      >
                        {[...Array(Number(item.quantity)).keys()].map((n) => (
                          <option value={n + 1} key={`${n}-${item.quantity}-quantity`}>
                            {n + 1}
                          </option>
                        ))}
                      </Select>
                    )}
                  </TableCell>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
