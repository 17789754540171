import { Env } from 'src/common/env';

// Your SDK settings for browser.
// Replace authorizationKey with your api browser key.
export default {
  core: {
    authorizationKey: Env.splitIoKey ?? '',
    key: '<customer-key>',
  },
  startup: {
    readyTimeout: 15, // 15 secs
  },
};

export const factoryAttributes = {
  permissions: ['read'],
};

export enum SplitFeatureName {
  ANNOUNCEMENTS = 'ANNOUNCEMENTS_SPLIT',
  SIMILAR_PRODUCTS = 'MLT_Split',
  CHECKOUT_ENABLED = 'CHECKOUT_ENABLED_SPLIT',
  CHECKOUT_PAYMENT_CARD = 'Circle_V1_Split',
  CHECKOUT_PAYMENT_DEVVPAY = 'DevvPay_V1_Split',
  EXCHANGE_ENABLED_SPLIT = 'EXCHANGE_ENABLED_SPLIT',
  BUYER_DEVVX_UX = 'BUYER_DEVVX_UX',
}
