import cloneDeep from 'lodash.clonedeep';
import { CartItemWithDiscount, DiscountType, PromoCodeData } from 'src/interfaces';

export const getOrderTotal = (nftItems: CartItemWithDiscount[]) =>
  nftItems.reduce((sum, item) => sum + item.salePrice, 0);

export const getDiscountedOrderTotal = ({
  nftItems,
  promoCodeData,
}: {
  nftItems: CartItemWithDiscount[];
  promoCodeData: PromoCodeData;
}) => {
  const totalWithoutDiscount = getOrderTotal(nftItems);
  const nftItemsCopy = cloneDeep(nftItems);
  let numberOfItemsUsedDiscount = 0;

  const calcPromoCode = {
    [DiscountType.PERCENTAGE]: (nftData: { salePrice: number; sellerId?: string; nftType?: string }) => {
      const discountedPrice =
        nftData.salePrice - parseFloat((nftData.salePrice * (promoCodeData.percentOff / 100)).toFixed(2));

      const sellersDiscount = promoCodeData.discountScope?.sellers || null;
      const nftTypesDiscount = promoCodeData.discountScope?.nftTypes || null;
      const pricesDiscount = promoCodeData.discountScope?.prices || null;

      const isSellersDiscount = nftData.sellerId && promoCodeData.discountScope?.sellers?.includes(nftData.sellerId);
      const isNftTypesDiscount = nftData.nftType && promoCodeData.discountScope?.nftTypes?.includes(nftData.nftType);
      const isPricesDiscount = pricesDiscount?.includes(nftData.salePrice);

      if (
        (isSellersDiscount && isNftTypesDiscount && isPricesDiscount) || // Applies discounts sellers, nftType and Price
        (isSellersDiscount && isNftTypesDiscount && pricesDiscount === null) || // Applies discounts sellers and nftType
        (isSellersDiscount && nftTypesDiscount === null && isPricesDiscount) || // Applies discounts sellers and price
        (isSellersDiscount && nftTypesDiscount === null && pricesDiscount === null) || // Applies discounts sellers
        (sellersDiscount === null && isNftTypesDiscount && isPricesDiscount) || // Applies discounts nftType and Price
        (sellersDiscount === null && nftTypesDiscount === null && isPricesDiscount) || // Applies discounts Price
        (sellersDiscount === null && isNftTypesDiscount && pricesDiscount === null) || // Applies discounts nftType
        Object.keys(promoCodeData.discountScope).length === 0 // Applies discounts for all
      ) {
        return discountedPrice;
      }

      return parseFloat(nftData.salePrice.toFixed(2));
    },
  };
  const orderTotal = parseFloat(
    nftItems
      .reduce((sum, item, index) => {
        const { salePrice, sellerId, nftType } = item;

        const funcSalePrice = calcPromoCode[promoCodeData.discountType];
        const discountSalePrice =
          funcSalePrice &&
          funcSalePrice({
            salePrice,
            sellerId,
            nftType,
          });

        nftItemsCopy[index].discountSalePrice = discountSalePrice;
        if (
          discountSalePrice === salePrice ||
          (promoCodeData?.numberOfItemsAvailableToUse &&
            numberOfItemsUsedDiscount >= promoCodeData.numberOfItemsAvailableToUse) // limit promo code to apply
        ) {
          delete nftItemsCopy[index].discountSalePrice;
          return sum + salePrice;
        }
        numberOfItemsUsedDiscount += 1;
        return sum + discountSalePrice;
      }, 0)
      .toFixed(2),
  );

  const totalDiscount =
    orderTotal === 0 && totalWithoutDiscount !== orderTotal ? totalWithoutDiscount : totalWithoutDiscount - orderTotal;

  return {
    nftItems: nftItemsCopy,
    orderTotal,
    totalDiscount,
    totalValue: parseFloat(orderTotal.toFixed(2)),
  };
};
