import NVD3Chart from 'react-nvd3';

interface BarChartProps {
  datum: any;
  timeFrame: any;
  staggerLabels: boolean;
  showLegend: boolean;
  showValues: boolean;
  margin: Record<string, unknown> | undefined | null;
  xLabel: string;
  yLabel: string;
  colors: Array<string>;
}
export const VolumeBarChart = (props: BarChartProps) => {
  const { datum, showLegend, timeFrame, staggerLabels, showValues, margin, colors, xLabel, yLabel } = props;

  return (
    <NVD3Chart
      xScale={(window as any).d3.time.scale().linear}
      height={200}
      duration={300}
      type="discreteBarChart"
      interpolate="linear"
      useInteractiveGuideline
      x={xLabel}
      y={yLabel}
      xAxis={{
        tickFormat: (d) => {
          return (window as any).d3.time.format(timeFrame.d3_time_format)(new Date(d));
        },
      }}
      yAxis={{
        tickFormat: (d: number) => {
          return d.toFixed();
        },
        axisLabel: 'Trading Volume',
        axisLabelDistance: 20,
      }}
      valueFormat={(d) => {
        return d && d.toFixed();
      }}
      margin={margin}
      tooltips={showValues}
      showLegend={showLegend}
      showValues={showValues}
      staggerLabels={!staggerLabels}
      strokeWidth={datum.strokeWidth}
      color={colors}
      datum={datum}
      clipEdge
    />
  );
};
