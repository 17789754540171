import React, { useContext } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { PageThemeContext } from 'src/common/context/PageThemeContext';

import { Button } from 'src/common/interactions/Button';
import { AppRoutes } from 'src/routes/app';

export const Footer = React.memo(() => {
  const { showFooter } = useContext(PageThemeContext);

  return (
    <div>
      {showFooter && (
        <footer className="bg-white" style={{ boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)' }}>
          <div className="max-w-7xl mx-auto px-4 py-2 flex md:h-16 justify-between items-center flex-col md:flex-row">
            <div className="flex items-center order-last pt-2 md:pt-0 md:order-first">
              &copy; {new Date().getFullYear()} Devvio. All Rights Reserved.
            </div>
            <div className="flex items-center flex-col md:flex-row">
              <Link to={generatePath(`/${AppRoutes.HOW_IT_WORKS}`)}>
                <Button.Link type="button" className="py-1 md:px-2">
                  How It Works
                </Button.Link>
              </Link>
              <span className="hidden md:block md:mx-4 text-blue text-lg">&bull;</span>
              <Link to={generatePath(`/${AppRoutes.ORDER_HISTORY}`)}>
                <Button.Link type="button" className="py-1 md:px-2">
                  Global Order History
                </Button.Link>
              </Link>
              <span className="hidden md:block md:mx-4 text-blue text-lg">&bull;</span>
              <Link to={generatePath(`/${AppRoutes.ENVIRONMENTAL_POLICY}`)}>
                <Button.Link type="button" className="py-1 md:px-2">
                  Environmental Policy
                </Button.Link>
              </Link>
              <span className="hidden md:block md:mx-4 text-blue text-lg">&bull;</span>
              <a href="mailto:support@glassblock.io">
                <Button.Link type="button" className="py-1 md:px-2">
                  Contact Glassblock Support
                </Button.Link>
              </a>
            </div>
            <div className="flex items-center flex-col md:flex-row">
              <Link to={generatePath(`/${AppRoutes.PRIVACY_POLICY}`)}>
                <Button.Link type="button" className="py-1 md:px-2">
                  Privacy Policy
                </Button.Link>
              </Link>
              <span className="hidden md:block md:mx-4 text-blue text-lg">&bull;</span>
              <Link to={generatePath(`/${AppRoutes.TERMS_OF_USE}`)}>
                <Button.Link type="button" className="py-1 md:px-2">
                  Terms of Use
                </Button.Link>
              </Link>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
});
